import { useTranslation } from 'react-i18next';
import IntuPage from '../../components/layout/IntuPage';
import { IntuForm } from '../../components/layout/IntuForm';
import { MerchantSettingsDashboard } from '../../components/account/merchantSettings/MerchantSettingsDashboard';

const MerchantSettings = () => {
  const { t } = useTranslation();

  return (
    <IntuPage
      pageTitle={t('pages.account.MerchantSettings.title')}
      pageDescription={t('pages.account.MerchantSettings.description')}
      hCentered
      lessMT
    >
      <MerchantSettingsDashboard />
    </IntuPage>
  );
};

export default MerchantSettings;
