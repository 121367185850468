import i18next from 'i18next';
import * as Yup from 'yup';

export const createAddNewProductValidationSchema = () => {
  const t = i18next.getFixedT(null, 'validation');
  const transTypes = i18next.getFixedT(null, 'types');

  return Yup.object({
    value: Yup.string()
      .required(
        t('attribute.value.required', { type: transTypes('attribute') }),
      )
      .min(
        3,
        t('attribute.value.min', { type: transTypes('attribute'), char: '3' }),
      ),
  });
};
