import React, { useMemo, useState } from 'react';
import useResponseHandling from '../../../../hooks/useResponseHandler';
import { useAxiosPrivate } from '../../../../hooks/axios/useAxiosPrivate';

// Mui Components
import {
  Button,
  Grid,
  Stack,
  CircularProgress,
  FormControl,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Form, Formik, useFormikContext } from 'formik';
import languages from '../../../../data/LanguagesData.json';
import ReactHtmlParser from 'react-html-parser';
import { convertFromRaw, convertToRaw } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';
import ContentTypeField from '../../fields/ContentTypeField';
import ContentCreationModeField from '../../fields/ContentCreationModeField';
import { contentCreationPluginValidation } from './validationSchema';
import KeywordsField from '../../fields/KeywordsField';
import AddresseeField from '../../fields/AddresseeField';
import { RichTextEditorField } from '../../../text/fields/RichTextEditorField';
import MultiLingualSelectionField from '../../fields/MultiLanguageSelectionField';

const ContentCreationForm = ({
  onChange: handleEditorChange,
  data,
  lang: editorLanguage,
  nodeId: sectionId,
}) => {
  // States for Loading and Progress
  const [loading, setLoading] = useState(false);

  const { values: parentFormValues, setFieldValue: setParentFieldValue } =
    useFormikContext();

  const supportedLanguages = languages
    .filter((language) => language.supported)
    .map((lang) => ({
      lang: lang.code,
      label: lang.name,
    }));

  // SetUp Axios
  const axios = useAxiosPrivate();

  // Response Handler
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();

  // Get AI Investor Pitch
  async function getAIContent({ values, temperature = 0.1 }) {
    setLoading(true);

    const addressees = values.addressee.join(', ');
    const keywords = values.keywords.join(', ');
    const languages = supportedLanguages.map((lng) => lng.label).join(', ');

    const messages = [];

    if (values.content_mode === 'create' || values.content_mode === 'tweak') {
      // Create or tweak Content
      const instruction = `${values.content_mode} a ${values.content_type} which includes a title and a body`;
      const instructionMessage = {
        role: 'system',
        content: instruction,
      };
      messages.push(instructionMessage);

      if (values.keywords.length > 0) {
        const keywordInstruction = `Make sure to include the following keywords ${keywords}.`;
        const keywordsMessage = {
          role: 'system',
          content: keywordInstruction,
        };
        messages.push(keywordsMessage);
      }

      if (values.addressee.length > 0) {
        const addresseeInstruction = `The content ${values.content_type} should address ${addressees}.`;
        const languageMessage = {
          role: 'system',
          content: addresseeInstruction,
        };
        messages.push(languageMessage);
      }
    } else {
      // Translate Conent
      const instruction = `${values.content_mode} the ${values.content_type} into ${values.language}`;
      const message = {
        role: 'system',
        content: instruction,
      };
      messages.push(message);
    }

    // Request content in JSON format for multiple languages
    let languagesInstruction;
    if (values.multilingual) {
      languagesInstruction = `Generate the ${values.content_type} in ${languages}. Return the result in a structured JSON format like this: {"en": "<content wrapped in html tags in the respective language>"}`;
    } else {
      languagesInstruction = `Generate the ${values.content_type} in ${values.language}. Return the result in a structured JSON format like this: {${values.language}: "<content wrapped in html tags in the respective language>""}`;
    }

    const languageMessage = {
      role: 'system',
      content: languagesInstruction,
    };
    messages.push(languageMessage);

    const userMessage = {
      role: 'user',
      content: values.content_text,
    };

    messages.push(userMessage);

    // Create the headline body
    let payload = {
      messages: messages,
      model: 'gpt-4o',
      temperature: temperature,
    };

    let url = '/api/ai/content-creation/create-content';

    const controller = new AbortController();
    const signal = controller.signal;
    try {
      const response = await axios.post(url, JSON.stringify(payload), {
        signal,
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });

      const data = response?.data;

      if (response.status === 200) {
        // Trim and Convert Content
        let messages = data.data[0];
        let recommendation = messages.message.content;

        return recommendation;
      } else {
        handleRegularResponse({
          open: true,
          status: data.status,
          message: data.message,
        });
      }
    } catch (err) {
      handleErrorResponse(err);
    } finally {
      controller.abort();
      setLoading(false);
    }
  }

  // Convert HTML to Draft.js ContentState
  const contentState = stateFromHTML(data?.content);

  // Initial values calculation outside the render function
  const initialValues = useMemo(
    () => ({
      addressee: data?.addressee || [],
      content: data?.content,
      content_rte: JSON.stringify(
        convertToRaw(stateFromHTML(data?.content || ' ')),
      ),
      content_text: contentState.getPlainText() || '',
      content_type: data?.content_type || '',
      content_mode: data?.content_mode || '',
      keywords: data.keywords || [],
      language: editorLanguage,
      multilingual: false,
    }),
    [editorLanguage],
  );

  // Function to update content for the specific cell
  function createTranslations(newContent) {
    // Create a deep copy of parentFormValues to avoid mutating the original state
    const updatedFormValues = { ...parentFormValues };

    // Traverse the rows to find the cell with the given ID
    for (let row of updatedFormValues.content.rows) {
      for (let cell of row.cells) {
        // Check if the cell has nested rows
        if (cell.rows) {
          for (let nestedRow of cell.rows) {
            for (let nestedCell of nestedRow.cells) {
              // If the cell ID matches the one we're looking for
              if (nestedCell.id === sectionId) {
                // Update the dataI18n content for each language
                for (let lang in newContent) {
                  if (newContent.hasOwnProperty(lang)) {
                    // If dataI18n does not exist, initialize it
                    if (!nestedCell.dataI18n) {
                      nestedCell.dataI18n = {};
                    }
                    nestedCell.dataI18n[lang] = {
                      content: newContent[lang],
                    };
                  }
                }
                break; // Exit once the cell is updated
              }
            }
          }
        }
      }
    }

    // Use setFieldValue to update the entire parent form values
    setParentFieldValue('content', updatedFormValues.content);
  }

  async function handleGenerateContent(values, setFieldValue) {
    let temperature;
    if (values.content_mode === 'translate') {
      temperature = 0;
    } else {
      temperature = 0.6;
    }

    const content = await getAIContent({ values, temperature });

    try {
      // Step 1: Parse the JSON stringified content
      const parsedContent = JSON.parse(content);

      // Step 2: Extract content for the specific editorLanguage
      const languageContent = parsedContent[editorLanguage]; // Access specific language content

      if (!languageContent) {
        console.error(
          `Content for language ${editorLanguage} is not available.`,
        );
        return;
      }

      // Step 3: Continue with existing logic
      setFieldValue('content', languageContent); // Set the language-specific content

      handleEditorChange({
        ...values,
        content: languageContent, // Use language-specific content
      });

      // Step 1: Convert the response HTML into a ContentState
      const contentState = stateFromHTML(languageContent);
      // console.log('Content State -> ', contentState);

      // Step 2: Convert the ContentState into the raw JSON format for mui-rte
      const rawContent = JSON.stringify(convertToRaw(contentState));
      // console.log('Raw content -> ', rawContent);

      // Step 3: Extract plain text from ContentState
      const plainText = contentState.getPlainText();
      // console.log('Plain Text -> ', plainText);

      // Set the HTML content as raw content for mui-rte
      setFieldValue('content_rte', rawContent); // mui-rte formatted value

      // Set the plain text content
      setFieldValue('content_text', plainText); // Plain text without HTML tags

      // Create or Update Translations
      if (values.multilingual) {
        const newContent = {
          ...parsedContent,
          default: parsedContent[editorLanguage],
        };

        return createTranslations(newContent);
      }
    } catch (error) {
      console.error('Error parsing JSON content:', error);
    }
  }

  function getLabelByLang(langCode) {
    const languageObject = supportedLanguages.find(
      (language) => language.lang === langCode,
    );
    return languageObject ? languageObject.label : null;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={contentCreationPluginValidation}
      validateOnMount={true}
      validateOnChange={true}
      enableReinitialize={true}
    >
      {(formik) => {
        const { values, setFieldValue } = formik;
        return (
          <Form role="form">
            <FormControl fullWidth>
              <Grid container spacing={2}>
                <Grid item xs={12} sx={{ marginTop: '20px' }}>
                  <Stack direction="column" spacing={2}>
                    {/* Mode */}
                    <ContentCreationModeField
                      required
                      onChange={(e) => {
                        setFieldValue('content_mode', e.target.value);
                        handleEditorChange({
                          ...values,
                          content_mode: e.target.value,
                        });
                      }}
                    />

                    {/* Content Type */}
                    <ContentTypeField
                      required
                      transition={values?.content_mode !== 'translate'}
                      color="secondary"
                      sx={{}}
                      onChange={(e) => {
                        setFieldValue('content_type', e.target.value);
                        handleEditorChange({
                          ...values,
                          content_type: e.target.value,
                        });
                      }}
                    />

                    {/* content */}
                    <RichTextEditorField
                      required
                      fieldID="content_text"
                      editorID="content_rte"
                      controls={[
                        'title',
                        'bold',
                        'italic',
                        'underline',
                        'fontColor',
                        'numberList',
                        'bulletList',
                        'quote',
                      ]}
                      transNS="fields"
                      transPrefix="RichTextEditorField"
                      defaultValue={JSON.stringify(values.content_rte)}
                      onChange={async (e) => {
                        const content = await e.getCurrentContent();
                        const rteContent = convertToRaw(content);

                        // Convert raw content to a Draft.js ContentState
                        const contentState = convertFromRaw(rteContent);

                        // Get plain text from ContentState
                        const plainText = contentState.getPlainText();

                        // Convert ContentState to HTML
                        const html = stateToHTML(contentState);

                        handleEditorChange({
                          ...values,
                          content: html,
                        });
                        setFieldValue('content_text', plainText);
                      }}
                      inlineToolbar={true}
                    />

                    {/* Select Multilingual Type */}
                    <MultiLingualSelectionField
                      variant="standard"
                      lng={getLabelByLang(editorLanguage)}
                      onChange={(e) => {
                        setFieldValue('multilingual', e.target.value);
                        handleEditorChange({
                          ...values,
                          multilingual: e.target.value,
                        });
                      }}
                    />

                    {values?.content_mode !== 'translate' && (
                      <>
                        {/* keywords */}
                        <KeywordsField
                          required
                          onChange={(e, options) => {
                            setFieldValue('keywords', options);
                            handleEditorChange({
                              ...values,
                              keywords: options,
                            });
                          }}
                        />

                        {/* Addressee */}
                        <AddresseeField
                          required
                          onChange={(e, options) => {
                            setFieldValue('addressee', options);
                            handleEditorChange({
                              ...values,
                              addressee: options,
                            });
                          }}
                        />
                      </>
                    )}

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        handleGenerateContent(values, setFieldValue)
                      }
                      disabled={loading || !formik.isValid}
                      startIcon={
                        loading ? <CircularProgress size={20} /> : null
                      }
                    >
                      {loading ? `Creating Content...` : 'Create Content'}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </FormControl>
          </Form>
        );
      }}
    </Formik>
  );
};

const contentCreationPlugin = {
  Renderer: (props) => {
    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            {ReactHtmlParser(props.data.content)}
          </Grid>
        </Grid>
      </>
    );
  },
  // Renderer: () => {},
  id: 'aiContent',
  title: 'AI Generated Content',
  description: 'Use a little magic to create or tweak your content',
  icon: <EditIcon />,
  version: 1,
  controls: {
    type: 'custom',
    Component: (props) => <ContentCreationForm {...props} />,
  },
};

export default contentCreationPlugin;
