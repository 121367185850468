import { Box, Link, Typography } from '@mui/material';
import { useAxiosPrivate } from '../../hooks/axios/useAxiosPrivate';
import { useEffect, useState } from 'react';

const CheckoutSuccessPage = () => {
  const axios = useAxiosPrivate();
  const [receiptUrl, setReceiptUrl] = useState(null);

  const getReceiptUrl = async () => {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      const paymentIntentId = queryParams.get('payment');
      const orderId = queryParams.get('order');
      const userId = queryParams.get('user');
      const email = queryParams.get('email');

      const payload = {
        orderId,
        userId,
        email,
        paymentIntentId,
      };

      const { data, status } = await axios.post(
        `/api/checkout/success`,
        payload,
      );
      // console.log('back from /checkout/success', data, status);
      if (status === 201) {
        setReceiptUrl(data.data);
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  useEffect(() => {
    getReceiptUrl();
  }, []);

  return (
    <Box
      width="60%"
      margin="40px auto 0"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Typography variant="h1">Thank you for your Order</Typography>
      <Typography variant="body1" align="center" mb={2}>
        In order to track and manage your order, we strongly recommend you to
        create an account with us. Otherwise, we will reach out to your email
        with any updates about your order.
      </Typography>
      {receiptUrl && (
        <Link href={receiptUrl} target="_blank" rel="noopener noreferrer">
          View your receipt
        </Link>
      )}
    </Box>
  );
};

export default CheckoutSuccessPage;
