import * as Yup from 'yup';
import i18next from 'i18next';

import blockedDomainList from '../../data/BlockedDomains.json';

let blockedDomains = [];
if (
  process.env.REACT_APP_ENV !== 'development' &&
  process.env.REACT_APP_ENV !== 'staging'
) {
  blockedDomains = blockedDomainList;
}

export const createCheckoutPageValidationSchema = () => {
  const t = i18next.getFixedT(null, 'validation');
  const transTypes = i18next.getFixedT(null, 'types');

  return Yup.object().shape({
    billingAddress: Yup.object({
      line1: Yup.string()
        .min(5, t('short', { type: transTypes('billing_address'), char: 5 }))
        .required(t('address.line1_required')),
      city: Yup.string()
        .min(3, t('short', { type: transTypes('billing_city'), char: 3 }))
        .required(t('address.city_required')),
      state: Yup.string()
        .min(2, t('short', { type: transTypes('billing_state'), char: 2 }))
        .required(t('address.state_required')),
      postcode: Yup.string()
        .min(3, t('short', { type: transTypes('billing_postcode'), char: 3 }))
        .required(t('address.postcode_required')),
      country: Yup.object({
        label: Yup.string().required(t('country.required')),
        dialCode: Yup.number(t('country.dial_code.invalid'))
          .required(t('country.dial_code.required'))
          .typeError(t('country.dial_code.error')),
        code: Yup.string()
          .required(t('country.code.required'))
          .min(2, t('country.code.min')),
      }),
    }),
    shippingAddress: Yup.object({
      line1: Yup.string()
        .min(5, t('short', { type: transTypes('shipping_address'), char: 5 }))
        .required(t('address.line1_required')),
      city: Yup.string()
        .min(3, t('short', { type: transTypes('shipping_city'), char: 3 }))
        .required(t('address.city_required')),
      state: Yup.string()
        .min(2, t('short', { type: transTypes('shipping_state'), char: 2 }))
        .required(t('address.state_required')),
      postcode: Yup.string()
        .min(3, t('short', { type: transTypes('shipping_postcode'), char: 3 }))
        .required(t('address.postcode_required')),
      country: Yup.object({
        label: Yup.string().required(t('country.required')),
        dialCode: Yup.number(t('country.dial_code.invalid'))
          .required(t('country.dial_code.required'))
          .typeError(t('country.dial_code.error')),
        code: Yup.string()
          .required(t('country.code.required'))
          .min(2, t('country.code.min')),
      }),
    }),
    billingIndividual: Yup.object({
      first_name: Yup.string()
        .min(2, t('short', { type: transTypes('first_name'), char: 2 }))
        .max(50, t('long', { type: transTypes('first_name'), char: 50 }))
        .matches(/^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/, t('name.first_required'))
        .required(t('name.first_required')),
      last_name: Yup.string()
        .min(2, t('short', { type: transTypes('last_name'), char: 2 }))
        .max(50, t('long', { type: transTypes('last_name'), char: 50 }))
        .matches(/^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/, t('name.last_matches'))
        .required(t('name.last_required')),
      email: Yup.string()
        .required(t('email.user_required'))
        .matches(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          t('email.invalid'),
        )
        .test(
          'is-not-burner-email',
          t('email.burner_blocked'), // Example: 'Burner email addresses are not allowed'
          (value) => {
            if (!value) return true; // Skip validation for empty values
            const domain = value.split('@')[1]?.toLowerCase();
            return !blockedDomains.includes(domain);
          },
        ),
      phone: Yup.object({
        value: Yup.string().required(t('phone.required')),
        info: Yup.object({
          countryCallingCode: Yup.number(t('phone.country_code.invalid'))
            .required(t('phone.country_code.required'))
            .typeError(t('phone.country_code.error')),
          nationalNumber: Yup.string()
            .required(t('phone.required'))
            .min(8, t('phone.min'))
            .max(15, t('phone.max')),
        }),
      }),
    }),
    shippingIndividual: Yup.object({
      first_name: Yup.string()
        .min(2, t('short', { type: transTypes('first_name'), char: 2 }))
        .max(50, t('long', { type: transTypes('first_name'), char: 50 }))
        .matches(/^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/, t('name.first_required'))
        .required(t('name.first_required')),
      last_name: Yup.string()
        .min(2, t('short', { type: transTypes('last_name'), char: 2 }))
        .max(50, t('long', { type: transTypes('last_name'), char: 50 }))
        .matches(/^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/, t('name.last_matches'))
        .required(t('name.last_required')),
      email: Yup.string()
        .required(t('email.user_required'))
        .matches(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          t('email.invalid'),
        )
        .test(
          'is-not-burner-email',
          t('email.burner_blocked'), // Example: 'Burner email addresses are not allowed'
          (value) => {
            if (!value) return true; // Skip validation for empty values
            const domain = value.split('@')[1]?.toLowerCase();
            return !blockedDomains.includes(domain);
          },
        ),
      phone: Yup.object({
        value: Yup.string().required(t('phone.required')),
        info: Yup.object({
          countryCallingCode: Yup.number(t('phone.country_code.invalid'))
            .required(t('phone.country_code.required'))
            .typeError(t('phone.country_code.error')),
          nationalNumber: Yup.string()
            .required(t('phone.required'))
            .min(8, t('phone.min'))
            .max(15, t('phone.max')),
        }),
      }),
    }),
  });
};
