import * as Yup from 'yup';
import i18next from 'i18next';
import blockedDomainList from '../../../../data/BlockedDomains.json';

// List of blocked burner email domains
let blockedDomains = [];
if (
  process.env.REACT_APP_ENV !== 'development' &&
  process.env.REACT_APP_ENV !== 'staging'
) {
  blockedDomains = blockedDomainList;
}

export const createPasswordResetRequestValidationSchema = () => {
  const t = i18next.getFixedT(null, 'validation');

  return Yup.object().shape({
    email: Yup.string()
      .required(t('email.user_required'))
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        t('email.invalid'),
      )
      .test(
        'is-not-burner-email',
        t('email.burner_blocked'), // Example: 'Burner email addresses are not allowed'
        (value) => {
          if (!value) return true; // Skip validation for empty values
          const domain = value.split('@')[1]?.toLowerCase();
          return !blockedDomains.includes(domain);
        },
      ),
  });
};

export const createPasswordResetConfirmValidationSchema = () => {
  const t = i18next.getFixedT(null, 'validation');

  return Yup.object().shape({
    userPass: Yup.string()
      .required(t('password.required'))
      .min(8, t('password.short'))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/,
        t('password.pattern'),
      ),
    userPassConf: Yup.string()
      .required(t('password.comf_required'))
      .oneOf([Yup.ref('userPass'), null], t('password.comf_match')),
  });
};
