import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAxiosPrivate } from '../../hooks/axios/useAxiosPrivate';

const TarifBox = () => {
  const axios = useAxiosPrivate();
  const { t: transFields } = useTranslation('fields');
  const { values, setValues, errors, touched } = useFormikContext();
  const [tarifs, setTarifs] = useState([]);
  const [selectedTarif, setSelectedTarif] = useState({
    htsno: values.tarif_code ?? '',
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { value } = e.target;

    if (value.length === 4) {
      getTarifData(value);
    }
  };

  const getTarifData = async (code) => {
    setIsLoading(true);
    try {
      const payload = {
        tarif_code: code,
      };
      const { data, status } = await axios.post(`/api/tarifs`, payload);
      if (status === 200) {
        setTarifs([...data.data]);
      }
    } catch (error) {
      console.error('error', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTarifSelect = (newTarif) => {
    setSelectedTarif(newTarif);
    setValues({
      ...values,
      tarif_code: newTarif ? newTarif.htsno : '',
    });
  };

  return (
    <Box mt={2}>
      <FormControl sx={{ flex: 0.4 }}>
        <Typography mb={2}>{transFields('products.tarif_code')}</Typography>
        <Autocomplete
          options={tarifs}
          noOptionsText="Enter the first 4 digits of your tarif code"
          getOptionLabel={(option) => {
            const { htsno = '', description = '' } = option;
            return [htsno, description].filter(Boolean).join(': ');
          }}
          onChange={(e, newValue) => handleTarifSelect(newValue)}
          value={selectedTarif}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Tarif Code"
              error={errors.tax_code && touched.tax_code}
              helperText={touched.tax_code && errors.tax_code}
              name="tarif_code_input"
              onChange={handleInputChange}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    {isLoading ? <CircularProgress size={20} /> : null}
                  </InputAdornment>
                ),
              }}
            />
          )}
          sx={{ minWidth: 460 }}
        />
      </FormControl>
    </Box>
  );
};

export default TarifBox;
