// import { useAxiosPrivate } from "../hooks/axios/useAxiosPrivate";
import axios from 'axios';
import { axiosPrivate } from '../../hooks/axios/axios.js';

import i18n from '../../i18n.js';

// List Campaign Offers
async function listReferralOffers() {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/referrals/offers/list';

  try {
    const { data } = await axiosPrivate.get(url, { signal });

    return data;
  } catch (err) {
    return err;
  }
}

// Create A New Referral Campaign
async function createNewReferralCampaign({ payload = {} }) {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/referrals/campaigns/create';

  // Guard Clause
  const req_fields = [];
  !payload && req_fields.push(i18n.t('routes.req_fields.payload'));

  if (req_fields.length > 0) {
    throw new Error(
      i18n.t('validation.missing_fields', { missingFields: req_fields }),
    );
  }

  // console.log('Payload -> ', payload);

  try {
    const { data } = await axiosPrivate.post(url, payload, {
      signal,
      headers: { 'Content-Type': 'application/json' },
    });

    return data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

// Get User Referrals List
async function listReferralCampaigns() {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/referrals/campaigns/list';

  try {
    const { data } = await axiosPrivate.get(url, {
      signal,
      headers: { 'Content-Type': 'application/json' },
    });

    return data;
  } catch (err) {
    console.error(err);
    return err;
  }
}
// Get Referral Campaign Details (AUTHENTICATED USERS)
async function getCampaignDetailsforAuthenticatedUsers({ id }) {
  if (!id) {
    console.error('Campaign ID missing');
    return;
  }

  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/referrals/campaigns/details';

  try {
    const { data } = await axiosPrivate.get(url, {
      signal,
      params: { _id: id },
      headers: { 'Content-Type': 'application/json' },
    });

    return data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

// Get Referral Campaign Details (AUTHENTICATED USERS)
async function getPublicCampaignDetails({ id }) {
  if (!id) {
    console.error('Campaign ID missing');
    return;
  }

  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/referrals/campaigns/public/details';

  try {
    const { data } = await axios.get(url, {
      signal,
      params: { _id: id },
      headers: { 'Content-Type': 'application/json' },
    });

    return data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export {
  createNewReferralCampaign,
  listReferralCampaigns,
  listReferralOffers,
  getCampaignDetailsforAuthenticatedUsers,
  getPublicCampaignDetails,
};
