import i18next from 'i18next';
import * as Yup from 'yup';
import {
  measurementOptions,
  weightOptions,
} from '../../../../data/MeasruementUnits';

export const createAddNewProductValueValidationSchema = ({
  selectedAttributeType,
}) => {
  const t = i18next.getFixedT(null, 'validation');
  const transTypes = i18next.getFixedT(null, 'types');

  const isWeight = weightOptions.filter(
    (w) => w === selectedAttributeType?.data_keys,
  );
  const isMeasurement = measurementOptions.filter(
    (m) => m === selectedAttributeType?.data_keys,
  );

  if (isWeight.length) {
    // Weight Validation
    // console.log('Validating Weight -> ', weightOptions);
    return Yup.object({
      unit: Yup.string()
        .oneOf(
          weightOptions,
          t('attribute.unit.required', { type: transTypes('weight') }),
        )
        .required(
          t('attribute.value.required', { type: transTypes('weight') }),
        ),
      value: Yup.number()
        .positive(t('attribute.value.positive', { type: transTypes('weight') }))
        .required(
          t('attribute.value.required', { type: transTypes('weight') }),
        ),
    });
  } else if (isMeasurement.length) {
    // console.log('Validating Measurement -> ', measurementOptions);
    // Measurement Validation
    return Yup.object({
      unit: Yup.string()
        .oneOf(
          measurementOptions,
          t('attribute.unit.required', { type: transTypes('measurement') }),
        )
        .required(
          t('attribute.value.required', { type: transTypes('measurement') }),
        ),
      value: Yup.number()
        .positive(
          t('attribute.value.positive', { type: transTypes('measurement') }),
        )
        .required(
          t('attribute.value.required', { type: transTypes('measurement') }),
        ),
    });
  } else {
    // Regular Validation
    return Yup.object({
      value: Yup.string().required(
        t('attribute.value.required', { type: transTypes('attribute') }),
      ),
    });
  }
};
