import { axiosPrivate } from '../../hooks/axios/axios';

// Get Exchange spot Rate
async function getExchangeSpotRate({
  base = null, // ISO-3 Currency Code e.g. USD, EUR
  date = null, // ISO Formatteed Date YYYYY-MM-DD
}) {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/open-exchange-rates/rates/spotrate';

  try {
    const params = {
      base: base,
      date: date,
    };

    const response = await axiosPrivate.get(url, {
      signal,
      params,
      headers: { 'Content-Type': 'application/json' },
    });

    const { data, status } = response;

    if (status) {
      return data;
    }
  } catch (error) {
    console.error('error', error);
    return error;
  }
}

export { getExchangeSpotRate };
