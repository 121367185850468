import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
} from '@mui/material';
import { useContext, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyProfileContext } from '../../../context/company/CompanyProfileProvider';
import { createInitialValuesNewCompanyForm } from './InitialValues';
import useUser from '../../../hooks/useUser';
import useAuth from '../../../hooks/useAuth';
import { createNewCompanyFormValidationSchema } from './ValidationSchema';
import { Formik } from 'formik';
import { NewCompanyForm } from './NewCompanyForm';
import { TermsOfServiceProvider } from '../../../context/providers/TermsOfServicecontextProvider';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NewCompanyFormDialog = ({ info }) => {
  const { t } = useTranslation('dialogs', {
    keyPrefix: 'NewCompanyForm',
  });

  const { t: transButtons } = useTranslation('buttons');

  const { newCompanyDialog, setNewCompanyDialog } = useContext(
    CompanyProfileContext,
  );

  const { user } = useUser();
  const { auth } = useAuth();

  const initialValues = createInitialValuesNewCompanyForm({ user, auth, info });
  const validationSchema = createNewCompanyFormValidationSchema();

  const handleClose = () => {
    setNewCompanyDialog(false);
  };

  return (
    <TermsOfServiceProvider>
      <Dialog
        open={newCompanyDialog}
        fullScreen
        TransitionComponent={Transition}
        onClose={handleClose}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, validateForm }) => {
            setSubmitting(true);
            // validateForm(true);

            // handle form submission
            // setSubmitting(false);
            // handleClose();

            //
            // -> Go into SignUp complete form and write new products, new brands, new application to database and create the new company

            console.log('Values', values);
          }}
        >
          {({ handleSubmit, isValid }) => (
            <form onSubmit={handleSubmit}>
              <DialogTitle>{t('title')}</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <NewCompanyForm />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Grid container>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={handleClose}
                    >
                      {transButtons('cancel_type')}
                    </Button>
                  </Grid>

                  <Grid item sx={{ marginLeft: 'auto' }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!isValid}
                    >
                      {transButtons('submit')}
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </TermsOfServiceProvider>
  );
};

export { NewCompanyFormDialog };
