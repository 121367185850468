import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import {
  AccountBalance,
  AutoFixHigh,
  Error,
  Info,
  PlayArrow,
  RotateLeft,
  RotateRight,
  SaveAlt,
  Stop,
  Upload,
  Visibility,
  VisibilityOff,
  Warning,
} from '@mui/icons-material';

const IntuIconButton = ({
  type = 'edit', // Define Button type e.g. add, edit, delete
  tooltipTitle = '', // Defines the Tooltip Label and/or Description
  tooltipType = '', // Pass in the type For further customization - used for translations
  size = 'small', // Defines the size of the Icon Button
  variant = 'contained', // Whether the button is of type outlined or contained
  iconOnly = false, // NEW PROPERTY: Simplifies logic for icon-only styling
  disabled = false, // Whether the Icon Button is disabled or not
  onClick = () => {}, // Function
  IconComponent = null, // Custom Icon Component
  isLoading = false, // If the button is loading
  sx = {},
  ...props
}) => {
  const theme = useTheme();
  const { t: transButtons } = useTranslation('buttons');
  const { t: transTypes } = useTranslation('types');
  const tooltip = transButtons(`${tooltipTitle}`, {
    type: transTypes(`${tooltipType}`),
  });

  const iconProps = {
    fontSize: size,
    sx: {
      color: iconOnly
        ? theme.palette[type]?.main
        : theme.palette[type]?.contrastText,
      ...sx,
    },
  };

  let iconElement;
  if (IconComponent) {
    iconElement = <IconComponent {...iconProps} />;
  } else {
    switch (type) {
      case 'add':
        iconElement = <AddIcon {...iconProps} />;
        break;
      case 'update':
        iconElement = <SaveAlt {...iconProps} />;
        break;
      case 'edit':
        iconElement = <EditIcon {...iconProps} />;
        break;
      case 'upload':
        iconElement = <Upload {...iconProps} />;
        break;
      case 'payout':
        iconElement = <AccountBalance {...iconProps} />;
        break;
      case 'reset':
        iconElement = <RotateLeft {...iconProps} />;
        break;
      case 'proceed':
        iconElement = <RotateRight {...iconProps} />;
        break;
      case 'warning':
        iconElement = <Warning {...iconProps} />;
        break;
      case 'connect':
        iconElement = <SettingsEthernetIcon {...iconProps} />;
        break;
      case 'cancel':
        iconElement = <CloseIcon {...iconProps} />;
        break;
      case 'delete':
        iconElement = <DeleteOutlineIcon {...iconProps} />;
        break;
      case 'error':
        iconElement = <Error {...iconProps} />;
        break;
      case 'info':
        iconElement = <Info {...iconProps} />;
        break;
      case 'extract':
        iconElement = <DocumentScannerIcon {...iconProps} />;
        break;
      case 'submit':
      case 'success':
        iconElement = <CheckIcon {...iconProps} />;
        break;
      case 'assistant':
        iconElement = <AutoFixHigh {...iconProps} />;
        break;
      case 'ar_on':
        iconElement = <Visibility {...iconProps} />;
        break;
      case 'ar_off':
        iconElement = <VisibilityOff {...iconProps} />;
        break;
      case 'play':
        iconElement = <PlayArrow {...iconProps} />;
        break;
      case 'stop':
        iconElement = <Stop {...iconProps} />;
        break;
      default:
        return null;
    }
  }

  return (
    <Tooltip title={!disabled ? tooltip : null} placement="top-start">
      <span>
        <IconButton
          {...props}
          disabled={disabled || isLoading}
          sx={{
            borderRadius: '50%',
            border: iconOnly
              ? 'none'
              : variant === 'outlined'
                ? '1px solid'
                : 'none',
            color: iconOnly
              ? theme.palette[type]?.main
              : variant === 'contained'
                ? theme.palette[type]?.contrastText
                : theme.palette[type]?.main,
            backgroundColor: iconOnly
              ? 'transparent'
              : variant === 'contained'
                ? theme.palette[type]?.main
                : 'none',
            borderColor: iconOnly ? 'none' : theme.palette[type]?.main,
            '& .MuiSvgIcon-root': {
              color: iconOnly
                ? theme.palette[type]?.dark
                : variant === 'contained'
                  ? theme.palette[type]?.contrastText
                  : theme.palette[type]?.main,
            },
            '&:disabled': {
              backgroundColor: iconOnly ? 'none' : theme.palette.disabled?.main,
              borderColor: iconOnly ? 'none' : theme.palette.disabled?.dark,
              '& .MuiSvgIcon-root': {
                color: iconOnly
                  ? theme.palette.disabled?.dark
                  : theme.palette.disabled?.dark,
              },
            },
            '&:hover': {
              backgroundColor: iconOnly
                ? 'transparent'
                : theme.palette[type]?.dark,
              borderColor: iconOnly ? 'none' : theme.palette[type]?.dark,
              '& .MuiSvgIcon-root': {
                color: iconOnly
                  ? theme.palette[type]?.dark
                  : theme.palette[type]?.contrastText,
              },
            },
            ...sx,
          }}
          onClick={onClick}
        >
          {isLoading ? <CircularProgress size={20} /> : iconElement}
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default IntuIconButton;
