import { useContext } from 'react';
import { Box } from '@mui/material';
import { CartContext } from '../../context/cart/CartContext';
import ManufacturerShippingBox from './ManufacturerShippingBox';
import IntutecShippingBox from './IntutecShippingBox';

const OrderShippingContainer = () => {
  const { manufacturers } = useContext(CartContext);

  // Get all products stocked with intutec
  let intutecProducts = [];
  Array.from(manufacturers).forEach(([key, value]) => {
    Object.keys(value.products).forEach((productKey) => {
      const product = value.products[productKey];
      if (product.product.fullfillment?.intutec) {
        intutecProducts.push(product);
      }
    });
  });

  return (
    <Box mt={2}>
      <>
        {manufacturers.size > 0
          ? Array.from(manufacturers).map(([key, value]) => (
              <ManufacturerShippingBox key={key} manufacturer={value} />
            ))
          : null}

        {intutecProducts.length > 0 ? (
          <IntutecShippingBox intutecProducts={intutecProducts} />
        ) : null}
      </>
    </Box>
  );
};
export default OrderShippingContainer;
