import { Box } from '@mui/material';
import ManufacturersOrdersList from './ManufacturersOrdersList';

const ManufacturersOrdersDashboard = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      px={4}
      margin="0 auto"
      width="100%"
    >
      <Box width="100%">
        <ManufacturersOrdersList />
      </Box>
    </Box>
  );
};

export default ManufacturersOrdersDashboard;
