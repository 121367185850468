import { Checkbox, FormControlLabel } from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';

const DifferentAddressCheckbox = ({
  isDifferentAddress,
  setIsDifferentAddress,
}) => {
  const { values, setValues, setTouched } = useFormikContext();

  useEffect(() => {
    if (isDifferentAddress) {
      setValues({
        ...values,
        shippingIndividual: values.shippingIndividual,
        shippingAddress: values.shippingAddress,
      });

      setTouched({
        shippingIndividual: true,
        shippingAddress: true,
      });
    }
  }, [isDifferentAddress]);

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={isDifferentAddress}
          onChange={(e) => setIsDifferentAddress(e.target.checked)}
          name="differentAddress"
          color="primary"
        />
      }
      label="Ship to a different address?"
    />
  );
};

export default DifferentAddressCheckbox;
