import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Stack,
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { reindexCommissionSchedule } from './reindexCommissionSchedule';
import PercentageField from '../fields/number/PercentField';
import AmountField from '../fields/number/AmountField';
import { toTitleCase } from '../../helpers/toTitleCase';
import IntuIconButton from '../buttons/IntuIconButton';
import { useContext } from 'react';
import { CommissionBoxContext } from './ComissionBox';

const AddCommissionDialog = ({
  isOpen, // State to open-close dialog true / false
  handleConfirm = (c) => {}, // Callback to handle confirm Action
  handleClose = (c) => {}, // Callback to handle close Action
}) => {
  const { t } = useTranslation();
  const { t: transButtons } = useTranslation('buttons');
  const { t: transTypes } = useTranslation('types');

  const formik = useFormikContext(); // Full Formik object
  const { values, isValid, setValues, resetForm } = formik;
  const { calculateCommissions, calculateCosts, calculateEffectiveCommission } =
    useContext(CommissionBoxContext);

  const handleConfirmClick = () => {
    const existingCommissionSchedule = values.commission_schedule;
    const newCommission = values.commission;
    const exampleRevenue = values.example.revenue;
    const index = values.commission_schedule.length || 0;

    const newCommissionSchedule = [
      ...existingCommissionSchedule,
      {
        ...newCommission,
        index: index,
      },
    ];

    const reIndexedCommissionSchedule = reindexCommissionSchedule(
      newCommissionSchedule,
    );

    const newExampleCommmissionShedule = calculateCommissions(
      reIndexedCommissionSchedule,
      exampleRevenue,
    );

    if (newExampleCommmissionShedule) {
      const newExampleCost = calculateCosts(newExampleCommmissionShedule);
      const effectiveCommission = calculateEffectiveCommission(
        newExampleCost,
        exampleRevenue,
      );

      // Update Values
      setValues({
        ...values,
        commission_schedule: newExampleCommmissionShedule,
        example: {
          revenue: exampleRevenue,
          commission: newExampleCost,
          effective_commission: effectiveCommission,
        },
      });
    }
    // Execute callback function
    handleConfirm({
      newCommission,
      newCommissionSchedule: newExampleCommmissionShedule,
    });
  };

  const handleCloseClick = () => {
    resetForm();
    handleClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>{t('components.commission.add')}</DialogTitle>
      <DialogContent style={{ paddingTop: '20px' }}>
        <FormControl fullWidth>
          <Stack spacing={2}>
            {/* Commission Amount */}
            <AmountField
              fieldID="commission.max_amount"
              transNS="fields"
              transPrefix="AmountField"
              transTypeID="max_commission_bracket"
              inputAdornment="$"
              thousandSeparator={true}
              allowNegative={false}
              fixedDecimalScale
              decimalScale={0}
            />

            {/* Commission Percentage */}
            <PercentageField
              fieldID="commission.commission"
              transNS="fields"
              transPrefix="PercentageField"
              transTypeID="commission"
              allowNegative={false}
              fixedDecimalScale
              decimalScale={2}
              inputAdornment="%"
            />
          </Stack>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ paddingBottom: '16px' }}>
        <Grid container sx={{ margin: '1rem 1rem 0 1rem' }}>
          <Grid item>
            {/* Cancel */}
            <IntuIconButton
              variant="outlined"
              type="cancel"
              color="error"
              tooltipTitle={transButtons('cancel')}
              onClick={handleCloseClick}
            />
          </Grid>
          <Grid item sx={{ marginLeft: 'auto' }}>
            <IntuIconButton
              tooltipTitle={transButtons('add', {
                type: toTitleCase(transTypes('commission')),
              })}
              onClick={() => handleConfirmClick()}
              color="primary"
              type="submit"
              disabled={!isValid}
              variant="contained"
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default AddCommissionDialog;
