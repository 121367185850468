import { useFormikContext } from 'formik';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputAdornment, Skeleton, TextField } from '@mui/material';
import axios from '../../../hooks/axios/useAxios';
import { getNestedValue } from '../../../helpers/getNestedValuesIn';
import ProcessingContext from '../../../context/providers/ProcessingProvider';
import FormikFieldWrapper from '../../formik/FormikFieldWrapper';
import { searchProducts } from '../../../api/products/productsRoutes.js';
import useResponseHandling from '../../../hooks/useResponseHandler';

const ProductMPNField = ({
  fieldTitle, // Title above the field
  fieldDescription, // Field description above the field
  fieldID = 'mpn', // Determines the Name and IDD of the Field
  transNS = 'fields', // Namespace for translation Value
  transPrefix = 'ProductMPNField', // KeyPrefix which holds label and placeholder
  transition = true, // Whether the field is being shown and faded in
  disabled = false, // Diables the field
  required = false, // Whether the field is required or not
  variant = 'outlined',
  isLoadingManual = false, // Set Loading State Manually
  showInitialErrors = false, // Show Errors even when the field has not been touched
  inputAdornment = null, // sign at the end of the field e.g. $, %
  ...props
}) => {
  const { t: transFields } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });
  const { t: transValidation } = useTranslation('validation');
  const { handleErrorResponse } = useResponseHandling();

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    isSubmitting,
    setValues,
  } = useFormikContext();

  const [hasDuplicateMPN, setHasDuplicateMPN] = useState(false);

  const handleMPNChange = async (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });

    handleChange(e);
  };

  const handleMPNBlur = async (e) => {
    const { value } = e.target;

    console.log('Handle Blur');

    try {
      const query = {
        mpn: value,
      };

      console.log('Search for MPN -> ', query);

      const { data, statusCode } = await searchProducts(query);

      console.log('Status Code -> ', statusCode);
      console.log('Found MPNS -> ', data);

      if (statusCode === 200 && data.length > 0) {
        console.log('MPN Exists');
        const [found] = data;
        console.log('Item Details -> ', found);
        if (found._id !== values._id) {
          setHasDuplicateMPN(true);
          return;
        }
      }
    } catch (error) {
      handleErrorResponse(error);
    }

    setHasDuplicateMPN(false);
    handleBlur(e);
  };

  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  const { isLoading } = useContext(ProcessingContext);

  return isLoading.status || isLoadingManual ? (
    <Skeleton variant="rectangular" width={'100%'} height={60} />
  ) : (
    <FormikFieldWrapper
      fieldTitle={fieldTitle}
      fieldDescription={fieldDescription}
      transition={transition}
    >
      <TextField
        id={fieldID}
        name={fieldID}
        label={transFields('label_alt')}
        placeholder={transFields('placeholder')}
        type="text"
        variant="outlined"
        onChange={(e) => handleMPNChange(e)}
        onBlur={(e) => {
          handleMPNBlur(e);
        }}
        error={(fieldError && fieldTouched) || hasDuplicateMPN}
        helperText={
          hasDuplicateMPN
            ? transValidation('product.mpn_duplicate')
            : fieldError && fieldTouched
              ? fieldError
              : ''
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{inputAdornment}</InputAdornment>
          ),
          sx: {
            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
              {
                WebkitAppearance: 'none',
                margin: 0,
              },
            '& input[type=number]': {
              MozAppearance: 'textfield',
            },
          },
        }}
        value={fieldValue}
        {...props}
      />
    </FormikFieldWrapper>
  );
};

export default ProductMPNField;
