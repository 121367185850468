import { Box, FormControl, MenuItem, Select, useTheme } from '@mui/material';

const staffpickOptions = [
  { value: true, label: 'Staff Pick' },
  { value: false, label: 'Regular' },
];

const StaffPickBox = ({ formik, setProductDetails, showField = false }) => {
  const { values, setFieldValue, errors, touched } = formik;
  const theme = useTheme();

  const handleChangeClick = (e) => {
    const { name, value } = e.target;

    // handleChange(e);
    setFieldValue(name, value);
    setProductDetails(value);
  };

  return showField ? (
    <Box>
      <FormControl sx={{ flex: 0.4 }}>
        <Select
          onChange={handleChangeClick}
          name="staffpick"
          defaultValue={staffpickOptions[0].value}
          value={values.staffpick}
          sx={{
            minWidth: 120,
            height: 30,
            color: 'common.white',
            backgroundColor: 'transparent',
            border: `1px solid ${
              values.staffpick === true
                ? theme.palette.primary.main
                : theme.palette.red.main
            }`,
            borderRadius: '8px',
            '& .MuiSelect-icon': { color: 'white' },
            '&:hover': {
              backgroundColor: theme.palette.secondary.main,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          }}
        >
          {staffpickOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  ) : null;
};

export default StaffPickBox;
