import { useState, useEffect, useRef, useContext } from 'react';
import { useAnalyticsEventTracker } from '../../../../hooks/useAnalyticsTracker.jsx';
import { useNavigate } from 'react-router-dom';
import axios from '../../../../hooks/axios/useAxios.js';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';

// Materail UI
import { Grid, Stack, FormControl, TextField, Button } from '@mui/material';
import { classes } from '../../../../settings/theme.js';

// Response & Loading Handlers
import useResponseHandling from '../../../../hooks/useResponseHandler.js';
import { useProcessingHandler } from '../../../../hooks/useProcessingHandler.js';

// Translator
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n.js';
import { FormBodyContext } from '../../../../context/layout/FormContextProvider.jsx';
import AuthContext from '../../../../context/auth/AuthProvider.jsx';
import { LayoutBodyContext } from '../../../../context/layout/LayoutContextProvider.jsx';
import {
  createInitialValuesResetConfirm,
  createInitialValuesResetRequest,
} from './initialValues.js';
import {
  createPasswordResetConfirmValidationSchema,
  createPasswordResetRequestValidationSchema,
} from './validationSchema.js';

import bcrypt from 'bcryptjs';

// FORM CONTENT
const ResetChangePasswordForm = () => {
  // Google Event Tracker
  const { gaEventTracker } = useAnalyticsEventTracker();

  // Context
  const { setShowForm, formType, formButton } = useContext(FormBodyContext);
  const { setLayoutTitle, setLayoutDescription } =
    useContext(LayoutBodyContext);
  const { auth, setAuth } = useContext(AuthContext);

  // SALT should be created ONE TIME upon sign up
  const salt = bcrypt.genSaltSync(10);

  // Response & Process Handling
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const { setIsLoading } = useProcessingHandler();

  const navigate = useNavigate();
  const host_url = window.location.host;

  const [userID, setUserID] = useState('');
  const [userOTP, setUserOTP] = useState('');

  // Translator
  const { t } = useTranslation();

  // Setup Initial Values for Form Validation

  // Set Up From refs
  const userRef = useRef();
  const passRef = useRef();
  const formRef = useRef(null);

  const [initialValues, setInitialValues] = useState(
    formType === 'accPassChange'
      ? createInitialValuesResetConfirm()
      : createInitialValuesResetRequest(),
  );
  const [formSet, setFormSet] = useState(false);

  const [validationSchema, setValidationSchema] = useState(null);

  useEffect(() => {
    if (formType === 'accPassChange') {
      setInitialValues(createInitialValuesResetConfirm());
      setValidationSchema(createPasswordResetConfirmValidationSchema());
    } else {
      setInitialValues(createInitialValuesResetRequest());
      setValidationSchema(createPasswordResetRequestValidationSchema());
    }

    return () => setFormSet(true);
  }, [formType, i18n.language]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const userID = params.get('userID');
    const userOTP = params.get('userOTP');

    if (formType === 'accPassChange') {
      if (userID) {
        setUserID(userID);
      }
      if (userOTP) {
        setUserOTP(userOTP);
      }
    } else {
    }
  }, [formType, i18n.language]);

  //handle form submission process
  async function submitForm(values, actions) {
    //reset password
    if (formType === 'accPassReset') {
      const email = values.email;

      const url = `/api/users/account/reset/password/request`;
      const payload = {
        email,
        host_url: host_url,
      };
      try {
        setIsLoading({
          status: true,
          type: 'spinner',
          text: t('context.account.resetPasswordContext.loading'),
        });
        const request = await axios.post(url, payload, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        const response = request?.data;

        if (response) {
          //Handle Response
          if (request.status === 200) {
            // password resetted
            gaEventTracker({
              category: 'Account Management',
              action: 'Event',
              label: 'Password Reset',
            });

            // Update Form Body
            setLayoutTitle(
              t('context.account.resetPasswordContext.response.success.title'),
            );
            setLayoutDescription(response.message);
            setShowForm(false);

            // Trigger Status Bar
            handleRegularResponse({
              open: true,
              status: response.status,
              message: response.message,
            });
          } else if (request.status === 230) {
            // user is pending
            actions.resetForm();
            navigate(response.navigate_to, { replace: true });
            handleRegularResponse({
              open: true,
              status: response.status,
              message: response.message,
            });
          } else if (request.status === 231) {
            actions.resetForm();
            handleRegularResponse({
              open: true,
              status: 'error',
              message: response.message,
            });
          } else {
            // all other responses
            handleRegularResponse({
              open: true,
              status: 'error',
              message: response.message,
            });
          }
        }
      } catch (err) {
        handleErrorResponse(err);
      } finally {
        setIsLoading({ status: false, type: '', text: '' });
      }
    } else if (formType === 'accPassChange') {
      const id = userID;
      const password = bcrypt.hashSync(values.userPass, salt);
      const otp = userOTP;

      const url = '/api/users/account/reset/password/confirm';
      const payload = {
        _id: id,
        host_url: host_url,
        new_password: password,
        otp: otp,
      };

      try {
        setIsLoading({
          status: true,
          type: 'spinner',
          text: t('context.account.changePasswordContext.loading'),
        });
        const request = await axios.post(url, payload);

        const { data, status } = request;

        // reset successful
        if (status === 200) {
          const { user_info, auth_info, navigate_to } = data;

          // Password Reset Successfull
          gaEventTracker({
            category: 'Account Management',
            action: 'Event',
            label: 'User Logged In',
          });
          let image = '';

          // Set Profile Picture
          if (user_info?.profile_picture?.mimetype) {
            image = `data:${user_info.profile_picture.mimetype};base64,${user_info.profile_picture.buffer.toString('base64')}`;
          }

          // Set User Auth State
          setAuth({
            auth_info,
            user_info: {
              ...user_info,
              profile_picture_view_url: image,
            },
          });

          // Navigate to user dashboard
          navigate(navigate_to, { replace: true });
        } else if (status === 246) {
          const { message, status } = data;

          // Link Expired
          gaEventTracker({
            category: 'Account Management',
            action: 'Event',
            label: 'Password Reset',
          });

          // Update Form Body
          setLayoutTitle(
            t('context.account.resetPasswordContext.response.success.title'),
          );
          setLayoutDescription(message);
          setShowForm(false);

          // Trigger Status Bar
          handleRegularResponse({
            open: true,
            status: status,
            message: message,
          });
        } else {
          const { message, status } = data;

          // all other responses
          handleRegularResponse({
            open: true,
            status: status,
            message: message,
          });
        }
      } catch (err) {
        handleErrorResponse(err);
      } finally {
        setIsLoading({ status: false, type: '', text: '' });
      }
    }
  }

  return (
    formSet && (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => submitForm(values, actions)}
        validateOnMount={true}
        validateOnChange={true}
        enableReinitialize={true}
        innerRef={formRef}
      >
        {(formik) => {
          const {
            values,
            setFieldValue,
            handleSubmit,
            errors,
            touched,
            handleBlur,
            isValid,
          } = formik;

          return (
            <Form
              className="form-horizontal"
              role="form"
              onSubmit={handleSubmit}
            >
              <FormControl fullWidth>
                <Stack spacing={4} sx={{ paddingBottom: '50px' }}>
                  <Stack spacing={4} style={classes.root}>
                    {/* Email address & Phone Number */}
                    <>
                      {formType === 'accPassReset' && (
                        <TextField
                          required
                          id="email"
                          name="email"
                          ref={userRef}
                          autoComplete="off"
                          className="form-select-field"
                          aria-invalid={errors.email ? 'true' : 'false'}
                          aria-describedby="uidnote"
                          variant="outlined"
                          label={t('form.account.password.reset.email.label')}
                          type="text"
                          placeholder={t(
                            'form.account.password.reset.email.placeholder',
                          )}
                          onChange={(e) => {
                            setFieldValue(e.target.name, e.target.value);
                          }}
                          inputProps={{
                            autoComplete: 'off',
                          }}
                          onBlur={handleBlur}
                          value={values?.email}
                          error={errors?.email && touched?.email ? true : false}
                          helperText={
                            errors?.email && touched?.email
                              ? errors?.email
                              : null
                          }
                        />
                      )}
                    </>
                  </Stack>

                  {/* User Password and Confirmation */}
                  <Stack
                    spacing={3}
                    direction={{ xs: 'column' }}
                    style={classes.root}
                  >
                    {formType === 'accPassChange' && (
                      <>
                        {/* Password */}
                        <TextField
                          required
                          ref={passRef}
                          style={classes.root}
                          id="userPass"
                          name="userPass"
                          className="form-select-field"
                          aria-invalid={errors.userPass ? 'true' : 'false'}
                          aria-describedby="uidnote"
                          variant="outlined"
                          label={t(
                            'form.account.password.change.password.label',
                          )}
                          type="password"
                          placeholder={t(
                            'form.account.password.change.password.placeholder',
                          )}
                          onChange={(e) => {
                            setFieldValue(e.target.name, e.target.value);
                          }}
                          inputProps={{
                            autoComplete: 'off',
                          }}
                          onBlur={handleBlur}
                          value={values.userPass}
                          error={
                            errors.userPass && touched.userPass ? true : false
                          }
                          helperText={
                            errors.userPass && touched.userPass
                              ? errors.userPass
                              : null
                          }
                        />

                        {/* Password Confirm */}
                        <TextField
                          required
                          id="userPassConf"
                          name="userPassConf"
                          className="form-select-field"
                          aria-invalid={errors.userPassConf ? 'true' : 'false'}
                          aria-describedby="uidnote"
                          variant="outlined"
                          label={t(
                            'form.account.password.change.passwordConf.label',
                          )}
                          type="password"
                          placeholder={t(
                            'form.account.password.change.passwordConf.placeholder',
                          )}
                          onChange={(e) => {
                            setFieldValue(e.target.name, e.target.value);
                          }}
                          inputProps={{
                            autoComplete: 'off',
                          }}
                          onBlur={handleBlur}
                          value={values.userPassConf}
                          error={
                            errors.userPassConf && touched.userPassConf
                              ? true
                              : false
                          }
                          helperText={
                            errors.userPassConf && touched.userPassConf
                              ? errors.userPassConf
                              : null
                          }
                        />
                      </>
                    )}
                  </Stack>
                </Stack>

                {/* CTA  */}
                <Grid
                  container
                  spacing={1}
                  direction={{ xs: 'column', sm: 'row' }}
                  style={classes.root}
                  sx={{ paddingBottom: '50px' }}
                >
                  <Grid item sx={{ marginLeft: 'auto' }}>
                    <Button
                      variant="contained"
                      color={
                        formType === 'accPassChange' ? 'submit' : 'warning'
                      }
                      type="submit"
                      disabled={!isValid}
                    >
                      {formButton}
                    </Button>
                  </Grid>
                </Grid>
              </FormControl>
            </Form>
          );
        }}
      </Formik>
    )
  );
};

export default ResetChangePasswordForm;
