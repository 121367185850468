import {
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import {
  ArrowBackIosNew,
  ArrowForwardIos,
  KeyboardDoubleArrowDown,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import Carousel from 'react-material-ui-carousel';
import { CarouselItem } from '../../mui/carousel/Carouseltem.jsx';
import { getFeaturedProducts } from '../../../api/products/productsRoutes.js';
import IntuThemeContext from '../../../context/providers/IntuThemeProvider.jsx';
import { motion } from 'framer-motion';
import i18n from 'i18next';
import { translateMultiLineText } from '../../../api/translation/google.js';
import { useNavigate } from 'react-router-dom';
import IntuIconButton from '../../buttons/IntuIconButton.jsx';

const ProductHeroContainer = () => {
  const { t: transButtons } = useTranslation('buttons');

  const { mobileView } = useContext(IntuThemeContext);
  const theme = useTheme();
  const navigate = useNavigate();

  const [products, setProducts] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isModelInteracting, setIsModelInteracting] = useState(false);
  const [wireframeMode, setWireframeMode] = useState(false);

  const [productDescription, setProductDescription] = useState([]);

  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  const fetchProductDescription = async ({ product }) => {
    if (!product || !product.descriptions) {
      setProductDescription([]);
      return;
    }

    const findLocalDescription = product?.descriptions.find(
      (description) => i18n.language === description.code,
    );

    if (findLocalDescription?.description) {
      setProductDescription(findLocalDescription?.description?.blocks || []);
    } else if (product.descriptions[0]?.description?.blocks) {
      try {
        const standardDescriptionText =
          product.descriptions[0].description.blocks.map((block) => block.text);

        const payload = {
          inputText: standardDescriptionText,
          targetLanguage: i18n.language,
        };

        const getTranslation = await translateMultiLineText(payload);
        const { data, statusCode } = getTranslation;

        if (statusCode === 200) {
          const translatedBlocks = data.map((text, index) => ({
            key: `${index}`,
            text,
            type:
              product.descriptions[0].description.blocks[index].type ||
              'unordered-list-item',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
          }));

          setProductDescription(translatedBlocks);
        } else {
          setProductDescription(
            product.descriptions[0]?.description?.blocks || [],
          );
        }
      } catch (error) {
        setProductDescription(
          product.descriptions[0]?.description?.blocks || [],
        );
      }
    }
  };

  const renderProductDescription = (blocks, productId) => {
    if (!blocks || blocks.length === 0) return null;

    // Combine all text blocks into a single string
    const combinedText = blocks.map((block) => block.text).join(' ');

    // Extract the first 200 characters as an excerpt
    const excerpt =
      combinedText.length > 100
        ? `${combinedText.slice(0, 120)}...`
        : combinedText;

    return (
      <Box
        sx={{
          position: 'absolute',
          bottom: '35%', // 👈 move this higher as needed
          left: '5%',
          backgroundColor: theme.palette.secondary.main,
          padding: '10px 20px',
          borderRadius: '5px',
          zIndex: 2,
          maxWidth: '60%',
        }}
      >
        <Typography
          variant="body1"
          sx={{
            color: theme.palette.secondary.contrastText,
            display: 'inline',
          }}
        >
          {excerpt}
        </Typography>
        {combinedText.length >= 120 && (
          <Typography
            component="a"
            href={`/product/${productId}`}
            variant="body1"
            sx={{
              color: theme.palette.primary.main,
              textDecoration: 'none',
              marginLeft: '5px',
              cursor: 'pointer',
            }}
          >
            {transButtons('more')}
          </Typography>
        )}
      </Box>
    );
  };

  useEffect(() => {
    getFeaturedProducts({ limit: 20, page: 1 })
      .then((res) => {
        const productData = res.data;

        const featuredProducts = productData.reduce((acc, product) => {
          if (Array.isArray(product.images)) {
            const gltfImage = product.images.find(
              (img) => img.imageType?.toLowerCase() === 'gltf',
            );

            if (gltfImage) {
              acc.push({
                image: gltfImage,
                title: product.product_name,
                _id: product._id,
                productData: product, // Store the original product data for description fetching
              });
            }
          }
          return acc;
        }, []);

        setProducts(featuredProducts);

        // Fetch Descriptions for All Products After Setting State
        featuredProducts.forEach(async (product) => {
          const description = await fetchProductDescription({
            product: product.productData,
          });
          setProducts((prevProducts) =>
            prevProducts.map((item) =>
              item._id === product._id ? { ...item, description } : item,
            ),
          );
        });
      })
      .catch((err) => console.warn(err));
  }, [i18n.language]);

  const [isHovered, setIsHovered] = useState(false);

  // Handler to stop autoplay when hovered
  const handleMouseEnter = () => setIsHovered(true);

  // Handler to resume autoplay when not hovered
  const handleMouseLeave = () => setIsHovered(false);

  // Set WireFrame Animation
  useEffect(() => {
    if (isHovered) return; // pause wireframe toggle on hover

    const interval = setInterval(() => {
      setWireframeMode((prev) => !prev);
    }, 6000); // toggle every 3 seconds

    return () => clearInterval(interval); // cleanup
  }, [isHovered]);

  return (
    <Box
      flexDirection="column"
      justifyContent="space-between"
      padding="1rem 0 1rem 0"
      textAlign="center"
      sx={{
        width: '100%',
        height: '100vh',
        alignContent: 'center',
      }}
    >
      {/* Main Header Content */}
      <Box
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{ width: '100vw', height: '100vh' }}
      >
        <Carousel
          sx={{
            top: 0,
            flex: 1,
            width: '100vw',
            height: '100vh',
            position: 'relative',
            backgroundColor: 'grey.main',
          }}
          index={activeIndex}
          animation="slide"
          duration={180}
          autoPlay={!isHovered && !isModelInteracting ? true : false}
          interval={15000}
          swipe={mobileView && !isModelInteracting}
          indicators={false}
          navButtonsAlwaysVisible={!isSmall && products.length > 1}
          NextIcon={
            !isSmall ? (
              <ArrowForwardIos
                sx={{ color: (theme) => theme?.palette?.tertiary.contrastText }}
              />
            ) : null
          }
          PrevIcon={
            !isSmall ? (
              <ArrowBackIosNew
                sx={{ color: (theme) => theme?.palette?.tertiary.contrastText }}
              />
            ) : null
          }
          navButtonsProps={{
            style: {
              display: products.length > 1 ? 'inline-flex' : 'none',
            },
          }}
          onChange={(now) => setActiveIndex(now)}
          onMouseEnter={handleMouseEnter} // Stop autoplay on hover
          onMouseLeave={handleMouseLeave} // Resume autoplay when not hovered
        >
          {/* Carouse Content */}
          {products.map((product, i) => (
            <Box
              key={i}
              sx={{ position: 'relative', width: '100%', height: '100vh' }}
            >
              {/* Gray Overlay */}
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.3)', // Less opacity to make GLTF viewable
                  zIndex: 1,
                  pointerEvents: 'none', // Allows interaction with underlying GLTF files
                }}
              />

              {/* Image Rendered Below Overlay */}
              <CarouselItem
                key={i}
                image={product.image}
                arrows={isSmall ? false : true}
                showSliders={false}
                viewMode="view"
                style={{
                  height: '100vh',
                  width: '100%',
                  objectFit: 'cover',
                }}
                setIsModelInteracting={setIsModelInteracting}
                isModelInteracting={isModelInteracting}
                wireframeMode={wireframeMode}
                setWireframeMode={setWireframeMode}
                initialCenter={true}
                autoRotate={true}
                autoRotateSpeed={0.5}
              />

              {/* Product Title Box - More to the Left */}
              <motion.div
                initial={{ y: -100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  type: 'spring',
                  stiffness: 60,
                  damping: 15,
                }}
              >
                {/* Product Title */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: '10%',
                    left: '5%',
                    backgroundColor: theme.palette.primary.main,
                    paddingLeft: '40px',
                    paddingRight: '40px',
                    paddingTop: '10px',
                    paddingBottom: 0,
                    borderRadius: '10px',
                    zIndex: 2,
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{ color: theme.palette.primary.contrastText }}
                  >
                    {product.title}
                  </Typography>
                </Box>
              </motion.div>

              {/* Product Description */}
              {!isSmall ? (
                <motion.div
                  initial={{ y: 100, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{
                    type: 'spring',
                    stiffness: 60,
                    damping: 15,
                    delay: 0.5, // Adds a slight delay for smoother chaining
                  }}
                >
                  {productDescription &&
                    renderProductDescription(productDescription, product._id)}
                </motion.div>
              ) : null}

              {/* AR Button */}
              <motion.div
                initial={{ x: 100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{
                  type: 'spring',
                  stiffness: 80,
                  damping: 20,
                  delay: 1,
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '10%',
                    transform: 'translateY(-50%)',
                    zIndex: 2,
                  }}
                >
                  <IntuIconButton
                    type={wireframeMode ? 'ar_off' : 'ar_on'}
                    tooltipTitle={
                      wireframeMode ? 'disable_wireframe' : 'enable_wireframe'
                    }
                    onClick={() => setWireframeMode((prev) => !prev)}
                  />
                </Box>
              </motion.div>

              {/* View Product Button & Scroll Down Button */}
              <motion.div
                initial={{ x: 100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{
                  type: 'spring',
                  stiffness: 80,
                  damping: 20,
                  delay: 1,
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: '5%',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{
                      padding: '15px 30px',
                      fontSize: '1rem',
                      backgroundColor:
                        theme?.palette?.primary?.main || '#1976d2',
                      '&:hover': {
                        backgroundColor:
                          theme?.palette?.primary?.dark || '#1565c0',
                      },
                    }}
                    onClick={() => navigate(`/product/${product._id}`)}
                  >
                    {transButtons('view_product')}
                  </Button>

                  {/* Two flashing arrows below */}
                  <Box
                    sx={{
                      marginTop: '1rem',
                      cursor: 'pointer',
                      animation: 'bounce 1.5s infinite',
                      '@keyframes bounce': {
                        '0%, 100%': {
                          transform: 'translateY(0)',
                          opacity: 1,
                        },
                        '50%': {
                          transform: 'translateY(10px)',
                          opacity: 0.5,
                        },
                      },
                    }}
                    onClick={() => {
                      const target = document.getElementById('homeContent');
                      if (target) {
                        target.scrollIntoView({ behavior: 'smooth' });
                      }
                    }}
                  >
                    <KeyboardDoubleArrowDown
                      sx={{
                        fontSize: '3rem',
                        color: 'white',
                      }}
                    />
                  </Box>
                </Box>
              </motion.div>
            </Box>
          ))}
        </Carousel>
      </Box>
    </Box>
  );
};

export default ProductHeroContainer;
