import { useTheme } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Initialize Stripe with the publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const ElementsContainer = ({
  paymentIntentSecret,
  children,
  stripeAccount,
}) => {
  const theme = useTheme();
  const appearance = {
    theme: 'none',
    variables: {
      fontFamily: theme.typography.fontFamily,
      fontSizeBase: theme.typography.body1.fontSize,
      fontWeight: theme.typography.fontWeightRegular,
      colorPrimary: theme.palette.primary.main,
      colorText: theme.palette.text.primary,
      colorDanger: theme.palette.error.main,
      borderRadius: theme.shape.borderRadius,
      spacingUnit: theme.spacing(1),
      colorBackground: theme.palette.background.default,
    },
    rules: {
      '.Input': {
        color: theme.palette.text.primary,
        border: `2px solid ${theme.palette.secondary.light}`,
        fontSize: theme.typography.body1.fontSize,
        padding: theme.spacing(2),
        borderRadius: '4px',
        backgroundColor: theme.palette.background.default,
      },
      '.Input:hover': {
        borderColor: theme.palette.primary.light,
      },
      '.Input:focus': {
        borderColor: theme.palette.primary.main,
        outline: 'none',
      },
      '.Label': {
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: theme.typography.body2.fontSize,
        color: theme.palette.text.secondary,
      },
      '.Error': {
        color: theme.palette.error.main,
      },
      '.Input:disabled': {
        backgroundColor: theme.palette.action.disabledBackground,
        color: theme.palette.text.disabled,
      },
    },
  };

  const options = {
    clientSecret: paymentIntentSecret,
    appearance,
    ...(stripeAccount && { stripeAccount }),
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  );
};

export default ElementsContainer;
