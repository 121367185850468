import {
  Typography,
  Box,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
} from '@mui/material';
import { useFormikContext } from 'formik';
import AmountField from '../fields/number/AmountField.jsx';
import { useTranslation } from 'react-i18next';
import { IntuRichTextEditor } from '../IntuRichTextEditor/IntuRichtTextEditor.js';
import { convertToRaw } from 'draft-js';

import LanguagesData from '../../data/LanguagesData.json';
import { useContext, useMemo, useState } from 'react';
import IntuIconButton from '../buttons/IntuIconButton.jsx';
import { Translate } from '@mui/icons-material';
import { ManageProductContext } from '../../pages/products/NewProductPage.jsx';
import useResponseHandling from '../../hooks/useResponseHandler.js';
import { translateMultiLineText } from '../../api/translation/google.js';
import { getRawContentState } from '../../helpers/muiRichtTextEditor.js';

const languageOptions = LanguagesData.filter((language) => language.supported);

const WarrantyTermsComponent = () => {
  const { setIsSaving } = useContext(ManageProductContext);
  const { values, setValues } = useFormikContext();
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.WarrantyTermsComponent',
  });
  const { t: transFields } = useTranslation('fields');
  const { t: transButtons } = useTranslation('buttons');
  const { t: transMessages } = useTranslation('messages');
  const { t: transTypes } = useTranslation('types');

  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();

  const [selectedLanguage, setSelectedLanguage] = useState({
    name: 'English',
    code: 'en',
  });

  const [localWarrantyTerms, setLocalWarrantyTerms] = useState(null);
  const [isTranslateLoading, setIsTranslateLoading] = useState(false);

  const handleRteChange = (event) => {
    const rteContent = convertToRaw(event.getCurrentContent());
    setLocalWarrantyTerms(rteContent);
  };

  const handleRteBlur = () => {
    if (!localWarrantyTerms) return;

    setValues(() => {
      // Add new language Term if it doesnt exist
      const found = values.tow?.terms.find(
        (d) => d.code === selectedLanguage.code,
      );

      if (!found) {
        const newTerm = {
          name: selectedLanguage.name,
          code: selectedLanguage.code,
          term: localWarrantyTerms,
        };

        return {
          ...values,
          tow: {
            ...values?.tow,
            terms: [...values?.tow?.terms, newTerm],
          },
        };
      }

      // Else update existing
      const newWarrantyTerm = values?.tow?.terms.map((term) => {
        if (term.code === selectedLanguage.code) {
          return {
            ...term,
            term: localWarrantyTerms,
          };
        }
        return term;
      });

      return {
        ...values,
        tow: {
          ...values?.tow,
          terms: newWarrantyTerm,
        },
      };
    });
  };

  const handleRteSave = (e) => {
    const parsed = JSON.parse(e);
    setValues(() => {
      // Add new language term if it doesnt exist
      const found = values?.tow?.terms.find(
        (d) => d.code === selectedLanguage.code,
      );

      if (!found) {
        const newTerm = {
          name: selectedLanguage.name,
          code: selectedLanguage.code,
          term: parsed,
        };

        return {
          ...values,
          tow: {
            ...values?.tow,
            terms: [...values?.tow?.terms, newTerm],
          },
        };
      }

      // Else update existing
      const newWarrantyTerms = values?.tow?.terms.map((term) => {
        if (term.code === selectedLanguage.code) {
          return {
            ...term,
            term: parsed,
          };
        }
        return term;
      });

      return {
        ...values,
        tow: {
          ...values?.tow,
          terms: newWarrantyTerms,
        },
      };
    });
  };

  const selectedTerm = useMemo(() => {
    return (
      values?.tow?.terms?.find((d) => d.code === selectedLanguage.code) || {
        name: selectedLanguage.name,
        code: selectedLanguage.code,
        term: getRawContentState(''),
      }
    );
  }, [selectedLanguage, values?.tow?.terms]);

  const handleTranslate = async () => {
    if (!languageOptions.length || !selectedTerm.term) return;

    const newTerms = [selectedTerm];

    setIsTranslateLoading(true);
    setIsSaving(true);

    const languages = languageOptions.filter(
      (option) => option.code !== selectedTerm?.code,
    );

    const languagesNice = languageOptions
      .filter((option) => option.code !== selectedTerm?.code)
      .map((option) => option.name);

    const messages = selectedTerm.term.blocks.map((block) => block.text);

    try {
      for (let i = 0; i < languages.length; i++) {
        const language = languages[i];

        const payload = {
          inputText: messages,
          targetLanguage: language.code,
        };

        const getTranslation = await translateMultiLineText(payload);
        const { data, statusCode } = getTranslation;

        if (statusCode === 200) {
          // Map translated text back into blocks
          const translatedBlocks = data.map((text, index) => ({
            key: `${index}`,
            text: text,
            type: selectedTerm.term.blocks[index].type || 'unordered-list-item',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
          }));

          const rawContentState = {
            blocks: translatedBlocks,
            entityMap: {},
          };

          // Construct the Item
          const newTerm = {
            name: language.name,
            code: language.code,
            term: rawContentState,
          };

          newTerms.push(newTerm);
        }
      }

      setValues({
        ...values,
        tow: {
          ...values?.tow,
          terms: newTerms,
        },
      });

      handleRegularResponse({
        open: true,
        status: 'success',
        statusCode: '200',
        message: transMessages('translate.200', { languages: languagesNice }),
      });
    } catch (error) {
      handleErrorResponse(error);
    } finally {
      setIsTranslateLoading(false);
      setIsSaving(false);
    }
  };

  return (
    <Box
      my={2}
      display="flex"
      flexDirection="column"
      gap={4}
      sx={{ mb: '2rem' }}
    >
      {/* Coverage in Month */}
      <Box sx={{ mb: '2rem' }}>
        <Typography variant="h6">{t('fields.duration.title')}</Typography>

        <AmountField
          required
          fieldID="tow.duration"
          transPrefix="WarrantyInMonth"
          transTypeID="duration"
          inputAdornment={transTypes('month')}
          decimalScale={0}
        />
      </Box>

      {/* Warranty Terms */}
      <Box sx={{ mb: '2rem' }}>
        <Typography variant="h6">{t('fields.terms.title')}</Typography>

        <FormControl sx={{ width: '200px' }}>
          <InputLabel id="language-select">
            {transFields('language')}
          </InputLabel>
          <Select
            label="Language"
            labelId="language-select"
            value={selectedLanguage.code}
            name="language"
            defaultValue="en"
            sx={{ mb: 2 }}
          >
            {languageOptions.map((language) => {
              return (
                <MenuItem
                  key={language.code}
                  value={language.code}
                  onClick={() => setSelectedLanguage(language)}
                >
                  {language.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <IntuRichTextEditor
          id="tow.terms"
          name="tow.terms"
          required={true}
          disabled={false}
          controls={[
            'title',
            'bold',
            'italic',
            'underline',
            'fontColor',
            'highlight',
            'undo',
            'redo',
            'numberList',
            'bulletList',
            'quote',
            'clear',
            'save',
          ]}
          className="form-select-field"
          aria-describedby="uidnote"
          variant="standard"
          label={transFields('WarrantyTerms.label')}
          placeholder={transFields('WarrantyTerms.placholder')}
          defaultValue={
            selectedTerm?.term ? JSON.stringify(selectedTerm.term) : null
          }
          onChange={handleRteChange}
          onBlur={handleRteBlur}
          onSave={handleRteSave}
          inlineToolbar={true}
        />
        <Box display="flex" justifyContent="flex-end" mt={2} gap={1}>
          <IntuIconButton
            type="info"
            tooltipTitle={transButtons('translate')}
            variant="outlined"
            onClick={handleTranslate}
            isLoading={isTranslateLoading}
            disabled={!selectedTerm.term}
            IconComponent={Translate}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default WarrantyTermsComponent;
