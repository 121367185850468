import { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

const NavigationFooterContext = createContext();

export const NavigationFooterProvider = ({ children }) => {
  const { t: transButtons } = useTranslation('buttons');
  const [footerConfig, setFooterConfig] = useState({
    confirmLabel: transButtons('confirm'),
    backLabel: transButtons('back'),
    ConfirmIcon: null,
    BackIcon: null,
    onConfirm: () => {},
    isLoading: false,
    isDisabled: false,
    showFooter: false,
    showConfirm: false,
    onBack: null,
  });

  return (
    <NavigationFooterContext.Provider value={{ footerConfig, setFooterConfig }}>
      {children}
    </NavigationFooterContext.Provider>
  );
};

export const useNavigationFooter = () => {
  const context = useContext(NavigationFooterContext);
  if (!context) {
    throw new Error(
      'useNavigationFooter must be used within a NavigationFooterProvider',
    );
  }
  return context;
};
