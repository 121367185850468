import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ResponseContext from '../../context/providers/ResponseProvider';
import IntuIconButton from '../buttons/IntuIconButton';

const ErrorDialog = () => {
  const { errorDialog, setErrorDialog } = useContext(ResponseContext);

  // Translator
  const { t } = useTranslation();

  // Handle Error Dialog
  function handleConfirmError() {
    setErrorDialog({ open: false, title: '', message: '' });
  }

  return (
    <Dialog
      open={errorDialog.open}
      onClose={handleConfirmError}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{errorDialog.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {errorDialog.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ paddingY: 2, paddingX: 3 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingY: 2,
            width: '100%',
          }}
        >
          <IntuIconButton
            variant="contained"
            type="cancel"
            color="error"
            onClick={handleConfirmError}
            autoFocus
            tooltipTitle={t('buttons.close')}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog;
