import { axiosPrivate } from '../../hooks/axios/axios';
import i18n from 'i18next';

const translateSingleText = async ({
  inputText = null,
  targetLanguage = i18n.language,
  convertToCamelCase = false,
}) => {
  // Guard Clause
  if (!inputText || !targetLanguage) {
    return;
  }

  // Translate Dynamic context
  const controller = new AbortController();
  const signal = controller.signal;
  // Translate Headline Title
  const url = '/api/translations/simple-translation';

  try {
    let payload = {
      inputText,
      targetLanguage,
      convertToCamelCase,
    };

    let request = await axiosPrivate.post(url, payload, {
      signal,
      headers: { 'Content-Type': 'application/json' },
    });

    const { data } = request;

    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

const translateMultiLineText = async ({
  inputText = [],
  targetLanguage = i18n.language,
}) => {
  // Guard Clause
  if (!inputText.length || !targetLanguage) {
    return;
  }

  // Translate Dynamic context
  const controller = new AbortController();
  const signal = controller.signal;
  // Translate Headline Title
  const url = '/api/translations/batch-translation';

  try {
    let payload = {
      inputText,
      targetLanguage,
    };

    let request = await axiosPrivate.post(url, payload, {
      signal,
      headers: { 'Content-Type': 'application/json' },
    });

    const { data } = request;

    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export { translateSingleText, translateMultiLineText };
