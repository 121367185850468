import React, { useContext } from 'react';
import { CompanyProfileContext } from '../../../../../context/company/CompanyProfileProvider';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import CompanyPaymentMethodField from '../../../../account/company/payment/CompanyPaymentMethodField';
import CompanyPaymentTermsField from '../../../../account/company/payment/CompanyPaymentTerms';

const CompanyPaymentTermsTab = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.account.company.CompanyPaymentTermsTab',
  });
  const { companyProfile } = useContext(CompanyProfileContext);

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Box>
        <CompanyPaymentMethodField
          fieldTitle={t('payment_methods.title')}
          fieldDescription={t('payment_methods.description')}
          required={companyProfile.type === 'manufacturer'}
          disabled={
            !companyProfile.isAdmin && companyProfile.type === 'manufacturer'
          }
          transition={companyProfile.type === 'manufacturer'}
        />
      </Box>
      <Box>
        <CompanyPaymentTermsField
          fieldTitle={t('payment_terms.title')}
          fieldDescription={t('payment_terms.description')}
          required={companyProfile.type === 'manufacturer'}
          disabled={
            !companyProfile.isAdmin && companyProfile.type === 'manufacturer'
          }
          transition={companyProfile.type === 'manufacturer'}
        />
      </Box>
    </Box>
  );
};

export default CompanyPaymentTermsTab;
