import { useState, useEffect, useContext } from 'react';
import { Avatar, Box, Button, LinearProgress, Typography } from '@mui/material';
import FaceIcon from '@mui/icons-material/Face';
import { useAxiosPrivate } from '../../hooks/axios/useAxiosPrivate';
import { CartContext } from '../../context/cart/CartContext';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

const shipFromAddress = {
  name: 'IN2TEC',
  address: {
    city: 'Memphis',
    state: 'TN',
    line1: '609 Bartlett Road',
    line2: '',
    postcode: '38134',
    country: {
      code: 'US',
      label: 'United States',
      dialCode: '1',
    },
  },
};

const IntutecShippingBox = ({ intutecProducts }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.ManufacturerShippingBox',
  });

  const axios = useAxiosPrivate();
  const { values, isValid } = useFormikContext();

  const shippingIndividual = values.shippingIndividual;
  const shippingAddress = values.shippingAddress;

  const { setCartProducts } = useContext(CartContext);

  const [estimates, setEstimates] = useState([]);
  const [selectedEstimate, setSelectedEstimate] = useState(null);
  const [isEstimatesLoading, setIsEstimatesLoading] = useState(false);

  const createShipment = async () => {
    try {
      setIsEstimatesLoading(true);
      const payload = {
        addressFrom: shipFromAddress.address,
        addressFromName: shipFromAddress.name,
        addressTo: shippingAddress,
        addressToName: `${shippingIndividual.first_name} ${shippingIndividual.last_name}`,
        products: intutecProducts,
      };

      const { data, status } = await axios.post(
        '/api/shippo/get-rates',
        payload,
      );

      if (status === 200) {
        setEstimates(
          data.data.sort(
            (a, b) => Number(a.amountLocal) - Number(b.amountLocal),
          ),
        );
      }
    } catch (error) {
      console.error('error', error);
    } finally {
      setIsEstimatesLoading(false);
    }
  };

  useEffect(() => {
    if (isValid) {
      createShipment();
    }
  }, [shippingAddress, isValid]);

  const handleSelectEstimate = (estimate) => {
    setSelectedEstimate(estimate);

    // Set on cart context which product gets which shipping option
    setCartProducts((prev) => {
      return prev.map((product) => {
        if (product.product.fullfillment?.intutec) {
          return {
            ...product,
            ship_from_address: {
              line1: shipFromAddress.address.line1,
              line2: shipFromAddress.address.line2,
              postal_code: shipFromAddress.address.postcode,
              city: shipFromAddress.address.city,
              state: shipFromAddress.address.state,
              country: shipFromAddress.address.country.code,
            },
            selected_shipping: estimate,
          };
        } else {
          return product;
        }
      });
    });
  };

  return (
    <Box>
      <Box height={40} bgcolor="secondary.light" />
      <Box
        display="flex"
        justifyContent="space-between"
        p={2}
        minHeight={350}
        gap={1}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          flex={1}
        >
          <Typography variant="h6">
            {t('title')}: {shipFromAddress.name}
          </Typography>
          <Typography variant="body2">
            {t('details')} {shipFromAddress.address.country.label}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent={isEstimatesLoading ? 'center' : 'flex-start'}
          flex={1}
          maxHeight={400}
          overflow="scroll"
        >
          {isEstimatesLoading ? (
            <LinearProgress sx={{ width: '90%' }} />
          ) : (
            estimates.map((estimate) => {
              return (
                <Button
                  key={estimate.objectId}
                  onClick={() => handleSelectEstimate(estimate)}
                  sx={{
                    width: '100%',
                    my: 1,
                    gap: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                  variant={
                    selectedEstimate &&
                    selectedEstimate.provider === estimate.provider &&
                    selectedEstimate.servicelevel.name ===
                      estimate.servicelevel.name
                      ? 'contained'
                      : 'outlined'
                  }
                  color="secondary"
                >
                  <Avatar
                    src={estimate.providerImage200}
                    alt="provider picture"
                    sx={{
                      width: 40,
                      height: 40,
                      bgcolor: 'transparent',
                      '& img': {
                        objectFit: 'contain',
                      },
                    }}
                  >
                    <FaceIcon sx={{ color: 'primary.dark' }} />
                  </Avatar>
                  <Typography flex={1} align="left" variant="body2">
                    {estimate.provider} - {estimate.servicelevel.name}
                  </Typography>
                  <Typography flex={1} align="left" variant="body2">
                    {t('days')} - {estimate.estimatedDays}
                  </Typography>

                  <Typography flex={0.5} align="center" variant="body2">
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: estimate.currencyLocal,
                    }).format(estimate.amountLocal)}
                  </Typography>
                </Button>
              );
            })
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default IntutecShippingBox;
