import React, { useContext } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Alert, Snackbar } from '@mui/material';
import ResponseContext from '../../context/providers/ResponseProvider';

const StatusBarComponent = () => {
  const { statusMsg, setStatusMsg } = useContext(ResponseContext);

  return (
    <Snackbar
      open={Boolean(statusMsg?.open)}
      autoHideDuration={6000}
      onClose={() => setStatusMsg((prev) => ({ ...prev, open: false }))}
    >
      <Alert
        onClose={() => setStatusMsg((prev) => ({ ...prev, open: false }))}
        severity={statusMsg?.status || 'info'}
        sx={{ width: '100%' }}
      >
        {ReactHtmlParser(statusMsg?.message || '')}
      </Alert>
    </Snackbar>
  );
};

export default StatusBarComponent;
