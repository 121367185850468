import {
  Card,
  CardActionArea,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const AccountRequirements = ({ requirements, title, divider = true }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.account.AccountRequiremenets',
  });

  if (requirements?.length === 0 || !requirements) {
    return;
  }

  return (
    <Grid sx={{ marginBottom: '3rem' }}>
      <Grid item>
        {title || <Typography variant="h5">{t('title')}</Typography>}
      </Grid>
      {divider && <Divider sx={{ marginBottom: '2rem' }} />}
      <Grid item>
        {requirements?.map((element, index) => {
          console.log('Requirement -> ', element.description);
          return (
            <Card key={index}>
              <CardActionArea
                sx={{
                  height: '100%',
                  color: 'error.contrastText',
                  pointerEvents: 'none',
                  backgroundColor: 'error.light',
                  '&[data-active]': {
                    backgroundColor: 'action.selected',
                    '&:hover': {
                      backgroundColor: 'action.selectedHover',
                    },
                  },
                }}
              >
                <CardContent sx={{ height: '100%' }}>
                  <Typography variant="body2" color="error.contrastText">
                    {element.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          );
        })}
      </Grid>
    </Grid>
  );
};

export { AccountRequirements };
