import ReactGA from 'react-ga4';

const updateGAConsent = (analyticsConsent) => {
  window.gtag('consent', 'default', {
    analytics_storage: analyticsConsent ? 'granted' : 'denied',
    ad_storage: analyticsConsent ? 'granted' : 'denied',
    ad_user_data: analyticsConsent ? 'granted' : 'denied',
    ad_personalization: analyticsConsent ? 'granted' : 'denied',
    analytics_storage: analyticsConsent ? 'granted' : 'denied',
  });
};

export const initGA = () => {
  ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
  updateGAConsent(true);
  // console.log('Tracking initialized');
};
