export const createInitialValueCurrencyConversionContainer = ({
  from,
  to,
  timstamp,
  amount,
  rate,
}) => {
  return {
    to: to,
    from: from,
    rate: rate,
    amount: amount || 0,
    timestamp: timstamp || null,
  };
};
