import {
  useState,
  useEffect,
  memo,
  useMemo,
  useContext,
  createContext,
} from 'react';
import {
  Grid,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  Divider,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { GridRow, GridColumnHeaders } from '@mui/x-data-grid-premium';
import useResponseHandling from '../../../../hooks/useResponseHandler.js';
import { Trans, useTranslation } from 'react-i18next';
import IntuIconButton from '../../../../components/buttons/IntuIconButton.jsx';
import { listPayoutAccount } from '../../../../api/stripe/payoutAccounts.js';
import { Add } from '@mui/icons-material';
import { toUpperCase } from '../../../../helpers/toTitleCase.js';
import { AddPayoutAccount } from './AddPayoutAccount.jsx';
import ElementsContainer from '../../../checkout/ElementsContainer.jsx';
import { StripeContext } from '../../../../context/providers/stripe/StripeContextProvider.jsx';
import { ManagePayoutAccount } from './ManagePayoutAccount.jsx';
import { ListStatusIcons } from '../../../listStatusIcons/ListStatusIcons.jsx';
import ListLoader from '../../../status/ListLoader.jsx';

export const PayoutAccountDetailsContext = createContext();

const createColumns = (
  t,
  transButtons,
  transTypes,
  setManagePayoutAccountModal,
  setExistingAccountData,
  theme,
) => {
  return [
    {
      field: 'bank_name',
      headerName: t('table.headers.bank_name'),
      editable: false,
      flex: 1,
      minWidth: 120,
    },
    {
      field: 'currency',
      headerName: t('table.headers.currency'),
      editable: false,

      minWidth: 50,
      valueGetter: (params) => {
        return toUpperCase(params.row.currency);
      },
    },
    {
      field: 'routing_number',
      headerName: t('table.headers.routing_number'),
      editable: false,
      minWidth: 80,
    },
    {
      field: 'last4',
      headerName: t('table.headers.account_number'),
      editable: false,
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        const accountNumber = `*****${params.row.last4}`;
        return accountNumber;
      },
    },
    {
      field: 'status',
      headerName: t('table.headers.status'),
      editable: false,
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => ListStatusIcons(params.row.status, theme),
    },
    {
      field: 'edit',
      headerName: t('table.headers.edit'),
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <IntuIconButton
            size="small"
            type="edit"
            variant="outlined"
            iconOnly={true}
            onClick={() => {
              setExistingAccountData(params.row);
              setManagePayoutAccountModal(true);
            }}
            tooltipTitle={transButtons('edit', {
              type: transTypes('bank_account'),
            })}
          />
        );
      },
    },
  ];
};

const PayoutAccountsList = () => {
  const [payoutAccounts, setPayoutAccounts] = useState([]);
  const { handleErrorResponse } = useResponseHandling();
  const MemoizedRow = memo(GridRow);
  const MemoizedColumnHeaders = memo(GridColumnHeaders);
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.stripe.PayoutAccountsList',
  });
  const { t: transButtons } = useTranslation('buttons');
  const { t: transTypes } = useTranslation('types');
  const theme = useTheme();
  const isMediumView = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { stripeUserData } = useContext(StripeContext);

  const [addPayoutAccountModal, setAddPayoutAccountModal] = useState(false);
  const [managePayoutAccountModal, setManagePayoutAccountModal] =
    useState(false);
  const [existingAccountData, setExistingAccountData] = useState({});
  const [isListLoading, setIsListLoading] = useState(false);

  // Close Payout Accounts Modal
  const handlePayoutAccountModalCloseState = (action) => {
    if (action === 'add') {
      setAddPayoutAccountModal(false);
    } else if (action === 'edit') {
      setManagePayoutAccountModal(false);
    }
  };

  const tableColumns = useMemo(() => {
    return createColumns(
      t,
      transButtons,
      transTypes,
      setManagePayoutAccountModal,
      setExistingAccountData,
      theme,
    );
  }, [t, handlePayoutAccountModalCloseState]);

  const getPayoutAccounts = async () => {
    setIsListLoading(true);

    try {
      const { data, status } = await listPayoutAccount();
      if (status === 200) {
        setPayoutAccounts(data.data.accounts);
      }
    } catch (error) {
      console.error('error', error);
      handleErrorResponse(error);
    } finally {
      setIsListLoading(false);
    }
  };

  useEffect(() => {
    getPayoutAccounts();
  }, []);

  return (
    <PayoutAccountDetailsContext.Provider
      value={{ existingAccountData, setExistingAccountData }}
    >
      <Grid container spacing={5} sx={{ marginBottom: '3rem' }}>
        <Grid item xs={12}>
          <Typography variant="h5">{t('title')}</Typography>
          <Divider sx={{ marginBottom: '2rem' }} />
          <Box display="flex" justifyContent="flex-end" mb={4}>
            <IntuIconButton
              type="subtmit"
              variant="outlined"
              IconComponent={Add}
              onClick={() => setAddPayoutAccountModal(true)}
              tooltipTitle={transButtons('add', {
                type: transTypes('bank_account'),
              })}
            />
          </Box>
          {isListLoading ? (
            <ListLoader columns={5} rows={4} />
          ) : !payoutAccounts?.length ? (
            <Grid item className="title content-centered" xs={12}>
              <Typography variant="body1">
                <Trans t={t} i18nKey="table.no_data" />
              </Typography>
            </Grid>
          ) : (
            <Box width="100%">
              <DataGrid
                isCellEditable={(params) => false}
                getRowId={(row) => row.id}
                rows={payoutAccounts}
                columns={tableColumns}
                hideFooterPagination
                hideFooterSelectedRowCount
                columnVisibilityModel={{
                  currency: !isMobileView && !isMediumView,
                  routing_number: !isMobileView && !isMediumView,
                  status: !isMobileView,
                  edit: !isMobileView,
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                  sorting: {
                    sortModel: [{}],
                  },
                }}
                pageSizeOptions={[5, 10, 100]}
                checkboxSelection={false}
                disableRowSelectionOnClick
                disableColumnMenu
                slots={{
                  Row: MemoizedRow,
                  ColumnHeaders: MemoizedColumnHeaders,
                }}
                sx={{
                  borderRadius: '12px',
                  boxShadow: 6,
                  '& .MuiDataGrid-columnSeparator': {
                    display: 'none',
                  },
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-cell:focus-within': {
                    outline: 'none',
                  },
                }}
              />
            </Box>
          )}
        </Grid>

        <ElementsContainer
          stripeAccount={stripeUserData?.stripe_connect_data?.id}
        >
          <AddPayoutAccount
            open={addPayoutAccountModal}
            onClose={() => handlePayoutAccountModalCloseState('add')}
            getPayoutAccounts={getPayoutAccounts}
          />

          <ManagePayoutAccount
            open={managePayoutAccountModal}
            onClose={() => handlePayoutAccountModalCloseState('edit')}
            getPayoutAccounts={getPayoutAccounts}
          />
        </ElementsContainer>
      </Grid>
    </PayoutAccountDetailsContext.Provider>
  );
};

export default PayoutAccountsList;
