export const getFileType = (file) => {
  if (!file) return '';
  return file.substr(file.lastIndexOf('.') + 1, file.length - 1);
};

export const getFileMimeType = (file) => {
  if (file.type) {
    return file.type; // Use browser-provided MIME type if available
  }

  // Manually determine file type based on extension
  const extension = file.name.split('.').pop().toLowerCase();
  const mimeTypes = {
    pdf: 'application/pdf',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    gif: 'image/gif',
    txt: 'text/plain',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    csv: 'text/csv',
    json: 'application/json',
    xml: 'application/xml',
    stp: 'application/step', // Common MIME type for STEP files
    step: 'application/step', // Alternative spelling
  };

  return mimeTypes[extension] || 'application/octet-stream'; // Default unknown type
};
