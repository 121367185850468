import React, { useContext } from 'react';
import { CompanyProfileContext } from '../../../../../context/company/CompanyProfileProvider';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import CompanyEmployeesList from '../../../../account/company/employees/EmployeesList';

const CompanyEmployeesTab = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.account.company.CompanyEmployeesTab',
  });
  const { companyProfile } = useContext(CompanyProfileContext);

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Box>
        <CompanyEmployeesList
          fieldDescription={t('employees.description')}
          transition={companyProfile.isAdmin}
        />
      </Box>
    </Box>
  );
};

export default CompanyEmployeesTab;
