import { axiosPrivate } from '../../hooks/axios/axios.js';

const getOrdersForManufacturer = async () => {
  try {
    const { data, status } = await axiosPrivate.get(`/api/orders/items/new`);

    return { data, status };
  } catch (error) {
    console.error('error', error);
  }
};

const getIn2tecOrdersForAdmin = async () => {
  try {
    const { data, status } = await axiosPrivate.get(
      `/api/orders/items/new/admin`,
    );

    return { data, status };
  } catch (error) {
    console.error('error', error);
  }
};

const getAllOrdersForAdmin = async () => {
  try {
    const { data, status } = await axiosPrivate.get(
      `/api/orders/items/new/admin/all`,
    );

    return { data, status };
  } catch (error) {
    console.error('error', error);
  }
};

const updateOrderItemDate = async (id, date) => {
  try {
    const payload = {
      date,
    };
    const { data, status } = await axiosPrivate.put(
      `/api/orders/items/date/${id}`,
      payload,
    );

    return { data, status };
  } catch (error) {
    console.error('error', error);
  }
};

const fulfillOrderItem = async (id, shippingLabel) => {
  try {
    const payload = {
      shippingLabel,
    };
    const { data, status } = await axiosPrivate.put(
      `/api/orders/items/fulfill/${id}`,
      payload,
    );

    return { data, status };
  } catch (error) {
    console.error('error', error);
  }
};

const getCustomerDetails = async (parent_id) => {
  try {
    const { data, status } = await axiosPrivate.get(
      `/api/orders/items/customer-details/${parent_id}`,
    );

    return { data, status };
  } catch (error) {
    console.error('error', error);
  }
};

export {
  getOrdersForManufacturer,
  getIn2tecOrdersForAdmin,
  getAllOrdersForAdmin,
  updateOrderItemDate,
  fulfillOrderItem,
  getCustomerDetails,
};
