import dayjs from 'dayjs';
import i18next from 'i18next';
import * as Yup from 'yup';
import blockedDomainList from '../../../../data/BlockedDomains.json';

// List of blocked burner email domains
let blockedDomains = [];
if (
  process.env.REACT_APP_ENV !== 'development' &&
  process.env.REACT_APP_ENV !== 'staging'
) {
  blockedDomains = blockedDomainList;
}

const eighteenYearsAgo = dayjs().subtract(18, 'year');

const CreateAddPersonValidationSchema = () => {
  const t = i18next.getFixedT(null, 'validation');
  const transTypes = i18next.getFixedT(null, 'types');

  return Yup.object().shape({
    first_name: Yup.string()
      .min(2, t('short', { type: transTypes('first_name'), char: 2 }))
      .max(50, t('long', { type: transTypes('first_name'), char: 50 }))
      .matches(/^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/, t('name.first_matches'))
      .required(t('name.first_required')),

    last_name: Yup.string()
      .min(2, t('short', { type: transTypes('last_name'), char: 2 }))
      .max(50, t('long', { type: transTypes('last_name'), char: 50 }))
      .matches(/^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/, t('name.last_matches'))
      .required(t('name.last_required')),
    email: Yup.string()
      .required(t('email.user_required'))
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        t('email.invalid'),
      )
      .test(
        'is-not-burner-email',
        t('email.burner_blocked'), // Example: 'Burner email addresses are not allowed'
        (value) => {
          if (!value) return true; // Skip validation for empty values
          const domain = value.split('@')[1]?.toLowerCase();
          return !blockedDomains.includes(domain);
        },
      ),
    title: Yup.string()
      .min(2, t('short', { type: transTypes('title'), char: 2 }))
      .max(30, t('long', { type: transTypes('title'), char: 30 }))
      .matches(/^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/, t('job_title.invalid'))
      .required(t('job_title.required')),
    percent_ownership: Yup.string().when('relationship', {
      is: (code) => code === 'owner',
      then: () => Yup.string().required(t('percent_ownership.required')),
    }),
    id_number: Yup.string().when('nationality.code', {
      is: (code) => code !== 'US',
      then: () => Yup.string().required(t('id_number.required')),
    }),
    ssn_last_4: Yup.string().when('nationality.code', {
      is: 'US',
      then: () =>
        Yup.string().length(4, t('short')).required(t('ssn_last_4.required')),
    }),
    address: Yup.object({
      line1: Yup.string()
        .min(5, t('short', { type: transTypes('address'), char: 5 }))
        .required(t('address.line1_required')),
      city: Yup.string()
        .min(3, t('short', { type: transTypes('city'), char: 3 }))
        .required(t('address.city_required')),
      state: Yup.string()
        .min(2, t('short', { type: transTypes('state'), char: 2 }))
        .required(t('address.state_required')),
      postcode: Yup.string()
        .min(3, t('short', { type: transTypes('postcode'), char: 3 }))
        .required(t('address.postcode_required')),
      country: Yup.object({
        label: Yup.string().required(t('country.required')),
        dialCode: Yup.number(t('country.dial_code.invalid'))
          .required(t('country.dial_code.required'))
          .typeError(t('country.dial_code.error')),
        code: Yup.string()
          .required(t('country.code.required'))
          .min(2, t('country.code.min')),
      }),
    }),
    dob: Yup.date()
      .max(eighteenYearsAgo, t('dob.invalid'))
      .required(t('dob.required')),
    political_exposure: Yup.string().required(t('political_exposure.required')),
    nationality: Yup.object({
      label: Yup.string().required(t('nationality.required')),
    }),
    phone: Yup.object({
      value: Yup.string().required(t('phone.required')),
      info: Yup.object({
        countryCallingCode: Yup.number(t('phone.country_code.invalid'))
          .required(t('phone.country_code.required'))
          .typeError(t('phone.country_code.error')),
        nationalNumber: Yup.string()
          .required(t('phone.required'))
          .min(8, t('phone.min'))
          .max(15, t('phone.max')),
      }),
    }),
  });
};

const ManagePersonValidationSchema = () => {
  const t = i18next.getFixedT(null, 'validation');
  const transTypes = i18next.getFixedT(null, 'types');

  return Yup.object().shape({
    first_name: Yup.string()
      .min(2, t('short', { type: transTypes('first_name'), char: 2 }))
      .max(50, t('long', { type: transTypes('first_name'), char: 50 }))
      .matches(/^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/, t('name.first_matches'))
      .required(t('name.first_required')),

    last_name: Yup.string()
      .min(2, t('short', { type: transTypes('last_name'), char: 2 }))
      .max(50, t('long', { type: transTypes('last_name'), char: 50 }))
      .matches(/^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/, t('name.last_matches'))
      .required(t('name.last_required')),
    email: Yup.string()
      .required(t('email.user_required'))
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        t('email.invalid'),
      )
      .test(
        'is-not-burner-email',
        t('email.burner_blocked'), // Example: 'Burner email addresses are not allowed'
        (value) => {
          if (!value) return true; // Skip validation for empty values
          const domain = value.split('@')[1]?.toLowerCase();
          return !blockedDomains.includes(domain);
        },
      ),
    title: Yup.string()
      .min(2, t('short', { type: transTypes('title'), char: 2 }))
      .max(30, t('long', { type: transTypes('title'), char: 30 }))
      .matches(/^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/, t('job_title.invalid'))
      .required(t('job_title.required')),
    percent_ownership: Yup.string().when('relationship', {
      is: (relationship) => relationship?.owner,
      then: () =>
        Yup.number()
          .required(t('percent_ownership.required'))
          .moreThan(0, t('percent_ownership.min')),
    }),
    id_number: Yup.string().when(['id_number_provided', 'nationality.code'], {
      is: (id_provided, code) => id_provided !== true,
      is: (code) => code !== 'US',
      then: () => Yup.string(),
    }),
    ssn_last_4: Yup.string().when(['ssn_last_4_provided', 'nationality.code'], {
      is: (provided, code) => provided !== true && code === 'US',
      then: () =>
        Yup.string()
          .required(t('ssn_last_4.required'))
          .length(4, t('exact', { type: transTypes('ssn'), char: 4 })),
    }),
    address: Yup.object({
      line1: Yup.string()
        .min(5, t('short', { type: transTypes('address'), char: 5 }))
        .required(t('address.line1_required')),
      city: Yup.string()
        .min(3, t('short', t('short', { type: transTypes('city'), char: 3 })))
        .required(t('address.city_required')),
      state: Yup.string()
        .min(2, t('short', t('short', { type: transTypes('state'), char: 2 })))
        .required(t('address.state_required')),
      postcode: Yup.string()
        .min(
          3,
          t('short', t('short', { type: transTypes('postcode'), char: 3 })),
        )
        .required(t('address.postcode_required')),
      country: Yup.object({
        label: Yup.string().required(t('country.required')),
        dialCode: Yup.number(t('country.dial_code.invalid'))
          .required(t('country.dial_code.required'))
          .typeError(t('country.dial_code.error')),
        code: Yup.string()
          .required(t('country.code.required'))
          .min(2, t('country.code.min')),
      }),
    }),
    dob: Yup.date()
      .max(eighteenYearsAgo, t('dob.invalid'))
      .required(t('dob.required')),
    political_exposure: Yup.string(),
    nationality: Yup.object({
      label: Yup.string().required(t('nationality.required')),
    }),
    phone: Yup.object({
      value: Yup.string().required(t('phone.required')),
      info: Yup.object({
        countryCallingCode: Yup.number(
          t('phone.country_code.invalid'),
        ).typeError(t('phone.country_code.error')),
        nationalNumber: Yup.string()
          .min(8, t('phone.min'))
          .max(15, t('phone.max')),
      }),
    }),
  });
};

export { CreateAddPersonValidationSchema, ManagePersonValidationSchema };
