import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useContext, useState, useEffect } from 'react';
import AuthContext from '../../context/auth/AuthProvider';
import { useAxiosPrivate } from '../../hooks/axios/useAxiosPrivate';
import ProcessingContext from '../../context/providers/ProcessingProvider';
import { useTranslation } from 'react-i18next';
import { LoadingSpinner } from '../../hooks/useProcessingHandler';

const RequireAccountType = ({ allowedAccountTypes }) => {
  const { auth } = useContext(AuthContext);
  const location = useLocation();
  const axios = useAxiosPrivate();
  const [isAuthorized, setIsAuthorized] = useState(null);
  const { isLoading, setIsLoading } = useContext(ProcessingContext);

  const { t } = useTranslation('messages');

  useEffect(() => {
    const checkAccountType = async () => {
      if (isAuthorized === null) {
        setIsLoading({
          status: true,
          text: t('global.authenticating'),
          type: 'spinner',
        });
      }

      // console.log('Checking Account type');

      if (auth?.auth_info) {
        try {
          setIsLoading({
            status: true,
            text: t('global.authenticating'),
            type: 'spinner',
          });

          const userAccountType = auth?.user_info?.account_type;

          const hasUserRequiredAccountType =
            allowedAccountTypes.includes(userAccountType) || false;

          if (hasUserRequiredAccountType === true) {
            // console.log('Seting Authorized to true');
            setIsAuthorized(true);
          } else {
            // console.log('Seting Authorized to false');
            setIsAuthorized(false);
          }
        } catch (err) {
          console.error(err);
          setIsAuthorized(false);
        } finally {
          setIsLoading({
            status: false,
            text: '',
            type: '',
          });
        }
      } else {
        setIsAuthorized(false);
      }
    };

    checkAccountType();
  }, [auth.user_info, allowedAccountTypes]);

  // **Wait until isAuthorized is determined (avoid premature redirection)**
  if (isAuthorized === null) {
    // console.log('Authorization status still being determined...');
    if (isAuthorized === null) {
      return <LoadingSpinner isLoading={isLoading} />;
    }
  }

  return !auth.auth_info ? (
    <Navigate to="/account/signin" state={{ from: location }} replace />
  ) : isAuthorized ? (
    <Outlet />
  ) : (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  );
};

export default RequireAccountType;
