import { useState, useEffect, memo, useMemo } from 'react';
import {
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  Divider,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { GridRow, GridColumnHeaders } from '@mui/x-data-grid-premium';
import useResponseHandling from '../../../../hooks/useResponseHandler.js';
import AddIcon from '@mui/icons-material/Add';
import { useContext } from 'react';
import { StripeContext } from '../../../../context/providers/stripe/StripeContextProvider.jsx';
import IntuIconButton from '../../../buttons/IntuIconButton.jsx';
import ConfirmActionDialog from '../../../confirmActionDialog/ConfrimActionDialog.jsx';
import {
  detachPaymentMethod,
  getPaymentMethodsForCustomer,
} from '../../../../api/stripe/paymentMethods.js';
import CustomerPaymentMethodsDialog from '../dialogs/CustomerPaymentMethodsDialog.jsx';
import { useTranslation } from 'react-i18next';
import ListLoader from '../../../status/ListLoader.jsx';

const createColumns = (t, transButtons, handleDetachClick) => {
  return [
    {
      field: 'brand',
      // headerName: t('form.products.name_label'),
      headerName: t('columns.brand'),
      editable: false,
      minWidth: 80,
      valueGetter: (params) => {
        return params.row.card.brand.toUpperCase();
      },
    },
    {
      field: 'last4',
      // headerName: t('form.products.mpn_label'),
      headerName: t('columns.card_number'),
      editable: false,
      minWidth: 50,
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      valueGetter: (params) => `****${params.row.card.last4}`,
    },
    {
      field: 'expiration',
      headerName: t('columns.expiration'),
      editable: false,
      minWidth: 100,
      valueGetter: (params) =>
        `${params.row.card.exp_month} / ${params.row.card.exp_year}`,
    },

    {
      field: 'detach',
      headerName: t('columns.remove'),
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <IntuIconButton
            size="small"
            type="delete"
            variant="outlined"
            onClick={() => handleDetachClick(params)}
            tooltipTitle={transButtons('remove')}
            iconOnly
          />
        );
      },
    },
  ];
};

const CustomerPaymentMethodsList = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.CustomerPaymentMethodsList',
  });
  const { t: transButtons } = useTranslation('buttons');
  const { t: transMessages } = useTranslation('messages');
  const { t: transTypes } = useTranslation('types');

  const [paymentMethods, setPaymentMethods] = useState([]);
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const MemoizedRow = memo(GridRow);
  const MemoizedColumnHeaders = memo(GridColumnHeaders);
  const theme = useTheme();
  const isMediumView = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { stripeUserData } = useContext(StripeContext);
  const [isConfirmActionDialogOpen, setIsConfirmActionDialogOpen] =
    useState(false);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(null);
  const [isListLoading, setIsListLoading] = useState(false);

  const handleDetachClick = async (params) => {
    setSelectedPaymentMethodId(params.row.id);
    setIsConfirmActionDialogOpen(true);
  };

  const handleConfirmActionDialogClose = () => {
    setSelectedPaymentMethodId(null);
    setIsConfirmActionDialogOpen(false);
  };

  const handleConfirmActionDialogConfirm = () => {
    setIsConfirmActionDialogOpen(false);
    handleDetach();
  };

  const handleDetach = async () => {
    if (!selectedPaymentMethodId) return;
    try {
      const { data, status } = await detachPaymentMethod(
        selectedPaymentMethodId,
      );

      if (status === 201) {
        setPaymentMethods((prev) =>
          prev.filter((pm) => pm.id !== selectedPaymentMethodId),
        );
        handleRegularResponse({
          open: true,
          status: data.status,
          message: data.message,
        });
      }
    } catch (error) {
      console.error('error', error);
    } finally {
      setSelectedPaymentMethodId(null);
    }
  };

  const columns = useMemo(
    () => createColumns(t, transButtons, handleDetachClick),
    [t, transButtons, handleDetachClick],
  );

  const getPaymentMethods = async () => {
    if (!stripeUserData?.stripe_customer_data?.id || paymentMethods.length)
      return;

    setIsListLoading(true);

    try {
      const { data, status } = await getPaymentMethodsForCustomer(
        stripeUserData.stripe_customer_data.id,
      );

      if (status === 200) {
        setPaymentMethods(data.data);
      }
    } catch (error) {
      console.error('error', error);
      handleErrorResponse(error);
    } finally {
      setIsListLoading(false);
    }
  };

  useEffect(() => {
    getPaymentMethods();
  }, [stripeUserData]);

  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const handlePaymentModalClose = () => {
    setIsPaymentModalOpen(false);
  };

  return (
    <Box>
      <Typography variant="h5">{t('title')}</Typography>
      <Divider sx={{ mb: 4 }} />
      <Box display="flex" justifyContent="flex-end" mb={4}>
        <IntuIconButton
          type="subtmit"
          variant="outlined"
          IconComponent={AddIcon}
          onClick={() => setIsPaymentModalOpen(true)}
          // tooltipTitle={transButtons('add', {
          //   type: transTypes('bank_account'),
          // })}
          tooltipTitle={transButtons('no_payment_method')}
        />
      </Box>
      {isListLoading ? (
        <ListLoader columns={8} rows={5} />
      ) : !paymentMethods?.length ? (
        <Box>
          <Typography variant="body1">{t('description')}</Typography>
        </Box>
      ) : (
        <Box height="auto" width="100%">
          <DataGrid
            isCellEditable={() => false}
            getRowId={(row) => row.id}
            rows={paymentMethods}
            columns={columns}
            columnVisibilityModel={{
              list_price: !isMobileView,
              attributes: !isMobileView && !isMediumView,
              applications: !isMobileView && !isMediumView,
              product_group: !isMobileView && !isMediumView,
              mpn: !isMobileView,
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
              sorting: {
                sortModel: [{}],
              },
            }}
            pageSizeOptions={[5, 10, 100]}
            checkboxSelection={false}
            disableRowSelectionOnClick
            disableColumnMenu
            slots={{
              Row: MemoizedRow,
              ColumnHeaders: MemoizedColumnHeaders,
            }}
            sx={{
              borderRadius: '12px',
              boxShadow: 6,
              '& .MuiDataGrid-columnSeparator': {
                display: 'none',
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-cell:focus-within': {
                outline: 'none',
              },
            }}
          />
        </Box>
      )}
      <ConfirmActionDialog
        open={isConfirmActionDialogOpen}
        onClose={handleConfirmActionDialogClose}
        onConfirm={handleConfirmActionDialogConfirm}
        dialogText={transMessages('confirm_deletion', {
          type: transTypes('credit_card'),
        })}
      />
      <CustomerPaymentMethodsDialog
        open={isPaymentModalOpen}
        onClose={handlePaymentModalClose}
      />
    </Box>
  );
};

export default CustomerPaymentMethodsList;
