import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  useTheme,
  Typography,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AttributeContainerContext } from '../../container/AttributeContainer';
import { NewAttributeDialog } from '../attribute/NewAttributeDialog';

export const ProductAttributeContext = createContext();

const AttributeSelection = () => {
  const theme = useTheme();
  const [searchValue, setSearchValue] = useState('');
  const {
    selectedAttribute,
    selectedAttributeType,
    selectedAttributes,
    productAttributeOptions,
    setSelectedAttribute,
    setSelectedAttributeValue,
    setProductAttributeValueOptions,
  } = useContext(AttributeContainerContext);

  const handleClick = (attribute) => {
    handleAttributeSelect(attribute);
    setSearchValue('');
  };
  const { t } = useTranslation('container', {
    keyPrefix: 'AttributeSelection',
  });

  const [newAttributeDialogOpen, setNewAttributeDialogOpen] = useState(false);

  const filteredAttributeOptions = useMemo(() => {
    if (!productAttributeOptions.length || !selectedAttributeType) return [];

    const attributeSet = new Set();
    const uniqueAttributes = [];

    productAttributeOptions.forEach((option) => {
      if (
        option.attribute_type === selectedAttributeType._id &&
        !attributeSet.has(option.attribute)
      ) {
        attributeSet.add(option.name);
        uniqueAttributes.push(option);
      }
    });

    return uniqueAttributes; // Returns array with unique items
  }, [productAttributeOptions, selectedAttributeType]);

  const searchedValues = useMemo(() => {
    if (!searchValue.length) return filteredAttributeOptions;

    return filteredAttributeOptions.filter((a) =>
      a.name.toLowerCase().includes(searchValue.toLowerCase()),
    );
  }, [filteredAttributeOptions, searchValue]);

  const handleNewAttribute = () => {
    // handleAttributeSelect(searchValue, true);
    setNewAttributeDialogOpen(true);
  };

  const handleAttributeSelect = (newAttribute, isNew = false) => {
    setSelectedAttribute(newAttribute);
    setSelectedAttributeValue(null);
    setProductAttributeValueOptions([]);
  };

  useEffect(() => {
    setSearchValue('');
  }, [selectedAttributeType, selectedAttributes]);

  const handleSearchInputKeyDown = (e) => {
    if (e.keyCode === 13 && !searchedValues.length) {
      handleNewAttribute();
    } else if (e.keyCode === 13) {
      handleAttributeSelect(searchedValues[0]);
    }
  };

  return (
    <ProductAttributeContext.Provider
      value={{ newAttributeDialogOpen, setNewAttributeDialogOpen }}
    >
      <Box flex={1} display="flex" flexDirection="column">
        {selectedAttributeType ? (
          <Box display="flex" flexDirection="column">
            {/* Section Title */}
            <Box>
              <Typography variant="body" textTransform="none" p={0}>
                {t('title')}
              </Typography>
            </Box>

            {/* Search Field */}
            <Box
              sx={{ height: '3rem', pb: '1rem' }}
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              className="intu__divider"
            >
              <TextField
                variant="standard"
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                onKeyDown={handleSearchInputKeyDown}
                placeholder="Search for an attribute"
                tabIndex={0}
              />
            </Box>

            {/* Filter (Placeholder) */}
            <Box sx={{ marginTop: '0.5rem', minHeight: '1.5rem' }} />

            {/* List */}
            <Box
              sx={{
                marginTop: '1rem',
                flex: 1,
                overflowY: 'auto',
                maxHeight: '500px',
                paddingRight: '8px',
              }}
            >
              <List>
                {searchedValues.map((attribute, index) => {
                  const isSelected = selectedAttribute === attribute;
                  return (
                    <ListItem
                      key={index}
                      component="button"
                      tabIndex={-1}
                      onMouseDown={() => handleClick(attribute)}
                      sx={{
                        background: isSelected && theme.palette.primary.main,
                        border: '1px solid',
                        borderRadius: '10px',
                        margin: '10px 0',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'center',
                        padding: '4px 0',
                        transform: isSelected
                          ? 'translateY(0px)'
                          : 'translateY(-2px)',
                        transition: 'all 0.15s ease-in-out',
                        '&:hover': {
                          color: 'white',
                          background: theme.palette.secondary.main,
                          transform: isSelected
                            ? 'translateY(0px)'
                            : 'translateY(-4px)',
                        },
                        '&:active': {
                          background: theme.palette.secondary.main,
                          transform: 'translateY(0px)',
                        },
                      }}
                    >
                      <ListItemText
                        primary={attribute.name}
                        primaryTypographyProps={{
                          sx: {
                            color: isSelected
                              ? 'white'
                              : (theme) => theme.palette.text.primary,

                            '&:hover': {
                              color: 'white', // ensure it matches your hover background
                            },
                          },
                        }}
                      />
                    </ListItem>
                  );
                })}
                {!selectedAttribute && (
                  <ListItem
                    key="new_attribute"
                    component="button"
                    onClick={handleNewAttribute}
                    sx={{
                      background: theme.palette.primary.main,
                      border: 'unset',
                      borderRadius: '10px',
                      boxShadow: theme.shadows[6],
                      margin: '10px 0',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      textAlign: 'center',
                      padding: '4px 0',
                      transform: 'translateY(-2px)',
                      transition: 'all 0.15s ease-in-out',
                      '&:active': {
                        background: theme.palette.secondary.main,
                        transform: 'translateY(0px)',
                      },
                    }}
                  >
                    <ListItemText primary={t('add_new_attribute.label')} />
                  </ListItem>
                )}
              </List>
            </Box>
          </Box>
        ) : null}
      </Box>
      <NewAttributeDialog />
    </ProductAttributeContext.Provider>
  );
};

export default AttributeSelection;
