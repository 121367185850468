import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import MeasurementField from '../fields/measurement/MeasurementField';
import UnitSelectionField from '../fields/unit/UnitSelectionField';
import { measurementOptions, weightOptions } from '../../data/MeasruementUnits';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  dimensions: Yup.object().shape({
    length: Yup.number()
      .min(1, 'Length must be greater than 0')
      .required('Length is required'),
    height: Yup.number()
      .min(1, 'Height must be greater than 0')
      .required('Height is required'),
    width: Yup.number()
      .min(1, 'Width must be greater than 0')
      .required('Width is required'),
  }),
  weight: Yup.object().shape({
    weight: Yup.number()
      .min(0.01, 'Weight must be greater than 0')
      .required('Weight is required'),
  }),
});

const OrderItemShippingDetailsDialogForm = ({ getShippingPrice }) => {
  const { t: transTypes } = useTranslation('types');

  const initialValues = {
    dimensions: {
      unit: 'in',
      length: 0,
      height: 0,
      width: 0,
    },
    weight: {
      unit: 'lb',
      weight: 0,
    },
  };
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema}>
      {(formik) => {
        const { values, handleBlur, validateForm } = formik;
        const handleFieldBlur = async (e) => {
          handleBlur(e);
          const errors = await validateForm();
          if (Object.keys(errors).length === 0) {
            getShippingPrice();
          }
        };
        return (
          <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" gap={2}>
              <UnitSelectionField
                fieldID="dimensions.unit"
                options={measurementOptions}
                transTypeID={transTypes('measurement')}
                onBlur={handleFieldBlur}
              />
              <MeasurementField
                fieldID="dimensions.length"
                transTypeID="length"
                inputAdornment={values.dimensions?.unit}
                decimalScale={0}
                fixedDecimalScale={true}
                onBlur={handleFieldBlur}
              />
              <MeasurementField
                fieldID="dimensions.width"
                transTypeID="width"
                decimalScale={0}
                inputAdornment={values.dimensions?.unit}
                fixedDecimalScale={true}
                onBlur={handleFieldBlur}
              />
              <MeasurementField
                fieldID="dimensions.height"
                transTypeID="height"
                decimalScale={0}
                inputAdornment={values.dimensions?.unit}
                fixedDecimalScale={true}
                onBlur={handleFieldBlur}
              />
            </Box>
            <Box display="flex" gap={2}>
              <UnitSelectionField
                fieldID="weight.unit"
                options={weightOptions}
                transTypeID={transTypes('weight')}
                onBlur={handleFieldBlur}
              />
              <MeasurementField
                fieldID="weight.weight"
                inputAdornment={values.weight.unit}
                decimalScale={2}
                fixedDecimalScale={true}
                onBlur={handleFieldBlur}
              />
            </Box>
          </Box>
        );
      }}
    </Formik>
  );
};

export default OrderItemShippingDetailsDialogForm;
