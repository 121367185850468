import { useTranslation } from 'react-i18next';
import IntuPage from '../../components/layout/IntuPage';
import { PublicProductsList } from '../../components/public-product/PublicProductsList';

const ProductsPage = () => {
  const { t } = useTranslation();

  return (
    <IntuPage
      pageTitle={t('pages.ProductsPage.title')}
      pageDescription={t('pages.ProductsPage.description')}
      hCentered
      lessMT
    >
      <PublicProductsList />
    </IntuPage>
  );
};

export default ProductsPage;
