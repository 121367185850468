import { Autocomplete, TextField } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { getBrandsByOwnerID, getBrandsList } from '../../../api/brandRoutes';
import { CompanyProfileContext } from '../../../context/company/CompanyProfileProvider';

const BrandContainer = () => {
  const { isAdmin } = useAuth();
  const [companyBrandOptions, setCompanyBrandOptions] = useState([]);
  const { t: transFields } = useTranslation();
  const { values, setValues } = useFormikContext();
  const { companyProfile } = useContext(CompanyProfileContext);

  const handleAutocompleteChange = (e, options, reason, element) => {
    console.log('Props -> ', element);
    setValues({
      ...values,
      brand: options ? element.option._id : '',
      manufacturer: options ? element.option.owner_id : '',
    });
  };

  // Get brands for company
  const getBrandOptions = async () => {
    // console.log('Company Info -> ', companyProfile?._id);

    if (!companyProfile._id && !isAdmin) {
      return;
    }

    try {
      if (isAdmin) {
        const { status, data } = await getBrandsList();

        // console.log('Brands List Result ->', status, data);

        if (status === 200) {
          const brandsList = data.data;

          setCompanyBrandOptions(brandsList);
        }
      } else {
        const { status, data } = await getBrandsByOwnerID(companyProfile._id);

        // console.log('Brands List Result ->', status, data);

        if (status === 200) {
          const brandsList = data.data;

          setCompanyBrandOptions(brandsList);
        }
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  useEffect(() => {
    getBrandOptions();
  }, [companyProfile?._id]);

  return (
    <Autocomplete
      id="new-product-brand-autocomplete"
      label={transFields('CompanyProductBrand.label')}
      placehlder={transFields('CompanyProductBrand.placeholder')}
      options={companyBrandOptions}
      getOptionLabel={(option) => option.brand}
      onChange={handleAutocompleteChange}
      value={
        companyBrandOptions.find((option) => option._id === values.brand) ||
        null
      }
      renderInput={(params) => <TextField {...params} label="Brand" />}
    />
  );
};

export default BrandContainer;
