import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  useTheme,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AttributeContainerContext } from '../../container/AttributeContainer';
import { listAttributeValues } from '../../../../api/products/attributes/attributeRoutes';
import { NewAttributeValueDialog } from '../attribute-values/NewAttributeValueDialog';
import {
  measurementOptions,
  otherUnits,
  weightOptions,
} from '../../../../data/MeasruementUnits';

export const ProductAttributeValueContext = createContext();

const AttributeValueSelection = () => {
  const { t } = useTranslation('container', {
    keyPrefix: 'AttributeValueSelection',
  });

  const { t: transFields } = useTranslation('fields');
  const { t: transTypes } = useTranslation('types');

  const {
    selectedAttributeType,
    selectedAttribute,
    setSelectedAttributeValue,
    productAttributeOptions,
    selectedAttributeValue,
    productAttributeValueOptions,
    setProductAttributeValueOptions,
  } = useContext(AttributeContainerContext);

  const theme = useTheme();
  const [searchValue, setSearchValue] = useState('');
  const [measurementUnitValue, setMeasurementUnitValue] = useState('');
  const [newAttributeValueDialog, setNewAttributeValueDialog] = useState(false);
  const [addAttributeValue, setAddAttributeValue] = useState({
    unit: '',
    value: '',
  });

  const [unitSelectionList, setUnitSelectionList] = useState([]);
  const [filter, setFilter] = useState([]);
  const [filterValue, setFilterValue] = useState('');

  const AttributeValueOptions = useMemo(() => {
    if (
      !productAttributeOptions?.length ||
      !selectedAttribute ||
      !selectedAttributeType
    ) {
      return [];
    }

    const listValues = [];
    productAttributeValueOptions.forEach((element) => {
      listValues.push(...element.values);
    });

    // Use a Map to store unique values based on unit-value combination
    const uniqueValues = new Map();

    listValues.forEach(({ unit, value }) => {
      if (typeof value === 'number' || !isNaN(value)) {
        const decimalCount = value.toString().split('.')[1]?.length || 0;
        const roundedValue =
          decimalCount > 3 ? parseFloat(value).toFixed(3) : value; // Only round if more than 3 decimals
        const key = `${roundedValue}-${unit}`; // Unique key based on value & unit

        // console.log('Get Unique Attribute -> ', value, unit);

        if (value > 0 && !uniqueValues.has(key)) {
          uniqueValues.set(key, { unit, value: roundedValue });
          // console.log('Unique Set -> ', uniqueValues);
        }
      } else {
        const key = `${value}-${unit}`; // Use raw value if it's not a number
        if (!uniqueValues.has(key)) {
          uniqueValues.set(key, { unit, value });
        }
      }
    });

    // Set Filter
    const filterValues = [];
    listValues.forEach((element) => {
      filterValues.push(element.unit);
    });
    const uniqueFilterValues = [...new Set(filterValues)];
    setFilter(uniqueFilterValues);

    const result = Array.from(uniqueValues.values());

    return result.sort((a, b) => a.value - b.value); // Sort numerically
  }, [productAttributeValueOptions, selectedAttribute, selectedAttributeType]);

  const handleGetAttributeValueOptions = async (id) => {
    const getAttributeValueoptions = await listAttributeValues({
      attributeId: id,
    });

    if (getAttributeValueoptions.statusCode === 200) {
      const { data } = getAttributeValueoptions;

      setProductAttributeValueOptions(data);
    } else {
      setProductAttributeValueOptions([]);
    }
  };

  // Construct A Units List
  const unitsListTemplate = [
    ...measurementOptions,
    ...weightOptions,
    ...otherUnits,
  ];

  const handleGetAttributeTypeUnitOptions = async () => {
    const dataKeys = selectedAttributeType?.data_keys;

    if (dataKeys?.length > 0) {
      setUnitSelectionList(dataKeys);
    } else {
      setUnitSelectionList(unitsListTemplate);
    }
  };

  // Handle Attribute Value Search
  const searchedValues = useMemo(() => {
    if (!AttributeValueOptions.length) {
      return;
    }

    // console.log('Attrbute Value Options ->', AttributeValueOptions);

    // Handle No Search Value
    if (!searchValue.length) {
      // No Filter & No Search

      if (!filterValue.length && !searchValue.length) {
        return AttributeValueOptions;
      }

      // Filtered
      if (filterValue.length && !searchValue.length) {
        return AttributeValueOptions.filter((a) =>
          String(a.unit).includes(filterValue),
        );
      } else {
        return [];
      }
    }

    // Filtered Search
    if (filterValue.length > 0 && searchValue.length > 0) {
      return AttributeValueOptions.filter(
        (a) =>
          String(a.value).includes(searchValue) &&
          String(a.unit).includes(filterValue),
      );
    } else {
      return AttributeValueOptions.filter((a) =>
        String(a.value).includes(searchValue),
      );
    }
  }, [AttributeValueOptions, searchValue, filterValue]);

  // Get Atribute Values based on Attribute Selection
  useEffect(() => {
    if (!selectedAttribute) {
      return;
    }

    handleGetAttributeValueOptions(selectedAttribute._id);
    handleGetAttributeTypeUnitOptions(selectedAttributeType._id);
  }, [selectedAttribute]);

  useEffect(() => {
    setSearchValue('');
  }, [selectedAttributeValue, AttributeValueOptions]);

  const handleSearchInputKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleNewAttribute();
    }
  };

  const handleNewAttribute = () => {
    setAddAttributeValue({ unit: measurementUnitValue, value: '' });
    setNewAttributeValueDialog(true);
  };

  const handleAttributeConfirm = (attributeValue) => {
    setSelectedAttributeValue(attributeValue);
  };

  return (
    <ProductAttributeValueContext.Provider
      value={{
        newAttributeValueDialog,
        setNewAttributeValueDialog,
        unitSelectionList,
        addAttributeValue,
        setAddAttributeValue,
        measurementUnitValue,
        setMeasurementUnitValue,
      }}
    >
      <Box flex={1} display="flex" flexDirection="column">
        {selectedAttribute ? (
          <Box>
            {/* Section Title */}
            <Box>
              <Typography variant="body" textTransform="none" p={0}>
                {t('title')}
              </Typography>
            </Box>

            {/* Search Field */}
            <Box
              sx={{ height: '3rem', pb: '1rem' }}
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              className="intu__divider"
            >
              {/* Search Field */}
              <Box>
                <TextField
                  value={searchValue}
                  variant="standard"
                  onChange={(e) => setSearchValue(e.target.value)}
                  onKeyDown={handleSearchInputKeyDown}
                  placeholder={transFields('SearchField.placeholder', {
                    type: transTypes('attribute'),
                  })}
                  tabIndex={0}
                />
              </Box>
            </Box>

            {/* Filter */}
            <Box sx={{ marginTop: '0.5rem', minHeight: '1.5rem' }}>
              {filter?.length ? (
                <FormControl variant="standard" fullWidth>
                  <Select
                    id="filter"
                    name="filter"
                    variant="standard"
                    displayEmpty
                    value={filterValue}
                    onChange={(e) => {
                      setFilterValue(e.target.value);
                    }}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        const label = transFields('FilterField.label', {
                          type: transTypes('attribute'),
                        });
                        return label;
                      }

                      return selected;
                    }}
                  >
                    {filter.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : null}
            </Box>

            {/* List */}
            <Box
              sx={{
                marginTop: '0.5rem',
                flex: 1,
                overflowY: 'auto',
                maxHeight: '500px',
                paddingRight: '8px',
              }}
            >
              <List sx={{ flex: 1 }}>
                {searchedValues
                  ? searchedValues.map((attributeValue, index) => {
                      const isSelected =
                        selectedAttributeValue?.unit === attributeValue?.unit &&
                        selectedAttributeValue?.value === attributeValue?.value;

                      return (
                        <ListItem
                          key={index}
                          component="button"
                          onClick={() => handleAttributeConfirm(attributeValue)}
                          sx={{
                            background:
                              isSelected && theme.palette.primary.main,
                            border: '1px solid',
                            borderRadius: '10px',
                            margin: '10px 0',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            textAlign: 'center',
                            padding: '4px 0',
                            transform: isSelected
                              ? 'translateY(0px)'
                              : 'translateY(-2px)',
                            transition: 'all 0.15s ease-in-out',
                            '&:hover': {
                              color: 'white',
                              background: theme.palette.secondary.main,
                              transform: isSelected
                                ? 'translateY(0px)'
                                : 'translateY(-4px)',
                            },
                            '&:active': {
                              background: theme.palette.secondary.main,
                              transform: 'translateY(0px)',
                            },
                          }}
                        >
                          <ListItemText
                            primary={`${attributeValue.value} ${attributeValue.unit}`}
                            primaryTypographyProps={{
                              sx: {
                                color: isSelected
                                  ? 'white'
                                  : (theme) => theme.palette.text.primary,

                                '&:hover': {
                                  color: 'white',
                                },
                              },
                            }}
                          />
                        </ListItem>
                      );
                    })
                  : null}
                {/* New Attribute Value */}
                {!selectedAttributeValue && (
                  <ListItem
                    key="new_attribute_value"
                    component="button"
                    onClick={handleNewAttribute}
                    sx={{
                      background: theme.palette.primary.main,
                      border: 'unset',
                      borderRadius: '10px',
                      boxShadow: theme.shadows[6],
                      margin: '10px 0',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      textAlign: 'center',
                      padding: '4px 0',
                      transform: 'translateY(-2px)',
                      transition: 'all 0.15s ease-in-out',
                      '&:active': {
                        background: theme.palette.secondary.main,
                        transform: 'translateY(0px)',
                      },
                    }}
                  >
                    <ListItemText primary={t('add_new_attribute.label')} />
                  </ListItem>
                )}
              </List>
            </Box>
          </Box>
        ) : null}
      </Box>

      <NewAttributeValueDialog />
    </ProductAttributeValueContext.Provider>
  );
};

export default AttributeValueSelection;
