import { useFormikContext } from 'formik';
import { Grid, Stack } from '@mui/material';
import ProductTypeField from '../../product-type/ProductTypeField';
import BrandContainer from '../container/BrandContainer';
import ProductMPNField from '../fields/ProductMPNField';
import ProductNameField from '../fields/ProductNameField';
import ApplicationsField from '../../applications/ApplicationsField';
import ProductDescription from '../ProductDescription';

const ProductInfoTabContent = () => {
  const { isSubmitting } = useFormikContext();

  return (
    <Grid container direction="column">
      <Stack spacing={3}>
        {/* Brands Fields */}
        <Grid item xs={12} md={6}>
          <BrandContainer />
        </Grid>
        {/* Product Name Field */}
        <Grid item xs={12} md={6}>
          <ProductNameField required disabled={isSubmitting} />
        </Grid>
        {/* Product MPN field */}
        <Grid item xs={12} md={6}>
          <ProductMPNField
            required
            isLoadingManual={isSubmitting}
            disabled={isSubmitting}
          />
        </Grid>

        {/* Product Type */}
        <Grid item xs={12} md={12}>
          <ProductTypeField required disabled={isSubmitting} />
        </Grid>

        {/* Product Application */}
        <Grid item xs={12} md={12}>
          <ApplicationsField
            required
            disabled={isSubmitting}
            industriesFieldID="product_industries"
            fieldID="product_applications"
            autocompleteID="product_applications"
          />
        </Grid>

        {/* Product Description */}
        <Grid item>
          <ProductDescription />
        </Grid>
      </Stack>
    </Grid>
  );
};

export default ProductInfoTabContent;
