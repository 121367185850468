import React, { useContext, useRef } from 'react';
import FormikFieldWrapper from '../../formik/FormikFieldWrapper';
import { Autocomplete, Skeleton, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import ProcessingContext from '../../../context/providers/ProcessingProvider';
import { getNestedValue } from '../../../helpers/getNestedValuesIn';

const ProductAttributeSelectionField = ({
  fieldTitle, // Title above the field
  fieldDescription, // Field description above the field
  fieldID = 'attribute', // Defines the ID and Name of the Field
  transition = true, // Whether the field is being shown and faded in
  disabled = false, // Disables the field
  options = [], // Array of Product Attribute  Options
  required = false, // Whether the field is required or not
  transNS = 'fields', // Namespace for translation Value
  transPrefix = 'ProductAttributeSelectionField', // KeyPrefix which holds label and placeholder
  isLoadingManual = false, // Manually control the Loading State
  defaultCurrency = null, // ISO 3 Code of Default Currency
  onChange = undefined,
  ...props
}) => {
  // Translation
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  // Context
  const { values, setFieldValue, touched, errors, handleBlur } =
    useFormikContext();

  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  const { isLoading } = useContext(ProcessingContext);

  const productAttributeSelectionRef = useRef();

  return isLoading.status || isLoadingManual ? (
    <Skeleton variant="rectangular" width={'100%'} height={60} />
  ) : (
    <FormikFieldWrapper
      fieldTitle={fieldTitle}
      fieldDescription={fieldDescription}
      transition={transition}
    >
      <Autocomplete
        disabled={disabled}
        options={options}
        getOptionLabel={(option) => option.name}
        value={fieldValue || null}
        onChange={(event, newValue) => {
          setFieldValue(fieldID, newValue);
          if (onChange) {
            onChange(event, newValue);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name={fieldID}
            id={fieldID}
            variant="outlined"
            label={t('label')}
            placeholder={t('placeholder')}
            onBlur={(e) => {
              handleBlur(e);
            }}
            error={fieldError && fieldTouched ? true : false}
            helperText={fieldError && fieldTouched ? fieldError : null}
            required={required}
          />
        )}
        ref={productAttributeSelectionRef}
        sx={{ width: '100%' }}
        {...props}
      />
    </FormikFieldWrapper>
  );
};

export default ProductAttributeSelectionField;
