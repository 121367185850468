import { Avatar, Box, Button, LinearProgress, Typography } from '@mui/material';
import FaceIcon from '@mui/icons-material/Face';

const RatesContainer = ({
  rates,
  isPriceLoading,
  handleSelectRate,
  selectedRate,
}) => {
  return (
    <Box mt={4}>
      {isPriceLoading ? (
        <LinearProgress sx={{ width: '100%' }} />
      ) : (
        rates.map((rate) => {
          return (
            <Button
              key={rate.objectId}
              onClick={() => handleSelectRate(rate)}
              sx={{
                width: '100%',
                my: 1,
                gap: 1,
                display: 'flex',
                justifyContent: 'space-between',
              }}
              variant={
                selectedRate &&
                selectedRate.servicelevel.name === rate.servicelevel.name
                  ? 'contained'
                  : 'outlined'
              }
            >
              <Avatar
                src={rate.providerImage200}
                alt="provider picture"
                sx={{
                  width: 40,
                  height: 40,
                  bgcolor: 'transparent',
                  '& img': {
                    objectFit: 'contain',
                  },
                }}
              >
                <FaceIcon sx={{ color: 'primary.dark' }} />
              </Avatar>
              <Typography flex={1} align="left" variant="body2">
                {rate.provider} - {rate.servicelevel.name}
              </Typography>
              <Typography flex={1} align="left" variant="body2">
                Est. days - {rate.estimatedDays}
              </Typography>

              <Typography flex={0.5} align="center" variant="body2">
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: rate.currencyLocal,
                }).format(rate.amountLocal)}
              </Typography>
            </Button>
          );
        })
      )}
    </Box>
  );
};
export default RatesContainer;
