import { useState, useEffect, memo, useMemo, useContext } from 'react';
import { Typography, Box, useTheme, useMediaQuery } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { GridRow, GridColumnHeaders } from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import PostAddIcon from '@mui/icons-material/PostAdd';
import {
  fulfillOrderItem,
  getIn2tecOrdersForAdmin,
} from '../../api/orders/orders.js';
import useResponseHandling from '../../hooks/useResponseHandler.js';
import { StripeContext } from '../../context/providers/stripe/StripeContextProvider.jsx';
import IntuIconButton from '../buttons/IntuIconButton.jsx';
import ListLoader from '../status/ListLoader.jsx';
import OrderItemDetailsDialog from './OrderItemDetailsDialog.jsx';
import OrderItemShippingDetailsDialog from './OrderItemShippingDetailsDialog.jsx';
import OrderItemShippingLabelDialog from './OrderItemShippingLabelDialog.jsx';
import OrderStatusIcon from './OrdersStatusIcon.jsx';

const createColumns = (t, transButtons, handleDetailsClick) => {
  return [
    {
      field: 'order',
      headerName: t('components.orders.order_id'),
      editable: false,
      flex: 0.3,
      minWidth: 80,
      valueGetter: (params) => params.row._id,
    },
    {
      field: 'productName',
      headerName: t('components.orders.product_name'),
      editable: false,
      flex: 0.5,
      minWidth: 50,
      valueGetter: (params) => params.row.product_name,
    },
    {
      field: 'orderDate',
      headerName: t('components.orders.order_date'),
      editable: false,
      flex: 0.2,
      minWidth: 100,
      valueGetter: (params) =>
        dayjs(params.row.created.date).format('MM/DD/YYYY'),
    },
    {
      field: 'fulfillment',
      headerName: t('components.orders.fulfilled_by'),
      editable: false,
      flex: 0.2,
      minWidth: 100,
      valueGetter: (params) =>
        params.row?.fullfillment.intutec ? 'In2tec' : 'You',
    },
    {
      field: 'status',
      headerName: t('components.orders.status'),
      editable: false,
      flex: 0.2,
      minWidth: 100,

      renderCell: (params) => {
        return <OrderStatusIcon status={params.row.status} />;
      },
    },
    {
      field: 'details',
      headerName: transButtons('details'),
      sortable: false,
      renderCell: (params) => {
        return (
          <IntuIconButton
            size="small"
            variant="outlined"
            onClick={() => handleDetailsClick(params)}
            tooltipTitle={transButtons('details')}
            IconComponent={PostAddIcon}
            iconOnly
          />
        );
      },
    },
  ];
};

const AdminIn2tecOrdersList = () => {
  const { t } = useTranslation();
  const { t: transButtons } = useTranslation('buttons');

  const [orders, setOrders] = useState([]);
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const MemoizedRow = memo(GridRow);
  const MemoizedColumnHeaders = memo(GridColumnHeaders);
  const theme = useTheme();
  const isMediumView = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { stripeUserData } = useContext(StripeContext);
  const [isOrderItemDetailsDialogOpen, setIsOrderItemDetailsDialogOpen] =
    useState(false);
  const [
    isOrderItemShippingDetailsDialogOpen,
    setIsOrderItemShippingDetailsDialogOpen,
  ] = useState(false);
  const [isListLoading, setIsListLoading] = useState(false);

  const [selectedOrderItem, setSelectedOrderItem] = useState(null);
  const [selectedRate, setSelectedRate] = useState(null);

  const handleDetailsClick = (params) => {
    setSelectedOrderItem(params.row);
    if (params.row.status === 'fulfilled') {
      setIsLabelDialogOpen(true);
    } else {
      setIsOrderItemDetailsDialogOpen(true);
    }
  };

  const handleConfirmActionDialogClose = () => {
    setIsOrderItemDetailsDialogOpen(false);
  };

  const handleOrderItemDetailsDialogConfirm = () => {
    setIsOrderItemDetailsDialogOpen(false);
    setIsOrderItemShippingDetailsDialogOpen(true);
  };

  const columns = useMemo(
    () => createColumns(t, transButtons, handleDetailsClick),
    [t, transButtons, handleDetailsClick],
  );

  const getOrders = async () => {
    setIsListLoading(true);

    try {
      const { data, status } = await getIn2tecOrdersForAdmin();

      if (status === 200) {
        setOrders(data.data);
      }
    } catch (error) {
      console.error('error', error);
      handleErrorResponse(error);
    } finally {
      setIsListLoading(false);
    }
  };

  useEffect(() => {
    getOrders();
  }, [stripeUserData]);

  const [isLabelDialogOpen, setIsLabelDialogOpen] = useState(false);

  const handleOrderItemShippingDetailsDialogConfirm = (selectedRate) => {
    setIsOrderItemShippingDetailsDialogOpen(false);
    setIsLabelDialogOpen(true);
    setSelectedRate(selectedRate);
  };

  const [confirmedDate, setConfirmedDate] = useState(
    dayjs(selectedOrderItem?.created?.date),
  );

  const handleFulfillConfirm = async (selectedOrderItem, shippingLabel) => {
    // Change status of order_item to shipped and add tracking number
    setIsLabelDialogOpen(false);
    setIsListLoading(true);
    try {
      const { data, status } = await fulfillOrderItem(
        selectedOrderItem._id,
        shippingLabel,
      );

      if (status === 201) {
        setOrders((prev) => {
          return prev.map((orderItem) => {
            if (orderItem._id === data.data._id) {
              return data.data;
            } else {
              return orderItem;
            }
          });
        });

        handleRegularResponse({
          open: true,
          status: data.status,
          message: 'Updated order',
        });
      }
    } catch (error) {
      console.error('error', error);
      handleErrorResponse(error);
    } finally {
      setIsListLoading(false);
    }
  };

  return (
    <Box>
      {isListLoading ? (
        <ListLoader columns={8} rows={5} />
      ) : !orders?.length ? (
        <Box>
          <Typography variant="body1">
            {t('components.orders.no_orders')}
          </Typography>
        </Box>
      ) : (
        <Box height="auto" width="100%">
          <DataGrid
            isCellEditable={(params) => false}
            getRowId={(row) => row._id}
            rows={orders}
            columns={columns}
            columnVisibilityModel={{
              list_price: !isMobileView,
              attributes: !isMobileView && !isMediumView,
              applications: !isMobileView && !isMediumView,
              product_group: !isMobileView && !isMediumView,
              mpn: !isMobileView,
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
              sorting: {
                sortModel: [{}],
              },
            }}
            pageSizeOptions={[5, 10, 100]}
            checkboxSelection={false}
            disableRowSelectionOnClick
            disableColumnMenu
            slots={{
              Row: MemoizedRow,
              ColumnHeaders: MemoizedColumnHeaders,
            }}
            sx={{
              borderRadius: '12px',
              boxShadow: 6,
              '& .MuiDataGrid-columnSeparator': {
                display: 'none',
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-cell:focus-within': {
                outline: 'none',
              },
            }}
          />
        </Box>
      )}
      <OrderItemDetailsDialog
        open={isOrderItemDetailsDialogOpen}
        onClose={handleConfirmActionDialogClose}
        onConfirm={handleOrderItemDetailsDialogConfirm}
        selectedOrderItem={selectedOrderItem}
        confirmedDate={confirmedDate}
        setConfirmedDate={setConfirmedDate}
      />
      <OrderItemShippingDetailsDialog
        open={isOrderItemShippingDetailsDialogOpen}
        onClose={() => setIsOrderItemShippingDetailsDialogOpen(false)}
        onConfirm={handleOrderItemShippingDetailsDialogConfirm}
        selectedOrderItem={selectedOrderItem}
        setOrders={setOrders}
      />
      <OrderItemShippingLabelDialog
        open={isLabelDialogOpen}
        onClose={() => setIsLabelDialogOpen(false)}
        onConfirm={handleFulfillConfirm}
        selectedOrderItem={selectedOrderItem}
        selectedRate={selectedRate}
      />
    </Box>
  );
};

export default AdminIn2tecOrdersList;
