import { useTranslation } from 'react-i18next';
import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { ReactComponent as DocSVG } from '../../../../assets/new-product/doc-image.svg';
import IntuIconButton from '../../../buttons/IntuIconButton';
import useResponseHandling from '../../../../hooks/useResponseHandler';
import { useAxiosPrivate } from '../../../../hooks/axios/useAxiosPrivate';
import { useContext } from 'react';
import { ProductFileContext } from './FilesContainer';
import { useFormikContext } from 'formik';
import filePurposeOptions from '../../../../data/FilePurposeOptions.json';
import { ReactComponent as Image3DSVG } from '../../../../assets/new-product/3d-image.svg';
import { getFileName } from '../../../../helpers/getFileName';

const ProductFiles = ({
  displayTitle = false, // Wheter or not to display the title
  transNS = 'containers',
  transPrefix = 'ProductFiles',
}) => {
  const { setIsUploadDocumentDialogOpen } = useContext(ProductFileContext);
  const { values, setValues } = useFormikContext();
  const { t: transContainers } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });
  const { t: transButtons } = useTranslation('buttons');
  const { t: transData } = useTranslation('data', {
    keyPrefix: 'FilePurposeOptions',
  });
  const theme = useTheme();
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();

  const axios = useAxiosPrivate();

  const handleDeleteFile = async (file) => {
    const fileName =
      typeof file === 'string'
        ? file.substring(file.lastIndexOf('/') + 1)
        : typeof file === 'object' && file.file_url
          ? file.file_url.substring(file.file_url.lastIndexOf('/') + 1)
          : '';

    const fileUrl =
      typeof file === 'string'
        ? file.substring(file.lastIndexOf('/') + 1)
        : typeof file === 'object' && file.file_url
          ? file.file_url.substring(file.file_url)
          : '';

    const productId = values._id.replace(/"/g, ''); // Remove any extra quotes

    try {
      const payload = {
        fileUrl,
      };
      // Remove from collection
      const { data, status } = await axios.put(
        `/api/products/update/${productId}/delete-file`,
        payload,
      );

      if (status === 200) {
        setValues({
          ...values,
          files: values.files.filter((f) => f !== file),
        });

        const bucketPayload = {
          folder: `products/${values._id}/documents/${fileName}`,
        };

        // Remove from DO bucket
        await axios.post('/api/aws/content/delete', bucketPayload, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        handleRegularResponse({
          open: true,
          status: 'success',
          message: data.message,
        });
      } else {
        handleRegularResponse({
          open: true,
          status: 'error',
          message: data.message,
        });
      }
    } catch (error) {
      console.error('error', error);
      handleErrorResponse(error);
    }
  };

  const files = values.files;

  // Get Localized Options
  const purposeOptions = filePurposeOptions.map((option) => ({
    ...option,
    label: transData(`${option.value}`),
  }));

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        sx={{ mb: '1rem' }}
      >
        {displayTitle && (
          <Typography
            variant="h6"
            textTransform="none"
            fontSize="1.2rem"
            mr={2}
          >
            {transContainers('title')}
          </Typography>
        )}

        {/* Action Buttons */}
        <Stack direction="row" spacing={1} sx={{ marginLeft: 'auto' }}>
          <Button
            variant="outlined"
            color="edit"
            fullWidth
            startIcon={<FileUploadIcon />}
            onClick={() => setIsUploadDocumentDialogOpen(true)}
            sx={{ textTransform: 'none' }}
          >
            {transButtons('upload_doc')}
          </Button>
        </Stack>
      </Box>

      <Box
        minHeight={350}
        width="100%"
        bgcolor="background.default"
        mb={2}
        p={2}
        boxShadow={theme.shadows[6]}
        borderRadius="5px"
      >
        <Box mb={2}>
          {Array.isArray(files) && files.length
            ? files.map((file, index) => {
                return (
                  <Box key={index} display="flex">
                    <Box>
                      {file?.purpose === '3D_files' ? (
                        <Image3DSVG width="50px" height="50px" />
                      ) : (
                        <DocSVG width="50px" height="50px" />
                      )}
                    </Box>

                    <Box alignContent="center">
                      {/* File Purpose */}
                      {file?.purpose && (
                        <Box>
                          <Typography variant="p" fontSize="12px" mx={1}>
                            {purposeOptions.find(
                              (option) => option.value === file.purpose,
                            )?.label || file.purpose}
                          </Typography>
                        </Box>
                      )}

                      {/* File Name */}
                      <Box>
                        <Typography variant="p" fontSize="12px" mx={1}>
                          {getFileName(file?.file_url)}
                        </Typography>
                      </Box>
                    </Box>

                    <Box sx={{ marginLeft: 'auto' }} alignContent="center">
                      <IntuIconButton
                        aria-label="delete file"
                        size="small"
                        type="delete"
                        color="error"
                        variant="outlined"
                        tooltipTitle={transButtons('delete')}
                        onClick={() => handleDeleteFile(file)}
                        IconComponent={DeleteIcon}
                      />
                    </Box>
                  </Box>
                );
              })
            : null}
        </Box>
      </Box>
    </>
  );
};

export default ProductFiles;
