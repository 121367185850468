import { Button, Stack } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAxiosPrivate } from '../../../hooks/axios/useAxiosPrivate';
import useAuth from '../../../hooks/useAuth';
import useUser from '../../../hooks/useUser';
import useResponseHandling from '../../../hooks/useResponseHandler';
import MobileOTPField from '../MobileOTPField';
import { useFormikContext } from 'formik';
import MobileNotificationCheckBox from './MobileNotificationCheckBox';
import { LoadingButton } from '@mui/lab';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import FormikFieldWrapper from '../../formik/FormikFieldWrapper';
import { TwoFactorVerificationContext } from '../../../context/providers/TwoFactorVerificationContextProvider';
import MobileNumberField from '../MobileNumberField';
import { getNestedValue } from '../../../helpers/getNestedValuesIn';
import { getUserPhoneDetails } from '../../../helpers/getUserPhoneDetails';

const TwoFactorVerificationField = ({
  fieldID,
  fieldTitle, // Title above the field
  fieldDescription, // Field description above the field
  transition, // Whether to Fade in the Field
  variant = 'outlined', // Style of the Fields
  changeShowNumberButton = false,
  ...props
}) => {
  const {
    values,
    setValues,
    setFieldValue,
    errors,
    setErrors,
    setFieldTouched,
  } = useFormikContext();

  const {
    isSendingOTP,
    setIsOTPSending,
    otpSent,
    setOTPSent,
    isConfirmingOTP,
    setIsConfirmingOTP,
  } = useContext(TwoFactorVerificationContext);

  const axios = useAxiosPrivate();
  const { auth } = useAuth();
  const { user } = useUser();
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();

  //SetUp Trnaslation
  const { t } = useTranslation('buttons', {
    keyPrefix: 'two-factor-authentication',
  });

  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);
  const [showChangeNumberButton, setShowChangeNumberButton] = useState(
    changeShowNumberButton,
  );

  const handleConfirmClick = async () => {
    if (!values?.otp) {
      return window.alert(t('responses.no_alert'));
    }
    setIsConfirmingOTP(true);

    const controller = new AbortController();
    const { signal } = controller;
    const url = '/api/verify/account/auth/confirm';
    const payload = {
      _id: auth.user_info._id,
      otp: values?.otp,
      mobile: values.mobile,
      mobile_verified: {
        accepted: true,
        ip: user?.location?.ip,
        date: new Date(),
        unixTimeStamp: Math.floor(Date.now() / 1000),
        agent: navigator.userAgent,
      },
      account_notifications: values?.account_notifications,
      userDashboard: true,
    };

    try {
      const response = await axios.put(url, JSON.stringify(payload), {
        signal,
      });

      const { data, status } = response;

      if (status === 200) {
        setValues({
          ...values,
          individual: {
            ...values.individual,
            mobile: values.mobile,
          },
          mobile_verified: {
            accepted: true,
            ip: user?.location?.ip,
            date: new Date(),
            unixTimeStamp: Math.floor(Date.now() / 1000),
            agent: navigator.userAgent,
          },
          otp: '',
        });
        setErrors({
          errors,
          mobile_verified: {},
        });

        setOTPSent(false);

        // setTwoFactorVerificationDialog(false);

        setTimeout(() => {
          setFieldTouched('mobile_verified', true, true);
        }, 100);

        handleRegularResponse({
          open: true,
          status: data.status,
          message: data.message,
        });
      } else {
        handleRegularResponse({
          open: true,
          status: data.status,
          message: data.message,
        });
      }
    } catch (err) {
      handleErrorResponse(err);
    } finally {
      setFieldValue('otp', '');
      setIsConfirmingOTP(false);
    }
  };

  const handleRequestClick = async () => {
    if (!fieldValue?.value) {
      return console.log(t('messages.no_alert'));
    }
    setIsOTPSending(true);

    const controller = new AbortController();
    const signal = controller.signal;
    let url = '/api/verify/account/auth/request';
    let payload = {
      mobile_number: values?.mobile?.value,
    };

    try {
      const request = await axios.put(url, JSON.stringify(payload), {
        signal,
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });

      const data = request?.data;

      if (request.status === 200) {
        setOTPSent(true);
        setShowChangeNumberButton(true);
      } else {
        handleRegularResponse({
          open: true,
          status: data.status,
          message: data.message,
        });
      }
    } catch (err) {
      setOTPSent(false);
      handleErrorResponse(err);
    } finally {
      setIsOTPSending(false);
    }
  };

  const handleChangeNumberClick = () => {
    const countryCode = user.location.country || 'US';

    setOTPSent(false);
    setShowChangeNumberButton(false);
    setValues({
      ...values,
      otp: '',
      mobile: getUserPhoneDetails(countryCode),
      mobile_verified: {
        accepted: false,
        agent: null,
        date: null,
        ip: null,
        unixTimeStamp: null,
      },
    });
  };

  return (
    <>
      <Stack
        spacing={4}
        sx={{
          paddingTop: '30px',
          paddingBottom: '30px',
        }}
      >
        {/* Mobile Phone Number */}
        <MobileNumberField
          fieldTitle={fieldTitle}
          fieldDescription={fieldDescription}
          fieldID="mobile"
          required={true}
          transition={transition}
          variant={variant}
          disabled={
            otpSent || isConfirmingOTP || values?.mobile_verified?.accepted
              ? true
              : false
          }
          {...props}
        />

        {/* OTP Verification Field */}
        <MobileOTPField
          fieldID="otp"
          required={true}
          transition={otpSent}
          disabled={!otpSent ? true : false}
          variant={variant}
        />
        {/* Mobile Verification Checkbox  */}
        <MobileNotificationCheckBox
          required={true}
          disabled={!otpSent ? true : false}
          transition={otpSent}
          variant={variant}
        />
      </Stack>
      <Stack spacing={2}>
        {/* Request OTP Button */}
        <FormikFieldWrapper
          transition={
            !fieldError?.info?.nationalNumber &&
            !values?.mobile_verified?.accepted &&
            !otpSent
          }
        >
          <LoadingButton
            variant="outlined"
            color="warning"
            disabled={
              fieldError && fieldError?.info?.nationalNumber ? true : false
            }
            onClick={() =>
              handleRequestClick({ values, setFieldValue, setValues })
            }
            sx={{ mr: 1 }}
            loading={isSendingOTP}
            startIcon={<PhoneIphoneIcon />}
          >
            {t('request')}
          </LoadingButton>
        </FormikFieldWrapper>

        {/* Confirm OTP Button */}
        <FormikFieldWrapper transition={otpSent}>
          <LoadingButton
            variant="contained"
            color="primary"
            disabled={!otpSent || values?.otp?.length < 4 ? true : false}
            onClick={() =>
              handleConfirmClick({ values, setValues, setFieldValue })
            }
            sx={{ mr: 1 }}
            loading={isConfirmingOTP}
            startIcon={<PhoneIphoneIcon />}
          >
            {t('confirm')}
          </LoadingButton>
        </FormikFieldWrapper>

        {/* Change Mobile Phone Number Button */}
        <FormikFieldWrapper transition={showChangeNumberButton}>
          <Button
            variant="outlined"
            color="warning"
            onClick={() => {
              // clear OTP & otp send status
              handleChangeNumberClick();
            }}
            sx={{ mr: 1 }}
          >
            {t('change')}
          </Button>
        </FormikFieldWrapper>
      </Stack>
    </>
  );
};

export default TwoFactorVerificationField;
