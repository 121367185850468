import React, { createContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import ChageUserPasswordDialog from '../../account/changePassword/ChangeUserPasswordDialog';
import useAuth from '../../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import TwoFactorVerificationField from '../../account/two-factor-authentication/TwoFactorVerificationField';

export const SecurityTabContext = createContext();

const SecurityTab = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.account.SecurityTab',
  });

  const { t: transButtons } = useTranslation('buttons');

  const { auth } = useAuth();
  const navigate = useNavigate();

  const [changePasswordDialog, setChangePasswordDialog] = useState(false);

  return (
    <SecurityTabContext.Provider
      value={{ changePasswordDialog, setChangePasswordDialog }}
    >
      <Box display="flex" flexDirection="column" gap={4}>
        {/* Password */}
        <Box>
          <Typography
            variant="h4"
            textTransform="none"
            textAlign={{ xs: 'center', md: 'left' }}
          >
            {t('password.title')}
          </Typography>

          <Button
            variant="outlined"
            onClick={() => {
              if (
                auth?.user_info?.auth_method?.provider &&
                auth?.user_info?.auth_method?.provider !== 'basic'
              ) {
                // Open User Change Dialog for Basic Authentication
                setChangePasswordDialog(true);
              }
              // Redirect User to his her Autnentication Provider's Profile
              // Check if it's an external URL
              else if (auth?.user_info?.auth_method.url.startsWith('http')) {
                window.open(auth?.user_info?.auth_method.url, '_blank');
              }
              // If it's an internal route, use navigate or Link
              else {
                navigate(auth?.user_info?.auth_method.url);
              }
            }}
            color="warning"
          >
            {transButtons('change_pw')}
          </Button>
        </Box>

        {/* 2FA */}
        <Box>
          <TwoFactorVerificationField
            changeShowNumberButton={true}
            fieldTitle={t('2fa.title')}
            fieldDescription={t('2fa.description')}
            fieldID="mobile"
            transNS="fields"
            transPrefix="TwoFactorauthenticationField"
            required={true}
          />
        </Box>

        {/* Change User Password */}
        <ChageUserPasswordDialog />
      </Box>
    </SecurityTabContext.Provider>
  );
};

export default SecurityTab;
