import {
  useEffect,
  useState,
  useMemo,
  useContext,
  useRef,
  createContext,
} from 'react';
import {
  Box,
  IconButton,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getPublicProductById } from '../../api/products/productsRoutes.js';
import Carousel from 'react-material-ui-carousel';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ProductRepresentativesContainer from '../../components/public-product/ProductRepresentativesContainer.jsx';
import MessageDialog from '../../components/public-product/MessageDialog';
import UserContext from '../../context/users/UserInfoProvider';
import PublicProductFiles from '../../components/public-product/PublicProductFiles';
import { useNavigationFooter } from '../../context/footer/NavigationFooterContext';
import { getProducRepresentativesById } from '../../api/products/productsRoutes.js';
import BuyDialog from './BuyDialog';
import { CartContext } from '../../context/cart/CartContext';
import { ReactComponent as Image3DSVG } from '../../assets/new-product/3d-image.svg';
import { CarouselItem } from '../../components/mui/carousel/Carouseltem.jsx';
import { trackActivity } from '../../api/tracker/trackerRoutes.js';
import ProductAttributesBox from '../../components/public-product/ProductAttributesBox.jsx';
import FormikTabs from '../../components/formik/FormikTabs.jsx';
import { useTranslation } from 'react-i18next';
import WarrantyTermsBox from '../../components/public-product/WarrantyTermsBox.jsx';
import ProductApplicationsBox from '../../components/public-product/ProductApplicationsBox.jsx';
import ProductTypesBox from '../../components/public-product/ProductTypesBox.jsx';
import { AddToCartBox } from '../../components/public-product/AddToCartBox.jsx';
import ProductDescriptionBox from '../../components/public-product/ProductDescriptionBox.jsx';
import { getPublicCampaignDetails } from '../../api/referrals/referralRoutes.js';
import IntuIconButton from '../../components/buttons/IntuIconButton.jsx';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ResponseContext from '../../context/providers/ResponseProvider.jsx';

export const PublicProductPageContext = createContext();

const PublicProductPage = () => {
  const { id } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { conversations, setConversations } = useContext(UserContext);
  const { setErrorDialog } = useContext(ResponseContext);
  const { setCartProducts } = useContext(CartContext);
  const { setFooterConfig } = useNavigationFooter();
  const params = new URLSearchParams(window.location.href);
  const referalID = params.get('referalID');
  const isShort = useMediaQuery('(max-height:950px)');

  const [product, setProduct] = useState(null);
  const [selectedRep, setSelectedRep] = useState(null);
  const [isMessageDialogOpen, setIsMessageDialogOpen] = useState(false);
  const [isBuyDialogOpen, setIsBuyDialogOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [wireframeMode, setWireframeMode] = useState(false);
  const [autoRotate, setAutoRoate] = useState(false);

  const { t } = useTranslation('translation', {
    keyPrefix: 'components.PublicProductPage',
  });
  const { t: transTypes } = useTranslation('types');
  const { t: transButtons } = useTranslation('buttons');
  const { t: transMessages } = useTranslation('messages');

  const modelViewerRef = useRef(null);

  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const productId = useMemo(() => {
    let productId = id;
    if (id.includes('&')) {
      productId = id.split('&')[0];
    }
    return productId;
  }, [id]);

  const getProduct = async () => {
    const response = await getPublicProductById(productId);
    setProduct(response);
  };

  const hasTrackedReferral = useRef(false);
  const hasTrackedVisit = useRef(false);

  const trackProductReferral = async (referalID) => {
    const payload = {
      documentId: referalID,
      module: 'referral_campaigns',
      action: 'visit',
      tracker: true,
    };
    await trackActivity({ payload });
  };

  const trackProductVisit = async (productId) => {
    const payload = {
      documentId: productId,
      module: 'products',
      action: 'visit',
      tracker: true,
    };
    await trackActivity({ payload });
  };

  useEffect(() => {
    //  Send request to acknowledge referral
    if (referalID && !hasTrackedReferral.current) {
      trackProductReferral(referalID);
      hasTrackedReferral.current = true;
    }
  }, [referalID]);

  useEffect(() => {
    //  Send request to acknowledge visit
    if (productId && !hasTrackedVisit.current) {
      trackProductVisit(productId);
      hasTrackedVisit.current = true;
    }
  }, [productId]);

  useEffect(() => {
    getProduct();
  }, []);

  const handleMessage = (rep) => {
    setSelectedRep(rep);
    setIsMessageDialogOpen(true);
  };

  const handleMessageDialogClose = () => {
    setSelectedRep(null);
    setIsMessageDialogOpen(false);
  };

  const handleSetChats = (newChats) => {
    setConversations(newChats);
    setIsMessageDialogOpen(false);
  };

  // Setup navigation footer
  useEffect(() => {
    // Check if user navigated from the same origin/domain
    const fromApp = window.location.hostname === location.state?.from;
    setFooterConfig({
      backLabel: transButtons('back'),
      showFooter: fromApp,
    });
    return () => {
      setFooterConfig((prev) => ({ ...prev, showFooter: false }));
    };
  }, []);

  const [productRepresentatives, setProductRepresentatives] = useState([]);

  const getProductRepresentatives = async () => {
    const response = await getProducRepresentativesById(productId);

    // Get Referral User ID
    if (referalID) {
      // Get Referral Campaign Details
      const getReferralDetails = await getPublicCampaignDetails({
        id: referalID,
      });

      if (getReferralDetails.statusCode === 200) {
        const referralData = getReferralDetails.data;
        const userID = referralData.referrer;

        const filterAssociates = response.filter((rep) => rep._id === userID);

        if (filterAssociates.length > 0) {
          setProductRepresentatives(filterAssociates);
        } else {
          setProductRepresentatives(response);
        }
        return;
      }
    }
    setProductRepresentatives(response);
  };

  useEffect(() => {
    getProductRepresentatives();
  }, []);

  const handleSkip = () => {
    navigate('/cart');

    // Set to intutec associate as appointedRep as default
    const intutecAssociate = productRepresentatives.find(
      (rep) =>
        rep.account_type === 'intutec' && rep.email === 'sales@in2tec.io',
    );

    setCartProducts((prev) => {
      return [
        ...prev,
        {
          product,
          quantity,
          appointedRep: intutecAssociate,
          referalID,
        },
      ];
    });
  };

  const handleBuyConfirm = (rep) => {
    setCartProducts((prev) => {
      return [
        ...prev,
        {
          product,
          quantity,
          appointedRep: rep,
          referalID,
        },
      ];
    });
    navigate('/cart');
  };

  const [activeIndex, setActiveIndex] = useState(0);

  const imageIndicators = useMemo(() => {
    if (!product || !product.images) return null;
    return product.images.map((image) => {
      if (image.imageType.toLowerCase() === 'gltf') {
        return <Image3DSVG width="50px" height="50px" />;
      }
      return (
        <img
          key={image.name}
          alt={image.name}
          src={image.url}
          style={{
            width: '50px',
            height: '50px',
            margin: '0 4px',
            objectFit: 'cover',
            objectPosition: 'center',
            borderRadius: '4px',
            boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
            imageRendering: 'crisp-edges',
          }}
        />
      );
    });
  }, [product]);

  const column1Tabs = [
    {
      title: (
        <Typography variant="h6" textTransform="none">
          {t('tabs.files.title')}
        </Typography>
      ),
      content: (
        <Box display="flex" flexDirection="column" gap={4}>
          <Box>
            <PublicProductFiles
              files={product?.files}
              images={product?.images}
            />
          </Box>

          <Box>
            <ProductApplicationsBox
              productApplications={product?.product_applications}
            />
          </Box>

          <Box>
            <ProductTypesBox productTypes={product?.product_types} />
          </Box>
        </Box>
      ),
    },
  ];

  const column2Tabs = [
    {
      title: (
        <Typography variant="h6" textTransform="none">
          {t('tabs.product_info.title')}
        </Typography>
      ),
      content: <ProductDescriptionBox product={product} displayTitle={false} />,
    },
    {
      title: (
        <Typography variant="h6" textTransform="none">
          {t('tabs.technical_specification.title')}
        </Typography>
      ),
      content: (
        <ProductAttributesBox
          productAttributes={product?.product_attributes}
          displayTitle={false}
        />
      ),
    },
    {
      title: (
        <Typography variant="h6" textTransform="none">
          {t('tabs.warranty.title')}
        </Typography>
      ),
      content: <WarrantyTermsBox product={product} displayTitle={false} />,
    },
  ];

  const column3Tabs = [
    {
      title: (
        <Typography variant="h6" textTransform="none">
          {t('tabs.purchase.title')}
        </Typography>
      ),
      content: (
        <>
          <AddToCartBox
            productRepresentatives={productRepresentatives}
            referalID={referalID}
          />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={2}
          >
            <Typography variant="body1">{transTypes('leadtime')}:</Typography>
            <Typography variant="body1">
              {product?.lead_time} {transTypes('days')}
            </Typography>
          </Box>
          <ProductRepresentativesContainer
            productRepresentatives={productRepresentatives}
            handleMessage={handleMessage}
            chats={conversations}
            displayTitle={true}
          />
        </>
      ),
    },
  ];

  if (!product) return <Skeleton height="60vh" />;

  return (
    <PublicProductPageContext.Provider
      value={{
        quantity,
        setQuantity,
        isBuyDialogOpen,
        setIsBuyDialogOpen,
        product,
        setProduct,
      }}
    >
      <Box
        mt={4}
        px={0}
        minHeight="80dvh"
        display="flex"
        flexDirection="column"
      >
        {/* Manufacturer */}
        <Typography variant="h6" textAlign="center">
          {product.brand_name}
        </Typography>

        {/* Product Name */}
        <Typography variant="h1" mt={2} textAlign="center">
          {product.product_name}
        </Typography>

        {/* Images */}
        <Box mb={2}>
          <Carousel
            style={{ flex: 1, minHeight: '700px' }}
            index={activeIndex}
            animation="slide"
            navButtonsAlwaysVisible={product.images.length > 1}
            autoPlay={false}
            swipe={false}
            IndicatorIcon={imageIndicators}
            indicatorContainerProps={{
              style: {
                background: theme.palette.background.default,
                margin: isShort ? '180px auto 0' : '0 auto',
                borderRadius: '5px',
                padding: 'px 0',
                borderColor: theme.palette.primary.main,
              },
            }}
            indicatorIconButtonProps={{
              style: {
                zIndex: 100,
              },
            }}
            activeIndicatorIconButtonProps={{
              style: {
                borderBottom: `3px solid ${theme.palette.primary.main}`,
                borderRadius: 0,
                paddingBottom: '5px',
              },
            }}
            NextIcon={
              <ArrowForwardIosIcon
                sx={{ color: (theme) => theme.palette.tertiary.contrastText }}
              />
            }
            PrevIcon={
              <ArrowBackIosNewIcon
                sx={{ color: (theme) => theme.palette.tertiary.contrastText }}
              />
            }
            navButtonsProps={{
              style: {
                display: product.images.length > 1 ? 'inline-flex' : 'none',
              },
            }}
            width={{ xs: '100vw', md: '100vw' }}
            height="50vh"
            onChange={(now) => setActiveIndex(now)}
          >
            {product.images.map((image, i) => (
              <CarouselItem
                key={i}
                image={image}
                showSliders={false}
                viewMode="view"
                initialCenter={true}
                enableZoom={true}
                wireframeMode={wireframeMode}
                autoRotate={autoRotate}
                autoRotateSpeed={0.5}
                showOrbitControls={true}
              />
            ))}
          </Carousel>
          {product.images[activeIndex]?.imageType?.toLowerCase() === 'gltf' && (
            <>
              {/* Wireframe Toggle */}
              <Box
                sx={{
                  position: 'absolute',
                  top: '6%',
                  right: '40px',
                  zIndex: 5,
                  p: 1,
                }}
              >
                <IntuIconButton
                  type={wireframeMode ? 'ar_off' : 'ar_on'}
                  tooltipTitle={
                    wireframeMode ? 'disable_wireframe' : 'enable_wireframe'
                  }
                  variant="outlined"
                  onClick={() => setWireframeMode((prev) => !prev)}
                />
              </Box>

              {/* Auto-Rotate Toggle */}
              <Box
                sx={{
                  position: 'absolute',
                  top: '8%',
                  right: '40px',
                  zIndex: 5,
                  p: 1,
                }}
              >
                <IntuIconButton
                  type={autoRotate ? 'stop' : 'play'}
                  tooltipTitle={autoRotate ? 'autoplay_stop' : 'autoplay_play'}
                  variant="outlined"
                  onClick={() => setAutoRoate((prev) => !prev)}
                />
              </Box>

              {/* AR Viewer */}
              {/* <Box
                sx={{
                  position: 'absolute',
                  top: '10%',
                  right: '10px',
                  zIndex: 5,
                }}
              >
  
                {isMobileView && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '8%',
                      right: '40px',
                      zIndex: 5,
                    }}
                  >
                    <Box sx={{ position: 'relative' }}>
                      <model-viewer
                        ref={modelViewerRef}
                        src={product.images[activeIndex].url}
                        alt={product.product_name}
                        ar
                        ar-modes="scene-viewer webxr quick-look"
                        environment-image="neutral"
                        auto-rotate
                        camera-controls
                        style={{
                          width: 1,
                          height: 1,
                          opacity: 0,
                          position: 'absolute',
                        }}
                      />

                      <IntuIconButton
                        type="assistant"
                        tooltipTitle="view_ar"
                        variant="outlined"
                        onClick={() => {
                          const viewer = modelViewerRef.current;
                          if (viewer?.activateAR) {
                            viewer.activateAR();
                          } else {
                            setErrorDialog({
                              open: true,
                              title: transMessages('ar_viewer.error.title'),
                              message: transMessages(
                                'ar_viewer.error.description',
                              ),
                            });
                          }
                        }}
                      />
                    </Box>
                  </Box>
                )}
              </Box> */}
            </>
          )}
        </Box>

        <Box
          display="flex"
          flexDirection={{ xs: 'column-reverse', lg: 'row' }}
          gap={4}
          my={4}
        >
          {/* Column 1 - Content */}
          <Box flex={0.25}>
            <FormikTabs tabsContent={column1Tabs} isScrollable={false} />
          </Box>

          {/* Column 2 - Content */}
          <Box flex={0.5}>
            <FormikTabs tabsContent={column2Tabs} isScrollable={true} />
          </Box>

          {/* Column 3 - Content */}
          <Box flex={0.25}>
            <FormikTabs tabsContent={column3Tabs} isScrollable={true} />
          </Box>
        </Box>

        <MessageDialog
          isOpen={isMessageDialogOpen}
          selectedRep={selectedRep}
          handleClose={handleMessageDialogClose}
          product={product}
          handleSetChats={handleSetChats}
          referalID={referalID}
        />
        <BuyDialog
          isOpen={isBuyDialogOpen}
          handleClose={() => setIsBuyDialogOpen(false)}
          handleConfirm={handleBuyConfirm}
          handleSkip={handleSkip}
          productName={product.product_name}
          quantity={quantity}
          productRepresentatives={productRepresentatives}
        />
      </Box>
    </PublicProductPageContext.Provider>
  );
};

export default PublicProductPage;
