import React, { useContext } from 'react';
import { CompanyProfileContext } from '../../../../../context/company/CompanyProfileProvider';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import CompanyBrandsField from '../../../../account/company/CompanyBrandsField';

const CompanyBrandsTab = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.account.company.CompanyBrandsTab',
  });
  const { companyProfile } = useContext(CompanyProfileContext);

  // console.log('Company Brands -> ', companyProfile?.brands);

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Box>
        <CompanyBrandsField
          fieldID="brands"
          fieldDescription={t('brands.description')}
          required={true}
          disabled={
            !companyProfile.isAdmin && companyProfile.type === 'manufacturer'
          }
          transition={
            companyProfile.isAdmin && companyProfile.type === 'manufacturer'
          }
          allowSelectBrand={true}
          allowOwnerRemoval={false}
        />
      </Box>
    </Box>
  );
};

export default CompanyBrandsTab;
