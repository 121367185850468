import { useState, useRef, useEffect, useContext } from 'react';
import { SketchPicker, ChromePicker } from 'react-color';
import { EditorState, Modifier, RichUtils } from 'draft-js';
import {
  Box,
  Fade,
  IconButton,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import IntuThemeContext from '../../context/providers/IntuThemeProvider';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { getNestedValue } from '../../helpers/getNestedValuesIn';
import ProcessingContext from '../../context/providers/ProcessingProvider';

const applyColor = (editorState, color) => {
  const selection = editorState.getSelection();
  const currentContent = editorState.getCurrentContent();
  const contentStateWithEntity = currentContent.createEntity(
    'COLOR',
    'MUTABLE',
    { color },
  );
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newContentState = Modifier.applyEntity(
    currentContent,
    selection,
    entityKey,
  );

  const newState = EditorState.push(
    editorState,
    newContentState,
    'apply-entity',
  );

  return RichUtils.toggleInlineStyle(newState, `COLOR-${color}`);
};

export const IntuColorPickerRichTextControl = ({
  editorState,
  setEditorState,
}) => {
  const [color, setColor] = useState('#000');

  const handleChangeComplete = (newColor) => {
    setColor(newColor.hex);
    const newState = applyColor(editorState, newColor.hex);
    setEditorState(newState);
  };

  return (
    <div style={{ position: 'relative' }}>
      <SketchPicker color={color} onChangeComplete={handleChangeComplete} />
    </div>
  );
};
export const ColorPickerField = ({
  fieldID = 'colors', //Name of the Target Field
  fieldTitle, // Title above the field
  fieldDescription, // Field description above the field
  defaultColor = '#000',
  disabled = false, //Whether the Field is disabled or not
  required = true, // Whether the fidl is required or not
  transition = true, // Whether to display the field ot not
  variant = 'outlined', // Variant
  transNS = 'fields', // Namespace for translation Value
  transPrefix = 'ColorPickerField', // KeyPrefix which holds label and placeholder,
  onChange, // Custom On Change Event
}) => {
  // Translations
  const { t: transFields } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const pickerRef = useRef(null);

  //   Formik Context
  const { values, touched, errors, setFieldValue } = useFormikContext();

  const { transitionInterval } = useContext(IntuThemeContext);

  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  const { isLoading } = useContext(ProcessingContext);

  const handleOnChange = (color) => {
    console.log(`Setting field ID ${fieldID} with color ${color}`);
    setFieldValue(fieldID, color);
  };

  const handlechange = (value, origin) => {
    let color;

    if (origin === 'field') {
      color = value.target.value;
    } else if (origin === 'picker') {
      color = value.hex;
    } else {
      return;
    }

    if (color.length === 0) {
      color = defaultColor;
    }

    onChange ? onChange(color) : handleOnChange(color);
  };

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setDisplayColorPicker(false);
    }
  };

  useEffect(() => {
    if (displayColorPicker) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [displayColorPicker]);

  return (
    transition &&
    (isLoading.status ? (
      <Skeleton variant="rectangular" width={'100%'} height={60} />
    ) : (
      <>
        {fieldTitle && (
          <Fade in={transition} timeout={transitionInterval}>
            <Typography variant="h4" textAlign={{ xs: 'center', md: 'left' }}>
              {fieldTitle}
            </Typography>
          </Fade>
        )}
        {fieldDescription && (
          <Fade in={transition} timeout={transitionInterval}>
            <Typography
              variant="body1"
              className="form-note"
              textAlign={{ xs: 'center', md: 'left' }}
              sx={{ marginBottom: '1rem' }}
            >
              {fieldDescription}
            </Typography>
          </Fade>
        )}

        <Box position="relative">
          <Box display="flex" alignItems="center">
            <Box
              style={{
                backgroundColor: fieldValue,
                width: 35,
                height: 35,
                border: '1px solid #000',
                marginRight: 8,
                cursor: 'pointer',
              }}
              onClick={handleClick}
            />
            <TextField
              value={fieldValue}
              onChange={(e) => handlechange(e, 'field')}
              variant={variant}
              margin="dense"
              label={transFields('label')}
              placeholder={transFields('placeholder')}
              disabled={disabled}
              required={required}
              error={fieldError && fieldTouched ? true : false}
              helperText={fieldError && fieldTouched ? fieldError : null}
            />
          </Box>
          {displayColorPicker && (
            <Box
              position="absolute"
              zIndex={1000}
              top={40} // Adjust the top value to position the picker
              left={0} // Adjust the left value to position the picker
              ref={pickerRef}
            >
              <Box display="flex" justifyContent="flex-end">
                <IconButton
                  style={{
                    color: 'var(--color-inputbox-border-error)',
                  }}
                  onClick={handleClose}
                >
                  <CancelIcon />
                </IconButton>
              </Box>
              <ChromePicker
                color={fieldValue}
                onChangeComplete={(e) => handlechange(e, 'picker')}
              />
            </Box>
          )}
        </Box>
      </>
    ))
  );
};
