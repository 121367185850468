import React, { useContext, useRef } from 'react';
import FormikFieldWrapper from '../formik/FormikFieldWrapper';

import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import { getNestedValue } from '../../helpers/getNestedValuesIn';
import ProcessingContext from '../../context/providers/ProcessingProvider';
import { Skeleton } from '@mui/material';

const PhoneField = ({
  fieldTitle, // Title above the field
  fieldDescription, // Field description above the field
  fieldID = 'phone', // Determines the Name and IDD of the Field
  transNS = 'fields', // Namespace for translation Value
  transTypeID = 'user', // Defines The Translation Type e.g. User, Company
  transPrefix = 'account.PhoneField', // KeyPrefix which holds label and placeholder
  transition = true, // Whether the field is being shown and faded in
  disabled = false, // Diables the field
  required = false, // Whether the field is required or not
  variant = 'outlined', // Standard or outlined
  onChange, // Override onChange behavior
  onBlur, // Override onBlur Beharios
  ...props
}) => {
  //   Field Reference
  const phoneRef = useRef();

  // Translation
  const { t: transFields } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  const { t: transTypes } = useTranslation('types');

  const { isLoading } = useContext(ProcessingContext);

  //   Formik Context
  const { values, touched, errors, handleBlur, setFieldValue } =
    useFormikContext();

  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  return isLoading.status ? (
    <Skeleton variant="rectangular" width={'100%'} height={60} />
  ) : (
    <FormikFieldWrapper
      fieldTitle={fieldTitle}
      fieldDescription={fieldDescription}
      transition={transition}
    >
      <MuiTelInput
        {...props}
        required={required}
        disabled={disabled}
        defaultCountry={values?.info?.countryCode || 'US'}
        ref={phoneRef}
        id={fieldID}
        variant={variant}
        name={fieldID}
        autoComplete="off"
        className="form-select-field"
        aria-invalid={errors[fieldID] ? 'true' : 'false'}
        label={transFields('label', { type: transTypes(transTypeID) })}
        placeholder={transFields('placeholder', {
          type: transTypes(transTypeID),
        })}
        onChange={(value, info) => {
          if (onChange) {
            onChange(value, info);
          } else {
            setFieldValue(fieldID, {
              value: value,
              info,
            });
          }
        }}
        onBlur={(e) => {
          if (onBlur) {
            onBlur(e);
          } else {
            handleBlur(e);
          }
        }}
        value={fieldValue?.value}
        error={
          fieldError && fieldTouched && fieldError?.info?.nationalNumber
            ? true
            : false
        }
        helperText={
          fieldError?.info?.nationalNumber && fieldTouched
            ? fieldError?.info?.nationalNumber
            : null
        }
      />
    </FormikFieldWrapper>
  );
};

export default PhoneField;
