import { createContext } from 'react';
import UploadDocumentDialog from '../../dialogs/fileUpload/UploadDocumentDialog';
import { useState } from 'react';
import ProductFiles from './ProductFiles';

export const ProductFileContext = createContext();

const ProductFilesContainer = () => {
  const [isUploadDocumentDialogOpen, setIsUploadDocumentDialogOpen] =
    useState(false);

  return (
    <ProductFileContext.Provider
      value={{ isUploadDocumentDialogOpen, setIsUploadDocumentDialogOpen }}
    >
      <ProductFiles displayTitle={false} />
      <UploadDocumentDialog />
    </ProductFileContext.Provider>
  );
};

export default ProductFilesContainer;
