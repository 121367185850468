import { useFormikContext } from 'formik';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { InputAdornment, Skeleton, TextField } from '@mui/material';
import { getNestedValue } from '../../../helpers/getNestedValuesIn';
import ProcessingContext from '../../../context/providers/ProcessingProvider';
import FormikFieldWrapper from '../../formik/FormikFieldWrapper';

const ProductNameField = ({
  fieldTitle, // Title above the field
  fieldDescription, // Field description above the field
  fieldID = 'product_name', // Determines the Name and IDD of the Field
  transNS = 'fields', // Namespace for translation Value
  transPrefix = 'ProductNameField', // KeyPrefix which holds label and placeholder
  transition = true, // Whether the field is being shown and faded in
  disabled = false, // Diables the field
  required = false, // Whether the field is required or not
  variant = 'outlined',
  isLoadingManual = false, // Set Loading State Manually
  showInitialErrors = false, // Show Errors even when the field has not been touched
  inputAdornment = null, // sign at the end of the field e.g. $, %
  ...props
}) => {
  const { t: transFields } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  const { values, errors, touched, handleBlur, setFieldValue } =
    useFormikContext();

  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  const { isLoading } = useContext(ProcessingContext);

  return isLoading.status || isLoadingManual ? (
    <Skeleton variant="rectangular" width={'100%'} height={60} />
  ) : (
    <FormikFieldWrapper
      fieldTitle={fieldTitle}
      fieldDescription={fieldDescription}
      transition={transition}
    >
      <TextField
        id={fieldID}
        name={fieldID}
        label={transFields('label')}
        placeholder={transFields('placeholder')}
        type="text"
        variant="outlined"
        onChange={(e) => setFieldValue(fieldID, e.target.value)}
        onBlur={handleBlur}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{inputAdornment}</InputAdornment>
          ),
          sx: {
            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
              {
                WebkitAppearance: 'none',
                margin: 0,
              },
            '& input[type=number]': {
              MozAppearance: 'textfield',
            },
          },
        }}
        value={fieldValue}
        error={
          showInitialErrors
            ? fieldError
              ? true
              : false
            : fieldTouched && fieldError
              ? true
              : false
        }
        helperText={
          showInitialErrors
            ? fieldError
              ? fieldError
              : null
            : fieldError && fieldTouched
              ? fieldError
              : null
        }
        {...props}
      />
    </FormikFieldWrapper>
  );
};

export default ProductNameField;
