import { useContext, useEffect, useState } from 'react';
import { Fade, Typography } from '@mui/material';
import IntuThemeContext from '../../context/providers/IntuThemeProvider';

const FormikFieldWrapper = ({
  fieldTitle, //Title of the Field (optional) String
  fieldDescription, //Description of the Field (optional) String
  transition = true, // Boolean
  transitionTime = 0, // Transition Time (optional) Number
  children, // The actual Field React.Element
}) => {
  const { transitionInterval } = useContext(IntuThemeContext);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (transition === true) {
      // FadeIn
      setIsVisible(transition);
    } else {
      // FadeOut
      setTimeout(() => setIsVisible(transition), transitionInterval);
    }
  }, [transition]);

  return (
    <>
      {isVisible && (
        <>
          {fieldTitle && (
            <Fade
              in={transition}
              timeout={transitionTime > 0 ? transitionTime : transitionInterval}
            >
              <Typography
                variant="h4"
                textTransform="none"
                textAlign={{ xs: 'center', md: 'left' }}
              >
                {fieldTitle}
              </Typography>
            </Fade>
          )}
          {fieldDescription && (
            <Fade
              in={transition}
              timeout={transitionTime > 0 ? transitionTime : transitionInterval}
            >
              <Typography
                variant="body1"
                className="form-note"
                textAlign={{ xs: 'center', md: 'left' }}
                sx={{ marginBottom: '1rem' }}
              >
                {fieldDescription}
              </Typography>
            </Fade>
          )}
          <Fade
            in={transition}
            timeout={transitionTime > 0 ? transitionTime : transitionInterval}
          >
            {children}
          </Fade>
        </>
      )}
    </>
  );
};

export default FormikFieldWrapper;
