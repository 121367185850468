import { useContext, useEffect, useMemo, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Box,
  Stack,
  InputAdornment,
  Typography,
  Button,
  createFilterOptions,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { createAddNewProductAttributeInitialValues } from './initialValues';
import { createAddNewProductValidationSchema } from './validationSchema';
import { AttributeContainerContext } from '../new-product/container/AttributeContainer';
import { ExtractDialogContext } from '../new-product/dialogs/ExtractDataDialog';
import { Formik } from 'formik';
import IntuIconButton from '../buttons/IntuIconButton';
import ProductAttributeTypeSelectionField from './fields/ProductAttributeTypeSelectionField';
import ProductAttributeSelectionField from './fields/ProductAttributeSelectionField';
import { measurementOptions, weightOptions } from '../../data/MeasruementUnits';
import UnitSelectionField from '../fields/unit/UnitSelectionField';
import RegularTextField from '../fields/text/RegularTextField';
import MeasurementField from '../fields/measurement/MeasurementField';
import { addNewAttribute } from '../../api/products/attributes/attributeRoutes';
import useResponseHandling from '../../hooks/useResponseHandler';

const NewAttributeDialog = () => {
  const { t } = useTranslation();
  const { t: transDialogs } = useTranslation('dialogs', {
    keyPrefix: 'NewAttribute',
  });
  const { t: transTypes } = useTranslation('types');
  const { t: transButtons } = useTranslation('buttons');

  const {
    newAttributeDialogOpen,
    setNewAttributeDialogOpen,
    editingItem,
    setEditingItem,
    unitSelectionList,
    handleItemAdd,
  } = useContext(ExtractDialogContext);

  const {
    setSelectedAttributeValue,
    selectedAttribute,
    setSelectedAttribute,
    selectedAttributeType,
    setSelectedAttributeType,
    productAttributeTypeOptions,
    productAttributeOptions,
    setProductAttributeOptions,
  } = useContext(AttributeContainerContext);

  const { handleRegularResponse, handleErrorResponse } = useResponseHandling();

  const [isMeasurement, setIsMeasurement] = useState(false);
  const [isWeight, setIsWeight] = useState(false);
  const [newAttribute, setNewAttribute] = useState({
    attribute_type: '',
    name: '',
    attribute: '',
  });
  const [isSaving, setIsSaving] = useState(false);

  // console.log('Edit Item DEtails -> ', editingItem);
  const initialValues = createAddNewProductAttributeInitialValues({
    selectedAttributeType,
    editingItem,
  });

  const validationSchema = createAddNewProductValidationSchema({
    selectedAttributeType,
  });

  useEffect(() => {
    // Set Unit Fields
    if (!selectedAttributeType?.data_keys?.length) {
      return;
    }

    // console.log('Evaluate Attribute Type -> ', selectedAttributeType);
    // console.log('Weight options -> ', weightOptions);
    const weightFilter = weightOptions.filter(
      (w) => w === selectedAttributeType?.data_keys[0],
    );

    // console.log('Weight Filter Result -> ', weightFilter);

    // console.log('Measurement options -> ', measurementOptions);
    const measurementFilter = measurementOptions.filter(
      (m) => m === selectedAttributeType?.data_keys[0],
    );
    // console.log('Measurement Filter Result -> ', measurementFilter);

    if (measurementFilter?.length > 0) {
      setIsMeasurement(true);
      setIsWeight(false);
    } else if (weightFilter?.length > 0) {
      setIsWeight(true);
      setIsMeasurement(false);
    }
  }, [selectedAttributeType, editingItem]);

  const filteredAttributeOptions = useMemo(() => {
    if (
      !selectedAttributeType ||
      Object.keys(selectedAttributeType).length === 0
    ) {
      return []; // Return an empty array instead of undefined
    }

    return productAttributeOptions.filter(
      (option) => option.attribute_type === selectedAttributeType._id,
    );
  }, [selectedAttributeType, productAttributeOptions]); // ✅ Added missing dependency

  const onConfirmClick = ({ values }) => {
    handleCloseClick();
    handleItemAdd(editingItem.attributeKey, values);
    setEditingItem(null);
  };

  // Mui Options
  const filter = createFilterOptions();

  const handleCloseClick = () => {
    setSelectedAttributeType(null);
    setSelectedAttribute(null);
    setSelectedAttributeValue(null);
    setEditingItem(null);
    setNewAttributeDialogOpen(false);
  };

  const handleAddAttribute = async (formik) => {
    const { values, setValues } = formik;
    setIsSaving(true);

    try {
      const addNewProductAttribute = await addNewAttribute(newAttribute);

      const { data, message } = addNewProductAttribute;

      if (addNewProductAttribute.statusCode === 200) {
        const [newAttribute] = data;

        setSelectedAttribute(newAttribute);

        setValues({
          ...values,
          attribute: newAttribute._id,
        });

        // Update the Options
        setProductAttributeOptions([...productAttributeOptions, newAttribute]);

        setNewAttribute({
          attribute_type: '',
          name: '',
          attribute: '',
        });

        handleRegularResponse({
          open: true,
          status: 'success',
          message: message,
        });
      } else {
        handleRegularResponse({
          open: true,
          status: 'error',
          message: message,
        });
      }
      setIsSaving(false);
    } catch (error) {
      handleErrorResponse(error);
    } finally {
      setIsSaving(false);
    }
  };

  // console.log('NewAttribute -> ', newAttribute);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={true}
      enableReinitialize={true}
      validateOnBlur={true}
    >
      {(formik) => {
        const { values, setValues, isValid } = formik;

        console.log('New Attribute Dialog -> ', values);
        return (
          <Dialog
            open={newAttributeDialogOpen}
            onClose={() => handleCloseClick(formik)}
            maxWidth="xs"
            fullWidth
            PaperProps={{
              sx: {
                color: 'white',
                backgroundColor: (theme) => `${theme.palette.grey.main}`,
                borderRadius: '2%', // Rounded box
                boxShadow: 'none',
              },
            }}
          >
            <DialogTitle>{transDialogs('title')}</DialogTitle>
            <DialogContent style={{ paddingTop: '20px' }}>
              <FormControl fullWidth>
                <Stack direction="column" spacing={2}>
                  {/* Attribute Type */}
                  <ProductAttributeTypeSelectionField
                    options={productAttributeTypeOptions}
                    required
                    onChange={(e, option) => {
                      setSelectedAttributeType(option);
                      setSelectedAttribute(null);
                      setValues({
                        ...values,
                        attribute_type: option?._id || '',
                        attribute: '',
                        unit: '',
                      });
                    }}
                    value={selectedAttributeType}
                    sx={{
                      '& label': {
                        color: 'white',
                      },
                      '& placeholder': {
                        color: 'white',
                      },
                      '& .MuiInputBase-input': {
                        color: 'white',
                      },
                      '& label.Mui-focused': {
                        color: 'white',
                      },
                      '& .MuiInput-underline:after': {
                        borderBottomColor: 'green.main',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'white',
                        },
                        '&:hover fieldset': {
                          borderColor: 'white',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'white',
                        },
                      },
                    }}
                  />

                  {/* Attribute  */}
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    width="100%"
                  >
                    <ProductAttributeSelectionField
                      options={filteredAttributeOptions}
                      handleHomeEndKeys
                      fullWidth
                      freeSolo
                      required
                      transition={values.attribute_type}
                      onInputChange={(event, newInputValue, reason) => {
                        console.log('onInputChange -> ', newInputValue, reason);
                        if (reason === 'input') {
                          // Handle free solo input

                          setValues({
                            ...values,
                            attribute: newInputValue,
                          });

                          setSelectedAttribute(null);

                          const formattedAttribute = newInputValue
                            .toLowerCase()
                            .replace(/\s+/g, '_');

                          setNewAttribute({
                            attribute_type: selectedAttributeType._id,
                            name: newInputValue,
                            attribute: formattedAttribute,
                          });
                        }
                      }}
                      onChange={(e, option, reason, element) => {
                        // Handle free solo input
                        setSelectedAttribute(option);
                        setNewAttribute({
                          attribute_type: '',
                          name: '',
                          attribute: '',
                        });
                        setValues({
                          ...values,
                          attribute: option?._id,
                          name: option?.name,
                        });
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        if (params.inputValue !== '' && filtered.length === 0) {
                          filtered.push({
                            inputValue: params.inputValue,
                            type_name: `${t('add')} "${params.inputValue}"`,
                          });
                        }
                        return filtered;
                      }}
                      value={selectedAttribute}
                      sx={{
                        '& label': {
                          color: 'white',
                        },
                        '& placeholder': {
                          color: 'white',
                        },
                        '& .MuiInputBase-input': {
                          color: 'white',
                        },
                        '& label.Mui-focused': {
                          color: 'white',
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: 'green.main',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'white',
                          },
                          '&:hover fieldset': {
                            borderColor: 'white',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'white',
                          },
                        },
                      }}
                    />

                    {newAttribute?.attribute && (
                      <IntuIconButton
                        disabled={!newAttribute.attribute || isSaving}
                        transition={!newAttribute.attribute}
                        tooltipTitle={transButtons('add')}
                        type="add"
                        variant="contained"
                        sx={{
                          marginLeft: 'auto',
                        }}
                        onClick={() => handleAddAttribute(formik)}
                      />
                    )}
                  </Stack>

                  {/* Unit Selection */}
                  <UnitSelectionField
                    fieldID="unit"
                    freeSolo={!isWeight.length && !isMeasurement.length}
                    options={unitSelectionList}
                    required={isWeight.length > 0 || isMeasurement.length > 0}
                    transition={selectedAttribute}
                    inputprops={{
                      sx: {
                        '& input': {
                          color: 'white', // Input text color
                        },
                        '& input::placeholder': {
                          color: 'white',
                        },
                      },
                    }}
                    sx={{
                      '& label': {
                        color: 'white',
                      },
                      '& placeholder': {
                        color: 'white',
                      },
                      '& label.Mui-focused': {
                        color: 'white',
                      },
                      '& .MuiInput-root': {
                        color: 'white',
                        borderBottomColor: 'green.main',
                      },
                      '& .MuiInput-underline:after': {
                        borderBottomColor: 'green.main',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'white',
                        },
                        '&:hover fieldset': {
                          borderColor: 'white',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'white',
                        },
                      },
                    }}
                  />

                  {/* Measurement ValueField */}

                  <MeasurementField
                    fieldID="value"
                    transTypeID={transTypes('value')}
                    inputAdornment={values.unit}
                    required={isWeight.length > 0 || isMeasurement.length > 0}
                    allowNegative={true}
                    transition={
                      (isWeight.length > 0 || isMeasurement.length > 0) &&
                      selectedAttribute &&
                      selectedAttributeType
                    }
                    decimalScale={2}
                    sx={{
                      '& label': {
                        color: 'white',
                      },
                      '& placeholder': {
                        color: 'white',
                      },
                      '& label.Mui-focused': {
                        color: 'white',
                      },
                      '& .MuiInput-underline:after': {
                        borderBottomColor: 'green.main',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'white',
                        },
                        '&:hover fieldset': {
                          borderColor: 'white',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'white',
                        },
                      },
                    }}
                    inputprops={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography color="white">{values.unit}</Typography>
                        </InputAdornment>
                      ),
                      sx: {
                        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                          {
                            WebkitAppearance: 'none',
                            margin: 0,
                          },
                        '& input[type=number]': {
                          MozAppearance: 'textfield',
                        },
                        '& input': {
                          color: 'white',
                        },
                        '& input::placeholder': {
                          color: 'white',
                        },
                      },
                    }}
                  />

                  {/* Regular Value Field */}
                  <RegularTextField
                    fieldID="value"
                    transTypeID="value"
                    inputAdornment={values.value}
                    transition={
                      !isWeight.length &&
                      !isMeasurement.length &&
                      selectedAttribute &&
                      selectedAttributeType
                    }
                    required={!isWeight.length || !isMeasurement.length}
                    inputprops={{
                      sx: {
                        '& input': {
                          color: 'white', // Input text color
                        },
                        '& input::placeholder': {
                          color: 'white',
                        },
                      },
                    }}
                    sx={{
                      '& label': {
                        color: 'white',
                      },
                      '& placeholder': {
                        color: 'white',
                      },
                      '& label.Mui-focused': {
                        color: 'white',
                      },
                      '& .MuiInput-root': {
                        color: 'white',
                        borderBottomColor: 'green.main',
                      },
                      '& .MuiInput-underline:after': {
                        borderBottomColor: 'green.main',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'white',
                        },
                        '&:hover fieldset': {
                          borderColor: 'white',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'white',
                        },
                      },
                    }}
                  />
                </Stack>
              </FormControl>
            </DialogContent>
            <DialogActions
              style={{ paddingBottom: '16px', justifyContent: 'space-between' }}
            >
              <Box>
                <IntuIconButton
                  onClick={() => handleCloseClick({ formik })}
                  type="cancel"
                  variant="contained"
                  tooltipTitle={t('buttons.close')}
                />
              </Box>

              <Box sx={{ marginLeft: 'auto' }}>
                <IntuIconButton
                  onClick={() => onConfirmClick({ values, formik })}
                  color="primary"
                  type="submit"
                  disabled={!isValid || newAttribute?.attribute}
                  variant="contained"
                  tooltipTitle={t('buttons.confirm')}
                />
              </Box>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default NewAttributeDialog;
