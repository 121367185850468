import React, { useContext } from 'react';
import { CompanyProfileContext } from '../../../../../context/company/CompanyProfileProvider';
import { useTranslation } from 'react-i18next';
import CompanyIntroField from '../../../../account/company/CompanyIntroField';
import AddressField from '../../../../address/AddressField';
import { Box } from '@mui/material';
import PhoneField from '../../../../account/PhoneField ';

const AboutCompanyTab = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.account.company.AboutCompanyTab',
  });
  const { t: transMessages } = useTranslation('messages');
  const { companyProfile } = useContext(CompanyProfileContext);

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Box>
        <CompanyIntroField
          fieldTitle={t('about.title')}
          fieldDescription={
            companyProfile.initial_setup === false
              ? transMessages('global.preparing')
              : t('about.description')
          }
          required={true}
          disabled={!companyProfile.isAdmin}
          showIntroAssistant={true}
          initialSetupComplete={companyProfile.initial_setup}
        />
      </Box>

      <Box>
        <AddressField
          fieldTitle={t('address.title')}
          fieldID="address"
          autocompleteID="address"
          fieldDescription={t('address.description')}
          required={true}
          disabled={!companyProfile.isAdmin}
        />
      </Box>

      <Box>
        <PhoneField
          fieldTitle={t('phone.title')}
          fieldDescription={t('phone.description')}
          disabled={!companyProfile.isAdmin}
          transNS="fields"
          transPrefix="account.company.PhoneField"
        />
      </Box>
    </Box>
  );
};

export default AboutCompanyTab;
