import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
} from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { createAddNewProductValidationSchema } from './validationSchema';
import { createAddNewProductAttributeInitialValues } from './initialValues';
import IntuIconButton from '../../../buttons/IntuIconButton';
import { AttributeContainerContext } from '../../container/AttributeContainer';
import { ProductAttributeContext } from '../selection/AttributeSelection';
import RegularTextField from '../../../fields/text/RegularTextField';
import { addNewAttribute } from '../../../../api/products/attributes/attributeRoutes';
import useResponseHandling from '../../../../hooks/useResponseHandler';
import StatusBarComponent from '../../../status/StatusBar';

const NewAttributeDialog = () => {
  const { t: transDialogs } = useTranslation('dialogs');
  const { t: transButtons } = useTranslation('buttons');
  const { handleRegularResponse } = useResponseHandling();

  const { newAttributeDialogOpen, setNewAttributeDialogOpen } = useContext(
    ProductAttributeContext,
  );

  const {
    selectedAttributeType,
    productAttributeOptions,
    setProductAttributeOptions,
  } = useContext(AttributeContainerContext);

  const initialValues = createAddNewProductAttributeInitialValues();

  const validationSchema = createAddNewProductValidationSchema();

  const [isSaving, setIsSaving] = useState(false);

  const handleConfirm = async (values) => {
    setIsSaving(true);
    const attribute_type = selectedAttributeType._id;
    const attribute = values.value.replace(' ', '_').toLowerCase();
    const name = values.value;

    const newAttribute = {
      attribute_type,
      name,
      attribute,
    };

    console.log('New Attribute', newAttribute);

    const addNewProductAttribute = await addNewAttribute({
      attribute_type,
      attribute,
      name,
    });

    const { data, message } = addNewProductAttribute;

    if (addNewProductAttribute.statusCode === 200) {
      const [newAttribute] = data;

      handleRegularResponse({
        open: true,
        status: 'success',
        message: message,
      });

      setProductAttributeOptions([...productAttributeOptions, newAttribute]);

      setNewAttributeDialogOpen(false);
    } else {
      handleRegularResponse({
        open: true,
        status: 'error',
        message: message,
      });
    }
    setIsSaving(false);
  };

  return (
    <Dialog
      open={newAttributeDialogOpen}
      onClose={() => setNewAttributeDialogOpen(false)}
      width="md"
      PaperProps={{
        sx: {
          color: 'white',
          backgroundColor: (theme) => `${theme.palette.grey.main}`,
          borderRadius: '2%', // Rounded box
          boxShadow: 'none',
        },
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        enableReinitialize={true}
        validateOnBlur={true}
      >
        {(formik) => {
          const { values, isValid, resetForm } = formik;

          return (
            <Form role="form">
              <DialogTitle color="white">
                {transDialogs('AttributeContainer.title')}
              </DialogTitle>

              <DialogContent>
                <Stack
                  direction="column"
                  spacing={2}
                  sx={{ paddingTop: '1rem' }}
                >
                  <RegularTextField
                    fieldID="value"
                    required
                    inputprops={{
                      sx: {
                        '& input': {
                          color: 'white', // Input text color
                        },
                        '& input::placeholder': {
                          color: 'white',
                        },
                      },
                    }}
                    sx={{
                      '& label': {
                        color: 'white',
                      },
                      '& placeholder': {
                        color: 'white',
                      },
                      '& label.Mui-focused': {
                        color: 'white',
                      },
                      '& .MuiInput-root': {
                        color: 'white',
                        borderBottomColor: 'green.main',
                      },
                      '& .MuiInput-underline:after': {
                        borderBottomColor: 'green.main',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'white',
                        },
                        '&:hover fieldset': {
                          borderColor: 'white',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'white',
                        },
                      },
                    }}
                  />
                </Stack>
              </DialogContent>
              <DialogActions
                style={{
                  paddingBottom: '16px',
                }}
              >
                <Grid container direction="row">
                  <Grid item>
                    <IntuIconButton
                      type="cancel"
                      tooltipTitle={transButtons('cancel')}
                      onClick={() => {
                        resetForm();
                        setNewAttributeDialogOpen(false);
                      }}
                      variant="contained"
                    />
                  </Grid>

                  <Grid item sx={{ marginLeft: 'auto' }}>
                    <IntuIconButton
                      disabled={!isValid}
                      tooltipTitle={transButtons('confirm')}
                      type="submit"
                      variant="contained"
                      sx={{
                        marginLeft: 'auto',
                      }}
                      isLoading={isSaving}
                      onClick={() => handleConfirm(values)}
                    />
                  </Grid>
                </Grid>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
      <StatusBarComponent />
    </Dialog>
  );
};

export { NewAttributeDialog };
