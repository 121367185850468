import { useMemo } from 'react';
import { Box, Chip, Typography } from '@mui/material';

const ProductAttributesBox = ({ productAttributes, displayTitle }) => {
  const productAttributeTypeSet = useMemo(() => {
    const set = new Set();
    productAttributes.forEach((a) => {
      set.add(a.attribute_type);
    });
    return set;
  }, [productAttributes]);
  return (
    <Box display="flex" flexDirection="column" my={4}>
      {displayTitle && (
        <Typography variant="h6">
          {/* {t('title')} */}
          Product Attributes
        </Typography>
      )}
      {productAttributeTypeSet.size
        ? productAttributeTypeSet.map((type) => {
            const localSet = new Set();
            productAttributes.forEach((a) => {
              if (a.attribute_type === type) {
                localSet.add(a);
              }
            });
            return (
              <Box key={type}>
                <Typography textTransform="capitalize" my={1}>
                  {type}:
                </Typography>

                {localSet.size
                  ? localSet.map((attribute) => {
                      const [value] = attribute.values;
                      const formattedValue = `${value.value} ${value.unit}`;
                      return (
                        <Chip
                          key={attribute.attribute}
                          label={`${attribute.attribute} - ${formattedValue}`}
                          variant="outlined"
                          sx={{
                            m: 1,
                            color: (theme) =>
                              theme.palette.tertiary.contrastText,
                          }}
                        />
                      );
                    })
                  : null}
              </Box>
            );
          })
        : null}
    </Box>
  );
};

export default ProductAttributesBox;
