import {
  Box,
  Button,
  Fade,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { IntuRichTextEditor } from '../../IntuRichTextEditor/IntuRichtTextEditor';
import { useFormikContext } from 'formik';
import { convertToRaw } from 'draft-js';
import { useTranslation } from 'react-i18next';
import IntuThemeContext from '../../../context/providers/IntuThemeProvider';
import { useContext } from 'react';
import ProcessingContext from '../../../context/providers/ProcessingProvider';
import GenerateProfileIntroDialog from '../GenerateProfileIntroDialog';
import {
  ProfileIntroProvider,
  ProfileIntroductionContext,
} from '../../../context/providers/ProfileIntroContextProvider';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import IntuIconButton from '../../buttons/IntuIconButton';

const CompanyIntroFieldContent = ({
  fieldTitle, // field Title
  fieldDescription, // Field Description
  transition = true, // Fade in = true and Fade Out = false
  required = false, // Whethher the field is required or not
  disabled = false, // Whether the Field is disabled
  showIntroAssistant = false, // Whether or not to show theIntro Assistant Button
  initialSetupComplete = true,
}) => {
  //SetUp Trnaslation
  const { t } = useTranslation('fields', {
    keyPrefix: 'account.company.CompanyIntroField',
  });

  const { transitionInterval } = useContext(IntuThemeContext);

  const { touched, setFieldTouched, values, setFieldValue, errors } =
    useFormikContext();

  const { setIntroDialog } = useContext(ProfileIntroductionContext);

  const { isLoading } = useContext(ProcessingContext);

  return (
    <>
      {fieldTitle && (
        <Fade in={transition} timeout={transitionInterval}>
          <Typography
            variant="h4"
            textTransform="none"
            textAlign={{ xs: 'center', md: 'left' }}
          >
            {fieldTitle}
          </Typography>
        </Fade>
      )}
      {fieldDescription && (
        <Fade in={transition} timeout={transitionInterval}>
          <Typography
            className="form-note"
            textAlign={{ xs: 'center', md: 'left' }}
            sx={{ mb: 2 }}
          >
            {fieldDescription}
          </Typography>
        </Fade>
      )}
      {isLoading.status || initialSetupComplete === false ? (
        <Skeleton variant="rectangular" width={'100%'} height={100} />
      ) : (
        <Box display="flex" flexDirection="column" gap={2}>
          <Box>
            <IntuRichTextEditor
              id="intro_rte"
              name="intro_rte"
              required={required}
              disabled={disabled}
              controls={[
                'title',
                'bold',
                'italic',
                'underline',
                'fontColor',
                'numberList',
                'bulletList',
                'quote',
              ]}
              className="form-select-field"
              aria-describedby="uidnote"
              variant="standard"
              label={t('label', { type: 'Company' })}
              defaultValue={JSON.stringify(values?.intro_rte)}
              value={JSON.stringify(values?.intro_rte)}
              onChange={(event) => {
                const rteContent = convertToRaw(event.getCurrentContent());
                rteContent && setFieldValue('intro', rteContent);
                setFieldTouched('intro', true);
              }}
              onBlur={() => setFieldTouched('intro', true)}
              inlineToolbar={true}
              error={errors?.intro && touched?.intro ? true : false}
              helperText={
                errors?.intro && touched?.intro ? errors?.intro : null
              }
            />
          </Box>

          {showIntroAssistant && !disabled && (
            <Box sx={{ ml: 'auto' }}>
              <IntuIconButton
                variant="outlined"
                type="assistant"
                color="info"
                tooltipTitle={t('buttons.assistant')}
                onClick={() => setIntroDialog(true)}
              />
            </Box>
          )}

          {/* CompanIntroDialog */}
          <GenerateProfileIntroDialog mode="company" />
        </Box>
      )}
    </>
  );
};

const CompanyIntroField = (props) => {
  return (
    <ProfileIntroProvider>
      <CompanyIntroFieldContent {...props} />
    </ProfileIntroProvider>
  );
};

export default CompanyIntroField;
