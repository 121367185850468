import { useTranslation } from 'react-i18next';
import FormContextProvider from '../../context/layout/FormContextProvider';
import DashboardLayoutComponent from '../../components/layout/DashboardLayout';
import useAuth from '../../hooks/useAuth';
import ManageReferralsComponent from '../../components/referrals/ManageReferrals';

function ManageReferralsListPage() {
  const { auth } = useAuth();
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.ManageReferralsListPage',
  });

  return (
    <FormContextProvider>
      {auth.user_info.account_type === 'manufacturer' ? (
        <DashboardLayoutComponent
          dashboardTitle={t('manufacturer.title')}
          dashboardDescription={t('manufacturer.description')}
        >
          <ManageReferralsComponent />
        </DashboardLayoutComponent>
      ) : auth.user_info.account_type === 'salesrep' ||
        auth.user_info.account_type !== 'referrals' ? (
        <DashboardLayoutComponent
          dashboardTitle={t('refferer.title')}
          dashboardDescription={t('refferer.description')}
        >
          <ManageReferralsComponent />
        </DashboardLayoutComponent>
      ) : auth.user_info.account_type === 'intutec' ? (
        <DashboardLayoutComponent
          dashboardTitle={t('admin.title')}
          dashboardDescription={t('admin.description')}
        >
          <ManageReferralsComponent />
        </DashboardLayoutComponent>
      ) : null}
    </FormContextProvider>
  );
}

export default ManageReferralsListPage;
