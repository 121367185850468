import { FormControlLabel, Switch } from '@mui/material';
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.primary.main,
  },
}));

const SimpleToggle = ({ isChecked, setIsChecked, text, disabled }) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      control={
        <Switch
          checked={isChecked}
          disabled={disabled}
          onChange={() => setIsChecked(!isChecked)}
          name="simple_toggle"
          color="primary"
        />
      }
      label={text}
      classes={{
        label: classes.label,
      }}
    />
  );
};

export default SimpleToggle;
