import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import IntuIconButton from '../../../buttons/IntuIconButton';
import { useStripe } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { CreateAddPersonValidationSchema } from './validationSchema';
import CountryField from '../../../address/CountryField';
import useResponseHandling from '../../../../hooks/useResponseHandler';
import { StripeContext } from '../../../../context/providers/stripe/StripeContextProvider';
import countryList from '../../../../data/CountryData.json';
import UserContext from '../../../../context/users/UserInfoProvider';
import PersonRelationshipField from './PersonRelationshipField';
import IndividualFirstNameField from '../../individual/IndividualFirstNameField';
import IndividualLastNameField from '../../individual/IndividualLastNameField';
import IndividualDOBField from '../../individual/IndividualDOBField';
import AddressField from '../../../address/AddressField';
import IndividualMobileNumberField from '../../individual/IndividualMobileNumberField';
import EmailField from '../../fields/email/EmailField';
import IndividualIDField from '../../individual/IndividualIDField';
import IndividualSSNField from '../../individual/IndividualSSNField';
import IndividualPoliticalExposureField from '../../individual/IndividualPoliticalExposureField';
import IndividualJobTitleField from '../../individual/IndividualJobTitleField';
import PersonOwnershipField from './PersonOwnershipField';
import { addAccountPerson } from '../../../../api/stripe/persons';

const AddStripePerson = ({
  open, //Modal Open State,
  onClose, // Modal Closed State,
  transNS = 'translation',
  transPrefix = 'components.stripe.AddPerson',
  getPersonsList = () => {},
}) => {
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();

  const { stripeUserData } = useContext(StripeContext);
  const { user } = useContext(UserContext);

  // console.log('Stripe User Data ->', stripeUserData);

  const [userCountryDetails, setUserCountryDetails] = useState({
    label: 'United States',
    dialCode: '1',
    code: 'US',
    currency: 'USD',
  });

  // Country Data
  useEffect(() => {
    const userCountry = user.location.country || null;

    if (userCountry) {
      const userCountryData = countryList.find(
        (element) => userCountry === element.code,
      );
      setUserCountryDetails(userCountryData);
    }
  }, [stripeUserData?.stripe_connect_data?.country, open]);

  const initialValues = {
    first_name: '', // Persons First Name
    last_name: '', // Persons Last Name,
    dob: null, // Date of Birth
    email: '', // Email
    id_number: '', // ID or Passport Number
    phone: {
      value: '',
      info: {
        countryCallingCode: null,
        countryCode: null,
        nationalNumber: null,
        numberType: null,
        numberValue: null,
        reason: null,
      },
    }, // Phone Number
    relationship: {
      director: false,
      executive: false,
      owner: false,
      representative: false,
    }, // Relationship to the Company
    ssn_last_4: '', // Last 4 of SSN (US Onle)
    percent_ownership: 0, // Percentage of Company Ownership (also Applies to Individuals)
    title: '', // Job title
    nationality: {
      label: '',
      dialCode: '',
      code: '',
      currency: '',
    }, // Nationality
    address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      postcode: '',
      country: userCountryDetails || '',
    }, // Address
    political_exposure: '', // Political Exposure
  };

  const validationSchema = CreateAddPersonValidationSchema();

  const stripe = useStripe();

  const handleSubmit = async (values, formik) => {
    formik.setSubmitting(true);
    try {
      // Extract values
      const day = values?.dob.$D;
      const month = values?.dob.$M + 1; // Add 1 to adjust for zero-indexing
      const year = values?.dob.$y;

      const accountDetails = Object.entries({
        relationship: {
          ...values?.relationship,
          percent_ownership: values?.percent_ownership,
          title: values?.title,
        },
        first_name: values?.first_name,
        last_name: values?.last_name,
        phone: values?.phone?.value,
        email: values?.email,

        address: {
          line1: values?.address?.line1,
          line2: values?.address?.line2,
          postal_code: values?.address?.postcode,
          city: values?.address?.city,
          state: values?.address?.state,
          country: values?.address?.country.code,
        },
        id_number: values?.id_number || null,
        ssn_last_4: values?.ssn_last_4,
        nationality: values?.nationality?.label,
        dob: {
          day: day,
          month: month,
          year: year,
        },
        political_exposure: values?.political_exposure,
      })
        .filter(
          ([key, value]) =>
            value !== '' && value !== null && value !== undefined,
        ) // Remove invalid values
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});

      console.log('Account Details Payload ->', accountDetails);

      const requestToken = await stripe.createToken('person', accountDetails);

      if (requestToken?.token) {
        const token = requestToken.token;
        // Create Stripe Account

        const addPerson = await addAccountPerson(token.id);

        if (addPerson?.data.statusCode === 201) {
          const response = addPerson.data;
          handleRegularResponse({
            open: true,
            status: response.status,
            statusCode: response.statusCode,
            message: response.message,
          });
          getPersonsList();
          formik.resetForm();
          onClose();
        } else {
          const response = addPerson.data;
          handleRegularResponse({
            open: true,
            status: response.status,
            statusCode: response.statusCode,
            message: response.message,
          });
        }
      } else {
        // Error Requesting Token
        const response = requestToken?.error;
        handleRegularResponse({
          open: true,
          status: 'error',
          statusCode: 400,
          message: response.message,
        });
      }
    } catch (error) {
      console.error('Error generating token', error);
      handleErrorResponse(error);
    } finally {
      formik.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={true}
      enableReinitialize={true}
      validateOnMount={true}
      validateOnBlur={true}
      initialErrors={{ keywords: true }}
    >
      {(formik) => {
        const { values, isSubmitting, isValid, setValues } = formik;
        return (
          <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
              component: 'form',
            }}
          >
            <DialogTitle> {t('title')}</DialogTitle>

            <DialogContent>
              <DialogContentText sx={{ marginBottom: '2rem' }}>
                {t('description')}
              </DialogContentText>

              {/* Relationship & functions */}
              <Grid item sx={{ marginBottom: '1rem' }}>
                <Grid item sx={{ marginBottom: '1rem' }}>
                  <Typography variant="h6">{t('person_role.title')}</Typography>
                  <Typography variant="body1">
                    {t('person_role.description')}
                  </Typography>
                </Grid>
                <Grid item sx={{ marginBottom: '1rem' }}>
                  <Stack spacing={2}>
                    {/* Persons Relationship*/}
                    <PersonRelationshipField
                      country={userCountryDetails.code}
                      required
                      isLoadingManual={isSubmitting}
                    />

                    {values?.relationship === 'owner' && (
                      <PersonOwnershipField
                        required
                        isLoadingManual={isSubmitting}
                      />
                    )}

                    {/* Job Title */}
                    <IndividualJobTitleField
                      required
                      isLoadingManual={isSubmitting}
                    />
                  </Stack>
                </Grid>
              </Grid>

              {/* Identity */}
              <Grid item sx={{ marginBottom: '2rem' }}>
                <Grid item sx={{ marginBottom: '1rem' }}>
                  <Typography variant="h6">
                    {t('person_idendity.title')}
                  </Typography>
                  <Typography variant="body1">
                    {t('person_idendity.description')}
                  </Typography>
                </Grid>
                <Grid item sx={{ marginBottom: '1rem' }}>
                  <Stack spacing={2}>
                    {/* Nationality */}
                    <CountryField
                      fieldID="nationality"
                      required
                      isLoadingManual={isSubmitting}
                      onChange={(e, option) => {
                        console.log('Option -> ', option);
                        setValues({
                          ...values,
                          nationality: option,
                          ssn_last_4: '',
                          id_number: '',
                        });
                      }}
                    />

                    {/* ID Number */}
                    {values?.nationality.code === 'US' ? (
                      <IndividualSSNField
                        required
                        isLoadingManual={isSubmitting}
                        transPrefix="individual.verification.ssn_last_4"
                      />
                    ) : (
                      <IndividualIDField
                        required
                        isLoadingManual={isSubmitting}
                        transPrefix="individual.verification.id_number"
                      />
                    )}
                  </Stack>
                </Grid>
              </Grid>

              {/* Personal Details */}
              <Grid item sx={{ marginBottom: '2rem' }}>
                <Grid item sx={{ marginBottom: '1rem' }}>
                  <Typography variant="h6">
                    {t('person_details.title')}
                  </Typography>
                  <Typography variant="body1">
                    {t('person_details.description')}
                  </Typography>
                </Grid>

                <Stack spacing={2}>
                  <Stack spacing={2} direction="row">
                    {/* First Name */}
                    <IndividualFirstNameField
                      fieldID="first_name"
                      required
                      isLoadingManual={isSubmitting}
                    />

                    {/* Last Name */}
                    <IndividualLastNameField
                      fieldID="last_name"
                      required
                      isLoadingManual={isSubmitting}
                    />
                  </Stack>

                  {/* Date of Birth */}
                  <IndividualDOBField
                    fieldID="dob"
                    required
                    isLoadingManual={isSubmitting}
                  />

                  {/* Address */}
                  <AddressField
                    fieldID="address"
                    required
                    isLoadingManual={isSubmitting}
                  />

                  {/* Phone */}
                  <IndividualMobileNumberField
                    fieldID="phone"
                    required
                    isLoadingManual={isSubmitting}
                    transPrefix="individual.verification.phone"
                  />

                  {/* Email */}
                  <EmailField
                    fieldID="email"
                    required
                    isLoadingManual={isSubmitting}
                    transPrefix="individual.verification.email"
                  />
                </Stack>
              </Grid>

              {/* Exposure */}
              <Grid item sx={{ marginBottom: '1rem' }}>
                <Grid item sx={{ marginBottom: '1rem' }}>
                  <Grid item sx={{ marginBottom: '1rem' }}>
                    <Typography variant="h6">
                      {t('person_exposure.title')}
                    </Typography>
                    <Typography variant="body1">
                      {t('person_exposure.description')}
                    </Typography>
                  </Grid>
                  <Grid item sx={{ marginBottom: '1rem' }}>
                    {/* Political Exposure */}
                    <IndividualPoliticalExposureField
                      required
                      isLoadingManual={isSubmitting}
                      transPrefix="individual.verification.political_exposure"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid container sx={{ margin: '1rem 1rem 0 1rem' }}>
                <Grid item>
                  <IntuIconButton
                    type="cancel"
                    onClick={onClose}
                    tooltipTitle="cancel"
                  />
                </Grid>
                <Grid item sx={{ marginLeft: 'auto' }}>
                  <IntuIconButton
                    type="submit"
                    disabled={!isValid || isSubmitting}
                    onClick={() => {
                      handleSubmit(values, formik);
                    }}
                    tooltipTitle="Add"
                  />
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export { AddStripePerson };
