import i18next from 'i18next';
import * as Yup from 'yup';

export const createValidationSchemaCurrencyConversionContainer = () => {
  const t = i18next.getFixedT(null, 'validation');

  return Yup.object().shape({
    from: Yup.string().required(t('currency.required')),
    amount: Yup.number()
      .required(t('amount.required'))
      .min(0.01, t('amount.min')),
  });
};
