import { Box, Paper } from '@mui/material';
import CanvasContainer from '../../new-product/container/image/CanvasContainer';
import { useState } from 'react';

export const CarouselItem = ({
  image,
  viewMode,
  showSliders,
  showOrbitControls,
  setIsModelInteracting,
  isModelInteracting,
  wireframeMode,
  setWireframeMode,
  enableZoom,
  autoRotate,
  autoRotateSpeed,
  initialCenter = false,
  maxDistance,
}) => {
  const {
    rotationX: savedRotationX,
    rotationY: savedRotationY,
    cameraPosition: savedCameraPosition,
  } = image.options;
  const [initialMeshes, setInitialMeshes] = useState([]);
  const [meshes, setMeshes] = useState([]);
  const [cameraPosition, setCameraPosition] = useState(
    savedCameraPosition ?? [0, 0, 50],
  );
  const [rotationX, setRotationX] = useState(savedRotationX ?? 0);
  const [rotationY, setRotationY] = useState(savedRotationY ?? 0);
  const [isProductImageDirtied, setIsProductImageDirtied] = useState(false);
  const imageType = image.imageType.toLowerCase();
  if (imageType === 'stp' || imageType === 'step') {
    return null;
  }
  if (imageType === 'gltf' && image?.url) {
    return (
      <CanvasContainer
        url={image?.url}
        options={image.options}
        viewMode={viewMode}
        showSliders={showSliders}
        showOrbitControls={showOrbitControls}
        rotationX={rotationX}
        setRotationX={setRotationX}
        rotationY={rotationY}
        setRotationY={setRotationY}
        cameraPosition={cameraPosition}
        setCameraPosition={setCameraPosition}
        setIsProductImageDirtied={setIsProductImageDirtied}
        meshes={meshes}
        setMeshes={setMeshes}
        setInitialMeshes={setInitialMeshes}
        setIsModelInteracting={setIsModelInteracting}
        isModelInteracting={isModelInteracting}
        wireframeMode={wireframeMode}
        setWireframeMode={setWireframeMode}
        enableZoom={enableZoom}
        autoRotate={autoRotate}
        autoRotateSpeed={autoRotateSpeed}
        initialCenter={initialCenter}
        maxDistance={maxDistance}
      />
    );
  }
  return (
    <Paper
      elevation={0}
      square
      sx={{
        background: 'transparent',
        display: 'flex',
        justifyContent: 'center',
        height: '40vh',
      }}
    >
      <Box
        width="80%"
        height="80%"
        mt={6}
        sx={{ objectFit: 'contain' }}
        component="img"
        src={image?.url}
      />
    </Paper>
  );
};
