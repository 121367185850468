import i18next from 'i18next';
import * as Yup from 'yup';

export const createValidationSchemaCommissionBox = () => {
  const t = i18next.getFixedT(null, 'validation');

  return Yup.object().shape({
    type: Yup.string().required(t('commission.type.required')),
    commission: Yup.object().shape({
      max_amount: Yup.number()
        .required(t('commission.max_amount.required'))
        .min(0.01, t('commission.max_amount.min')),
      commission: Yup.number()
        .min(0.01, t('commission.commission.min'))
        .max(1, t('commission.commission.max'))
        .required(t('commission.commission.required')),
    }),
  });
};
