import { getUserCountryDetails } from '../../../../helpers/getUserCountryDetails';
import { getUserPhoneDetails } from '../../../../helpers/getUserPhoneDetails';

export const createInitialValuesBusinessDetailsForm = ({ user, auth }) => {
  //Guard Clause
  if (!user || !auth) {
    return;
  }

  let businessType = '';
  if (auth?.user_info?.account_type === 'manufacturer') {
    businessType = 'company';
  }

  const countryCode = user.location.country || 'US';

  return {
    account_type: auth?.user_info?.account_type || null,
    business_type: businessType,
    company: {
      _id: '',
      name: '',
      address: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        postcode: '',
        country: getUserCountryDetails(user),
        coordinates: { latitude: '', longitude: '' },
      },
      website: 'https://',
      email: '',
      phone: getUserPhoneDetails(countryCode),
      employees: [],
      isSubsidiary: false,
      parent_id: '',
      brands: [],
    },
  };
};

export const createInitialValuesIndustriesAndApplicationsForm = () => {
  return {
    applications: [],
    industries: [],
    product_types: [],
  };
};

export const createInitialValuesPersonalDetailsform = ({
  user,
  auth,
  userProfile,
}) => {
  //Guard Clause
  if (!user) {
    return;
  }
  const countryCode = user.location.country || 'US';

  let initialValues = {
    individual: {
      name: userProfile.full_name,
      address: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        postcode: '',
        country: getUserCountryDetails(user),
        coordinates: { latitude: '', longitude: '' },
      },
      profile_link: userProfile?.profile_link,
      linkedin: userProfile?.linkedin || {
        localizedLastName: '',
        profilePicture: {
          displayImage: '',
        },
        firstName: {
          localized: {
            en_US: '',
            de_DE: '',
          },
          preferredLocale: {
            country: '',
            language: '',
          },
        },
        vanityName: '',
        lastName: {
          localized: {
            en_US: '',
            de_DE: '',
          },
          preferredLocale: {
            country: '',
            language: '',
          },
        },
        localizedHeadline: '',
        id: '',
        headline: {
          localized: {
            en_US: '',
            de_DE: '',
          },
          preferredLocale: {
            country: '',
            language: '',
          },
        },
        localizedFirstName: '',
      },
      email: userProfile?.email,
      phone: getUserPhoneDetails(countryCode),
    },
  };

  if (
    auth?.user_info?.account_type === 'salesrep' ||
    auth?.user_info?.account_type === 'referrer'
  ) {
    initialValues.profile_link = 'https://';
  }

  return initialValues;
};

export const createInitialValuesTermsOfServiceForm = ({ user, auth }) => {
  //Guard Clause
  if (!user || !auth) {
    return;
  }

  const countryCode = user.location.country || 'US';

  return {
    otp: '',
    tos_accepted: {
      accepted: false,
      ip: '',
      date: '',
      unixTimeStamp: '',
      agent: '',
    },
    ppa_accepted: {
      accepted: false,
      ip: '',
      date: '',
      unixTimeStamp: '',
      agent: '',
    },
    ssa_accepted: {
      accepted: false,
      ip: '',
      date: '',
      unixTimeStamp: '',
      agent: '',
    },
    mobile_verified: {
      accepted: false,
      ip: '',
      date: '',
      unixTimeStamp: '',
      agent: '',
    },
    mobile: getUserPhoneDetails(countryCode),
    account_notifications: true,
  };
};
