import { memo, useContext, useEffect, useState } from 'react';
import {
  Box,
  Typography,
  ClickAwayListener,
  Slider,
  Stack,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { SketchPicker } from 'react-color';
import * as THREE from 'three';
import PaletteIcon from '@mui/icons-material/Palette';
import { useTranslation } from 'react-i18next';
import IntuIconButton from '../../../buttons/IntuIconButton';
import ReplayIcon from '@mui/icons-material/Replay';
import { ImageContext } from '../../dialogs/UploadImageDialog';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import PanoramaHorizontalIcon from '@mui/icons-material/PanoramaHorizontal';
import ThreeSixtyIcon from '@mui/icons-material/ThreeSixty';
import MouseIcon from '@mui/icons-material/Mouse';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { createPortal } from 'react-dom';

const ColorPickerComponent = ({
  mesh,
  index,
  updateMeshColor,
  closePicker,
}) => {
  const [currentColor, setCurrentColor] = useState(
    mesh.material.color.getHexString(),
  );

  const handleColorChange = (color) => {
    setCurrentColor(color.hex);
  };

  const handleColorChangeComplete = (color) => {
    updateMeshColor(index, color.hex);
  };

  const handleClickAway = () => {
    closePicker(); // Close the picker when clicking outside
  };

  return createPortal(
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        style={{
          position: 'absolute',
          top: '10%', // Adjust this to position it correctly
          left: '10%', // Adjust this to position it correctly
          zIndex: 9999, // Make sure it's on top of everything
        }}
      >
        <SketchPicker
          color={currentColor}
          onChange={handleColorChange}
          onChangeComplete={handleColorChangeComplete}
        />
      </div>
    </ClickAwayListener>,
    document.body, // Render this outside of the normal DOM hierarchy
  );
};

const createHexString = (color) => {
  if (!color) {
    return;
  }

  const { r, g, b } = color;
  const threeJsColor = {
    r,
    g,
    b,
  };
  const hexColor =
    '#' +
    new THREE.Color(
      threeJsColor.r,
      threeJsColor.g,
      threeJsColor.b,
    ).getHexString();
  return hexColor;
};

const ColorControlsContainer = ({
  showingArray,
  setShowingArray,
  scene,
  viewMode = 'edit',
  meshes,
  setMeshes,
  setInitialMeshes,
  setIsProductImageDirtied,
}) => {
  const { t } = useTranslation();
  const { t: transDialogs } = useTranslation('dialogs');
  const { t: transButtons } = useTranslation('buttons');
  const { t: transTypes } = useTranslation('types');

  const { handleResetScene, is3DFile } = useContext(ImageContext);

  const [showingColorOptions, setShowingColorOptions] = useState(false);
  const [showTips, setShowTips] = useState(false);

  useEffect(() => {
    handleSceneLoaded();
  }, []);

  const handleSceneLoaded = () => {
    if (viewMode === 'edit') {
      let meshObjects = [];
      let initialMeshStates = [];

      scene.traverse((child) => {
        if (child.isMesh && child.name) {
          meshObjects.push(child);
          // Store initial state with explicit color, roughness, and metalness values
          initialMeshStates.push({
            name: child.name,
            color: child.material.color.getHex(),
            roughness: child.material.roughness,
            metalness: child.material.metalness,
          });
        }
      });

      setMeshes(meshObjects);
      setInitialMeshes(initialMeshStates);
      setShowingArray(Array.from({ length: meshObjects.length }, () => false));
    }
  };

  // Color Change
  const [activePickerIndex, setActivePickerIndex] = useState(null);
  const [currentColor, setCurrentColor] = useState(null);

  const handleColorBoxClick = (index) => {
    if (activePickerIndex === index) {
      setActivePickerIndex(null);
    } else {
      setActivePickerIndex(index);
      setCurrentColor(createHexString(meshes[index].material.color)); // Set initial color
    }
  };

  const handleColorChangeComplete = (newColor, index) => {
    const { hex } = newColor;

    setMeshes((prevState) => {
      const newMeshes = prevState.map((m, i) => {
        if (i !== index) return m;
        m.material.color.set(hex);
        return m;
      });
      return newMeshes;
    });

    // setActivePickerIndex(null);
    setIsProductImageDirtied(true);
  };

  // 3D Roughness
  const handleRoughnessChange = (e, index) => {
    setMeshes((prevState) => {
      const newMeshes = prevState.map((m, i) => {
        if (i !== index) return m;
        m.material.roughness = Number(e.target.value);
        return m;
      });
      return newMeshes;
    });
    setIsProductImageDirtied(true);
  };

  const handleMetalnessChange = (e, index) => {
    setMeshes((prevState) => {
      const newMeshes = prevState.map((m, i) => {
        if (i !== index) return m;
        m.material.metalness = Number(e.target.value);
        return m;
      });
      return newMeshes;
    });
    setIsProductImageDirtied(true);
  };

  const handleShowColorOptionsClick = () => {
    setShowingColorOptions(!showingColorOptions);
    setShowTips(false);
  };

  const handleShowTips = () => {
    setShowTips(!showTips);
    setShowingColorOptions(false);
  };

  const handleClickAway = () => {
    // Close color picker if open
    if (showingArray.some((showing) => showing)) {
      setShowingArray(Array(showingArray.length).fill(false));
    }

    // Close the entire color controls panel
    setShowingColorOptions(false);

    // Close the tips box
    if (showTips) {
      setShowTips(false);
    }
  };

  if (!meshes.length) return null;
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box position="absolute" top={150} left={20}>
        <Stack direction="column" spacing={2}>
          <IntuIconButton
            size="small"
            type="info"
            color="info"
            variant="outlined"
            tooltipTitle={transButtons('tips', {
              type: transTypes('3d_navigation'),
            })}
            onClick={handleShowTips}
            disabled={!is3DFile}
            // IconComponent={ReplayIcon}
          />
          <IntuIconButton
            type="add"
            variant="outlined"
            tooltipTitle={`${showingColorOptions ? 'Hide' : 'Show'} Color Options`}
            IconComponent={PaletteIcon}
            onClick={handleShowColorOptionsClick}
          />
          <IntuIconButton
            size="small"
            type="connect"
            color="error"
            variant="outlined"
            tooltipTitle={transButtons('reset')}
            onClick={handleResetScene}
            disabled={!is3DFile || showTips || showingColorOptions}
            IconComponent={ReplayIcon}
          />
        </Stack>

        {/* Coloring Box */}
        <Box
          position="absolute"
          top={30}
          left={20}
          minHeight={350}
          maxHeight="100%"
          minWidth={330}
          overflow="scroll"
          mt={2}
          display={showingColorOptions ? 'block' : 'none'}
          p={2}
          zIndex={1}
          sx={{
            backgroundColor: (theme) => `${theme.palette.grey.main}99`,
            borderRadius: '2%', // Make it a round box
            color: 'white',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Optional: Add a shadow for better visibility
          }}
        >
          <Box display="flex" gap={2}>
            <Typography color="primary.contrastText" minWidth={150}>
              {t('components.colorControls.mesh')}
            </Typography>
            <Typography color="primary.contrastText">
              {t('components.colorControls.color')}
            </Typography>
            <Typography color="primary.contrastText">
              {t('components.colorControls.rough')}
            </Typography>
            <Typography color="primary.contrastText">
              {t('components.colorControls.metal')}
            </Typography>
          </Box>

          <Box display="flex" flexDirection="column" gap={1} mt={2}>
            {meshes.map((m, i) => (
              <Box
                key={`${m.uuid}-${i}`}
                display="flex"
                gap={2}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  color="primary.contrastText"
                  flex={0.8}
                  minWidth={150}
                >
                  {m.name}
                </Typography>
                <Box minWidth={35}>
                  <Box minWidth={35}>
                    {activePickerIndex === i ? (
                      <ColorPickerComponent
                        mesh={meshes[i]}
                        index={i}
                        updateMeshColor={(index, hex) =>
                          handleColorChangeComplete({ hex }, index)
                        }
                        closePicker={() => setActivePickerIndex(null)}
                      />
                    ) : (
                      <Box
                        style={{
                          backgroundColor: createHexString(m?.material?.color),
                          width: 35,
                          height: 35,
                          border: '1px solid #000',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleColorBoxClick(i)}
                      />
                    )}
                  </Box>
                </Box>
                <Box minWidth={100}>
                  <Slider
                    type="range"
                    min={0}
                    max={1}
                    step={0.1}
                    onChange={(e) => handleRoughnessChange(e, i)}
                  />
                </Box>
                <Box minWidth={100}>
                  <Slider
                    type="range"
                    min={0}
                    max={1}
                    step={0.1}
                    onChange={(e) => handleMetalnessChange(e, i)}
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </Box>

        {/* Tip Box */}
        <Box
          position="absolute"
          top={30}
          left={40}
          minHeight={350}
          maxHeight="100%"
          minWidth={400}
          overflow="scroll"
          mt={2}
          display={showTips ? 'block' : 'none'}
          p={2}
          zIndex={1}
          sx={{
            backgroundColor: (theme) => `${theme.palette.grey.main}90`,
            borderRadius: '2%', // Rounded box
            color: 'white', // Default text color for the Box
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          {/* Title */}
          <Typography variant="h6" sx={{ color: 'white' }} gutterBottom>
            {transDialogs('3DNavigationTips.title')}
          </Typography>

          {/* List of Tips */}
          <List>
            <ListItem>
              <ListItemIcon>
                <ZoomInIcon sx={{ color: 'white' }} />
              </ListItemIcon>
              <ListItemText
                primary={transDialogs('3DNavigationTips.zoom')}
                primaryTypographyProps={{ sx: { color: 'white' } }} // FORCES TEXT TO WHITE
              />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <MouseIcon sx={{ color: 'white' }} />
              </ListItemIcon>
              <ListItemText
                primary={transDialogs('3DNavigationTips.perspective')}
                primaryTypographyProps={{ sx: { color: 'white' } }} // Ensures white text
              />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <DragIndicatorIcon sx={{ color: 'white' }} />
              </ListItemIcon>
              <ListItemText
                primary={transDialogs('3DNavigationTips.position')}
                primaryTypographyProps={{ sx: { color: 'white' } }}
              />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <PanoramaHorizontalIcon sx={{ color: 'white' }} />
              </ListItemIcon>
              <ListItemText
                primary={transDialogs('3DNavigationTips.rotate_horizontal')}
                primaryTypographyProps={{ sx: { color: 'white' } }}
              />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <ThreeSixtyIcon sx={{ color: 'white' }} />
              </ListItemIcon>
              <ListItemText
                primary={transDialogs('3DNavigationTips.rotate_vertical')}
                primaryTypographyProps={{ sx: { color: 'white' } }}
              />
            </ListItem>
          </List>
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

export default memo(ColorControlsContainer);
