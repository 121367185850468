export const getFileName = (file) => {
  if (typeof file === 'string') {
    return file.substring(file.lastIndexOf('/') + 1);
  }

  if (typeof file === 'object' && file.file_url) {
    return file.file_url.substring(file.file_url.lastIndexOf('/') + 1);
  }

  return '';
};
