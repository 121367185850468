import { useState, useEffect, memo, useMemo } from 'react';
import {
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  Divider,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { GridRow, GridColumnHeaders } from '@mui/x-data-grid-premium';
import useResponseHandling from '../../../../hooks/useResponseHandler.js';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { StripeContext } from '../../../../context/providers/stripe/StripeContextProvider.jsx';
import IntuIconButton from '../../../buttons/IntuIconButton.jsx';
import dayjs from 'dayjs';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { listStripeCharges } from '../../../../api/stripe/charges.js';
import { ListStatusIcons } from '../../../listStatusIcons/ListStatusIcons.jsx';
import ListLoader from '../../../status/ListLoader.jsx';

const createColumns = (t, transButtons, theme, handleViewClick) => {
  return [
    {
      field: 'brand',
      // headerName: t('form.products.name_label'),
      headerName: 'Description',
      editable: false,
      flex: 1,
      minWidth: 80,
      valueGetter: (params) => params.row.description,
    },
    {
      field: 'status',
      // headerName: t('form.products.mpn_label'),
      headerName: 'Status',
      editable: false,
      flex: 0.3,
      minWidth: 50,
      renderCell: (params) => ListStatusIcons(params.row.status, theme),
    },
    {
      field: 'expiration',
      headerName: 'Amount',
      editable: false,
      flex: 1,
      minWidth: 100,
      valueGetter: (params) =>
        new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(params.row.amount / 100),
    },
    {
      field: 'created',
      headerName: 'Created',
      editable: false,
      flex: 1,
      minWidth: 100,
      valueGetter: (params) =>
        dayjs.unix(params.row.created).format('MM/DD/YYYY'),
    },
    {
      field: 'view',
      headerName: 'View',
      sortable: false,
      renderCell: (params) => {
        return (
          <IntuIconButton
            size="small"
            type="edit"
            variant="outlined"
            onClick={() => handleViewClick(params.row.receipt_url)}
            tooltipTitle={transButtons('View Receipt')}
            IconComponent={ReceiptIcon}
            iconOnly
          />
        );
      },
    },
  ];
};

const CustomerChargesList = () => {
  const { t } = useTranslation();
  const { t: transButtons } = useTranslation('buttons');

  const [charges, setCharges] = useState([]);
  const { handleErrorResponse } = useResponseHandling();
  const [isListLoading, setIsListLoading] = useState(false);
  const MemoizedRow = memo(GridRow);
  const MemoizedColumnHeaders = memo(GridColumnHeaders);
  const theme = useTheme();
  const isMediumView = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { stripeUserData } = useContext(StripeContext);

  const handleViewClick = (receipt_url) => {
    if (!receipt_url) return;
    window.open(receipt_url, '_blank', 'noopener,noreferrer');
  };

  const columns = useMemo(
    () => createColumns(t, transButtons, theme, handleViewClick),
    [t, transButtons, handleViewClick, theme],
  );

  const getCustomerCharges = async () => {
    if (!stripeUserData?.stripe_customer_data?.id) return;

    setIsListLoading(true);

    try {
      const { data, status } = await listStripeCharges({
        customerId: stripeUserData.stripe_customer_data.id,
      });

      if (status === 200) {
        setCharges(data.data.charges);
      }
    } catch (error) {
      console.error('error', error);
      handleErrorResponse(error);
    } finally {
      setIsListLoading(false);
    }
  };

  useEffect(() => {
    getCustomerCharges();
  }, [stripeUserData]);

  return (
    <Box>
      <Typography variant="h5">Charges</Typography>
      <Divider sx={{ mb: 4 }} />
      {isListLoading ? (
        <ListLoader columns={4} rows={4} />
      ) : !charges?.length ? (
        <Box>
          <Typography variant="body1">No Charges</Typography>
        </Box>
      ) : (
        <Box height="auto" width="100%">
          <DataGrid
            isCellEditable={(params) => false}
            getRowId={(row) => row.id}
            rows={charges}
            columns={columns}
            columnVisibilityModel={{
              list_price: !isMobileView,
              attributes: !isMobileView && !isMediumView,
              applications: !isMobileView && !isMediumView,
              product_group: !isMobileView && !isMediumView,
              mpn: !isMobileView,
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
              sorting: {
                sortModel: [{}],
              },
            }}
            pageSizeOptions={[5, 10, 100]}
            checkboxSelection={false}
            disableRowSelectionOnClick
            disableColumnMenu
            slots={{
              Row: MemoizedRow,
              ColumnHeaders: MemoizedColumnHeaders,
            }}
            sx={{
              borderRadius: '12px',
              boxShadow: 6,
              '& .MuiDataGrid-columnSeparator': {
                display: 'none',
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-cell:focus-within': {
                outline: 'none',
              },
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default CustomerChargesList;
