import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Skeleton,
  Switch,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ProcessingContext from '../../../context/providers/ProcessingProvider';
import FormikFieldWrapper from '../../formik/FormikFieldWrapper';
import { useContext } from 'react';
import { getNestedValue } from '../../../helpers/getNestedValuesIn';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.primary.main,
  },
}));

const ToggleField = ({
  fieldTitle, // Title above the field
  fieldDescription, // Field description above the field
  fieldID = 'isPurchasable', // Defines the ID and Name of the Field
  transition = true, // Whether the field is being shown and faded in
  transTypeID = 'product',
  disabled = false, // Disables the field
  required = false, // Whether the field is required or not
  transNS = 'fields', // Namespace for translation Value
  transPrefix = 'ProductPurchasableToggleField', // KeyPrefix which holds label and placeholder
  isLoadingManual = false, // Manually control the Loading State
  defaultCurrency = null, // ISO 3 Code of Default Currency
  color = 'primary', // Color
  onChange = undefined,
  ...props
}) => {
  // Context
  const classes = useStyles();
  const { values, setFieldValue, touched, errors, handleBlur } =
    useFormikContext();

  const { t: transFields } = useTranslation('fields', {
    keyPrefix: transPrefix,
  });
  const { t: transTypes } = useTranslation('types');

  const { isLoading } = useContext(ProcessingContext);

  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  return isLoading.status || isLoadingManual ? (
    <Skeleton variant="rectangular" width={'100%'} height={60} />
  ) : (
    <FormikFieldWrapper
      fieldTitle={fieldTitle}
      fieldDescription={fieldDescription}
      transition={transition}
    >
      <FormControl component="fieldset" error={fieldError}>
        <FormControlLabel
          control={
            <Switch
              id={fieldID}
              name={fieldID}
              color={color}
              checked={fieldValue}
              disabled={disabled}
              onChange={(event, newValue) => {
                setFieldValue(fieldID, newValue);
                if (onChange) {
                  onChange(event, newValue);
                }
              }}
              {...props}
            />
          }
          label={
            <Typography variant="body1">
              {transFields('label', { type: transTypes(transTypeID) })}
            </Typography>
          }
          classes={{
            label: classes.label,
          }}
        />
        {fieldError && fieldTouched ? (
          <FormHelperText>{fieldError}</FormHelperText>
        ) : null}
      </FormControl>
    </FormikFieldWrapper>
  );
};

export default ToggleField;
