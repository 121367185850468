import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Box } from '@mui/material';
import IndividualLanguageField from '../../account/individual/IndividualLanguageField';
import ProductTypeField from '../../product-type/ProductTypeField';
import ApplicationsField from '../../applications/ApplicationsField';
import { UserProfileContext } from '../../../context/users/UserProfileProvider';
import useAuth from '../../../hooks/useAuth';

const ExpertiseTab = () => {
  const { t: transTypes } = useTranslation();
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.account.ExpertiseTab',
  });

  const { auth, isAdmin } = useAuth();

  const { isSubmitting, values } = useFormikContext();

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      {/* Language Field */}
      <Box>
        <IndividualLanguageField
          fieldTitle={t('languages.title')}
          fieldDescription={t('languages.description')}
          fieldID="languages"
          required={true}
          showInitialErrors={true}
          disabled={isSubmitting}
        />
      </Box>
      {/* Hide Profile Settings from IN2TEC Employees */}
      {/* Applications*/}
      <Box>
        <ApplicationsField
          tran
          fieldTitle={t('applications.title')}
          fieldDescription={t('applications.description')}
          transition={!auth.user_info.account_type === 'intutec' ? true : false}
          required={
            isAdmin || auth.user_info.account_type === 'intutec' ? false : true
          }
        />
      </Box>
      {/* Products */}
      <Box>
        <ProductTypeField
          fieldTitle={t('products.title')}
          fieldDescription={t('products.description')}
          required={
            isAdmin || auth.user_info.account_type === 'intutec' ? false : true
          }
          transition={!auth.user_info.account_type === 'intutec' ? true : false}
        />
      </Box>
    </Box>
  );
};

export default ExpertiseTab;
