import { Container, Typography, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n.js';
import { useContext, useEffect } from 'react';
import { LayoutBodyContext } from '../../context/layout/LayoutContextProvider.jsx';

// Icons
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { ExistingCustomersByIndustry } from '../howitworks/containers/manufacturers/ExistingCustomers.jsx';
import IntuThemeContext from '../../context/providers/IntuThemeProvider.jsx';
import BrandsInfiniteSliderContainer from '../../containers/brandsInfiniteSliderContainer/BrandsInfiniteSliderContainer.jsx';
import { VerifiedUser } from '@mui/icons-material';
import { FeaturedProductsList } from '../public-product/FeaturedProductsList.jsx';

export const HomeComponent = () => {
  const { setLayoutTitle } = useContext(LayoutBodyContext);

  const { t } = useTranslation('translation', {
    keyPrefix: 'components.home.main',
  });

  const { mode } = useContext(IntuThemeContext);

  useEffect(() => {
    // Set the Page Title
    setLayoutTitle(t('title'));
  }, []);

  const theme = useTheme();
  const medium = useMediaQuery(theme.breakpoints.down('md'));

  const params = new URLSearchParams(window.location.search);
  // const fName = params.get('fName') || '';
  const cName = params.get('cName') || '';

  return (
    <Container id="homeContent" component="main" maxWidth="auto" disableGutters>
      {/* Brands */}
      <BrandsInfiniteSliderContainer initialDelay={2} />

      {/* How your brand is currently marketed */}
      <FeaturedProductsList />

      {/* Benefits */}
      <Grid
        container
        direction={{ xs: 'column', md: 'row' }}
        justifyContent={{ xs: 'center', md: 'flex-start' }}
        textAlign="center"
        spacing={8}
        sx={{ padding: '0 2rem 10rem 2rem' }}
      >
        {/* Title */}
        <Grid item xs={12}>
          <Typography variant="h1">{t('section_3.title')}</Typography>
        </Grid>
        {/* Benefit 1 */}
        <Grid item container direction="column" xs={12} md={4}>
          <Grid item>
            {/* <GpsFixedIcon sx={{ fontSize: '6rem' }} /> */}
            <EngineeringIcon sx={{ fontSize: '6rem' }} />
          </Grid>
          <Grid item>
            <Typography variant="h6">
              {t('section_3.benefits.benefit_1.title')}
            </Typography>
            <Typography variant="body1">
              {t('section_3.benefits.benefit_1.description')}
            </Typography>
          </Grid>
        </Grid>
        {/* Benefit 2 */}
        <Grid item container direction="column" xs={12} md={4}>
          <Grid item>
            <VerifiedUser sx={{ fontSize: '6rem' }} />
          </Grid>
          <Grid item>
            <Typography variant="h6">
              {t('section_3.benefits.benefit_2.title')}
            </Typography>
            <Typography variant="body1">
              {t('section_3.benefits.benefit_2.description', {
                name: cName ? cName : i18n.t('manufacturer', { ns: 'common' }),
              })}
            </Typography>
          </Grid>
        </Grid>
        {/* Benefit 3 */}
        <Grid item container direction="column" xs={12} md={4}>
          <Grid item>
            <AttachMoneyIcon sx={{ fontSize: '6rem' }} />
          </Grid>
          <Grid item>
            <Typography variant="h6">
              {t('section_3.benefits.benefit_3.title')}
            </Typography>
            <Typography variant="body1">
              {t('section_3.benefits.benefit_3.description')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* Our Customer Base */}
      <Grid
        container
        direction="column"
        justifyContent="center"
        textAlign="center"
        sx={{ width: '100%', padding: 0, margin: 0, pb: '10rem' }}
      >
        <Grid item textAlign="center">
          <Typography variant="h1">{t('section_4.title')}</Typography>
          <Typography variant="body1">
            {t('section_4.description', {
              name: cName ? cName : i18n.t('your_company', { ns: 'common' }),
            })}
          </Typography>
        </Grid>
      </Grid>

      <ExistingCustomersByIndustry
        title={false}
        description={false}
        legend={true}
        sx={{ margin: '0 0 10rem 0' }}
      />
    </Container>
  );
};
