import { Grid, Stack } from '@mui/material';
import FulfillmentContainer from '../container/FulfillmentContainer';

const FullfillmentInfoTabContent = () => {
  return (
    <Grid container direction="column">
      <Stack spacing={3}>
        <Grid item xs={12}>
          <FulfillmentContainer />
        </Grid>
      </Stack>
    </Grid>
  );
};

export default FullfillmentInfoTabContent;
