import { useContext, useMemo, useState } from 'react';
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromRaw,
} from 'draft-js';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import IntuIconButton from '../buttons/IntuIconButton';
import LanguagesData from '../../data/LanguagesData.json';
import { axiosPrivate } from '../../hooks/axios/axios';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import TranslateIcon from '@mui/icons-material/Translate';
import { IntuRichTextEditor } from '../IntuRichTextEditor/IntuRichtTextEditor';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import useResponseHandling from '../../hooks/useResponseHandler';
import { ManageProductContext } from '../../pages/products/NewProductPage';
import { translateMultiLineText } from '../../api/translation/google';
import { getRawContentState } from '../../helpers/muiRichtTextEditor';

const languageOptions = LanguagesData.filter((language) => language.supported);

const ProductDescription = () => {
  const { t: transFields } = useTranslation('fields');
  const { t: transButtons } = useTranslation('buttons');
  const { t: transMessages } = useTranslation('messages');
  const { values, setValues } = useFormikContext();

  const { setIsSaving } = useContext(ManageProductContext);

  const [selectedLanguage, setSelectedLanguage] = useState({
    name: 'English',
    code: 'en',
  });

  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();

  const [isTweakLoading, setIsTweakLoading] = useState(false);
  const [isTranslateLoading, setIsTranslateLoading] = useState(false);

  const selectedDescription = useMemo(() => {
    return (
      values.descriptions.find((d) => d.code === selectedLanguage.code) || {
        name: selectedLanguage.name,
        code: selectedLanguage.code,
        description: getRawContentState(''),
      }
    );
  }, [selectedLanguage, values.descriptions]);

  const [localDescription, setLocalDescription] = useState(null);
  const handleRteDescriptionChange = (event) => {
    const rteContent = convertToRaw(event.getCurrentContent());
    setLocalDescription(rteContent);
  };

  const handleRteDescriptionBlur = () => {
    if (!localDescription) return;

    setValues(() => {
      // Add new language description if it doesnt exist
      const found = values.descriptions.find(
        (d) => d.code === selectedLanguage.code,
      );

      if (!found) {
        const newDescription = {
          name: selectedLanguage.name,
          code: selectedLanguage.code,
          description: localDescription,
        };

        return {
          ...values,
          descriptions: [...values.descriptions, newDescription],
        };
      }

      // Else update existing
      const newDescriptions = values.descriptions.map((description) => {
        if (description.code === selectedLanguage.code) {
          return {
            ...description,
            description: localDescription,
          };
        }
        return description;
      });

      return {
        ...values,
        descriptions: newDescriptions,
      };
    });
  };

  const handleRteDescriptionSave = (e) => {
    const parsed = JSON.parse(e);
    setValues(() => {
      // Add new language description if it doesnt exist
      const found = values.descriptions.find(
        (d) => d.code === selectedLanguage.code,
      );

      if (!found) {
        const newDescription = {
          name: selectedLanguage.name,
          code: selectedLanguage.code,
          description: parsed,
        };

        return {
          ...values,
          descriptions: [...values.descriptions, newDescription],
        };
      }

      // Else update existing
      const newDescriptions = values.descriptions.map((description) => {
        if (description.code === selectedLanguage.code) {
          return {
            ...description,
            description: parsed,
          };
        }
        return description;
      });

      return {
        ...values,
        descriptions: newDescriptions,
      };
    });
  };

  const handleAITweak = async () => {
    if (!selectedDescription.description) return;
    setIsTweakLoading(true);
    setIsSaving(true);

    const description = selectedDescription.description.blocks[0].text;
    const payload = {
      messages: [
        {
          role: 'assistant',
          content: `Tweak the following product description to make it more compelling in this language: ${selectedLanguage.name} ${description}`,
        },
      ],
      temperature: 0.1,
    };

    try {
      const { data, status } = await axiosPrivate.post(
        '/api/ai/content-creation/create-content',
        payload,
      );
      if (status === 200) {
        const [tweaked] = data.data;
        const rawContentState = getRawContentState(tweaked.message.content);

        setValues(() => {
          const newDescription = {
            language: selectedLanguage.name,
            code: selectedLanguage.code,
            description: rawContentState,
          };

          const newDescriptions = values.descriptions.map((description) => {
            if (selectedLanguage.code === description.code)
              return newDescription;
            return description;
          });
          return {
            ...values,
            descriptions: newDescriptions,
          };
        });
        handleRegularResponse({
          open: true,
          status: data.status,
          statusCode: '200',
          message: transMessages('tweak.200', {
            content: transFields('ProductDescription.label'),
          }),
        });
      }
    } catch (error) {
      console.error('error', error);
      handleErrorResponse(error);
    } finally {
      setIsTweakLoading(false);
      setIsSaving(false);
    }
  };

  const handleTranslate = async () => {
    if (!languageOptions.length || !selectedDescription.description) return;

    const newDescriptions = [selectedDescription];

    setIsTranslateLoading(true);
    setIsSaving(true);

    const languages = languageOptions.filter(
      (option) => option.code !== selectedDescription?.code,
    );

    const languagesNice = languageOptions
      .filter((option) => option.code !== selectedDescription?.code)
      .map((option) => option.name);

    const messages = selectedDescription.description.blocks.map(
      (block) => block.text,
    );

    try {
      for (let i = 0; i < languages.length; i++) {
        const language = languages[i];

        const payload = {
          inputText: messages,
          targetLanguage: language.code,
        };

        const getTranslation = await translateMultiLineText(payload);
        const { data, statusCode } = getTranslation;

        if (statusCode === 200) {
          // Map translated text back into blocks
          const translatedBlocks = data.map((text, index) => ({
            key: `${index}`,
            text: text,
            type:
              selectedDescription.description.blocks[index].type ||
              'unordered-list-item',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
          }));

          const rawContentState = {
            blocks: translatedBlocks,
            entityMap: {},
          };

          EditorState.createWithContent(convertFromRaw(rawContentState));

          // Construct the Item
          const newDescription = {
            name: language.name,
            code: language.code,
            description: rawContentState,
          };

          // console.log('New description -> ', newDescription);

          newDescriptions.push(newDescription);
        }
      }

      setValues({
        ...values,
        descriptions: newDescriptions,
      });

      handleRegularResponse({
        open: true,
        status: 'success',
        statusCode: '200',
        message: transMessages('translate.200', { languages: languagesNice }),
      });
    } catch (error) {
      handleErrorResponse(error);
    } finally {
      setIsTranslateLoading(false);
      setIsSaving(false);
    }
  };

  return (
    <Box>
      <Typography variant="h6">
        {transFields('ProductDescription.title')}
      </Typography>
      <FormControl sx={{ width: '200px' }}>
        <InputLabel id="language-select">{transFields('language')}</InputLabel>
        <Select
          label="Language"
          labelId="language-select"
          value={selectedLanguage.code}
          name="language"
          defaultValue="en"
          sx={{ mb: 2 }}
        >
          {languageOptions.map((language) => {
            return (
              <MenuItem
                key={language.code}
                value={language.code}
                onClick={() => setSelectedLanguage(language)}
              >
                {language.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <IntuRichTextEditor
        id="product_description"
        name="product_description"
        required={true}
        disabled={false}
        controls={[
          'title',
          'bold',
          'italic',
          'underline',
          'fontColor',
          'highlight',
          'undo',
          'redo',
          'numberList',
          'bulletList',
          'quote',
          'clear',
          'save',
        ]}
        className="form-select-field"
        aria-describedby="uidnote"
        variant="standard"
        label={transFields('ProductDescription.label')}
        placeholder={transFields('ProductDescription.placholder')}
        defaultValue={
          selectedDescription.description
            ? JSON.stringify(selectedDescription.description)
            : null
        }
        onChange={handleRteDescriptionChange}
        onBlur={handleRteDescriptionBlur}
        onSave={handleRteDescriptionSave}
        inlineToolbar={true}
      />
      <Box display="flex" justifyContent="flex-end" mt={2} gap={1}>
        <IntuIconButton
          type="connect"
          tooltipTitle={transButtons('tweak_ai')}
          variant="outlined"
          color="primary"
          onClick={handleAITweak}
          isLoading={isTweakLoading}
          disabled={!selectedDescription.description || isTranslateLoading}
          IconComponent={AutoFixHighIcon}
        />
        <IntuIconButton
          type="info"
          tooltipTitle={transButtons('translate')}
          variant="outlined"
          onClick={handleTranslate}
          isLoading={isTranslateLoading}
          disabled={!selectedDescription.description || isTweakLoading}
          IconComponent={TranslateIcon}
        />
      </Box>
    </Box>
  );
};

export default ProductDescription;
