import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductAttributeValueContext } from '../selection/AttributeValueSelection';
import UnitSelectionField from '../../../fields/unit/UnitSelectionField';
import MeasurementField from '../../../fields/measurement/MeasurementField';
import { Form, Formik } from 'formik';
import { createAddNewProductValueValidationSchema } from './validationSchema';
import { createAddNewProductAttributeValueInitialValues } from './initialValues';
import IntuIconButton from '../../../buttons/IntuIconButton';
import { AttributeContainerContext } from '../../container/AttributeContainer';
import {
  measurementOptions,
  weightOptions,
} from '../../../../data/MeasruementUnits';
import RegularTextField from '../../../fields/text/RegularTextField';

const NewAttributeValueDialog = () => {
  const { t: transDialogs } = useTranslation('dialogs');
  const { t: transButtons } = useTranslation('buttons');

  const {
    newAttributeValueDialog,
    setNewAttributeValueDialog,
    unitSelectionList,
    setMeasurementUnitValue,
  } = useContext(ProductAttributeValueContext);

  const {
    setSelectedAttributeValue,
    selectedAttribute,
    selectedAttributeType,
    productAttributeValueOptions,
    setProductAttributeValueOptions,
  } = useContext(AttributeContainerContext);

  const weightFilter = weightOptions.filter(
    (w) => w === selectedAttributeType?.data_keys,
  );
  const measurementFilter = measurementOptions.filter(
    (m) => m === selectedAttributeType?.data_keys,
  );

  const [isMeasurement, setIsMeasuremenet] = useState(false);
  const [isWeight, setIsWeight] = useState(false);

  if (measurementFilter?.length > 0) {
    setIsMeasuremenet(true);
    setIsWeight(false);
  } else if (weightFilter?.length > 0) {
    setIsWeight(true);
    setIsMeasuremenet(false);
  }

  const initialValues = createAddNewProductAttributeValueInitialValues({
    selectedAttributeType,
  });

  const validationSchema = createAddNewProductValueValidationSchema({
    selectedAttributeType,
  });

  const handleConfirm = (values) => {
    const newAttribute = {
      attribute: selectedAttribute._id,
      attribute_type: selectedAttributeType._id,
      name: selectedAttribute.name,
      values: [
        {
          unit: values.unit,
          value: values.value,
        },
      ],
    };

    setSelectedAttributeValue(values);
    setMeasurementUnitValue(values.unit);

    setProductAttributeValueOptions([
      ...productAttributeValueOptions,
      newAttribute,
    ]);

    setNewAttributeValueDialog(false);
  };

  return (
    <Dialog
      open={newAttributeValueDialog}
      onClose={() => setNewAttributeValueDialog(false)}
      width="md"
      PaperProps={{
        sx: {
          color: 'white',
          backgroundColor: (theme) => `${theme.palette.grey.main}`,
          borderRadius: '2%', // Rounded box
          boxShadow: 'none',
        },
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        enableReinitialize={true}
        validateOnBlur={true}
      >
        {(formik) => {
          const { values, isValid, resetForm } = formik;

          return (
            <Form role="form">
              <DialogTitle color="white">
                {transDialogs('AttributeValueContainer.title')}
              </DialogTitle>

              <DialogContent>
                <Stack
                  direction="column"
                  spacing={2}
                  sx={{ paddingTop: '1rem' }}
                >
                  <UnitSelectionField
                    fieldID="unit"
                    options={unitSelectionList}
                    required={isWeight || isMeasurement}
                    inputprops={{
                      sx: {
                        '& input': {
                          color: 'white', // Input text color
                        },
                        '& input::placeholder': {
                          color: 'white',
                        },
                      },
                    }}
                    sx={{
                      '& label': {
                        color: 'white',
                      },
                      '& placeholder': {
                        color: 'white',
                      },
                      '& label.Mui-focused': {
                        color: 'white',
                      },
                      '& .MuiInput-root': {
                        color: 'white',
                        borderBottomColor: 'green.main',
                      },
                      '& .MuiInput-underline:after': {
                        borderBottomColor: 'green.main',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'white',
                        },
                        '&:hover fieldset': {
                          borderColor: 'white',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'white',
                        },
                      },
                    }}
                  />

                  {isWeight || isMeasurement ? (
                    <MeasurementField
                      fieldID="value"
                      inputAdornment={values.unit}
                      required
                      decimalScale={2}
                      sx={{
                        width: '200px',
                        '& label': {
                          color: 'white',
                        },
                        '& placeholder': {
                          color: 'white',
                        },
                        '& label.Mui-focused': {
                          color: 'white',
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: 'green.main',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'white',
                          },
                          '&:hover fieldset': {
                            borderColor: 'white',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'white',
                          },
                        },
                      }}
                      inputprops={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography color="white">{values.unit}</Typography>
                          </InputAdornment>
                        ),
                        sx: {
                          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                            {
                              WebkitAppearance: 'none',
                              margin: 0,
                            },
                          '& input[type=number]': {
                            MozAppearance: 'textfield',
                          },
                          '& input': {
                            color: 'white',
                          },
                          '& input::placeholder': {
                            color: 'white',
                          },
                        },
                      }}
                    />
                  ) : (
                    <RegularTextField
                      fieldID="value"
                      inputAdornment={values.unit}
                      required
                      inputprops={{
                        sx: {
                          '& input': {
                            color: 'white', // Input text color
                          },
                          '& input::placeholder': {
                            color: 'white',
                          },
                        },
                      }}
                      sx={{
                        width: '200px',
                        '& label': {
                          color: 'white',
                        },
                        '& placeholder': {
                          color: 'white',
                        },
                        '& label.Mui-focused': {
                          color: 'white',
                        },
                        '& .MuiInput-root': {
                          color: 'white',
                          borderBottomColor: 'green.main',
                        },
                        '& .MuiInput-underline:after': {
                          borderBottomColor: 'green.main',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'white',
                          },
                          '&:hover fieldset': {
                            borderColor: 'white',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'white',
                          },
                        },
                      }}
                    />
                  )}
                </Stack>
              </DialogContent>
              <DialogActions
                style={{
                  paddingBottom: '16px',
                }}
              >
                <Grid container direction="row">
                  <Grid item>
                    <IntuIconButton
                      type="cancel"
                      tooltipTitle={transButtons('cancel')}
                      onClick={() => {
                        setNewAttributeValueDialog(false);
                        resetForm();
                      }}
                      variant="contained"
                    />
                  </Grid>

                  <Grid item sx={{ marginLeft: 'auto' }}>
                    <IntuIconButton
                      disabled={!isValid}
                      tooltipTitle={transButtons('confirm')}
                      type="submit"
                      variant="contained"
                      sx={{
                        marginLeft: 'auto',
                      }}
                      onClick={() => handleConfirm(values)}
                    />
                  </Grid>
                </Grid>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export { NewAttributeValueDialog };
