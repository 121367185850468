import React from 'react';
import IndividualIntroField from '../../account/individual/IndividualIntroField';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import IndividualDOBField from '../../account/individual/IndividualDOBField';
import { Box } from '@mui/material';
import IndividualProfileField from '../../account/individual/IndividualProfileField';

const AboutMeTab = () => {
  const { t: transTypes } = useTranslation();
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.account.AboutMeTab',
  });

  const { isSubmitting } = useFormikContext();

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      {/* Introduction */}
      <Box>
        <IndividualIntroField
          fieldTitle={t('intro.title', {
            type: transTypes('yourself'),
          })}
          fieldDescription={t('intro.description', {
            type: transTypes('yourself'),
          })}
          showIntroAssistant={!isSubmitting}
          disabled={isSubmitting}
        />
      </Box>

      {/* DOB */}
      <Box>
        <IndividualDOBField
          fieldTitle={t('dob.title')}
          fieldDescription={t('dob.description')}
          required={true}
          fieldID="dob"
          disabled={isSubmitting}
        />
      </Box>

      {/* User Profile Link */}
      <Box>
        <IndividualProfileField
          fieldTitle={t('profile.title')}
          fieldDescription={t('profile.description')}
          fieldID="profile_link"
          transNS="fields"
          required={true}
          showLinkedInButton={true}
          disabled={isSubmitting}
        />
      </Box>
    </Box>
  );
};

export default AboutMeTab;
