import { CheckCircle, Error, Warning, AccessTime } from '@mui/icons-material';
import { useTheme, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

const OrderStatusIcon = ({ status }) => {
  const theme = useTheme();
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.OrderStatusIcon',
  });

  const STATUS_ICON_MAP = {
    fulfilled: { icon: CheckCircle, color: 'success', label: t('fulfilled') },
    pending: { icon: AccessTime, color: 'info', label: t('pending') },
    charged: { icon: Warning, color: 'success', label: t('charged') },
    error: { icon: Error, color: 'error', label: t('error') },
    default: { icon: Error, color: 'error', label: t('unknown') },
  };

  const { icon: IconComponent, color } =
    STATUS_ICON_MAP[status] || STATUS_ICON_MAP.default;

  return (
    <Tooltip
      title={STATUS_ICON_MAP[status]?.label || STATUS_ICON_MAP.default.label}
    >
      <IconComponent sx={{ color: theme.palette[color].main }} />
    </Tooltip>
  );
};

export default OrderStatusIcon;
