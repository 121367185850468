import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
  Box,
  LinearProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useResponseHandling from '../../hooks/useResponseHandler';
import { useAxiosPrivate } from '../../hooks/axios/useAxiosPrivate';
import IntuIconButton from '../buttons/IntuIconButton';

const OrderItemShippingLabelDialog = ({
  open,
  onClose,
  onConfirm,
  selectedOrderItem,
  selectedRate,
}) => {
  const theme = useTheme();
  const { t: transButtons } = useTranslation('buttons');
  const { handleErrorResponse } = useResponseHandling();
  const [isLoading, setIsLoading] = useState(false);
  const [shippingLabel, setShippingLabel] = useState(null);
  const axios = useAxiosPrivate();

  const createShippingLabel = async () => {
    setIsLoading(true);

    try {
      const payload = {
        rate: selectedRate,
      };

      const { data, status } = await axios.post(
        '/api/shippo/create-transaction',
        payload,
      );
      if (status === 200) {
        setShippingLabel(data.data);
      }
    } catch (error) {
      console.error('error', error);
      handleErrorResponse(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (open && selectedOrderItem.status !== 'fulfilled') {
      createShippingLabel();
    }
  }, [open, selectedOrderItem]);

  if (!selectedOrderItem) return null;
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle textTransform="none">Order Item Label</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2} my={2}>
          {isLoading ? (
            <>
              <DialogContentText
                border={`1px solid ${theme.palette.primary.main}`}
                borderRadius={1}
                p={1}
                mb={2}
              >
                Generating Label...
              </DialogContentText>
              <LinearProgress sx={{ width: '100%' }} />
            </>
          ) : shippingLabel ? (
            <Box margin="0 auto" width="100%" height="auto">
              <embed
                src={`${shippingLabel.labelUrl}#page=1`}
                type="application/pdf"
                width="100%"
                height="500px"
              />
            </Box>
          ) : selectedOrderItem.status === 'fulfilled' ? (
            <Box margin="0 auto" width="100%" height="auto">
              <embed
                src={`${selectedOrderItem.shipping.labelUrl}#page=1`}
                type="application/pdf"
                width="100%"
                height="500px"
              />
            </Box>
          ) : null}
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '16px 24px',
        }}
      >
        <IntuIconButton
          onClick={onClose}
          color="primary"
          variant="outlined"
          type="cancel"
          tooltipTitle={transButtons('cancel')}
        />
        <IntuIconButton
          onClick={() => onConfirm(selectedOrderItem, shippingLabel)}
          color="primary"
          variant="contained"
          type="add"
          tooltipTitle={transButtons('fulfill')}
          disabled={!shippingLabel}
        />
      </DialogActions>
    </Dialog>
  );
};

export default OrderItemShippingLabelDialog;
