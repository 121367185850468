import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  ListItemButton,
  ListItemText,
  Divider,
  List,
  Grid,
  Collapse,
  useTheme,
  Box,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import SubMenu from '../drawer/SubMenu';
import { motion } from 'framer-motion';
import {
  NavList,
  NavItem,
  LanguageItem,
} from '../../../framer-motion/animations';
import { useTranslation } from 'react-i18next';
import { useAnalyticsEventTracker } from '../../../hooks/useAnalyticsTracker';
import { NavBarDrawerContext } from '../../../context/nav/NavBarDrawerContextProvider';
import NavBarContent from '../drawer/NavBarContent';

export const SubNavDrawerContext = createContext({});

const NonAuthNavItems = ({ mode }) => {
  const navigate = useNavigate();
  const { handleDrawerClose } = useContext(NavBarDrawerContext);
  const [openSubmenu, setOpenSubmenu] = useState(false);
  const { gaEventTracker } = useAnalyticsEventTracker();

  const handleSubMenuOpen = () => {
    setOpenSubmenu(!openSubmenu);
  };

  const { t } = useTranslation('buttons', {
    keyPrefix: 'navigation',
  });

  const theme = useTheme();

  const generalNavItems = [
    {
      index: 10,
      key: 'Home',
      label: t('home'),
      icon: '',
      target: () => handleNavigate('Home'),
      event: 'Home',
      location: 'drawer',
      visible: true,
    },
    {
      index: 20,
      key: 'Products',
      label: t('products'),
      icon: '',
      target: () => handleNavigate('Products'),
      event: 'Products',
      location: 'drawer',
      visible: true,
    },
    {
      index: 30,
      key: 'HowItWorks',
      label: t('learn_more'),
      icon: '',
      target: () => handleNavigate('HowItWorks'),
      event: 'Learn More',
      location: 'drawer',
      variant: 'contained',
      color: 'primary',
      visible: true,
    },
    {
      index: 40,
      key: 'Investors',
      label: t('investors'),
      icon: '',
      target: () => handleNavigate('Invest'),
      event: 'Investors',
      location: 'drawer',
      variant: 'contained',
      color: 'warning',
      visible: true,
    },
    {
      index: 60,
      key: 'Company',
      label: t('company'),
      icon: '',
      target: () => handleNavigate('Company'),
      event: 'About Company',
      location: 'drawer',
      visible: true,
    },
    {
      index: 70,
      key: 'Contact',
      label: t('contact_us'),
      icon: '',
      target: () => handleNavigate('Contact'),
      event: 'Contact Us',
      location: 'drawer',
      visible: true,
    },
    {
      index: 80,
      key: 'Blog',
      label: t('blog'),
      icon: '',
      target: () => handleNavigate('Blog'),
      event: 'Blog',
      location: 'drawer',
      visible: true,
    },
    {
      index: 90,
      key: 'SignUp',
      variant: 'outlined',
      label: t('sign_up'),
      icon: '',
      target: () => handleNavigate('SignUp'),
      width: '150px',
      event: 'SignUp',
      location: 'nav',
      visible: true,
    },
    {
      index: 100,
      key: 'Shop',
      label: t('shop'),
      variant: 'contained',
      icon: '',
      width: '150px',
      target: () => handleNavigate('Shop'),
      event: 'Shop',
      location: 'all',
      visible: true,
    },
  ];

  function handleNavigate(target) {
    handleDrawerClose();
    if (target === 'Home') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'Home',
      });
      return navigate('/');
    } else if (target === 'Blog') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'Blog',
        variant: 'outlined',
      });
      return navigate('/blog');
    } else if (target === 'SignUp') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'User Registration',
        variant: 'outlined',
      });
      return navigate('/account/signup');
    } else if (target === 'SignIn') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'User SignIn',
        variant: 'contained',
      });
      return navigate('/account/signin');
    } else if (target === 'HowItWorks') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'How It Woks',
      });
      return navigate('/how-it-works');
    } else if (target === 'Shop') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'How It Woks',
      });
      return navigate('/products');
    } else if (target === 'SalesEvents') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'Sales Events',
      });
      return navigate('/events/sales');
    } else if (target === 'VendorEvents') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'Vendor Events',
      });
      return navigate('/events/vendors');
    } else if (target === 'Company') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'About the Company',
      });
      return navigate('/company');
    } else if (target === 'Contact') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'Contact us',
      });
      return navigate('/contact');
    } else if (target === 'Invest') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'Invest in us',
      });
      return navigate('/investor');
    } else if (target === 'Products') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'Products',
      });
      return navigate('./products');
    } else if (target === 'Links') {
      return navigate('/credits');
    }
  }

  if (mode === 'NavBar') {
    return <NavBarContent generalNavItems={generalNavItems} mode={mode} />;
  } else {
    return (
      <SubNavDrawerContext.Provider
        value={{
          openSubmenu,
          setOpenSubmenu,
          handleNavigate,
          handleDrawerClose,
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          height="100%" // Full height
        >
          {/* Main Navigation Items */}
          {generalNavItems.map((menuItem) =>
            menuItem.visible &&
            (menuItem.location === 'drawer' || menuItem.location === 'all') ? (
              <React.Fragment key={`nav-wrapper.${menuItem.index}`}>
                {menuItem.key === 'Divider' ? (
                  <Divider
                    key={`${mode}.nav-divider.${menuItem.index}`}
                    sx={{
                      padding: 2,
                    }}
                  />
                ) : null}

                <Grid
                  item
                  key={`${mode}.nav-container.${menuItem.index}`}
                  sx={{ margin: '0px 5px 0 5px' }}
                >
                  <List
                    key={`${mode}.nav-list.${menuItem.index}`}
                    sx={{
                      width: '100%',
                      maxWidth: 360,
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                  >
                    <motion.div
                      animate="visible"
                      variants={NavList}
                      initial="hidden"
                      key={`${mode}.motiondiv-container.${menuItem.index}`}
                    >
                      {menuItem.drawerButton === true ? (
                        <motion.div
                          variants={LanguageItem}
                          whileHover={{ scale: 1.025 }}
                          key={`${mode}.motiondiv-item.${menuItem.index}`}
                        >
                          <Button
                            sx={{ width: '100%' }}
                            key={`${mode}.motiondiv-item-button.${menuItem.index}`}
                            variant={menuItem.variant}
                            color={menuItem?.color || 'primary'}
                            type="submit"
                            onClick={menuItem.target}
                          >
                            {menuItem.label}
                          </Button>
                        </motion.div>
                      ) : menuItem?.children?.length > 0 ? (
                        <motion.li
                          variants={NavItem}
                          key={`${mode}.motiondiv-children-item.${menuItem.index}`}
                        >
                          <ListItemButton
                            sx={{
                              height: '2rem',
                              justifyContent: 'initial',
                              px: 2.5,
                            }}
                            onClick={handleSubMenuOpen}
                            key={`${mode}.list-icon-button.${menuItem.index}`}
                          >
                            <ListItemText
                              primary={menuItem.label}
                              key={`${mode}.icon--button-text.${menuItem.index}`}
                              disableTypography
                            />

                            {openSubmenu ? <ExpandLess /> : <ExpandMore />}
                          </ListItemButton>
                          <Collapse
                            in={openSubmenu}
                            key={`${mode}.sub-menu.${menuItem.index}`}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List
                              component="div"
                              disablePadding
                              key={`${mode}.sub-menu-list.${menuItem.index}`}
                            >
                              {menuItem.children.map((subItem) => (
                                <SubMenu
                                  menuItem={subItem}
                                  key={`${mode}.submenu-item.${subItem.key}`}
                                />
                              ))}
                            </List>
                          </Collapse>
                        </motion.li>
                      ) : (
                        <motion.li
                          variants={NavItem}
                          whileHover={{ scale: 1.05 }}
                          key={`${mode}.motiondiv-list-item.${menuItem.index}`}
                        >
                          <ListItemButton
                            sx={{
                              height: '2rem',
                              justifyContent: 'initial',
                              px: 2.5,
                            }}
                            onClick={() => {
                              menuItem.target();
                              handleDrawerClose();
                            }}
                            key={`${mode}.motion-list-icon-button.${menuItem.index}`}
                          >
                            <ListItemText
                              secondary={menuItem.label}
                              disableTypography
                              key={`${mode}.motion-list-icon-text.${menuItem.index}`}
                            />
                          </ListItemButton>
                        </motion.li>
                      )}
                    </motion.div>
                  </List>
                </Grid>
              </React.Fragment>
            ) : null,
          )}

          {/* Sign In & SignUp Button */}
          <Box
            sx={{
              marginTop: 'auto',
              padding: 2,
              borderTop: `1px solid ${theme.palette.divider}`,
            }}
          >
            <Button
              variant="outlined"
              fullWidth
              sx={{ marginBottom: 1 }}
              onClick={() => {
                navigate('/account/signin');
                handleDrawerClose();
              }}
            >
              {t('sign_in')}
            </Button>
            <Button
              variant="contained"
              fullWidth
              color="success"
              onClick={() => {
                navigate('/account/signup');
                handleDrawerClose();
              }}
            >
              {t('sign_up')}
            </Button>
          </Box>
        </Box>
      </SubNavDrawerContext.Provider>
    );
  }
};

export default NonAuthNavItems;
