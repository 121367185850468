import { useTranslation } from 'react-i18next';
import { Container, Typography } from '@mui/material';
import FormikTabs from '../formik/FormikTabs';
import ReferralOffersList from './list/ReferralOffersList';
import { createContext, useEffect, useState } from 'react';
import {
  listReferralCampaigns,
  listReferralOffers,
} from '../../api/referrals/referralRoutes';
import useResponseHandling from '../../hooks/useResponseHandler';
import ReferralActiveCampaignList from './list/ReferralCampaignActiveList';
import ReferralCompletedCampaignList from './list/ReferralCampaignCompletedList';

export const ManageReferralsContext = createContext();

const ManageReferralsComponent = () => {
  // Translation
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.ManageReferrals',
  });

  // Context
  const { handleRegularResponse, handleErrorResponse } = useResponseHandling();

  // States
  const [referralOffers, setReferralOffers] = useState({
    count: 0,
    fetching: false,
    data: [],
  });
  const [referralCampaignsActive, setReferralCampaignsActive] = useState({
    count: 0,
    fetching: false,
    data: [],
  });
  const [referralCampaignsCompleted, setReferralCampaignsCompleted] = useState({
    count: 0,
    fetching: false,
    data: [],
  });

  const tabContent = [
    {
      title: (
        <Typography variant="h6" textTransform="none">
          {t('tabs.active_referral_campaigns.title')}
        </Typography>
      ),
      description: (
        <Typography variant="h6" textTransform="none">
          {t('tabs.active_referral_campaigns.description')}
        </Typography>
      ),
      content: <ReferralActiveCampaignList />,
      visible: referralCampaignsActive.count > 0 ? true : false,
    },
    {
      title: (
        <Typography variant="h6" textTransform="none">
          {t('tabs.referral_offers.title')}
        </Typography>
      ),
      description: (
        <Typography variant="h6" textTransform="none">
          {t('tabs.referral_offers.description')}
        </Typography>
      ),
      content: <ReferralOffersList />,
      visible: referralOffers.count > 0 ? true : false,
    },
    {
      title: (
        <Typography variant="h6" textTransform="none">
          {t('tabs.completed_referral_campaigns.title')}
        </Typography>
      ),
      description: (
        <Typography variant="h6" textTransform="none">
          {t('tabs.completed_referral_campaigns.description')}
        </Typography>
      ),
      content: <ReferralCompletedCampaignList />,
      // content: <ReferralActiveCampaignList />,
      visible: referralCampaignsCompleted.count > 0 ? true : false,
    },
  ];

  const getActiveReferralCampaigns = async ({ refresh = false }) => {
    setReferralCampaignsActive({ ...referralCampaignsActive, fetching: true });

    try {
      const { statusCode, data, status, message } =
        await listReferralCampaigns();

      // console.log('Get Active Campaigns Response -> ', data);

      if (statusCode === 200) {
        const filteredCampaigns = data.filter(
          (campaign) =>
            campaign?.status === 'pending' || campaign?.status === 'active',
        );

        setReferralCampaignsActive({
          ...referralCampaignsActive,
          count: filteredCampaigns.length,
          data: filteredCampaigns,
          fetching: false,
        });

        if (refresh === true) {
          handleRegularResponse({
            open: true,
            status: status,
            statusCode: statusCode,
            message: message,
          });
        }
      }
    } catch (error) {
      console.error('error', error);

      setReferralCampaignsActive({
        ...referralCampaignsActive,
        fetching: false,
        count: 0,
        data: [],
      });

      handleErrorResponse(error);
    }
  };

  const getCompletedReferralCampaigns = async ({ refresh = false }) => {
    setReferralCampaignsCompleted({
      ...referralCampaignsCompleted,
      fetching: true,
    });

    try {
      const { statusCode, data, status, message } =
        await listReferralCampaigns();

      if (statusCode === 200) {
        const filteredCampaigns = data.filter(
          (campaign) =>
            campaign?.status === 'completed' || campaign?.status === 'archived',
        );
        setReferralCampaignsCompleted({
          ...referralCampaignsCompleted,
          count: filteredCampaigns.length,
          data: filteredCampaigns,
          fetching: false,
        });

        if (refresh === true) {
          handleRegularResponse({
            open: true,
            status: status,
            statusCode: statusCode,
            message: message,
          });
        }
      }
    } catch (error) {
      console.error('error', error);
      handleErrorResponse(error);
      setReferralCampaignsCompleted({
        ...referralCampaignsCompleted,
        fetching: false,
        count: 0,
        data: [],
      });
    }
  };

  const getReferralOffers = async ({ refresh = false }) => {
    setReferralOffers({
      ...referralOffers,
      fetching: true,
    });

    try {
      const { message, status, statusCode, data } = await listReferralOffers();

      if (statusCode === 200) {
        setReferralOffers({
          ...referralOffers,
          fetching: false,
          count: data.length,
          data: data,
        });

        if (refresh === true) {
          handleRegularResponse({
            open: true,
            status: status,
            statusCode: statusCode,
            message: message,
          });
        }
      } else {
        handleRegularResponse({
          open: true,
          status: status,
          message: message,
        });
      }
    } catch (error) {
      console.error('error', error);
      setReferralOffers({
        ...referralOffers,
        fetching: false,
        count: 0,
        data: [],
      });
    }
  };

  useEffect(() => {
    getActiveReferralCampaigns({ refresh: false });
    getCompletedReferralCampaigns({ refresh: false });
    getReferralOffers({ refresh: false });
  }, []);

  // console.log('Referall Offers Context -> ', referralOffers);
  // console.log('Referral Campaigns Active -> ', referralCampaignsActive);
  // console.log('Referral Campaigns Completed -> ', referralCampaignsCompleted);
  // console.log('Referral Campaign Offers -> ', referralOffers);

  return (
    <ManageReferralsContext.Provider
      value={{
        referralOffers,
        setReferralOffers,
        referralCampaignsActive,
        setReferralCampaignsActive,
        referralCampaignsCompleted,
        setReferralCampaignsCompleted,
        getActiveReferralCampaigns,
        getReferralOffers,
      }}
    >
      <FormikTabs tabsContent={tabContent} />
    </ManageReferralsContext.Provider>
  );
};

export default ManageReferralsComponent;
