import { useContext } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import IntuIconButton from '../../buttons/IntuIconButton';
import { ReferralOfferContext } from '../list/ReferralOffersList';

const CampaignOfferDetailsDialog = () => {
  const { offerDetailsDialog, setOfferDetailsDialog, handleAcceptReferral } =
    useContext(ReferralOfferContext);

  const { t: transDialogs } = useTranslation('dialogs', {
    keyPrefix: 'CampaignOfferDetailsDialog',
  });
  const { t: transButtons } = useTranslation('buttons');

  const handleClose = () => {
    setOfferDetailsDialog({
      ...offerDetailsDialog,
      open: false,
    });
  };

  const handleAcceptReferralDialog = (data) => {
    setOfferDetailsDialog({
      ...offerDetailsDialog,
      open: false,
    });
    handleAcceptReferral(data);
  };

  return (
    <Dialog
      open={offerDetailsDialog.open}
      onClose={() => handleClose()}
      maxWidth="md"
    >
      <DialogTitle>{transDialogs('title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {transDialogs('description', {
            product: offerDetailsDialog.data?.product_name,
          })}
        </DialogContentText>
        <Box
          display="flex"
          flexDirection="column"
          gap={1}
          sx={{ marginTop: '1rem' }}
        >
          {/* Campaign Lifetime */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="body1">
                {transDialogs('fields.lifetime.label')}
              </Typography>
            </Box>
            <Box>
              {transDialogs('fields.lifetime.value', {
                lifetime: offerDetailsDialog?.data?.referral_terms?.lifetime,
              })}
            </Box>
          </Box>

          {/* Reward per Visit */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="body1">
                {transDialogs('fields.cpc')}
              </Typography>
            </Box>
            <Box>
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(offerDetailsDialog?.data?.referral_terms?.cpc)}
            </Box>
          </Box>

          {/* Reward per Inquiry */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="body1">
                {transDialogs('fields.cpa')}
              </Typography>
            </Box>
            <Box>
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(offerDetailsDialog?.data?.referral_terms?.cpa)}
            </Box>
          </Box>

          {/* Reward per order*/}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="body1">
                {transDialogs('fields.cpo')}
              </Typography>
            </Box>
            <Box>
              {new Intl.NumberFormat('en-US', {
                style: 'percent',
                minimumFractionDigits: 2, // Ensures two decimal places
                maximumFractionDigits: 2,
              }).format(offerDetailsDialog?.data?.referral_terms?.cpo)}
            </Box>
          </Box>

          {/* Example  */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
                {transDialogs('fields.example', {
                  listPrice: new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(offerDetailsDialog?.data?.list_price),
                  reward: new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(
                    offerDetailsDialog?.data?.referral_terms?.cpo *
                      offerDetailsDialog.data?.list_price,
                  ),
                })}
              </Typography>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
        >
          <IntuIconButton
            onClick={() => handleClose()}
            type="cancel"
            tooltipTitle={transButtons('cancel')}
          />

          <IntuIconButton
            onClick={() => handleAcceptReferralDialog(offerDetailsDialog?.data)}
            type="add"
            tooltipTitle={transButtons('cancel')}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export { CampaignOfferDetailsDialog };
