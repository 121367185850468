import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: { xs: '2rem 0 0 0', md: '2rem 2rem 2rem 0' } }}>
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function FormikTabs({ tabsContent = [], isScrollable = false }) {
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Default visibility to true if not explicitly set
  const visibleTabs = tabsContent.filter((tab) => tab?.visible !== false);

  // Ensure the selected tab remains within valid bounds
  useEffect(() => {
    if (value >= visibleTabs.length) {
      setValue(visibleTabs.length - 1 >= 0 ? visibleTabs.length - 1 : 0);
    }
  }, [visibleTabs.length, value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box flexGrow={1} width="100%">
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="responsive tabs"
          variant={
            isMobile ? 'fullWidth' : isScrollable ? 'scrollable' : 'standard'
          }
          scrollButtons="auto"
        >
          {visibleTabs.map((tab, index) => (
            <Tab
              key={index}
              label={tab?.title}
              {...a11yProps(index)}
              sx={{
                minWidth: 'auto',
                fontSize: { xs: '0.8rem', sm: '1rem' },
                color: 'text.primary',
              }}
            />
          ))}
        </Tabs>
      </Box>
      {visibleTabs.map((tab, index) => (
        <CustomTabPanel key={index} value={value} index={index}>
          {tab?.content}
        </CustomTabPanel>
      ))}
    </Box>
  );
}
