import {
  useState,
  useCallback,
  useContext,
  createContext,
  useEffect,
} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Chip,
  IconButton,
  CircularProgress,
  Stack,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  useAxiosPrivate,
  useAxiosPrivateData,
} from '../../../hooks/axios/useAxiosPrivate';
import { convertFileToBase64 } from '../../../helpers/convertFileToBase64';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import IntuIconButton from '../../buttons/IntuIconButton';
import NewAttributeDialog from '../../product-attributes/NewAttributeDialog';
import DropzoneContainer from '../../dropzoneContainer/DropzoneContainer';
import { Cancel, Close } from '@mui/icons-material';
import { AttributeContainerContext } from '../container/AttributeContainer';
import { useFormikContext } from 'formik';
import {
  measurementOptions,
  otherUnits,
  weightOptions,
} from '../../../data/MeasruementUnits';
import useResponseHandling from '../../../hooks/useResponseHandler';
import { axiosPrivate } from '../../../hooks/axios/axios';

export const ExtractDialogContext = createContext();

const ExtractDataDialog = () => {
  const {
    isExtractDataDialogOpen,
    setIsExtractDataDialogOpen,
    productAttributeOptions,
    productAttributeTypeOptions,
    setSelectedAttributeType,
    selectedAttributeType,
    setSelectedAttribute,
    selectedAttribute,
    getAttributeTypeOptions,
    setSelectedAttributes,
    setProductAttributeOptions,
    selectedAttributes,
  } = useContext(AttributeContainerContext);

  const { values } = useFormikContext();

  const { t: transDialog } = useTranslation('dialogs', {
    keyPrefix: 'ExtractDataDialog',
  });
  const { t: transButtons } = useTranslation('buttons');

  const axios = useAxiosPrivate();
  const axiosData = useAxiosPrivateData();

  const [file, setFile] = useState(null);
  const [previewURL, setPreviewURL] = useState(null);
  const [isExtractionLoading, setIsExtractionLoading] = useState(false);
  const [previewChips, setPreviewChips] = useState([]);
  const [extractedData, setExtractedData] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [selectedExtractedData, setSelectedExtractedData] = useState({});
  const [fileUrl, setFileUrl] = useState(null);
  const [newAttributeDialogOpen, setNewAttributeDialogOpen] = useState(false);
  const [unitSelectionList, setUnitSelectionList] = useState([]);
  const [abortController, setAbortController] = useState(null);
  const [newDataFound, setNewDataFound] = useState(true);

  // Attribute Names
  const attributeLookup = Object.fromEntries(
    productAttributeOptions.map((a) => [a._id, a]),
  );

  const attributeTypeLookup = Object.fromEntries(
    productAttributeTypeOptions.map((a) => [a._id, a]),
  );

  const { handleRegularResponse } = useResponseHandling();

  const formatKey = (key) => {
    return key
      .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before uppercase letters
      .replace(/_/g, ' ') // Replace underscores with spaces
      .split(' ')
      .map((word) => `${word[0].toUpperCase()}${word.slice(1).toLowerCase()}`) // Capitalize each word
      .join(' ');
  };

  const formatDataLabel = ({ option }) => {
    if (!option.name || !option.attribute || !option.attribute_type) {
      console.warn('Missing required fields:', {
        name: option.name,
        attribute: option.attribute,
        attribute_type: option.attribute_type,
      });
      return;
    }

    let label = '';

    // LookUp Attribute Name
    const attribute = attributeLookup[option.attribute];
    if (!attribute?.name) {
      console.warn('Attribute not found in lookup:', option.attribute);
      label = `${formatKey(option.name)} : ${option.value} ${option.unit ? `${option.unit}` : ''}`;
    } else {
      label = `${attribute?.name} : ${option.value} ${option.unit ? `${option.unit}` : ''}`;
    }

    return label;
  };

  const onDrop = useCallback((file) => {
    if (file) {
      setFile(file);
      handleUpload(file);
    }
  }, []);

  const handleReset = async ({ values }) => {
    // Remove File from DO
    const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
    const bucketPayload = {
      folder: `products/${values._id}/documents/${fileName}`,
    };

    // Remove from DO bucket
    await axios.post('/api/aws/content/delete', bucketPayload, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    setFile(null);
    setFileUrl(null);
    setPreviewChips([]);
    setPreviewURL(null);
    setExtractedData(null);
  };

  const handleUpload = async (file) => {
    if (file) {
      if (file.type === 'application/pdf') {
        let fileUrl = '';
        try {
          setPreviewURL(URL.createObjectURL(file));
          setFile(file);

          const base64File = await convertFileToBase64(file);

          const requestData = {
            file: base64File,
            file_type: 'pdf',
            file_name: file.name,
            folder: `products/${values?._id}/documents`,
          };

          const { data, status } = await axiosData.post(
            '/api/aws/content/add',
            JSON.stringify(requestData),
            {
              headers: {
                'Content-Type': 'application/json',
              },
            },
          );

          if (status === 200) {
            setFileUrl(data.data);
            fileUrl = data.data;
          }
        } catch (error) {
          setPreviewURL(fileUrl);
          console.error('error', error);
        }
      }
    }
  };

  const handleDataExtraction = async () => {
    if (!file) return;

    const controller = new AbortController();
    setAbortController(controller);

    const signal = controller.signal;

    const url = '/api/ai/data-extraction/fetch-attributes';

    setIsExtractionLoading(true);

    try {
      const response = await axiosPrivate.get(url, {
        params: {
          fileUrl,
          fileName: file.name,
        },
        responseType: 'stream',
        adapter: 'fetch',
        signal,
      });

      if (response.status === 200) {
        const reader = response.data.getReader();
        const decoder = new TextDecoder();

        let previewChip = '';
        let result = '';

        while (true) {
          if (signal.aborted) {
            console.warn('Stream request was cancelled.');
            reader.cancel();
            return;
          }

          const { done, value } = await reader.read();
          if (done) break;

          const chunk = decoder.decode(value, { stream: true });
          result += chunk;
          previewChip += chunk;

          const chunkResponse = result.split(/data:\s*/).filter(Boolean);
          const chunkJSONResponse = JSON.parse(chunkResponse.pop());
          console.log('Chunk Response => ', chunkJSONResponse);

          if (chunkJSONResponse.statusCode === 200) {
            const jsonChunks = chunkJSONResponse.data;

            if (!selectedAttributes.has(jsonChunks.attribute)) {
              setPreviewChips((prevState) => [...prevState, jsonChunks]);
              setExtractedData((prevState) => [...prevState, jsonChunks]);
            } else {
              setNewDataFound(false);
            }

            previewChip = '';
          }
        }

        // Response Handler
        const finalResponse = result.split(/data:\s*/).filter(Boolean);
        const finalJSONResponse = JSON.parse(finalResponse.pop());
        console.log('final Response => ', finalJSONResponse);

        if (finalJSONResponse.statusCode === 200) {
          setExtractedData(finalJSONResponse.data);

          getAttributeTypeOptions(); // Fetch the Latest Attributes in case the AI Assistant added one in the backend
        }

        handleRegularResponse({
          open: true,
          status: finalJSONResponse.status,
          statusCode: finalJSONResponse.statusCode,
          message: finalJSONResponse.message,
        });
      }
    } catch (error) {
      console.error('Error while extracting data:', error);
    } finally {
      setIsExtractionLoading(false);
      setAbortController(null);
    }
  };

  // 🔹 Function to Cancel Request
  const cancelExtractData = () => {
    if (abortController) {
      abortController.abort();
      setAbortController(null);
    }
  };

  const handleItemEdit = ({ key, value }) => {
    // Get Attribute Type
    // console.log(' Handle Edit Key -> ', key);
    // console.log(' Handle Edit Value -> ', value);

    // Lookuop Selected Attribute Type
    const attributeType = attributeTypeLookup[value?.attribute_type];
    if (attributeType) {
      // console.log('Found Attribute Type -> ', attributeType);
      setSelectedAttributeType(attributeType);
    }

    // console.log('New Selected Attribute Type -> ', selectedAttributeType);

    // Set Attribute
    if (value) {
      setSelectedAttribute(value);
    }

    // console.log('New Selected Attribute -> ', selectedAttribute);

    // Set Editing item Details
    setEditingItem({
      attributeKey: key,
      attributeValue: value,
    });

    setNewAttributeDialogOpen(true);
  };

  // Construct A Units List
  const unitsListTemplate = [
    ...measurementOptions,
    ...weightOptions,
    ...otherUnits,
  ];

  const handleGetAttributeTypeUnitOptions = async () => {
    const dataKeys = selectedAttributeType?.data_keys;

    if (dataKeys?.length > 0) {
      setUnitSelectionList(dataKeys);
    } else {
      setUnitSelectionList(unitsListTemplate);
    }
  };

  // Get Atribute Values based on Attribute Selection
  useEffect(() => {
    if (!selectedAttributeType) {
      return;
    }

    handleGetAttributeTypeUnitOptions(selectedAttributeType._id);
  }, [selectedAttributeType]);

  const handleItemAdd = (key, value) => {
    const { [key]: itemToRemove, ...data } = extractedData;
    setExtractedData({ ...data });
    setSelectedExtractedData((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const handleItemRemove = (key, value) => {
    const { [key]: itemToRemove, ...data } = selectedExtractedData;
    setSelectedExtractedData({ ...data });
    setExtractedData({
      ...extractedData,
      [key]: value,
    });
  };

  // console.log('Selected Attributes on Mount -> ', selectedAttributes);

  const onConfirm = async () => {
    try {
      // console.log('Writing Attributes to Container');

      const mappedExtractedData = Object.keys(selectedExtractedData).map(
        (key) => selectedExtractedData[key],
      );

      if (mappedExtractedData.length > 0) {
        const newAttributes = mappedExtractedData.map((attribute) => ({
          attribute_type: attribute.attribute_type,
          attribute: attribute.attribute,
          name: attribute.name,
          values: [
            {
              unit: attribute?.unit || null,
              value: attribute?.value,
            },
          ],
        }));

        // console.log('New Attributes to Add ->', newAttributes);

        // Update selectedAttributes properly

        console.log(
          'Selected Attributes before the update -> ',
          selectedAttributes,
        );

        setSelectedAttributes((prevState) => {
          const attributeMap = new Map();

          // Add existing attributes to the map
          prevState.forEach((attr) => attributeMap.set(attr.attribute, attr));

          // Add new attributes, replacing if necessary
          newAttributes.forEach((attr) =>
            attributeMap.set(attr.attribute, attr),
          );

          return new Set(attributeMap.values()); // Convert back to Set
        });
        console.log(
          'Selected Attributes after the update -> ',
          selectedAttributes,
        );

        // Update productAttributeOptions correctly
        setProductAttributeOptions((prevState) => [
          ...prevState,
          ...newAttributes,
        ]);

        // Remove newAttributes from extracted data, so user can come back
        if (
          typeof extractedData === 'object' &&
          Object.values(extractedData).length > 0 &&
          newAttributes.length > 0
        ) {
          // Convert extractedData object to an array of entries (if needed)
          const extractedArray = Object.values(extractedData);

          // Filter out the attributes that exist in newAttributes
          const remainingAttributes = extractedArray.filter(
            (attr) =>
              !newAttributes.some(
                (newAttr) => newAttr?.attribute === attr?.attribute,
              ),
          );

          // If you need to convert back to an object
          const remainingAttributesObj = Object.fromEntries(
            remainingAttributes.map((item) => [item.attribute, item]),
          );

          setExtractedData(remainingAttributesObj);
        }
      } else {
        return;
      }
    } catch (error) {
      console.error('error', error);
    } finally {
      setIsExtractDataDialogOpen(false);
    }
  };

  // console.log('Selected Attributes -> ', selectedAttributes);
  // console.log('file -> ', file);
  // console.log('Seelcted Extracted Data -> ', selectedExtractedData);

  return (
    <ExtractDialogContext.Provider
      value={{
        newAttributeDialogOpen,
        setNewAttributeDialogOpen,
        editingItem,
        setEditingItem,
        unitSelectionList,
        setUnitSelectionList,
        selectedExtractedData,
        setSelectedExtractedData,
        extractedData,
        setExtractedData,
        handleItemAdd,
      }}
    >
      <Dialog
        open={isExtractDataDialogOpen}
        onClose={() => {
          setIsExtractDataDialogOpen(false);
          setNewDataFound(true);
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{transDialog('title')}</DialogTitle>
        <DialogContent style={{ paddingTop: '20px' }}>
          {!previewURL ? (
            <DropzoneContainer
              onDrop={onDrop}
              onClick={(e) => console.log(e)}
            />
          ) : null}

          {file && !previewURL ? (
            <Typography variant="body2" mt={2}>
              {transDialog('sected_file', { fileName: file.name })}
            </Typography>
          ) : null}

          <Box display="flex" gap={2} mt={4}>
            {previewURL ? (
              <Box minWidth={400}>
                <embed
                  src={`${previewURL}#page=1`}
                  type="application/pdf"
                  width="100%"
                  height="500px"
                />
              </Box>
            ) : null}
            <Box
              width="50%"
              maxHeight={500}
              display="flex"
              flexDirection="column"
            >
              {/* Is Loading State */}

              {isExtractionLoading && (
                <Box
                  sx={{
                    minHeight: 60,
                    display: 'flex',
                    flexDirection: 'column',
                    mb: '2rem',
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ width: '100%' }}
                  >
                    <Typography mr={2}>
                      {transDialog('loading.title')}
                    </Typography>
                    <CircularProgress />
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ width: '100%', mb: 2 }}
                  >
                    <Box width="100%" sx={{ mb: 2 }}>
                      <Typography mr={2}>
                        {transDialog('loading.description')}
                      </Typography>
                    </Box>

                    <Box display="flex" justifyContent="center" width="100%">
                      <Button
                        color="error"
                        type="cancel"
                        variant="outlined"
                        endIcon={<Close sx={{ color: 'error.main' }} />}
                        onClick={cancelExtractData}
                        disabled={!isExtractionLoading}
                        sx={{ mr: 6 }}
                      >
                        {transButtons('cancel')}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )}

              {/* Preview Attributes  */}
              {isExtractionLoading && previewChips.length > 0 ? (
                <Box>
                  <Box>
                    <Typography variant="p">
                      {transDialog('extracted')}
                    </Typography>
                  </Box>
                  {previewChips.length > 0 && (
                    <Box mt={2}>
                      {previewChips.map((pc, index) => {
                        // console.log('Preview Chip Details -> ', pc);
                        if (
                          pc?.name &&
                          pc?.name !== null &&
                          pc?.attribute &&
                          pc?.attribute !== null &&
                          pc?.attribute_type &&
                          pc?.attribute !== null &&
                          pc?.value &&
                          pc?.value !== null
                        ) {
                          return (
                            <Chip
                              key={index}
                              label={formatDataLabel({ option: pc })}
                              variant="outlined"
                              sx={{
                                mb: 0.5,
                                mr: 0.5,
                                background: (theme) =>
                                  theme.palette.disabled.main,
                                color: (theme) => theme.palette.grey.main,
                              }}
                            />
                          );
                        }
                      })}
                    </Box>
                  )}
                </Box>
              ) : null}

              {/* Extracted Attributes */}
              {!isExtractionLoading &&
                extractedData &&
                Object.keys(extractedData)?.length > 0 && (
                  <Box>
                    <Box>
                      <Typography align="center" variant="p">
                        {transDialog('extracted')}
                      </Typography>
                    </Box>
                    <Box mt={2}>
                      {Object.keys(extractedData).length > 0 &&
                        Object.keys(extractedData).map((dataKey, index) => {
                          return (
                            <Chip
                              key={index}
                              label={formatDataLabel({
                                option: extractedData[dataKey],
                              })}
                              variant="outlined"
                              sx={{
                                mb: 0.5,
                                mr: 0.5,
                                background: (theme) =>
                                  theme.palette.disabled.main,
                                color: (theme) => theme.palette.grey.main,
                              }}
                              onDelete={() => {}}
                              deleteIcon={
                                <Box>
                                  <IconButton
                                    size="small"
                                    color="warning"
                                    disabled={isExtractionLoading}
                                    onClick={() =>
                                      handleItemEdit({
                                        key: dataKey,
                                        value: extractedData[dataKey],
                                        data: extractedData,
                                      })
                                    }
                                  >
                                    <EditIcon
                                      sx={{
                                        color: 'grey.light',
                                      }}
                                    />
                                  </IconButton>
                                  <IconButton
                                    size="small"
                                    disabled={isExtractionLoading}
                                    onClick={() =>
                                      handleItemAdd(
                                        dataKey,
                                        extractedData[dataKey],
                                      )
                                    }
                                  >
                                    <AddIcon
                                      sx={{
                                        color: 'grey.light',
                                      }}
                                    />
                                  </IconButton>
                                </Box>
                              }
                            />
                          );
                        })}
                    </Box>
                  </Box>
                )}

              {/* Selected Attributes */}
              {selectedExtractedData &&
                Object.keys(selectedExtractedData)?.length > 0 && (
                  <Box>
                    <Box>
                      <Typography variant="p" mt={2}>
                        {transDialog('chose')}
                      </Typography>
                    </Box>
                    <Box mt={2}>
                      {Object.keys(selectedExtractedData).length ? (
                        <>
                          {Object.keys(selectedExtractedData).map(
                            (dataKey, index) => {
                              return (
                                <Chip
                                  key={index}
                                  label={formatDataLabel({
                                    option: selectedExtractedData[dataKey],
                                  })}
                                  variant="outlined"
                                  sx={{
                                    mb: 0.5,
                                    mr: 0.5,
                                    '& .MuiChip-deleteIcon': {
                                      color: 'error.light',
                                      '&:hover': {
                                        color: 'error.dark',
                                      },
                                    },
                                    background: (theme) =>
                                      theme.palette.disabled.main,
                                    color: (theme) => theme.palette.grey.main,
                                  }}
                                  onDelete={() => {}}
                                  deleteIcon={
                                    <Box>
                                      <IconButton
                                        disabled={isExtractionLoading}
                                        size="small"
                                        onClick={() =>
                                          handleItemRemove(
                                            dataKey,
                                            selectedExtractedData[dataKey],
                                          )
                                        }
                                      >
                                        <Cancel
                                          sx={{
                                            color: 'grey.light',
                                          }}
                                        />
                                      </IconButton>
                                    </Box>
                                  }
                                />
                              );
                            },
                          )}
                        </>
                      ) : null}
                    </Box>
                  </Box>
                )}

              {/* No NEw Attributes Found */}
              {!newDataFound && (
                <Box
                  sx={{
                    minHeight: 60,
                    display: 'flex',
                    flexDirection: 'column',
                    mb: '2rem',
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ width: '100%' }}
                  >
                    <Typography mr={2}>{transDialog('no_data')}</Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'space-between',
            paddingBottom: '16px',
            paddingRight: '16px',
            paddingLeft: '16px',
          }}
        >
          <Stack direction="row" spacing={2}>
            <IntuIconButton
              type="cancel"
              variant="outlined"
              tooltipTitle={transButtons('close')}
              onClick={() => setIsExtractDataDialogOpen(false)}
              disabled={isExtractionLoading}
            />
            {fileUrl && (
              <IntuIconButton
                type="reset"
                variant="outlined"
                tooltipTitle={transButtons('reset')}
                onClick={() => handleReset({ values })}
                disabled={isExtractionLoading}
              />
            )}
          </Stack>

          <Stack direction="row" spacing={2} sx={{ marginLeft: 'auto' }}>
            {/* Extract */}
            {fileUrl && !isExtractionLoading && (
              <IntuIconButton
                type="extract"
                variant="outlined"
                tooltipTitle={transButtons('extract')}
                onClick={handleDataExtraction}
                disabled={!file || isExtractionLoading}
              />
            )}

            {/* Confirm Button */}
            {Object.keys(selectedExtractedData)?.length ? (
              <IntuIconButton
                type="submit"
                variant="outlined"
                tooltipTitle={transButtons('confirm')}
                onClick={() => onConfirm()}
                disabled={
                  !file ||
                  isExtractionLoading ||
                  !Object.keys(selectedExtractedData)?.length
                }
              />
            ) : null}
          </Stack>
        </DialogActions>

        {/* New Attribute Dialog */}

        <NewAttributeDialog
          selectedAttribute={editingItem}
          productAttributeOptions={productAttributeOptions}
        />
      </Dialog>
    </ExtractDialogContext.Provider>
  );
};

export { ExtractDataDialog };
