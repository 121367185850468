import { Box, Divider, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { translateMultiLineText } from '../../api/translation/google';

const renderProductDescription = (blocks) => {
  return blocks.map((block, index) => (
    <Typography key={index} variant="body1" paragraph>
      {block.text}
    </Typography>
  ));
};

const ProductDescriptionBox = ({ product, displayTitle = true }) => {
  const [productDescription, setProductDescription] = useState([]);
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.ProductDescriptionBox',
  });

  useEffect(() => {
    const fetchProductDescription = async () => {
      if (!product || !product.descriptions) {
        setProductDescription([]);
        return;
      }

      const findLocalDescription = product?.descriptions.find(
        (description) => i18n.language === description.code,
      );

      if (findLocalDescription?.description) {
        setProductDescription(findLocalDescription?.description?.blocks || []);
      } else if (product.descriptions[0]?.description?.blocks) {
        try {
          const standardDescriptionText =
            product.descriptions[0].description.blocks.map(
              (block) => block.text,
            );

          const payload = {
            inputText: standardDescriptionText,
            targetLanguage: i18n.language,
          };

          const getTranslation = await translateMultiLineText(payload);
          const { data, statusCode } = getTranslation;

          if (statusCode === 200) {
            const translatedBlocks = data.map((text, index) => ({
              key: `${index}`,
              text,
              type:
                product.descriptions[0].description.blocks[index].type ||
                'unordered-list-item',
              depth: 0,
              inlineStyleRanges: [],
              entityRanges: [],
            }));

            setProductDescription(translatedBlocks);
          } else {
            setProductDescription(
              product.descriptions[0]?.description?.blocks || [],
            );
          }
        } catch (error) {
          setProductDescription(
            product.descriptions[0]?.description?.blocks || [],
          );
        }
      }
    };

    fetchProductDescription();
  }, [product, i18n.language]);

  return (
    <Box display="flex" flexDirection="column">
      {displayTitle && (
        <Box>
          <Typography variant="h6">{t('title')}</Typography>
          <Divider />
        </Box>
      )}
      <Box>{renderProductDescription(productDescription)}</Box>
    </Box>
  );
};

export default ProductDescriptionBox;
