import * as React from 'react';

import { listBalanceTransactions } from '../../../../api/stripe/balanceTransactions.js';
import {
  Grid,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  Divider,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useState, useMemo, useEffect, createContext, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { StripeContext } from '../../../../context/providers/stripe/StripeContextProvider.jsx';
import ListLoader from '../../../status/ListLoader.jsx';
import ReceiptIcon from '@mui/icons-material/Receipt';
import IntuIconButton from '../../../buttons/IntuIconButton.jsx';
import dayjs from 'dayjs';
import { toUpperCase } from '../../../../helpers/toTitleCase.js';

export const BalanceTransactionDetailsContext = createContext();

const StripeBalanceTransactionsList = () => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumView = useMediaQuery(theme.breakpoints.down('md'));
  const { stripeUserData } = useContext(StripeContext);
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.stripe.BalanceTransactionsList',
  });
  const { t: transButtons } = useTranslation('buttons');
  const { t: transTypes } = useTranslation('types');

  const [balanceTransactions, setBalanceTransactions] = useState([]);
  const [isListLoading, setIsListLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const handlePaginationChange = ({ page, pageSize }) => {
    console.log('Page -> ', page);
    console.log('Page Size -> ', pageSize);
    setPage(page);
    setPageSize(pageSize);

    if (hasNextPage) {
      fetchBalanceTransactions({ page, pageSize });
    }
  };

  const tableColumns = useMemo(() => {
    return [
      {
        field: 'date',
        headerName: t('table.headers.date'),
        editable: false,
        flex: 0.2,
        minWidth: 50,
        align: 'left',
        valueGetter: (params) => {
          const userLocale = navigator.languages
            ? navigator.languages[0]
            : navigator.language;

          // Create a date object with the adjusted time
          const date = dayjs.unix(params.row.created).toDate();

          // Format the date in the user's local format
          return new Intl.DateTimeFormat(userLocale, {
            dateStyle: 'medium', // Adjust to 'full', 'long', 'medium', or 'short'
            // timeStyle: 'short', // Include time if needed
          }).format(date);
        },
      },
      {
        field: 'description',
        headerName: t('table.headers.description'),
        editable: false,
        flex: 0.3,
        minWidth: 120,
        align: 'left',
      },
      {
        field: 'net',
        headerName: t('table.headers.amount'),
        editable: false,
        flex: 0.1,
        minWidth: 100,
        valueGetter: (params) => {
          const amount = (params.row.net / 100).toFixed(2);
          const formattedAmount = `${amount} ${toUpperCase(params.row.currency)}`;
          return formattedAmount;
        },
        align: 'right',
        headerAlign: 'right',
      },
      {
        field: 'exchange_rate',
        headerName: t('table.headers.exchange_rate'),
        editable: false,
        flex: 0.1,
        minWidth: 100,
        valueGetter: (params) => {
          const amount = params.row.exchange_rate?.toFixed(3) || null;
          return amount;
        },
        align: 'right',
        headerAlign: 'right',
      },
      {
        field: 'available_on',
        headerName: t('table.headers.available_on'),
        editable: false,
        flex: 0.1,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        valueGetter: (params) => {
          const userLocale = navigator.languages
            ? navigator.languages[0]
            : navigator.language;

          // Create a date object with the adjusted time
          const date = dayjs.unix(params.row.created).add(30, 'day').toDate();

          // Format the date in the user's local format
          return new Intl.DateTimeFormat(userLocale, {
            dateStyle: 'short', // Adjust to 'full', 'long', 'medium', or 'short'
            // timeStyle: 'short', // Include time if needed
          }).format(date);
        },
      },
      {
        field: 'receipt_url',
        headerName: t('table.headers.receipt'),
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => {
          // Handle Receipt View Click
          function handleViewClick(receipt_url) {
            if (!receipt_url) return;
            window.open(receipt_url, '_blank', 'noopener,noreferrer');
          }

          return (
            params.row.receipt_url && (
              <IntuIconButton
                size="small"
                type="edit"
                variant="outlined"
                onClick={() => handleViewClick(params.row.receipt_url)}
                tooltipTitle={transButtons('view_receipt')}
                IconComponent={ReceiptIcon}
                iconOnly
              />
            )
          );
        },
      },
    ];
  }, [t, stripeUserData]);

  /**
   * Ensures Only Number of Rows defined in the PageSize are being displayed per page
   */
  const paginatedRows = balanceTransactions.slice(
    page * pageSize,
    (page + 1) * pageSize,
  );

  /**
   * Fetch Balance Transactions
   * Fetches transactions from the server based on the current pagination model.
   */
  const fetchBalanceTransactions = async () => {
    setIsListLoading(true);
    try {
      const params = { limit: pageSize };

      // Forward pagination
      if (hasNextPage) {
        console.log(
          'Laat Balance Trasacrion -> ',
          balanceTransactions[balanceTransactions.length - 1].id,
        );
        params.starting_after =
          balanceTransactions[balanceTransactions.length - 1].id;
      }

      const { data, status } = await listBalanceTransactions(params);

      if (status === 200) {
        const newTransactions = [
          ...balanceTransactions,
          ...data.data.transactions,
        ];

        setBalanceTransactions(newTransactions);
        setHasNextPage(data.data.hasMore);
      }
    } catch (error) {
      console.error('Error fetching transactions:', error);
    } finally {
      setIsListLoading(false);
    }
  };

  // Fetch data when the pagination model changes
  useEffect(() => {
    fetchBalanceTransactions();
  }, []);

  /**
   * Custom Pagination Label Display
   * Corrects "1-10 from 18" to "11-18 from 18".
   */
  const labelDisplayedRows = ({ from, to, count }) => {
    return `${from}–${to} ${transTypes('of')} ${balanceTransactions.length}`;
  };

  return (
    <BalanceTransactionDetailsContext.Provider value={{}}>
      <Grid container spacing={5} sx={{ marginBottom: '3rem' }}>
        <Grid item xs={12}>
          <Typography variant="h5">{t('title')}</Typography>
          <Divider sx={{ marginBottom: '2rem' }} />

          {isListLoading ? (
            <ListLoader columns={5} rows={4} />
          ) : balanceTransactions.length === 0 ? (
            <Grid item xs={12} className="title content-centered">
              <Typography variant="body1">
                <Trans t={t} i18nKey="table.no_data" />
              </Typography>
            </Grid>
          ) : (
            <Box width="100%">
              <DataGrid
                rows={paginatedRows}
                getRowId={(row) => row.id}
                columns={tableColumns}
                loading={isListLoading}
                page={page}
                rowCount={
                  hasNextPage ? pageSize + 1 : balanceTransactions.length
                }
                estimatedRowCount={100}
                pageSizeOptions={[5, 10, 25, 50]}
                paginationModel={{ page, pageSize }}
                onPaginationModelChange={handlePaginationChange}
                paginationMode="server"
                localeText={{
                  MuiTablePagination: { labelDisplayedRows },
                }}
                columnVisibilityModel={{
                  description: !isMobileView,
                  exchange_rate: !isMobileView && !isMediumView,
                }}
                sx={{
                  borderRadius: '12px',
                  boxShadow: 6,
                  '& .MuiDataGrid-columnSeparator': { display: 'none' },
                  '& .MuiDataGrid-cell:focus': { outline: 'none' },
                  '& .MuiDataGrid-cell:focus-within': { outline: 'none' },
                }}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </BalanceTransactionDetailsContext.Provider>
  );
};

export default StripeBalanceTransactionsList;
