import React from 'react';
import { useTranslation } from 'react-i18next';
import AddressField from '../../address/AddressField';
import PhoneField from '../../account/PhoneField ';
import { Box } from '@mui/material';

const PersonalContactTab = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.account.PersonalContactTab',
  });

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      {/* Address */}
      <Box>
        <AddressField
          fieldTitle={t('address.title')}
          fieldDescription={t('address.description')}
          fieldID="address"
          autocompleteID="address"
          transNS="fields"
          transPrefix="AddressField"
          required={true}
        />
      </Box>

      {/* Phone */}
      <Box>
        <PhoneField
          fieldTitle={t('phone.title')}
          fieldDescription={t('phone.description')}
          fieldID="phone"
          transNS="fields"
          transPrefix="account.individual.PhoneField"
          required={true}
        />
      </Box>
    </Box>
  );
};

export default PersonalContactTab;
