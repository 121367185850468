import {
  measurementOptions,
  weightOptions,
} from '../../../../data/MeasruementUnits';

export const createAddNewProductAttributeValueInitialValues = ({
  selectedAttributeType,
}) => {
  const isWeight = weightOptions.filter(
    (w) => w === selectedAttributeType?.data_keys,
  );
  const isMeasurement = measurementOptions.filter(
    (m) => m === selectedAttributeType?.data_keys,
  );

  let unit = '';
  if (isWeight.length > 0 || isMeasurement.length > 0) {
    unit = selectedAttributeType?.data_keys[0];
  }

  if (isWeight || isMeasurement) {
    return {
      unit: unit,
      value: '',
    };
  } else {
    return {
      unit: null,
      value: '',
    };
  }
};
