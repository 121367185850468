import { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import { Box, Skeleton, Typography } from '@mui/material';
import {
  convertDataToGeoJSON,
  createColorExpression,
  getUniqueCategories,
  getUniqueCategoriesWithColors,
} from '../../helpers/mapHelperFunctions';
import './maps.css';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_TOKEN;

const intuLightBlue = '#6ea4d4';
const intuDarkGreen = '#A8C957';
const intuDarkGrey = '#333333';
const intuMiddleGrey = '#6F7978';
const defaultColor = intuDarkGreen;

const styles = {
  globe: 'mapbox://styles/intutecio/clmbwv14801ay01pf80c7hkm6',
  mercator: 'mapbox://styles/intutecio/clnqhhdpn00fc01qw0w2j6y4a',
};

const ClusteredMap = ({
  locations,
  initialZoom,
  projection,
  cluster = true,
  center = [-74.006, 40.7128],
  rounded,
  legend = true,
  isDataLoaded,
  disableBorder,
}) => {
  const [isMounted, setIsMounted] = useState(false);
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);

  const geojsonData = convertDataToGeoJSON(locations);
  const categoryColors = getUniqueCategoriesWithColors(locations);
  const uniqueCategories = getUniqueCategories(locations);

  // Initialize the map once
  useEffect(() => {
    if (mapRef.current || !mapContainerRef.current) return;

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: styles[projection] || 'mapbox://styles/mapbox/streets-v11',
      center,
      zoom: initialZoom,
      attributionControl: false,
    });

    mapRef.current = map;

    map.on('load', () => {
      map.addSource('points', {
        type: 'geojson',
        data: geojsonData,
        cluster,
        clusterMaxZoom: 10,
        clusterRadius: 40,
      });

      map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'points',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            intuDarkGreen,
            100,
            intuMiddleGrey,
            750,
            intuLightBlue,
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40,
          ],
        },
      });

      const colorExpression = createColorExpression(
        uniqueCategories,
        categoryColors,
        defaultColor,
      );

      map.addLayer({
        id: 'individual-points',
        type: 'circle',
        source: 'points',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': colorExpression,
          'circle-radius': 4,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff',
        },
      });

      map.on('click', 'individual-points', (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();
        const properties = e.features[0].properties;

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(properties.content)
          .addTo(map);
      });

      map.resize();
      setIsMounted(true);
    });

    return () => {
      map.remove();
      mapRef.current = null;
    };
  }, []);

  // Update data if locations change
  useEffect(() => {
    if (mapRef.current && mapRef.current.getSource('points')) {
      mapRef.current.getSource('points').setData(geojsonData);
    }
  }, [geojsonData]);

  // Resize map on window resize (important for mobile)
  useEffect(() => {
    const handleResize = () => {
      if (mapRef.current) {
        mapRef.current.resize();
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Box
      className={disableBorder ? '' : 'intu__divider'}
      width="100%"
      position="relative"
    >
      <Box
        ref={mapContainerRef}
        sx={{
          width: '100%',
          height: '500px',
          minHeight: '300px',
          borderRadius: rounded ? '25px' : '0px',
          overflow: 'hidden',
        }}
      />
      {!isMounted && isDataLoaded && (
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          animation="wave"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            borderRadius: rounded ? '25px' : '0px',
            zIndex: 1,
          }}
        />
      )}
      {legend && uniqueCategories.length > 0 && (
        <Box display="flex" flexWrap="wrap" mt={2}>
          {uniqueCategories.map((category, index) => (
            <Box key={category} display="flex" alignItems="center" mb={1}>
              <Box
                width={20}
                height={20}
                bgcolor={categoryColors[index]}
                mx={1}
                borderRadius="50%"
              />
              <Typography variant="body2">{category}</Typography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default ClusteredMap;
