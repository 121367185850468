import { Suspense, useEffect } from 'react';
import { useThree } from '@react-three/fiber';
import {
  PerspectiveCamera,
  OrbitControls,
  Environment,
} from '@react-three/drei';
import { Box3, Vector3 } from 'three';
import CanvasLoader from './CanvasLoader';
import GLTFModel from './GLTFModel';

const ThreeModel = ({
  url,
  handleSceneLoaded,
  showOrbitControls,
  cameraPosition,
  setCameraPosition,
  rotationX,
  rotationY,
  setIsProductImageDirtied,
  initialCenter = false,
  wireframeMode,
  enableZoom = false,
  autoRotate = false,
  autoRotateSpeed = 0,
  maxDistance = 1000,
}) => {
  const { scene, camera } = useThree();

  const handleOrbitControlsChange = () => {
    setIsProductImageDirtied(true);
    setCameraPosition([
      camera.position.x,
      camera.position.y,
      camera.position.z,
    ]);
  };

  // Center on initial load
  useEffect(() => {
    if (!scene || !camera || !initialCenter) return;

    // Compute bounding box of the loaded model
    const box = new Box3().setFromObject(scene);
    const center = new Vector3();
    box.getCenter(center);

    // Move entire scene to the center
    scene.position.sub(center);

    // Adjust camera position to fit the entire model
    const size = box.getSize(new Vector3());
    const maxDim = Math.max(size.x, size.y, size.z);
    const fov = camera.fov * (Math.PI / 180);
    const cameraDistance = Math.abs(maxDim / (2 * Math.tan(fov / 2)));

    camera.position.set(0, 0, cameraDistance); // Adjust multiplier for zoom level
    camera.lookAt(0, 0, 50);
    camera.updateProjectionMatrix();

    if (setCameraPosition) {
      setCameraPosition([
        camera.position.x,
        camera.position.y,
        camera.position.z,
      ]);
    }

    handleSceneLoaded(scene);
  }, [initialCenter, scene, camera]);

  useEffect(() => {
    if (!scene) return;

    scene.traverse((child) => {
      if (child.isMesh && child.material) {
        if (Array.isArray(child.material)) {
          child.material.forEach((mat) => (mat.wireframe = wireframeMode));
        } else {
          child.material.wireframe = wireframeMode;
        }
      }
    });
  }, [scene, wireframeMode]);

  return (
    <Suspense fallback={<CanvasLoader />}>
      <Environment preset="warehouse" />
      <GLTFModel
        url={url}
        rotationX={rotationX}
        rotationY={rotationY}
        handleSceneLoaded={handleSceneLoaded}
      />
      <ambientLight intensity={1} />
      <PerspectiveCamera position={cameraPosition} makeDefault />
      {showOrbitControls && (
        <OrbitControls
          enableZoom={enableZoom}
          autoRotate={autoRotate}
          autoRotateSpeed={autoRotateSpeed}
          maxDistance={maxDistance}
          onEnd={handleOrbitControlsChange}
          showOrbitControls={showOrbitControls}
        />
      )}
    </Suspense>
  );
};

export default ThreeModel;
