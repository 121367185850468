import i18next from 'i18next';
import * as Yup from 'yup';

const OrderPaymentValidationSchema = () => {
  const t = i18next.getFixedT(null, 'validation');
  const transTypes = i18next.getFixedT(null, 'types');

  return Yup.object().shape({
    name: Yup.string().required(t('name.required')),
    address: Yup.object({
      line1: Yup.string()
        .min(5, t('short', { type: transTypes('address'), char: 5 }))
        .required(t('address.line1_required')),
      city: Yup.string()
        .min(3, t('short', { type: transTypes('city'), char: 3 }))
        .required(t('address.city_required')),
      state: Yup.string()
        .min(2, t('short', { type: transTypes('state'), char: 2 }))
        .required(t('address.state_required')),
      postcode: Yup.string()
        .min(3, t('short', { type: transTypes('postcode'), char: 3 }))
        .required(t('address.postcode_required')),
      country: Yup.object({
        label: Yup.string().required(t('country.required')),
        dialCode: Yup.number(t('country.dial_code.invalid'))
          .required(t('country.dial_code.required'))
          .typeError(t('country.dial_code.error')),
        code: Yup.string()
          .required(t('country.code.required'))
          .min(2, t('country.code.min')),
      }),
    }),
    card_number: Yup.boolean()
      .oneOf([true], t('credit_card.number_invalid'))
      .required(t('credit_card.number_invalid')),

    expiration: Yup.boolean()
      .oneOf([true], t('credit_card.expiration_invalid'))
      .required(t('credit_card.expiration_required')),

    cvc: Yup.boolean()
      .oneOf([true], t('credit_card.cvc_invalid'))
      .required(t('credit_card.cvc_required')),
  });
};

export { OrderPaymentValidationSchema };
