export const createInitialValuesCommissionsBox = (
  commissionType,
  commissionSchedule,
  commission,
  percentCommission,
  exampleRevenue,
) => {
  return {
    commission_schedule: commissionType === 'tiered' ? commissionSchedule : [],
    type: commissionType || '',
    commission: {
      max_amount: 0,
      commission: commissionType === 'flat' ? commissionSchedule : 0,
      cost: null,
      index: null,
    },
    example: {
      revenue: exampleRevenue,
      commission: commission || 0,
      effective_commission: percentCommission || 0,
    },
  };
};
