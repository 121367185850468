import React, { useContext } from 'react';
import FormikFieldWrapper from '../formik/FormikFieldWrapper';
import { TextField, useTheme } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import { useFormikContext } from 'formik';
import { classes } from '../../settings/theme';
import { TwoFactorVerificationContext } from '../../context/providers/TwoFactorVerificationContextProvider';
import { getNestedValue } from '../../helpers/getNestedValuesIn';

const MobileOTPField = ({
  fieldTitle, // field Title
  fieldDescription, // Field Description
  fieldID = 'otp', //Determines the Name and ID of the field
  transNS = 'fields', // Name Spoace of Transaltion
  transPrefix = 'MobileOTPField', // Namespace for translation Value
  transition = true, // Fade in = true and Fade Out = false
  required = false, // Whethher the field is required or not
  disabled = false, // Whether the Field is disabled
  variant = 'outlined', // Takes Standard or outlined as a property
}) => {
  // Context
  const { values, setFieldValue, errors, handleBlur, touched } =
    useFormikContext();

  // Translation
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  return (
    <FormikFieldWrapper
      fieldTitle={fieldTitle}
      fieldDescription={fieldDescription}
      transition={transition}
    >
      <TextField
        required={required}
        disabled={disabled}
        style={classes.root}
        id={fieldID}
        name={fieldID}
        className="form-select-field"
        autoComplete="one-time-code"
        aria-invalid={fieldError ? 'true' : 'false'}
        aria-describedby="uidnote"
        variant={variant}
        label={t('label')}
        type="text"
        placeholder={t('placeholder')}
        onChange={(e) => {
          setFieldValue(fieldID, e.target.value);
        }}
        inputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]*',
        }}
        onBlur={handleBlur}
        value={fieldValue}
        error={fieldError && fieldTouched ? true : false}
        helperText={fieldError && fieldTouched ? fieldError : null}
      />
    </FormikFieldWrapper>
  );
};

export default MobileOTPField;
