import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  LinearProgress,
  Skeleton,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PersonIcon from '@mui/icons-material/Person';
import IntuIconButton from '../../components/buttons/IntuIconButton';
import Find from '../../components/find/Find';
import { getSalesReps } from '../../api/usersRoutes';

const useCreateColumns = () => {
  const { t: transButtons } = useTranslation('buttons');
  const { t: transTypes } = useTranslation('types');
  const navigate = useNavigate();
  return [
    {
      field: 'name',
      headerName: 'Name',
      editable: false,
      flex: 1,
      minWidth: 80,
      sortable: false,
      renderCell: (params) => {
        let image;
        if (params.row.profile_picture) {
          image = `data:${params.row.profile_picture.mimetype};base64,${params.row.profile_picture.buffer.toString('base64')}`;
        }
        return (
          <Box display="flex" alignItems="center">
            <Avatar src={image || ''} alt="brand logo">
              <PersonIcon sx={{ color: 'primary.main' }} />
            </Avatar>
            <Box display="flex" flexDirection="column" ml={2}>
              <Typography fontWeight="bold">{params.row.name}</Typography>
              {params.row.address ? (
                <Typography fontSize="0.7rem">
                  {params.row.address.city},{' '}
                  {params.row.address.state?.toUpperCase()}
                </Typography>
              ) : null}
            </Box>
          </Box>
        );
      },
    },
    {
      field: 'net_promoter_score',
      headerName: 'Net Promoter Score',
      editable: false,
      flex: 1,
      minWidth: 50,
      renderCell: (params) => {
        return (
          <LinearProgress
            variant="determinate"
            value={params.row.net_promoter_score * 10}
            color="primary"
            sx={{ width: '100%', borderRadius: 5 }}
          />
        );
      },
    },
    {
      field: 'applications',
      headerName: 'Applications',
      editable: false,
      sortable: false,
      flex: 1,
      valueGetter: (params) =>
        params.row.applications.map((type) => type.name_en).join(', '),
    },
    {
      field: 'product_types',
      headerName: 'Product Type',
      flex: 1,
      sortable: false,
      editable: false,
      valueGetter: (params) =>
        params.row.product_types.map((type) => type.name_en).join(', '),
    },
    {
      field: 'average_commission',
      headerName: 'Avg. Commission',
      valueGetter: (params) =>
        new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(params.row.average_commission),
      flex: 1,
      editable: false,
    },
    {
      field: 'view',
      editable: false,
      flex: 0.5,
      sortable: false,
      renderHeader: () => null,
      renderCell: (params) => {
        return (
          <IntuIconButton
            size="small"
            type="add"
            variant="outlined"
            tooltipTitle={transButtons('view', {
              type: transTypes('salesrep'),
            })}
            onClick={() => navigate(`/app/user-profile/${params.row.id}`)}
            IconComponent={VisibilityIcon}
          />
        );
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      editable: false,
      sortable: false,
      flex: 1,
    },
  ];
};

const FindSalesRepsPage = () => {
  const { t } = useTranslation();
  const [salesReps, setSalesReps] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    fetchSalesReps();
  }, []);

  const fetchSalesReps = async () => {
    setIsLoading(true);
    const res = await getSalesReps();
    setSalesReps(res);
    setIsLoading(false);
  };

  useEffect(() => {
    // Parse URL parameters to set initial filter state
    const params = new URLSearchParams(location.search);
    const initialFilters = filters.map((filter) => {
      const paramValue = params.get(filter.id);
      return {
        ...filter,
        value: paramValue ? paramValue.split(',') : [],
      };
    });
    setFilters(initialFilters);
  }, [location.search]);

  const [filters, setFilters] = useState([
    {
      id: 'map',
      label: 'Map',
      type: 'map',
      value: [],
      options: [],
    },
    {
      id: 'industry',
      label: 'Industries',
      type: 'autocomplete',
      value: [],
      options: [],
    },
    {
      id: 'product_type',
      label: 'Product Types',
      type: 'autocomplete',
      value: [],
      options: [],
    },
    {
      id: 'product_application',
      label: 'Product Applications',
      type: 'autocomplete',
      value: [],
      options: [],
    },
  ]);

  const handleFilterChange = (newValue, reason, details, filterId) => {
    let updatedFilters = [];
    if (reason === 'map') {
      updatedFilters = filters.map((filter) => {
        if (reason === filter.id) {
          return {
            ...filter,
            value: newValue.selected
              ? [...filter.value, newValue.id]
              : filter.value.filter((x) => x === newValue.id),
          };
        }
        return filter;
      });
    } else if (reason === 'clear') {
      updatedFilters = filters.map((filter) => {
        if (filter.id === filterId) {
          return {
            ...filter,
            value: [],
          };
        }
        return filter;
      });
    } else if (reason === 'removeOption') {
      updatedFilters = filters.map((filter) => {
        if (filter.id === filterId) {
          return {
            ...filter,
            value: filter.value.filter((x) => x !== details.option),
          };
        }
        return filter;
      });
    } else {
      const value = newValue[newValue.length - 1];

      updatedFilters = filters.map((filter) => {
        if (filter.id === filterId) {
          return {
            ...filter,
            value: [...filter.value, value],
          };
        }
        return filter;
      });
    }

    setFilters(updatedFilters);

    // Update URL parameters
    const params = new URLSearchParams(location.search);
    updatedFilters.forEach((filter) => {
      const filterValue = filter.value.join(',');
      if (filterValue) {
        params.set(filter.id, filterValue);
      } else {
        params.delete(filter.id);
      }
    });
    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  };

  const productTypeFilterValue = useMemo(() => {
    return filters.find((filter) => filter.id === 'product_type').value;
  }, [filters]);

  const productApplicationFilterValue = useMemo(() => {
    return filters.find((filter) => filter.id === 'product_application').value;
  }, [filters]);

  const industryFilterValue = useMemo(() => {
    return filters.find((filter) => filter.id === 'industry').value;
  }, [filters]);

  const mapFilterValue = useMemo(() => {
    return filters.find((filter) => filter.id === 'map').value;
  }, [filters]);

  const filteredList = useMemo(() => {
    const nonActiveSalesReps = salesReps.filter(
      (rep) => rep.relationship_status !== 'active',
    );

    const hasSearched = filters.some((filter) => filter.value.length > 0);
    if (!hasSearched) return nonActiveSalesReps;

    // Check if salesReps contains our filters
    return nonActiveSalesReps.filter((rep) => {
      let containsProductType = true;
      let containsApplication = true;
      let containsIndustry = true;
      let containsMap = true;

      if (productTypeFilterValue.length) {
        let equals = false;
        for (const type of productTypeFilterValue) {
          equals = rep.product_types.some((_type) => _type.name_en === type);
          if (equals) {
            break;
          }
        }
        if (equals) {
          containsProductType = true;
        } else {
          containsProductType = false;
        }
      }

      if (productApplicationFilterValue.length) {
        let equals = false;
        for (const application of productApplicationFilterValue) {
          equals = rep.applications.some(
            (_application) => _application.name_en === application,
          );
          if (equals) {
            break;
          }
        }
        if (equals) {
          containsApplication = true;
        } else {
          containsApplication = false;
        }
      }

      if (industryFilterValue.length) {
        let equals = false;
        for (const industry of industryFilterValue) {
          equals = rep.industries.some(
            (_industry) => _industry.name_en === industry,
          );
          if (equals) {
            break;
          }
        }
        if (equals) {
          containsIndustry = true;
        } else {
          containsIndustry = false;
        }
      }

      if (mapFilterValue.length) {
        const containsMapFilter = mapFilterValue.some((filterValue) => {
          if (!rep.address || !rep.address.state) return false;
          return filterValue
            .toLowerCase()
            .includes(rep.address.state.toLowerCase());
        });

        if (containsMapFilter) {
          containsMap = true;
        } else {
          containsMap = false;
        }
      }

      return containsProductType &&
        containsApplication &&
        containsIndustry &&
        containsMap
        ? rep
        : null;
    });
  }, [
    salesReps,
    productTypeFilterValue,
    productApplicationFilterValue,
    industryFilterValue,
    mapFilterValue,
  ]);

  const industryOptions = useMemo(() => {
    if (!filteredList.length) return new Map();

    const industryOptionsMap = new Map();
    filteredList.forEach((rep) => {
      rep.industries.forEach((industry) => {
        industryOptionsMap.set(industry._id, industry.name_en);
      });
    });
    return industryOptionsMap;
  }, [filteredList]);

  const productTypeOptions = useMemo(() => {
    if (!filteredList.length) return new Map();

    const productTypeOptionsMap = new Map();
    filteredList.forEach((brand) => {
      brand.product_types.forEach((type) => {
        productTypeOptionsMap.set(type._id, type);
      });
    });
    return productTypeOptionsMap;
  }, [filteredList]);

  const productApplicationOptions = useMemo(() => {
    if (!filteredList.length) return new Map();

    const productApplicationOptionsMap = new Map();
    filteredList.forEach((brand) => {
      brand.applications.forEach((type) => {
        productApplicationOptionsMap.set(type._id, type);
      });
    });
    return productApplicationOptionsMap;
  }, [filteredList]);

  useEffect(() => {
    const optionsArray = Array.from(industryOptions, ([key, value]) => value);

    setFilters((prevState) => {
      return prevState.map((filter) => {
        if (filter.id === 'industry') {
          return {
            ...filter,
            options: optionsArray,
          };
        }
        return filter;
      });
    });
  }, [industryOptions]);

  useEffect(() => {
    const optionsArray = Array.from(
      productTypeOptions,
      ([key, value]) => value.name_en,
    );

    setFilters((prevState) => {
      return prevState.map((filter) => {
        if (filter.id === 'product_type') {
          return {
            ...filter,
            options: optionsArray,
          };
        }
        return filter;
      });
    });
  }, [productTypeOptions]);

  useEffect(() => {
    const optionsArray = Array.from(
      productApplicationOptions,
      ([key, value]) => value.name_en,
    );

    setFilters((prevState) => {
      return prevState.map((filter) => {
        if (filter.id === 'product_application') {
          return {
            ...filter,
            options: optionsArray,
          };
        }
        return filter;
      });
    });
  }, [productApplicationOptions]);

  const columns = useCreateColumns();

  return (
    <Box mt={10} p={4}>
      <Typography variant="h2">Find Sales Engineers</Typography>
      {isLoading || !salesReps.length ? (
        <Skeleton width="100%" height={500} />
      ) : (
        <Find
          list={salesReps}
          filteredList={filteredList}
          filters={filters}
          columns={columns}
          onFilterChange={handleFilterChange}
          filtersHaveValues={
            productTypeFilterValue.length ||
            productApplicationFilterValue.length ||
            industryFilterValue.length
          }
        />
      )}
    </Box>
  );
};

export default FindSalesRepsPage;
