import React, { useRef, memo } from 'react';
import { useDrag, useDrop } from 'intu-dnd';
import { ReactComponent as Image3DSVG } from '../../../assets/new-product/3d-image.svg';

const ItemType = 'INDICATOR';

const DraggableImage = memo(({ image }) => {
  return (
    <img
      key={`${image.url}-image-${image.modified || image.created || ''}`}
      alt={image.name}
      src={image.url}
      style={{
        width: '50px',
        height: '50px',
        objectFit: 'cover',
        borderRadius: '4px',
        boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
      }}
    />
  );
});

const Draggable3DFile = memo(({ image }) => {
  return (
    <Image3DSVG
      width="50px"
      height="50px"
      key={`${image.url}-image-${image.modified || image.created || ''}`}
    />
  );
});

const DraggableIndicator = ({
  image,
  index,
  actualIndex,
  onClick,
  isActive,
  theme,
  moveImage,
}) => {
  const ref = useRef(null);

  const [, drag] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => {
      return { isDragging: monitor.isDragging() };
    },
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem, monitor) => {
      const draggedIndex = monitor.getItem().index;
      if (draggedIndex === index) return; // Prevent self-hovering

      moveImage(draggedIndex, index);

      // Update the dragged item's index in a safe way
      monitor.getItem().index = index;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  // Attach drag and drop refs
  drag(drop(ref));

  if (!image) return null;

  return (
    <div
      ref={ref}
      key={`${image.url}-${index}-container`}
      onClick={() => onClick(actualIndex)}
      style={{
        cursor: 'pointer',
        transition: 'all 0.2s ease-in-out',
        borderBottom: isActive
          ? `2px solid ${theme.palette.primary.main}`
          : 'none',
        background: 'transparent',
        padding: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {image.imageType.toLowerCase() === 'gltf' ? (
        <Draggable3DFile image={image} />
      ) : (
        <DraggableImage image={image} />
      )}
    </div>
  );
};

export default DraggableIndicator;
