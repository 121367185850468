import React, { useEffect, useState } from 'react';
import ClusteredMap from '../../../maps/ClusteredMap';
import { useAxiosPrivate } from '../../../../hooks/axios/useAxiosPrivate';
import { useTranslation } from 'react-i18next';
import useResponseHandling from '../../../../hooks/useResponseHandler';
import { PieChartContext } from '../../../../charts/pieChart';
import { Grid, Typography } from '@mui/material';
import useIntuTheme from '../../../../hooks/useIntuTheme';
import { listPublicCustomerInformation } from '../../../../api/customers/customers';

const ExistingCustomersByIndustry = ({
  title = true,
  description = true,
  legend = true,
  padding = false,
  ...props
}) => {
  // Subscribe to Context
  const { handleErrorResponse } = useResponseHandling();
  const { mobileView } = useIntuTheme();

  // Translator
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.howItWorks.lead_context.existing_customers',
  });

  // SetUp Axios
  const axios = useAxiosPrivate();

  // SetUp States
  const [customerLocations, setCustomerLocations] = useState([]);
  const [customerIndustries, setCustomerIndustries] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  async function getCustomers() {
    try {
      const request = await listPublicCustomerInformation();

      if (request.statusCode === 200) {
        const { data } = request;

        // Prepare Customer Locations
        let locations = [];
        let industries = [];

        for (let i = 0; i < data.length; i++) {
          const location = data[i]?.location;
          const customer = data[i]?.company;
          const industry = data[i]?.industry;
          if (location?.longitude && location?.latitude) {
            locations.push({
              name: customer,
              content: `<h3>${customer}</h3><p>${industry}</p>`,
              category: industry,
              coordinates: {
                lat: location.latitude,
                lng: location.longitude,
              },
            });
          }

          if (industry) {
            industries.push(industry);
          }
        }

        // Prepare Industries Chart
        const industryChartData = categorizeAndCount(industries);

        // Set Customer Industries Chart Data
        setCustomerIndustries({
          id: 'customerIndustries',
          title: t('industry_chart_title'),
          description: t('industry_chart_desc'),
          data: industryChartData,
        });

        // Filter locations where the count is greater than 1,000
        const filteredLocations = locations.filter((location) =>
          industryChartData.some(
            (categoryItem) =>
              categoryItem.category === location.category &&
              categoryItem.value > 1000,
          ),
        );

        setCustomerLocations(filteredLocations);
        setIsDataLoaded(true);
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  }

  // Categorize Customer Industries
  function categorizeAndCount(array) {
    const countMap = array.reduce((acc, value) => {
      acc[value] = (acc[value] || 0) + 1;
      return acc;
    }, {});

    // Filter categories with count > 1,000
    const categorizedArray = Object.keys(countMap)
      .map((key) => ({
        category: key,
        value: countMap[key],
      }))
      .filter((item) => item.value > 1000);

    return categorizedArray;
  }

  useEffect(() => {
    let isMounted = true;

    async function preLoadFormData() {
      if (!isMounted) return;
      await getCustomers();
    }

    preLoadFormData();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Grid {...props}>
      {title === true && (
        <Typography variant="h4" textAlign="center">
          {t('cust_industry_title')}
        </Typography>
      )}
      {description === true && (
        <Typography
          variant="body1"
          textAlign="center"
          sx={{ marginBottom: '2rem' }}
        >
          {t('cust_industry_content')}
        </Typography>
      )}
      {customerLocations.length ? (
        <ClusteredMap
          initialZoom={2}
          locations={customerLocations}
          popup={true}
          cluster={false}
          legend={false}
          projection="globe"
          center={[-98.5795, 39.8283]}
          fullwidth={true}
          sx={{ width: '100vw', margin: 0, padding: 0 }}
          isDataLoaded={isDataLoaded}
        />
      ) : null}

      {legend === true && customerIndustries?.data ? (
        <Grid item container xs={12}>
          <PieChartContext
            data={customerIndustries?.data}
            chartId={customerIndustries?.id}
            mobileView={mobileView}
            displayValueAsPercentage={false}
            displayValueAsLabel="Customers"
            hideLabels={true}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export { ExistingCustomersByIndustry };
