import * as Yup from 'yup';
import i18next from 'i18next';
import blockedDomainList from '../../../../data/BlockedDomains.json';

// List of blocked burner email domains
let blockedDomains = [];
if (
  process.env.REACT_APP_ENV !== 'development' &&
  process.env.REACT_APP_ENV !== 'staging'
) {
  blockedDomains = blockedDomainList;
}

export const createSignUpValidationSchema = () => {
  const t = i18next.getFixedT(null, 'validation');
  const transTypes = i18next.getFixedT(null, 'types');

  return Yup.object().shape({
    account_type: Yup.string().required(t('role_required')),
    first_name: Yup.string()
      .min(2, t('short', { type: transTypes('first_name'), char: 2 }))
      .max(50, t('long', { type: transTypes('first_name'), char: 50 }))
      .matches(/^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/, t('name.first_required'))
      .required(t('name.first_required')),
    last_name: Yup.string()
      .min(2, t('short', { type: transTypes('last_name'), char: 2 }))
      .max(50, t('long', { type: transTypes('last_name'), char: 50 }))
      .matches(/^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/, t('name.last_matches'))
      .required(t('name.last_required')),
    email: Yup.string()
      .required(t('email.user_required'))
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        t('email.invalid'),
      )
      .test(
        'is-not-burner-email',
        t('email.burner_blocked'), // Example: 'Burner email addresses are not allowed'
        (value) => {
          if (!value) return true; // Skip validation for empty values
          const domain = value.split('@')[1]?.toLowerCase();
          return !blockedDomains.includes(domain);
        },
      ),
    password: Yup.string()
      .required(t('password.required'))
      .min(8, t('password.short'))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/,
        t('password.pattern'),
      ),
    passwordConf: Yup.string()
      .required(t('password.comf_required'))
      .oneOf([Yup.ref('password'), null], t('password.comf_match')),
  });
};
