import { useTranslation } from 'react-i18next';
import { IntuForm } from '../../components/layout/IntuForm';
import ResetChangePasswordForm from '../../components/account/forms/reset/ResetPasswordForm';
import IntuPage from '../../components/layout/IntuPage';
import './account.css';
import { LayoutBodyContext } from '../../context/layout/LayoutContextProvider';
import { useContext, useEffect } from 'react';

function AccountChangePassword() {
  const { t } = useTranslation();

  const {
    layoutTitle,
    setLayoutTitle,
    layoutDescription,
    setLayoutDescription,
  } = useContext(LayoutBodyContext);

  useEffect(() => {
    setLayoutTitle(t('context.account.changePasswordContext.title'));
    setLayoutDescription(t('context.account.changePasswordContext.body'));
  }, []);

  return (
    <IntuPage
      pageTitle={layoutTitle}
      pageDescription={layoutDescription}
      hCentered={true}
    >
      <IntuForm
        formType="accPassChange"
        formButton={t('buttons.change_password')}
      >
        <ResetChangePasswordForm />
      </IntuForm>
    </IntuPage>
  );
}
export default AccountChangePassword;
