import {
  FormControl,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  Stack,
} from '@mui/material';
import { useFormikContext } from 'formik';
import TaxCodeBox from '../TaxCodeBox';
import TarifBox from '../TarifBox';
import MeasurementField from '../../fields/measurement/MeasurementField';
import AmountField from '../../fields/number/AmountField';
import { useTranslation } from 'react-i18next';
import UnitSelectionField from '../../fields/unit/UnitSelectionField.jsx';
import {
  measurementOptions,
  weightOptions,
} from '../../../data/MeasruementUnits.js';
import CountryField from '../../address/CountryField.jsx';
import { useEffect } from 'react';

const FulfillmentContainer = () => {
  const { values, setValues } = useFormikContext();
  const { t } = useTranslation('container', {
    keyPrefix: 'FullfillmentContainer',
  });
  const { t: transTypes } = useTranslation('types');
  const { t: transFields } = useTranslation('fields');

  const handleCheckboxChange = () => {
    setValues({
      ...values,
      fullfillment: {
        ...values.fullfillment,
        intutec: !values.fullfillment?.intutec,
      },
    });
  };

  // Calculate Volume
  useEffect(() => {
    const { length, width, height, unit } = values?.dimensions;

    if (!length || !width || !height) {
      // console.log('Missing Info');
      return;
    }

    console.log('triggered Volume Calculation', values.dimensions);

    if (length && width && height) {
      let cbm = 0;
      let cbf = 0;

      if (unit === 'mm') {
        // Convert mm to CBM and CBF
        cbm = (length * width * height) / 1000000000; // mm to m^3
        cbf = length * width * height * 0.0000353147; // mm to ft^3
      } else if (unit === 'cm') {
        // Convert cm to CBM and CBF
        cbm = (length * width * height) / 1000000; // cm to m^3
        cbf = length * width * height * 0.0353147; // cm to ft^3
      } else if (unit === 'm') {
        // Convert m to CBM and CBF
        cbm = length * width * height; // m^3 is already in the right unit
        cbf = length * width * height * 35.3147; // m to ft^3
      } else if (unit === 'in') {
        // Convert inches to CBM and CBF
        cbm = length * width * height * 0.0000163871; // in to m^3
        cbf = length * width * height * 0.000578704; // in to ft^3
      } else if (unit === 'ft') {
        // Convert feet to CBM and CBF
        cbm = length * width * height * 0.0283168; // ft to m^3
        cbf = length * width * height; // ft^3 is already in the right unit
      }

      setValues((prevValues) => ({
        ...prevValues,
        volume: {
          cbm: parseFloat(cbm.toFixed(4)),
          cbf: parseFloat(cbf.toFixed(4)),
        },
      }));
      console.log('Values -> ', values);
    }
  }, [
    values.dimensions.length,
    values.dimensions.width,
    values.dimensions.height,
    values.dimensions.unit,
    setValues,
  ]);

  return (
    <Box
      width="100%"
      my={2}
      display="flex"
      flexDirection="column"
      gap={4}
      sx={{ mb: '2rem' }}
    >
      {/* Packaging Dimensions */}
      <Box display="flex" flexDirection="column" gap={3} width="100%">
        {/* Title */}
        <Box width="100%">
          <Typography variant="body" mb={2}>
            {t('packaging.title')}
          </Typography>
        </Box>

        <Box
          width={{ xs: '100%', md: '50%' }}
          display="flex"
          flexDirection="row"
          gap={2}
        >
          {/* Unit */}
          <Box>
            <UnitSelectionField
              required
              fieldID="dimensions.unit"
              options={measurementOptions}
              transTypeID={transTypes('measurement')}
            />
          </Box>

          {/* legth */}
          <Box>
            <MeasurementField
              required
              fieldID="dimensions.length"
              transTypeID="length"
              inputAdornment={values.dimensions?.unit}
              decimalScale={0}
              fixedDecimalScale={true}
            />
          </Box>

          {/* width */}
          <Box>
            <MeasurementField
              required
              fieldID="dimensions.width"
              transTypeID="width"
              decimalScale={0}
              inputAdornment={values.dimensions?.unit}
              fixedDecimalScale={true}
            />
          </Box>

          {/* height */}
          <Box>
            <MeasurementField
              required
              fieldID="dimensions.height"
              transTypeID="height"
              decimalScale={0}
              inputAdornment={values.dimensions?.unit}
              fixedDecimalScale={true}
            />
          </Box>
        </Box>
      </Box>

      {/* Product Weight */}
      <Box
        display="flex"
        flexDirection="column"
        gap={3}
        width={{ xs: '100%', md: '50%' }}
      >
        <Typography mb={2}>{t('weight.title')}</Typography>
        <Stack direction="row" spacing={2}>
          {/* Unit */}
          <UnitSelectionField
            required
            fieldID="weight.unit"
            options={weightOptions}
            transTypeID={transTypes('weight')}
          />

          {/* Weight */}
          <MeasurementField
            required
            fieldID="weight.weight"
            transTypeID={transTypes('weight')}
            inputAdornment={values.weight.unit}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </Stack>
      </Box>

      {/* Lead Time */}
      <Box sx={{ mb: '2rem' }} width={{ xs: '100%', md: '50%' }}>
        <Typography>{transFields('LeadTime.title')}</Typography>
        <FormControl sx={{ my: 2, mr: 2 }}>
          <AmountField
            required
            fieldID="lead_time"
            transPrefix="LeadTimeField"
            transTypeID="days"
            inputAdornment={transTypes('days')}
          />
        </FormControl>
      </Box>

      {/* ales Tax & Customs */}
      <Box sx={{ mb: '2rem' }} width={{ xs: '100%', md: '50%' }}>
        {/* Sales Tax */}
        <TaxCodeBox />

        {/* Customs */}
        <TarifBox />

        <CountryField
          required
          fieldTitle="Country of Origin"
          fieldID="country_of_origin"
          fieldTitleStyles={{
            my: 2,
            fontSize: '16px',
            textAlign: 'left',
            display: 'flex',
          }}
        />
      </Box>

      {/* Fillfillment by IN2TEC */}
      <Box sx={{ mb: '1rem' }} width={{ xs: '100%', md: '50%' }}>
        <FormControlLabel
          checked={values?.fullfillment?.intutec}
          control={<Checkbox name="fullfillment.intutec" />}
          label={t('fbi.label')}
          onChange={handleCheckboxChange}
        />
      </Box>
    </Box>
  );
};

export default FulfillmentContainer;
