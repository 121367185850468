import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Box } from '@mui/material';
import AccountTypeField from '../../account/fields/type/AccountTypeField';
import IndividualCompanyNameField from '../../account/individual/IndividualCompanyNameField';
import useAuth from '../../../hooks/useAuth';

const AccountTab = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.account.AccountTab',
  });

  const { isSubmitting } = useFormikContext();

  const { auth } = useAuth();

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      {/* User Company Association */}
      <Box>
        <IndividualCompanyNameField
          fieldTitle={t('company.title')}
          fieldDescription={t('company.description')}
          required={auth?.user_info?.account_type === 'manufacturer'}
          disabled={
            auth?.user_info?.account_type === 'intutec' || isSubmitting
              ? true
              : false
          }
        />
      </Box>

      {/* Account Type */}
      <Box>
        <AccountTypeField
          fieldTitle={t('account_type.title')}
          fieldDescription={t('account_type.description')}
          required={true}
          disabled={
            auth?.user_info?.account_type === 'manufacturer' ||
            auth?.user_info?.account_type === 'intutec' ||
            isSubmitting
              ? true
              : false
          }
        />
      </Box>
    </Box>
  );
};

export default AccountTab;
