import { useTranslation } from 'react-i18next';

import IntuPage from '../../../components/layout/IntuPage';
import SubscriptionsDashboard from '../../../components/account/subscriptions/dashboards/SubscriptionsDashboard';

const SubscriptionsPage = () => {
  const { t } = useTranslation();

  return (
    <IntuPage
      pageTitle={t('pages.account.Subscriptions.title')}
      pageDescription={t('pages.account.Subscriptions.description')}
      hCentered
      lessMT
    >
      <SubscriptionsDashboard />
    </IntuPage>
  );
};

export default SubscriptionsPage;
