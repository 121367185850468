import {
  Box,
  Chip,
  Divider,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const ProductTypesBox = ({
  productTypes = [],
  displayTitle = true,
  ...props
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.ProductTypesBox',
  });

  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="column" {...props}>
      {displayTitle && (
        <Box>
          <Tabs
            value={0}
            variant="scrollable"
            sx={{
              '& .MuiTab-root': {
                textTransform: 'none',
                fontSize: '1.25rem',
                color: theme.palette.text.primary,
              },
              '& .Mui-selected': {
                color: theme.palette.text.primary + '!important',
              },
            }}
          >
            <Tab label={t('title')} />
          </Tabs>
          <Divider
            sx={{
              borderBottomWidth: '1px',
              borderColor: theme.palette.divider,
            }}
          />
        </Box>
      )}

      <Box>
        {productTypes.map((type) => {
          return (
            <Chip
              key={type}
              label={type}
              variant="outlined"
              sx={{
                m: 1,
                color: (theme) => theme.palette.tertiary.contrastText,
              }}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default ProductTypesBox;
