import { Box, Button, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useAxiosPrivate } from '../../../../hooks/axios/useAxiosPrivate';
import useResponseHandling from '../../../../hooks/useResponseHandler';
import { useTranslation } from 'react-i18next';

const PromotionCodeContainer = ({
  promotion,
  setPromotion,
  checkoutDetails,
  setCheckoutDetails,
  disabled,
}) => {
  const { t } = useTranslation('container', {
    keyPrefix: 'PromotionCodeContainer',
  });
  const { t: transButtons } = useTranslation('buttons');
  const axios = useAxiosPrivate();
  const { handleRegularResponse } = useResponseHandling();

  const handleApplyClick = async () => {
    const url = `/api/stripe/promotion-codes/${promotion?.code}`;

    const controller = new AbortController();
    const { signal } = controller;

    try {
      const { data, status } = await axios.get(url, {
        params: {
          module: 'subscriptions',
        },
        signal,
      });
      // console.log('Response from Applying coupon code', data, status);

      if (status === 200) {
        const promoDetails = data.data;

        if (promoDetails?.coupon?.valid) {
          const baseValue = promotion?.base;
          const discount = (promoDetails.coupon?.percent_off / 100) * baseValue;
          const salesPrice = baseValue - discount;

          setPromotion({
            ...promotion,
            id: promoDetails.id,
            valid: promoDetails.coupon?.valid,
            discount: promoDetails.coupon?.percent_off,
            sale: salesPrice,
            data: promoDetails,
          });

          setCheckoutDetails({
            ...checkoutDetails,
            discount: discount,
            total: checkoutDetails.subtotal - discount,
          });

          handleRegularResponse({
            open: true,
            status: data.status,
            message: t('messages.valid'),
          });
        }
      } else {
        handleRegularResponse({
          open: true,
          status: data.status,
          message: t('messages.invalid'),
        });
      }
    } catch (error) {
      console.error('error getting promotions', error);
    }
  };

  // console.log('Promotion Details -> ', promotion);
  return (
    <Box display="flex" alignItems="center" my={2}>
      <Box>
        <Typography mr={2}>{t('title')}</Typography>
      </Box>

      {/* Promo Code */}
      <Box sx={{ marginLeft: 'auto' }}>
        <TextField
          placeholder={t('fields.coupon.placeholder')}
          type="text"
          value={promotion?.code}
          onChange={(event) =>
            setPromotion({
              ...promotion,
              code: event.target.value,
            })
          }
          sx={{ width: 250 }}
          disabled={!promotion || disabled}
          margin="dense"
          variant="standard"
        />
      </Box>
      <Box>
        {!promotion.id ? (
          <Button
            onClick={() => handleApplyClick()}
            disabled={!promotion?.code || promotion?.valid || disabled}
            color="primary"
            sx={{ marginLeft: 2 }}
            variant={promotion?.valid ? 'outlined' : 'contained'}
          >
            {transButtons('apply')}
          </Button>
        ) : (
          <Button
            onClick={() =>
              setPromotion({
                ...promotion,
                id: '',
                code: '',
                valid: false,
                discount: 0,
                sale: 0,
                data: {},
              })
            }
            disabled={!promotion?.code || !promotion?.valid || disabled}
            color="error"
            sx={{ marginLeft: 2 }}
            variant="contained"
          >
            {transButtons('remove')}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default PromotionCodeContainer;
