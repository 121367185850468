import { useState, useContext, useEffect, useRef } from 'react';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import FaceIcon from '@mui/icons-material/Face';
import IntuIconButton from '../../components/buttons/IntuIconButton';
import UserContext from '../../context/users/UserInfoProvider';
import MinimizeIcon from '@mui/icons-material/Minimize';
import dayjs from 'dayjs';
import Transition from './Transition';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import getUserImage from '../../helpers/getUserImage';

const ChatDialog = ({
  isOpen,
  handleClose,
  selectedChat,
  handleRemoveConversation,
}) => {
  const { t: transButtons } = useTranslation('buttons');
  const [message, setMessage] = useState('');

  const { sendMessage } = useContext(UserContext);
  const theme = useTheme();

  const onSend = async (message, selectedChat) => {
    setMessage('');

    await sendMessage(message, selectedChat.chat_id);

    setTimeout(() => {
      scrollToBottom();
    }, 100);
  };

  const boxRef = useRef(null);

  const scrollToBottom = () => {
    if (boxRef.current) {
      boxRef.current.scrollTop = boxRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (isOpen || selectedChat?.unread_count) {
      setTimeout(() => {
        scrollToBottom();
      }, 100);
    }
  }, [isOpen, selectedChat]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      TransitionComponent={Transition}
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
        },
        '& .MuiDialog-paper': {
          margin: '0 16px 0 0',
          width: '100%',
        },
      }}
      hideBackdrop
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
        Chat with {selectedChat?.sender?.name}
        <Box display="flex" gap={1}>
          <IntuIconButton
            size="small"
            type="add"
            variant="outlined"
            tooltipTitle={transButtons('minimize')}
            onClick={handleClose}
            IconComponent={MinimizeIcon}
          />
          <IntuIconButton
            size="small"
            type="add"
            variant="outlined"
            tooltipTitle={transButtons('close')}
            onClick={() => {
              handleRemoveConversation(selectedChat.chat_id);
            }}
            IconComponent={CloseIcon}
          />
        </Box>
      </DialogTitle>
      <DialogContent style={{ paddingTop: '20px' }}>
        <Box
          mt={2}
          flex={1}
          display="flex"
          flexDirection="column"
          sx={{ overflowY: 'scroll' }}
          maxHeight={600}
          ref={boxRef}
        >
          {selectedChat?.messages.map((message) => {
            const isUser = message.sender_id !== selectedChat.sender._id;
            const otherUserImage = getUserImage(selectedChat.sender);
            return (
              <Box
                display="flex"
                alignItems="center"
                flexDirection={isUser ? 'row-reverse' : 'row'}
                my={1}
                key={message.created_at}
              >
                <Avatar
                  src={isUser ? '' : otherUserImage}
                  alt="profile picture"
                >
                  <FaceIcon sx={{ color: 'primary.dark' }} />
                </Avatar>
                <Box
                  bgcolor={isUser ? 'primary.main' : 'grey.light'}
                  color={isUser ? 'tertiary.backgroundColor' : 'primary.main'}
                  borderRadius={2}
                  boxShadow={6}
                  p={2}
                  mx={2}
                >
                  <Typography
                    fontStyle={message.type === 'system' ? 'italic' : 'initial'}
                    color={
                      message.type === 'system' && !isUser
                        ? 'primary'
                        : 'background.paper'
                    }
                  >
                    {message.message}
                  </Typography>
                </Box>
                <Typography variant="body1">
                  {dayjs(message.created_at).format('h:mm A')}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </DialogContent>
      <DialogActions
        style={{
          paddingBottom: '16px',
          justifyContent: 'space-evenly',
        }}
      >
        <TextField
          type="text"
          label="Type a message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && message) {
              onSend(message, selectedChat);
            }
          }}
          sx={{
            // backgroundColor: 'black',
            input: {
              color: `${theme.palette.primary.dark} !important`,
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => onSend(message, selectedChat)}
                  disabled={!message}
                >
                  {transButtons('send')}
                </Button>
              </InputAdornment>
            ),
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ChatDialog;
