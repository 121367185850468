import * as React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import RequireAuth from './components/routes/RequireAuth';
import useUser from './hooks/useUser';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';

// Meta Tag Handling
import { Helmet } from 'react-helmet-async';

// Theming and Styling
import './app.css';

// Pages
import {
  SignUpSuccess,
  Missing,
  Unauthorized,
  Home,
  PrivacyPolicy,
  ContactUs,
  AboutCompany,
  SignUp,
  AdminDashboard,
  CompanyProfile,
  AccountResetPassword,
  AccountChangePassword,
  IntuUsers,
  IntuLeads,
  HowItWorksLeads,
  HowItWorksInvestors,
  Blog,
  IntuContacts,
  Post,
  PostCreate,
  UserPosts,
  Subscribers,
  SignIn,
  ProductsPage,
  AccountConfirm,
  SignUpComplete,
  CompanySalesTeam,
  CompanyLeads,
  ManageProductListPage,
  IntuSalesEvents,
  IntuVendorEvents,
  IntuManageEvents,
  NewProductPage,
  TermsOfUse,
  CreditsExplainer,
  UserPublicProfile,
  CompanyPublicProfile,
  PublicProductPage,
  CartPage,
  CheckoutPage,
  SalesHome,
  MfgHome,
  SubscriptionsPage,
  MerchantSettings,
  OrdersPage,
  UserDashboardPage,
  CheckoutSuccessPage,
  FindSalesRepsPage,
  Chat,
  FindBrandsPage,
  UserProfilePage,
} from './pages';

// Translator
import i18n from './i18n';

// Import Available Languages
import CountryData from './data/CountryData.json';
import { useEffect, useContext } from 'react';
import ProfileFormLayoutComponent from './components/layout/ProfileFormLayout';
import GlobalLayout from './components/layout/GlobalLayout';
import CheckAuth from './components/routes/CheckAuth';
import NonAuthLayout from './components/layout/NonAuthLayout';
import AuthLayout from './components/layout/AuthLayout';
import { useAnalyticsPageViewTracker } from './hooks/useAnalyticsTracker';
import { LayoutBodyContext } from './context/layout/LayoutContextProvider';
import { trackLinkedInConversion } from './helpers/trackLinkedInConversion';
import RequireAccountType from './components/routes/RequireAccountType';
import ManageReferralsListPage from './pages/referrals/ManageReferralsListPage';

const App = () => {
  const { user } = useUser();

  //Detects User's country and sets displayed language
  useEffect(() => {
    if (!user) {
      return;
    }

    const userCountry = CountryData.find(
      (country) => country.code === user.location.country,
    );

    if (process.env.REACT_APP_ENV === 'development') {
      userCountry?.languageCode
        ? i18n.changeLanguage(userCountry.languageCode)
        : i18n.changeLanguage('en');
    }

    // Track Page Visit
    trackLinkedInConversion(18916850);
  }, [user]);

  // Google Page Tracker
  const location = useLocation();
  const { layoutTitle } = useContext(LayoutBodyContext);
  const { gaPageViewTracker } = useAnalyticsPageViewTracker();

  useEffect(() => {
    document.title = layoutTitle || 'IN2TEC - Technology Marketplace';

    gaPageViewTracker({
      type: 'pageview',
      page: document.location.pathname,
      name: `Page Visit - ${layoutTitle || document.location.pathname}`,
    });

    window.scrollTo(0, 0);
  }, [layoutTitle, location, gaPageViewTracker]);

  return (
    <>
      {/* TO DO -> Make public pages server side rendered and remove HELMET */}
      <Helmet>
        <title>IN2TEC - Distribution 2.0</title>
        <meta
          name="description"
          content="Connect B2B buyers to sophisticated technology in process automation, warehouse automation."
        />
        <meta name="theme-color" content="#A8C957" />
        <meta
          property="og:title"
          content="B2B Marketplace for sophisticated technology products."
        />
        <meta property="og:image" content="//in2tec.io/intu512.png" />
        <meta
          property="og:description"
          content="Market and Procure automation solutions direct and effectively"
        />
        <meta property="og:url" content="//in2tec.io" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@IN2TEC" />
        <meta
          name="twitter:title"
          content="Market and Procure automation solutions direct and effectively"
        />
        <meta
          name="twitter:description"
          content="Market and Procure automation solutions direct and effectively"
        />
        <meta name="twitter:image" content="https://in2tec.io/intu512.png" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0, viewport-fit=cover"
        />
      </Helmet>
      {/* Nabvbar */}

      {/* Content */}
      <div className="wrapper" id="root">
        {/* Routes */}
        <Routes>
          {/* LinkedIn */}
          <Route
            exact
            path="/linkedin-callback"
            element={<LinkedInCallback />}
          />
          <Route element={<CheckAuth />}>
            {/* HOME */}
            <Route path="/" element={<Home />} />

            {/* CONTENT */}
            <Route path="/" element={<GlobalLayout />}>
              {/* < ------ Check for user authentication in order to show the correct nav items------> */}

              {/* < ------ NON-AUTH (PUBLIC) LAYOUTS ------> */}
              <Route path="/" element={<NonAuthLayout />}>
                {/* < ------ PAGES ------> */}
                {/* Home */}
                <Route path="/saleshome" element={<SalesHome />} />
                <Route path="/mfghome" element={<MfgHome />} />

                {/* Privacy & Tos */}
                <Route path="privacy" element={<PrivacyPolicy />} />
                <Route path="terms-of-use" element={<TermsOfUse />} />
                <Route path="products" element={<ProductsPage />} />
                <Route path="credits" element={<CreditsExplainer />} />

                {/* Events */}
                <Route path="events/sales" element={<IntuSalesEvents />} />
                <Route path="events/vendors" element={<IntuVendorEvents />} />

                {/* About the Company */}
                <Route path="company" element={<AboutCompany />} />

                {/* Blog */}
                <Route path="blog" element={<Blog />} />
                <Route path="blog/:id" element={<Post />} />

                {/* Not authorized*/}
                <Route path="unauthorized" element={<Unauthorized />} />

                {/* Catch Not Found */}
                <Route path="*" element={<Missing />} />

                {/* < ------ FORMS ------> */}
                {/* How it works */}
                <Route path="how-it-works" element={<HowItWorksLeads />} />

                <Route path="investor" element={<HowItWorksInvestors />} />

                {/* sign in */}
                <Route path="account/signin" element={<SignIn />} />

                {/* Password Reset */}
                <Route
                  path="account/reset-password"
                  element={<AccountResetPassword />}
                />

                {/* Password change */}
                <Route
                  path="account/change-password"
                  element={<AccountChangePassword />}
                />

                {/* SignUp */}
                <Route path="account/signup" element={<SignUp />} />

                {/* Contact Us */}
                <Route path="contact" element={<ContactUs />} />

                {/* SignUp Confirm */}
                <Route path="account/confirm" element={<AccountConfirm />} />

                {/* subscribers */}
                <Route
                  path="subscribers/signup"
                  element={<Subscribers mode="signup-" source="direct" />}
                />
                <Route
                  path="subscribers/confirm"
                  element={<Subscribers mode="confirm" />}
                />
                <Route
                  path="subscribers/verify"
                  element={<Subscribers mode="verify" />}
                />
                <Route
                  path="subscribers/profile"
                  element={<Subscribers mode="profile" />}
                />

                <Route path="product/:id" element={<PublicProductPage />} />

                <Route path="/cart" element={<CartPage />} />
                <Route path="/checkout" element={<CheckoutPage />} />
                <Route
                  path="/checkout-success"
                  element={<CheckoutSuccessPage />}
                />
              </Route>
              {/* < ------ AUTH (PROTECTED) LAYOUTS------> */}
              <Route path="/" element={<AuthLayout />}>
                {/* <----- Protected Routes for Admins ----->*/}
                <Route element={<RequireAuth allowedRoles={['admin']} />}>
                  <Route
                    path="app/admin/dashboard"
                    element={<AdminDashboard />}
                  />
                  <Route
                    path="app/admin/manage-users"
                    element={<IntuUsers />}
                  />
                  <Route
                    path="app/admin/manage-products"
                    element={<ManageProductListPage />}
                  />
                  <Route
                    path="app/admin/manage-leads"
                    element={<IntuLeads />}
                  />
                  <Route
                    path="app/admin/manage-contacts"
                    element={<IntuContacts />}
                  />
                  <Route
                    path="app/admin/manage-events"
                    element={<IntuManageEvents />}
                  />
                  <Route path="app/admin/posts" element={<UserPosts />} />
                </Route>

                {/* <----- Protected Routes for Users ----->*/}
                <Route
                  element={<RequireAuth allowedRoles={['user', 'admin']} />}
                >
                  <Route
                    path="app/user/dashboard"
                    element={<UserDashboardPage />}
                  />
                  <Route path="app/user/posts" element={<UserPosts />} />
                  <Route path="account-completed" element={<SignUpSuccess />} />

                  {/* Merchant Settings */}
                  <Route
                    element={
                      <RequireAccountType
                        allowedAccountTypes={[
                          'manufacturer',
                          'salesrep',
                          'referrer',
                        ]}
                      />
                    }
                  >
                    <Route
                      path="app/account/payment/settings"
                      element={<MerchantSettings />}
                    />
                  </Route>

                  {/* Manage Referral Campaigns  */}
                  <Route
                    element={
                      <RequireAccountType
                        allowedAccountTypes={['salesrep', 'referrer']}
                      />
                    }
                  >
                    <Route
                      path="app/campaigns/referrals/list"
                      element={<ManageReferralsListPage />}
                    />
                  </Route>

                  {/* Manage Orders */}
                  <Route
                    element={
                      <RequireAccountType
                        allowedAccountTypes={[
                          'manufacturer',
                          'intutec',
                          'salesrep',
                        ]}
                      />
                    }
                  >
                    <Route path="app/orders" element={<OrdersPage />} />
                  </Route>

                  {/* Manage Products */}
                  <Route
                    element={
                      <RequireAccountType
                        allowedAccountTypes={['manufacturer', 'intutec']}
                      />
                    }
                  >
                    <Route
                      path="app/manage-products/list"
                      element={<ManageProductListPage />}
                    />

                    <Route
                      path="app/new-product/:id"
                      element={<NewProductPage />}
                    />
                    <Route
                      path="app/edit-product/:id"
                      element={<NewProductPage isEdit />}
                    />
                  </Route>

                  {/* Manage Sales Team */}
                  <Route
                    element={
                      <RequireAccountType
                        allowedAccountTypes={['manufacturer', 'salesrep']}
                      />
                    }
                  >
                    <Route
                      path="app/company/salesteam"
                      element={<CompanySalesTeam />}
                    />
                  </Route>

                  {/* Manage Leads */}
                  <Route
                    element={
                      <RequireAccountType
                        allowedAccountTypes={['manufacturer', 'salesrep']}
                      />
                    }
                  >
                    <Route
                      path="app/company/leads"
                      element={<CompanyLeads />}
                    />
                  </Route>

                  {/* Discover & Share Products */}
                  <Route
                    element={
                      <RequireAccountType allowedAccountTypes={['salesrep']} />
                    }
                  >
                    {/* Products */}
                    <Route
                      path="app/refer-products/list"
                      element={<ManageProductListPage />}
                    />
                  </Route>

                  {/* All Account Types */}
                  <Route path="/" element={<ProfileFormLayoutComponent />}>
                    <Route
                      path="app/user/profile"
                      element={<UserProfilePage />}
                    />
                    <Route
                      path="app/company/profile"
                      element={<CompanyProfile />}
                    />
                    <Route
                      path="app/account/complete"
                      element={<SignUpComplete />}
                    />
                    {/* Subscriptions */}
                    <Route
                      path="app/account/subscriptions"
                      element={<SubscriptionsPage />}
                    />
                  </Route>

                  {/* Blog */}
                  <Route path="app/blog/create" element={<PostCreate />} />
                  <Route path="app/blog/edit/:id" element={<Post />} />

                  {/* Public Profiles */}
                  <Route
                    path="app/user-profile/:id"
                    element={<UserPublicProfile />}
                  />
                  <Route
                    path="app/company-profile/:id"
                    element={<CompanyPublicProfile />}
                  />

                  {/* Find Brands */}
                  <Route path="app/find/brands" element={<FindBrandsPage />} />

                  {/* Find Sales Reps */}
                  <Route
                    path="app/find/salesreps"
                    element={<FindSalesRepsPage />}
                  />

                  {/* Chat Page */}
                  <Route path="app/chat" element={<Chat />} />
                </Route>
              </Route>
            </Route>
          </Route>
        </Routes>
      </div>
    </>
  );
};

export default App;
