import { useTranslation } from 'react-i18next';
import { CompanyProfileForm } from '../../components/company/profile/manage/CompanyProfileForm';
import { IntuForm } from '../../components/layout/IntuForm';
import IntuPage from '../../components/layout/IntuPage';
import { useContext } from 'react';
import { CompanyProfileContext } from '../../context/company/CompanyProfileProvider';

function CompanyProfile() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.company.companyProfile',
  });

  const { companyProfile } = useContext(CompanyProfileContext);

  return (
    <IntuPage
      pageTitle={t('title')}
      pageDescription={
        companyProfile.isAdmin === true ? t('description') : t('non_auth')
      }
      hCentered={true}
      lessMT
    >
      <IntuForm>
        <CompanyProfileForm />
      </IntuForm>
    </IntuPage>
  );
}

export default CompanyProfile;
