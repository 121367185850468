import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import CompletionStatusContainer from './containers/CompletionStatusContainer.jsx';
import { CompanyProfileContext } from '../../../context/company/CompanyProfileProvider.jsx';
import { StripeContext } from '../../../context/providers/stripe/StripeContextProvider.jsx';
import { UserProfileContext } from '../../../context/users/UserProfileProvider.jsx';
import { createStripeConnectAccount } from '../../../api/stripe/connect.js';
import StripeConsentContainer from './containers/StripeConsentDialog.jsx';
import ProcessingContext from '../../../context/providers/ProcessingProvider.jsx';
import useResponseHandling from '../../../hooks/useResponseHandler.js';

const UserDashboardComponent = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.account.UserDashboardComponent',
  });
  const { t: transValidations } = useTranslation('validation');
  const { t: transMessages } = useTranslation('messages');
  const { t: transTypes } = useTranslation('types');

  const navigate = useNavigate();
  const {
    userProfile,
    setUserProfile,
    getUserProfile,
    isFetched: userProfileFetched,
  } = useContext(UserProfileContext);
  const { companyProfile, isFetched: companyProfileFetched } = useContext(
    CompanyProfileContext,
  );
  const {
    stripeUserData,
    usersActiveSubscriptions,
    getStripeAccountDetails,
    isFetched: stripeProfileFetched,
  } = useContext(StripeContext);

  const location = useLocation();
  const [isRendered, setIsRender] = useState(false);

  const handleGetUserProfile = async () => {
    const profile = await getUserProfile();
    setUserProfile(profile);
  };

  const { setErrorDialog, handleErrorResponse, handleRegularResponse } =
    useResponseHandling();
  const { isLoading, setIsLoading } = useContext(ProcessingContext);

  useEffect(() => {
    if (location.state?.from === 'SignUpCompleteForm') {
      handleGetUserProfile();
    }
  }, []);

  const [consent, setConsent] = useState({
    accepted: false,
    ip: '',
    date: '',
    unixTimeStamp: '',
    agent: '',
  });

  // Handle Account Creation
  const handleCreateStripeConnectAccount = async () => {
    const accountType = userProfile?.account_type;
    const businessType = companyProfile?._id ? 'company' : 'individual';

    if (consent?.accepted !== true) {
      setErrorDialog({
        open: true,
        title: transMessages('stripe.terms.not_accepted.title'),
        message: transMessages('stripe.terms.not_accepted.message'),
      });
      return;
    }

    setIsLoading({
      status: true,
      type: 'spinner',
      text: transMessages('spinner.global.create', {
        type: transTypes('stripe_account'),
      }),
    });

    try {
      // Create Stripe Account
      const stripeAccountParam = {
        account_type: accountType,
        business_type: businessType,
        ssa_accepted: consent,
      };

      const createStripeAccount =
        await createStripeConnectAccount(stripeAccountParam);

      if (createStripeAccount.status !== 200) {
        setErrorDialog({
          open: true,
          title: transMessages('stripe.create.error'),
          message: createStripeAccount.data.message,
        });
      } else {
        // Update Stripe Context & Set Response
        getStripeAccountDetails();

        handleRegularResponse({
          open: true,
          status: createStripeAccount.data.status,
          message: createStripeAccount.data.message,
        });
      }
    } catch (error) {
      // Show Errors
      handleErrorResponse(error);
    } finally {
      setIsLoading({ status: false, type: '', text: '' });
    }
  };

  // console.log('Company Profile Admin -> ', companyProfile.isAdmin);
  // console.log('Stripe Account ID -> ', stripeUserData?.stripe_connect_data?.id);
  // console.log('Stripe Profile Fetched -> ', stripeProfileFetched);
  // console.log('Stripe Profile Fetched -> ', usersActiveSubscriptions);

  return (
    <Box
      display="flex"
      flexDirection="column"
      width={{ xs: '100%', md: '80%' }}
    >
      {/* Personal Profile */}
      <Box>
        {/* Personal Profile */}
        {userProfileFetched && userProfile?.profile_completion < 100 ? (
          <CompletionStatusContainer
            message={t('components.complete_profile')}
            progress={userProfile.profile_completion}
            cta={() => navigate('/app/user/profile')}
            missingFields={userProfile.missing_fields}
            requirementsLabel="labels.missing_fields"
            type="user"
          />
        ) : null}

        {/* Company Profile */}
        {companyProfileFetched &&
        companyProfile?.profile_completion < 100 &&
        companyProfile.isAdmin ? (
          <CompletionStatusContainer
            message={t('components.complete_company_profile')}
            progress={companyProfile.profile_completion}
            cta={() => navigate('/app/company/profile')}
            missingFields={companyProfile.missing_fields}
            type="company"
            requirementsLabel="labels.missing_fields"
          />
        ) : null}

        {/* Missing Connect Stripe Account */}
        {stripeProfileFetched &&
        !stripeUserData?.stripe_connect_data?.id &&
        companyProfile?.isAdmin ? (
          <CompletionStatusContainer
            message={t('components.connect_account')}
            progress={stripeUserData.profile_completion}
            cta={() => handleCreateStripeConnectAccount()}
            type="stripe_account"
            requirementsLabel={null}
            formatLabels={false}
            component={
              <StripeConsentContainer
                consent={consent}
                setConsent={setConsent}
              />
            }
            disableCTA={
              consent.accepted !== true || isLoading.status === true
                ? true
                : false
            }
          />
        ) : null}

        {/* Stripe Merchant Settings */}
        {stripeUserData.account_link ? (
          <CompletionStatusContainer
            message={t('components.stripe_requirements')}
            progress={stripeUserData.profile_completion}
            cta={() => navigate('/app/account/payment/settings')}
            missingFields={stripeUserData?.currently_due}
            type="stripe_account"
            formatLabels={false}
            requirementsLabel="labels.missing_requirements"
          />
        ) : null}

        {/* Subscription */}
        {!usersActiveSubscriptions ? (
          <CompletionStatusContainer
            message={t('components.subscription_requirements')}
            cta={() => navigate('/app/account/subscriptions')}
            missingFields={[
              { description: transValidations('stripe_subscription.required') },
            ]}
            type={'stripe_subscription'}
            requirementsLabel={null}
            formatLabels={false}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default UserDashboardComponent;
