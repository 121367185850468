import { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavBarDrawerContext } from '../../../context/nav/NavBarDrawerContextProvider';
import {
  Badge,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import BusinessIcon from '@mui/icons-material/Business';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { motion } from 'framer-motion';
import { NavList, NavItem } from '../../../framer-motion/animations';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/styles';
import useAuth from '../../../hooks/useAuth';
import { useAnalyticsEventTracker } from '../../../hooks/useAnalyticsTracker';
import UserContext from '../../../context/users/UserInfoProvider';
import { StripeContext } from '../../../context/providers/stripe/StripeContextProvider';
import { AttachMoney, LocalMall } from '@mui/icons-material';
import { CompanyProfileContext } from '../../../context/company/CompanyProfileProvider';
import NavBarContent from '../drawer/NavBarContent';

const IS_PRODUCTION = process.env.REACT_APP_ENV === 'production';

const AllAccountsNavItems = ({ mode }) => {
  const navigate = useNavigate();
  const { auth, isAdmin } = useAuth();
  const { companyProfile } = useContext(CompanyProfileContext);

  // console.log('Company Profile -> ', companyProfile);

  const { conversations } = useContext(UserContext);
  const theme = useTheme();
  const { handleDrawerClose } = useContext(NavBarDrawerContext);
  const { stripeUserData } = useContext(StripeContext);
  const { gaEventTracker } = useAnalyticsEventTracker();
  const { t } = useTranslation('buttons', { keyPrefix: 'navigation' });

  const numUnreadMessages = useMemo(() => {
    if (!conversations || !conversations.length) return 0;
    return conversations.reduce((acc, curr) => acc + curr.unread_count, 0);
  }, [conversations]);

  function handleNavigate(target) {
    if (target === 'Dashboard') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'User Dashboard',
      });
      if (isAdmin === true) {
        return navigate('/app/admin/Dashboard');
      } else {
        return navigate('/app/user/Dashboard');
      }
    } else if (target === 'UserProfile') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'User Profile',
      });
      return navigate('/app/user/profile');
    } else if (target === 'Messages') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'Messages',
      });
      return navigate('/app/chat');
    } else if (target === 'CompanyProfile') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'Company Profile',
      });
      return navigate('/app/company/profile');
    } else if (target === 'MerchantSettings') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'Merchant Settings',
      });
      return navigate('/app/account/payment/settings');
    } else if (target === 'CompleteAccount') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'Complete Account',
      });
      return navigate('/app/account/complete');
    } else if (target === 'Subscriptions') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'Subscriptions',
      });
      return navigate('/app/account/subscriptions');
    } else if (target === 'Shop') {
      gaEventTracker({
        category: 'Navigation',
        action: 'Event',
        label: 'Shop',
      });
      return navigate('/products');
    }
    handleDrawerClose();
  }

  const generalNavItems = [
    {
      index: 1,
      key: 'Home',
      label: t('home'),
      location: 'drawer',
      icon: <HomeRoundedIcon color="primary" />,
      target: () => handleNavigate('Dashboard'),
      event: 'User Dashboard',
      visible: auth?.user_info?.account_complete,
    },
    {
      index: 2,
      key: 'Messages',
      label: t('messages'),
      location: 'drawer',
      icon: (
        <Badge badgeContent={numUnreadMessages} color="primary">
          <MailOutlineIcon />
        </Badge>
      ),
      target: () => handleNavigate('Messages'),
      visible: !IS_PRODUCTION && auth?.user_info?.account_complete,
    },
    {
      index: 3,
      key: 'Profile',
      label: t('user_profile'),
      location: 'drawer',
      icon: <PersonIcon color="primary" />,
      target: () => handleNavigate('UserProfile'),
      event: 'User Profile',
      visible: auth?.user_info?.account_complete,
    },
    {
      index: 4,
      key: 'Company',
      label: t('company_profile'),
      location: 'drawer',
      icon: <BusinessIcon color="primary" />,
      target: () => handleNavigate('CompanyProfile'),
      event: 'Company Profile',
      visible: companyProfile?._id,
    },
    {
      index: 5,
      key: 'MerchantSettings',
      label: t('merchant_settings'),
      icon: <AttachMoney color="primary" />,
      target: () => handleNavigate('MerchantSettings'),
      event: 'Merchant Settings',
      location: 'drawer',
      visible:
        auth?.user_info?.account_complete &&
        ((!isAdmin &&
          companyProfile._id &&
          companyProfile.isAdmin &&
          stripeUserData?.stripe_connect_data?.id) ||
          (!isAdmin && !companyProfile._id)),
    },
    {
      index: 6,
      key: 'Complete Account',
      label: t('complete_account'),
      location: 'drawer',
      icon: <CheckCircleOutlineIcon color="primary" />,
      target: () => handleNavigate('CompleteAccount'),
      event: 'Account Completion',
      visible: auth?.user_info?.account_complete === false,
    },
    {
      index: 7,
      key: 'Subscriptions',
      label: t('subscriptions'),
      icon: <RestartAltIcon color="primary" />,
      target: () => handleNavigate('Subscriptions'),
      event: 'Subscriptions',
      location: 'drawer',
      visible:
        auth?.user_info?.account_complete &&
        ((companyProfile._id && companyProfile.isAdmin) ||
          (!isAdmin && !companyProfile._id)),
    },
    {
      index: 99,
      key: 'Shop',
      label: t('shop'),
      width: '150px',
      icon: <LocalMall color="primary" />,
      target: () => handleNavigate('Shop'),
      event: 'Shop',
      location: 'nav',
      variant: 'contained',
      visible:
        auth?.user_info?.account_complete &&
        ((companyProfile._id && companyProfile.isAdmin) ||
          (!isAdmin && !companyProfile._id)),
    },
  ];

  if (mode === 'NavBar') {
    return <NavBarContent generalNavItems={generalNavItems} mode={mode} />;
  } else {
    return (
      <List>
        {generalNavItems.map((menuItem) => {
          if (menuItem.visible && menuItem.location === 'drawer') {
            return (
              <motion.div
                variants={NavList}
                initial="hidden"
                animate="visible"
                key={menuItem.key}
              >
                <ListItem sx={{ display: 'block' }}>
                  <motion.div variants={NavItem} whileHover={{ scale: 1.1 }}>
                    <ListItemButton
                      sx={{
                        height: '2.5rem',
                        justifyContent: 'initial',
                        px: 2.5,
                        fontSize: '14px',
                      }}
                      onClick={() => {
                        menuItem.target();
                        handleDrawerClose();
                      }}
                    >
                      <ListItemIcon>{menuItem.icon}</ListItemIcon>
                      <ListItemText
                        primary={menuItem.label}
                        disableTypography
                      />
                    </ListItemButton>
                  </motion.div>
                </ListItem>
              </motion.div>
            );
          }
        })}
      </List>
    );
  }
};

export default AllAccountsNavItems;
