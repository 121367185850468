import { useEffect, useState, useRef, useContext, createContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import useAuth from '../../hooks/useAuth';
import { useAxiosPrivate } from '../../hooks/axios/useAxiosPrivate';
import { createNewProductValidationSchema } from './NewProductValidationSchema';
import ProductImageHeader from '../../components/new-product/container/image/ProductImageHeader';
import ProductForm from '../../components/new-product/ProductForm';
import StatusBarComponent from '../../components/status/StatusBar';
import { useNavigationFooter } from '../../context/footer/NavigationFooterContext';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import StatusBox from '../../components/new-product/StatusBox';
import { StripeContext } from '../../context/providers/stripe/StripeContextProvider';
import useResponseHandling from '../../hooks/useResponseHandler';
import { ApplicationsContext } from '../../context/applications/ApplicationsContextProvider';
import { ProductTypeContext } from '../../context/providers/ProductTypeContextProvider';
import ProcessingContext from '../../context/providers/ProcessingProvider';
import { IndustriesContext } from '../../context/industries/IndustriesContextProvider';
import {
  getProductDetailsById,
  updateProduct,
} from '../../api/products/productsRoutes.js';
import ResponseContext from '../../context/providers/ResponseProvider.jsx';
import StaffPickBox from '../../components/new-product/StaffPickBox.jsx';

const commissionTemplate = {
  commission: [
    { id: 0, max_amount: 1_000, commission: 0.45, cost: null },
    { id: 1, max_amount: 10_000, commission: 0.4, cost: null },
    { id: 2, max_amount: 50_000, commission: 0.35, cost: null },
    { id: 3, max_amount: 100_000, commission: 0.3, cost: null },
    { id: 4, max_amount: 250_000, commission: 0.27, cost: null },
    { id: 5, max_amount: 500_000, commission: 0.25, cost: null },
    { id: 6, max_amount: 1_000_000, commission: 0.2, cost: null },
  ],
  type: 'tiered',
};

const defaultProductState = {
  _id: '',
  product_name: '',
  mpn: '',
  brand: null,
  isPurchasable: true,
  list_price: 0,
  min_price: 0,
  currency: {
    symbol: 'USD',
    rate: 1,
    timestamp: null,
    base_amount: {
      list_price: 0,
      min_price: 0,
    },
  },
  manufacturer: null,
  files: [],
  images: [],
  descriptions: [
    {
      name: 'English',
      code: 'en',
    },
  ],
  product_attributes: [],
  product_types: [],
  product_applications: [],
  product_industries: [],
  commission_schedule: commissionTemplate,
  referral_terms: {
    active: true,
    lifetime: 30,
    cpc: 0.01,
    cpa: 0.5,
    cpo: 1,
  },
  dimensions: {
    unit: '',
    length: 0,
    width: 0,
    height: 0,
  },
  volume: {
    cbm: 0,
    cbf: 0,
  },
  weight: {
    weight: 0,
    unit: 'lb',
  },
  lead_time: 0,
  fullfillment: {
    intutec: false,
    stock: 0,
  },
  tax_code: 'txcd_99999999',
  tarif_code: '',
  status: 'draft',
  staffpick: false,
};

const StyledNewProductPage = styled('div')(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  width: '100%',
  display: 'grid',
  gridTemplateAreas: `
    "image image image image"
    "form form form form"
    "status status status status"
  `,
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  gridTemplateRows: '0.5fr 1fr 0.5fr',
  gap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    gridTemplateAreas: `
    "image image image image"
    "form form form form"
    "status status status status"
  `,
  },
}));

export const ManageProductContext = createContext();

const NewProductPage = ({ isEdit = false }) => {
  const { t: transValidation } = useTranslation('validation');
  const { t: transButtons } = useTranslation('buttons');
  const { t: transTypes } = useTranslation('types');
  const { t: transMessages } = useTranslation('messages');
  const navigate = useNavigate();
  const { isAdmin } = useAuth();
  const { id } = useParams();
  const axios = useAxiosPrivate();
  const { stripeUserData, usersActiveSubscriptions } =
    useContext(StripeContext);
  const { setFooterConfig } = useNavigationFooter();
  const [isSaving, setIsSaving] = useState(false);
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();

  const formikRef = useRef(null);

  const validationSchema = createNewProductValidationSchema(transValidation);
  const [commissionScheduleTemplate, setCommissionScheduleTemplate] =
    useState(commissionTemplate);
  const [productDetails, setProductDetails] = useState('draft');

  const { isLoading, setIsLoading } = useContext(ProcessingContext);
  const { setErrorDialog } = useContext(ResponseContext);
  const {
    newProductTypeList,
    addNewProductTypesToDatabase,
    getProductTypeNamesById,
    getProductGroupsById,
  } = useContext(ProductTypeContext);
  const {
    newApplicationList,
    addNewApplicationsToDatabase,
    getApplicationNamesById,
  } = useContext(ApplicationsContext);

  const { getIndustriesById } = useContext(IndustriesContext);

  const getProductById = async (id) => {
    try {
      const { data, statusCode } = await getProductDetailsById({ id });
      if (statusCode === 200) {
        const [productData] = data;

        let productTypes = [];
        if (productData?.product_types?.length > 0) {
          productTypes = await getProductTypeNamesById(
            productData.product_types,
          );
        }

        let productGroups = [];
        if (productData?.product_groups?.length > 0) {
          productGroups = await getProductGroupsById(
            productData.product_groups,
          );
        }

        let applications = [];
        if (productData?.product_applications?.length > 0) {
          applications = await getApplicationNamesById(
            productData.product_applications,
          );
        }

        let industries = [];
        if (productData?.product_industries?.length > 0) {
          industries = await getIndustriesById(productData.product_industries);
        }

        formikRef.current.setValues({
          ...defaultProductState,
          ...productData,
          product_applications: applications,
          product_industries: industries,
          product_types: productTypes,
          product_groups: productGroups,
        });
        setProductDetails({
          ...defaultProductState,
          ...productData,
          product_applications: applications,
          product_industries: industries,
          product_types: productTypes,
          product_groups: productGroups,
        });
        setCommissionScheduleTemplate(productData.commission_schedule);
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const pathname = window.location.pathname;

  const getProductIdFromURL = () => {
    const pathname = window.location.pathname; // e.g., "/app/new-product/67a7d1563f1239129f5d252b"
    const segments = pathname.split('/'); // Split the path into parts
    const productId = segments[segments.length - 1]; // Get the last segment
    return productId;
  };

  useEffect(() => {
    const productId = getProductIdFromURL();
    getProductById({ id: productId });
  }, [pathname, stripeUserData]);

  const handleSave = async ({ values, newProductTypes, newApplications }) => {
    // Check if user is admin and has stripe account completed
    const currentProductValues = values;

    // Check for Stripe Connect Account Completion
    if (currentProductValues.status === 'published' && !isAdmin) {
      if (stripeUserData.account_complete !== true) {
        return handleRegularResponse({
          open: true,
          status: 'warning',
          statusCode: 403,
          message: transMessages('products.merchant_settings.incomplete'),
        });
      }

      // console.log('Users Active Subscriptions -> ', usersActiveSubscriptions);

      if (!usersActiveSubscriptions.length) {
        return handleRegularResponse({
          open: true,
          status: 'warning',
          statusCode: 403,
          message: transMessages('products.subscription.missing'),
        });
      }
    }

    if (currentProductValues.status === 'published') {
      const errors = await formikRef.current.validateForm();

      if (Object.keys(errors).length > 0) {
        const errorMessages = Object.entries(errors).map(([field, message]) => {
          if (typeof message === 'object') {
            const subErrors = Object.entries(message).map(
              ([subField, subMessage]) => ({
                subField: subField.replace(/_/g, ' '),
                subMessage: subMessage.replace(/_/g, ' '),
              }),
            );
            return {
              field: field.replace(/_/g, ' '),
              subErrors,
            };
          }
          return {
            field: field.replace(/_/g, ' '),
            message: message.replace(/_/g, ' '),
          };
        });

        const listItems = (
          <List>
            {errorMessages.map(({ field, message, subErrors }, i) => (
              <ListItem key={i} disablePadding sx={{ my: 1 }}>
                <ListItemText
                  primary={
                    <Box sx={{ my: 1 }}>
                      <Typography
                        component="span"
                        sx={{
                          fontWeight: 'bold',
                          color: 'error.main',
                          textTransform: 'capitalize',
                        }}
                      >
                        {field}
                      </Typography>

                      {subErrors ? (
                        <List sx={{ listStyleType: 'disc', pl: 4 }}>
                          {subErrors.map(({ subField, subMessage }, j) => (
                            <ListItem
                              key={j}
                              sx={{ display: 'list-item', py: 0 }}
                              disablePadding
                            >
                              <ListItemText
                                primary={
                                  <Typography component="span">
                                    <strong
                                      style={{ textTransform: 'capitalize' }}
                                    >
                                      {subField}
                                    </strong>
                                    {`: ${subMessage}`}
                                  </Typography>
                                }
                              />
                            </ListItem>
                          ))}
                        </List>
                      ) : (
                        <Typography component="div" sx={{ mt: 0.5 }}>
                          {message}
                        </Typography>
                      )}
                    </Box>
                  }
                />
              </ListItem>
            ))}
          </List>
        );

        setErrorDialog({
          open: true,
          title: transMessages('form.invalid', { type: transTypes('product') }),
          message: listItems,
        });
        return;
      }
    }

    formikRef.current.setSubmitting(true);

    setIsLoading({
      status: true,
      type: 'spinner',
      text: transMessages('spinner.global.update', {
        type: transTypes('product'),
      }),
    });

    try {
      // Restructure applications by id's
      let applicationsByID = currentProductValues?.product_applications || [];
      if (applicationsByID.length > 0) {
        applicationsByID = currentProductValues.product_applications
          .filter((application) => application.application_id)
          .map((application) => application.application_id);
      }

      // Add New Applications to the Database
      if (newApplications.length > 0) {
        const newApplicationData = await addNewApplicationsToDatabase();
        if (newApplicationData?.length > 0) {
          const newApplicationsById = newApplicationData.map(
            (application) => application.application_id,
          );

          applicationsByID = [...applicationsByID, ...newApplicationsById];
        }
      }

      // Add New Product Types to Database
      let productTypesByID = currentProductValues?.product_types || [];
      if (productTypesByID.length > 0) {
        productTypesByID = currentProductValues?.product_types
          .filter((productType) => productType.type_id)
          .map((productType) => productType.type_id);
      }

      if (newProductTypes.length > 0) {
        const newProductData = await addNewProductTypesToDatabase();

        if (newProductData?.length > 0) {
          const newProductTypesById = newProductData.map(
            (product) => product.type_id,
          );

          productTypesByID = [...productTypesByID, ...newProductTypesById];
        }
      }

      // Format Groups to Database
      // console.log('Current Product Values on save -> ', currentProductValues);
      let productGroupsByID = [];
      if (currentProductValues?.product_types.length > 0) {
        productGroupsByID = currentProductValues?.product_types
          .filter((productType) => productType.group_id)
          .map((productType) => productType.group_id);
      }

      // console.log('Product Groups by ID -> ', productGroupsByID);

      // Reformat Industries by ID
      let industriesByID = [];
      if (currentProductValues?.product_industries.length > 0) {
        industriesByID = currentProductValues?.product_industries
          .filter((industry) => industry.industry_id)
          .map((industry) => industry.industry_id);
      }

      // Update the Product
      const payload = {
        ...currentProductValues,
        product_applications: applicationsByID,
        product_types: productTypesByID,
        product_groups: productGroupsByID,
        product_industries: industriesByID,
      };

      const { data, statusCode, status, message } = await updateProduct({
        id: currentProductValues._id,
        payload: payload,
      });

      if (statusCode === 201) {
        handleRegularResponse({
          open: true,
          status: status,
          message: message,
        });

        const [updatedProductData] = data;

        formikRef.current.setValues({
          ...updatedProductData,
          product_applications:
            (updatedProductData?.product_applications?.length > 0 &&
              (await getApplicationNamesById(
                updatedProductData.product_applications,
              ))) ||
            [],
          product_industries:
            updatedProductData?.product_industries?.length > 0
              ? await getIndustriesById(updatedProductData.product_industries)
              : [],
          product_types:
            (updatedProductData?.product_types.length > 0 &&
              (await getProductTypeNamesById(
                updatedProductData.product_types,
              ))) ||
            [],
          product_groups:
            (updatedProductData?.product_groups.length > 0 &&
              (await getProductGroupsById(
                updatedProductData.product_groups,
              ))) ||
            [],
        });

        setCommissionScheduleTemplate(updatedProductData.commission_schedule);
      } else {
        handleRegularResponse({
          open: true,
          status: status,
          message: message,
        });
      }
    } catch (error) {
      console.error('error', error);
      handleErrorResponse(error);
    } finally {
      formikRef.current.setSubmitting(false);
      setIsLoading({ status: false, type: 'skeleton', text: '' });
    }
  };

  // Set Footer Navigation
  useEffect(() => {
    setFooterConfig((prev) => ({
      ...prev,
      backLabel: transButtons('back'),
      BackIcon: null,
      confirmLabel: transButtons('update', { type: transTypes('product') }),
      ConfirmIcon: SaveAltIcon,
      onConfirm: () => {
        if (formikRef.current) {
          handleSave({
            values: formikRef.current.values,
            newProductTypes: newProductTypeList,
            newApplications: newApplicationList,
          }); // Always use latest Formik values
        }
      },
      isLoading: isLoading.status,
      isDisabled: false,
      showFooter: true,
      onBack: () => {
        handleCancel();
      },
      showConfirm: true,
      otherComponents: formikRef.current ? (
        <Box display="flex" flexDirection="row" gap={2}>
          <StaffPickBox
            formik={formikRef.current}
            showField={isAdmin}
            setProductDetails={setProductDetails}
          />

          <StatusBox
            formik={formikRef.current}
            setProductDetails={setProductDetails}
          />
        </Box>
      ) : null,
    }));

    return () => {
      setFooterConfig((prev) => ({ ...prev, showFooter: false }));
    };
  }, [
    isEdit,
    newApplicationList,
    newProductTypeList,
    isLoading.status,
    productDetails,
  ]); // Only re-run when editing state or saving state changes

  const handleCancel = async () => {
    if (isEdit) {
      navigate(-1);
      return;
    }

    try {
      const currentValues = formikRef.current.values;

      // Delete documents and images from DO
      if (currentValues.images.length || currentValues.files.length) {
        const bucketPayload = {
          folder: `products/${id}`,
        };
        await axios.post('/api/aws/content/delete', bucketPayload, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
      }

      await axios.delete(`/api/products/${id}`);
    } catch (error) {
      console.error('error', error);
    } finally {
      navigate(-1);
    }
  };

  return (
    <ManageProductContext.Provider
      value={{
        commissionScheduleTemplate,
        setCommissionScheduleTemplate,
        isSaving,
        setIsSaving,
      }}
    >
      <StyledNewProductPage>
        <Formik
          innerRef={formikRef}
          initialValues={defaultProductState}
          validationSchema={validationSchema}
          validateOnChange
          validateOnMount
          enableReinitialize
        >
          {() => {
            return (
              <>
                <Box gridArea="image">
                  <ProductImageHeader />
                </Box>
                <Box gridArea="form" py={2} px={4}>
                  <ProductForm />
                </Box>
              </>
            );
          }}
        </Formik>
        <StatusBarComponent />
      </StyledNewProductPage>
    </ManageProductContext.Provider>
  );
};

export default NewProductPage;
