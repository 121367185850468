import { useTranslation } from 'react-i18next';
import IntuPage from '../../components/layout/IntuPage';
import ManufacturersOrdersDashboard from '../../components/orders/ManufacturersOrdersDashboard';
import useAuth from '../../hooks/useAuth';
import AdminOrdersDashboard from '../../components/orders/AdminOrdersDashboard';

const OrdersPage = () => {
  const { t } = useTranslation();
  const { auth } = useAuth();

  return (
    <IntuPage
      pageTitle={t('pages.Orders.title')}
      pageDescription={t('pages.Orders.description')}
      hCentered
      lessMT
    >
      {auth.user_info.account_type === 'manufacturer' ? (
        <ManufacturersOrdersDashboard />
      ) : auth.user_info.account_type === 'intutec' ? (
        <AdminOrdersDashboard />
      ) : null}
    </IntuPage>
  );
};

export default OrdersPage;
