import React, { useContext, useEffect, useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { DndProvider } from 'intu-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { CarouselItem } from './carousel/Carouseltem';
import { useIntuTheme } from '../../context/providers/IntuThemeProvider';
import { ProductImageHeaderContext } from '../new-product/container/image/ProductImageHeader';
import DraggableIndicator from './carousel/DraggableIndicator';

// Utility Function to move an array item
const arrayMove = (array, fromIndex, toIndex) => {
  const newArray = [...array];
  const [movedItem] = newArray.splice(fromIndex, 1);
  newArray.splice(toIndex, 0, movedItem);
  return newArray;
};

export default function DraggableCarousel() {
  const {
    activeImage,
    setActiveImage,
    images,
    editingImage,
    handleReSortImages,
  } = useContext(ProductImageHeaderContext);
  const { theme, mobileView } = useIntuTheme();

  const [filteredImages, setFilteredImages] = useState([]);

  useEffect(() => {
    const filtered = images.filter(
      (image) =>
        image.imageType !== 'stp' &&
        image.imageType !== 'step' &&
        image.imageType !== 'obj',
    );
    setFilteredImages(filtered);
  }, [images]);

  const moveImage = (fromIndex, toIndex) => {
    const updatedFilteredImages = arrayMove(filteredImages, fromIndex, toIndex);

    handleReSortImages(updatedFilteredImages);
  };

  useEffect(() => {
    if (!filteredImages || activeImage !== 0) return;
    setActiveImage(0);
  }, [filteredImages]);

  return (
    <DndProvider backend={HTML5Backend}>
      <Carousel
        style={{ flex: 1 }}
        index={activeImage}
        animation="slide"
        navButtonsAlwaysVisible={images.length > 1}
        autoPlay={false}
        swipe={mobileView}
        indicators={false}
        NextIcon={<ArrowForwardIos sx={{ color: 'white' }} />}
        PrevIcon={<ArrowBackIosNew sx={{ color: 'white' }} />}
        navButtonsProps={{
          style: { display: images.length > 1 ? 'inline-flex' : 'none' },
        }}
        width={{ xs: '60vw', md: '100vw' }}
        height="40vh"
        onChange={(now) => setActiveImage(now)}
      >
        {filteredImages.map((image, i) => {
          if (editingImage) return null;
          return (
            <CarouselItem
              key={`${image?.url}-${i}`}
              image={image}
              showSliders={false}
            />
          );
        })}
      </Carousel>

      {/* Draggable Indicators */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '10px 0',
        }}
      >
        {filteredImages.map((image, index) => (
          <DraggableIndicator
            key={`${image.url}-${index}`} // Important: This key ensures React re-renders the component correctly
            image={image}
            index={index}
            actualIndex={index}
            isActive={activeImage === index}
            onClick={setActiveImage}
            theme={theme}
            moveImage={moveImage}
          />
        ))}
      </div>
    </DndProvider>
  );
}
