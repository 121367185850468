import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Formik, useFormik, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { toTitleCase } from '../../helpers/toTitleCase.js';
import i18n from '../../i18n.js';
import {
  getProductCategories,
  getProductGroups,
} from '../../api/products/productsRoutes.js';
// Response & Loading Handlers
import useResponseHandling from '../../hooks/useResponseHandler.js';
import { ProductTypeContext } from '../../context/providers/ProductTypeContextProvider.jsx';
import { useTheme } from '@emotion/react';
import { useIntuTheme } from '../../context/providers/IntuThemeProvider.jsx';
import IntuIconButton from '../buttons/IntuIconButton.jsx';

const NewProductTypeDialog = ({ fieldID = 'product_types' }) => {
  // Translator
  const { t } = useTranslation('dialogs', {
    keyPrefix: 'NewProductTypeDialog',
  });
  const { t: transButtons } = useTranslation('buttons');
  const { t: transTypes } = useTranslation('types');

  const { values: parentFormValues, setValues: parentFormSetValues } =
    useFormikContext();

  // Theme
  const theme = useTheme();
  const { mode } = useIntuTheme();

  // Product Type Form Context
  const {
    newProductDialogOpen,
    setNewProductDialogOpen,
    productNew,
    setProductNew,
    newProductTypeList,
    setNewProductTypeList,
  } = useContext(ProductTypeContext);

  const [productFamilyOptions, setProductFamilyOptions] = useState([]);
  const [productGroups, setProductGroups] = useState([]);
  const [filteredProductGroups, setFilteredProductGroups] = useState([]);

  const { handleErrorResponse } = useResponseHandling();

  useEffect(() => {
    // Get Product Families from database
    async function handleGetProductCategories() {
      try {
        let productCategoriesList = await getProductCategories();
        if (productCategoriesList.status === 'success') {
          setProductFamilyOptions(productCategoriesList.data);
        }
      } catch (err) {
        handleErrorResponse(err);
      }
    }

    //Get Product Groups from database
    async function handleGetProductGroups() {
      try {
        let productGroupList = await getProductGroups();
        if (productGroupList.status === 'success') {
          setProductGroups(productGroupList.data);
        }
      } catch (err) {
        handleErrorResponse(err);
      }
    }

    async function preLoadformInfo() {
      await handleGetProductCategories();
      await handleGetProductGroups();
    }

    preLoadformInfo();
  }, [i18n.language]);

  const formik = useFormik({
    initialValues: productNew,
    initialTouched: {},
    values: {},
  });

  const validationSchema = Yup.object();

  return (
    <Formik
      initialValues={productNew}
      values={formik.values}
      errors={formik.errors}
      validationSchema={validationSchema}
      validateOnMount={true}
      validateOnChange={true}
      enableReinitialize={true}
    >
      {(formik) => {
        const { values, setValues, setFieldValue, resetForm } = formik;
        return (
          <Dialog open={newProductDialogOpen}>
            <form>
              <DialogTitle>{t('title')}</DialogTitle>
              <DialogContent sx={{ width: 600, height: 500 }}>
                <DialogContentText>{t('content')}</DialogContentText>
                {/* Type Name */}
                <TextField
                  autoFocus
                  disabled
                  margin="dense"
                  id="type_name"
                  value={values?.type_name}
                  onChange={(e) => {
                    setFieldValue('type_name', toTitleCase(e.target.value));
                  }}
                  label={t('form.productType.label')}
                  placeholder={t('form.productType.placeholder')}
                  type="text"
                  variant="standard"
                  sx={{
                    '& .MuiInputBase-input.Mui-disabled': {
                      '-webkit-text-fill-color':
                        mode === 'dark'
                          ? theme.palette.disabled.main
                          : theme.palette.grey.main,
                    },
                  }}
                />
                {/* Product Family/Category */}
                <Autocomplete
                  id="product_family"
                  options={
                    productFamilyOptions
                      ? productFamilyOptions?.sort((a, b) =>
                          a.family_name.localeCompare(b.family_name),
                        )
                      : []
                  }
                  getOptionLabel={(option) => option?.family_name || ''}
                  onChange={(e, options, reason, element) => {
                    setFieldValue('product_group', null);
                    if (reason === 'selectOption') {
                      // Set Product Family Value
                      setValues({
                        ...values,
                        product_family: element.option,
                        product_group: null,
                      });

                      // Filter Product Groups based on selection
                      const filteredProductGroups = productGroups.filter(
                        (el) => {
                          return el.family_id === element.option.family_id;
                        },
                      );
                      setFilteredProductGroups(filteredProductGroups);
                    } else if (reason === 'clear') {
                      setValues({
                        ...values,
                        product_family: { family_id: '', family_name: '' },
                        product_group: { group_id: '', group_name: '' },
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      id="name"
                      value={values.family_id}
                      onChange={() => {
                        setValues({
                          ...values,
                          product_family: null,
                          product_group: null,
                        });
                      }}
                      label={t('form.productFamily.label')}
                      placeholder={t('form.productFamily.placeholder')}
                      type="text"
                      variant="standard"
                    />
                  )}
                />
                {/* Product Group */}
                {values.product_family.family_id && (
                  <Autocomplete
                    id="product_group"
                    disabled={!values?.product_family?.family_id}
                    options={
                      filteredProductGroups.length
                        ? filteredProductGroups?.sort()
                        : []
                    }
                    getOptionLabel={(option) => option.group_name || ''}
                    isOptionEqualToValue={(option, value) => {
                      if (!option || !value) {
                        return false;
                      }
                      if (!value.group_id) {
                        return false;
                      }
                      return option.group_id === value.group_id;
                    }}
                    clearOnBlur={true}
                    onChange={(e, newValue) => {
                      setFieldValue(
                        'product_group',
                        newValue || { group_id: '', group_name: '' },
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="dense"
                        id="name"
                        label={t('form.productGroup.label')}
                        placeholder={t('form.productGroup.placeholder')}
                        type="text"
                        variant="standard"
                      />
                    )}
                  />
                )}
              </DialogContent>
              <DialogActions>
                <Grid container sx={{ margin: '1rem 1rem 0 1rem' }}>
                  {/* Cancel */}
                  <Grid item>
                    <IntuIconButton
                      type="cancel"
                      tooltipTitle={transButtons('cancel_type')}
                      onClick={() => {
                        setNewProductDialogOpen(false);
                        setProductNew({
                          type_name: '',
                          product_family: { family_id: '', family_name: '' },
                          product_group: { group_id: '', group_name: '' },
                        });
                      }}
                    />
                  </Grid>

                  {/* Submit */}
                  <Grid item sx={{ marginLeft: 'auto' }}>
                    <IntuIconButton
                      type="submit"
                      tooltipTitle={transButtons('add', {
                        type: transTypes('products'),
                      })}
                      disabled={
                        !values?.product_family?.family_id ||
                        !values?.product_group?.group_id ||
                        !values?.type_name
                          ? true
                          : false
                      }
                      onClick={(e) => {
                        const newProductType = {
                          type_name: values.type_name,
                          family_name: values.product_family.family_name,
                          family_id: values.product_family.family_id,
                          group_name: values.product_group.group_name,
                          group_id: values.product_group.group_id,
                        };

                        parentFormSetValues(() => ({
                          ...parentFormValues,
                          [fieldID]: [
                            ...parentFormValues[fieldID],
                            newProductType,
                          ],
                        }));

                        setNewProductTypeList([
                          ...newProductTypeList,
                          newProductType,
                        ]);

                        resetForm();

                        setNewProductDialogOpen(false);
                      }}
                    />
                  </Grid>
                </Grid>
              </DialogActions>
            </form>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default NewProductTypeDialog;
