import { memo, useMemo, useContext, createContext, useState } from 'react';
import { Grid, Typography, Box, useTheme, useMediaQuery } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { GridRow, GridColumnHeaders } from '@mui/x-data-grid-premium';
import { Trans, useTranslation } from 'react-i18next';
import IntuIconButton from '../../buttons/IntuIconButton.jsx';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ListLoader from '../../status/ListLoader.jsx';
import { UserProfileContext } from '../../../context/users/UserProfileProvider.jsx';
import { Add } from '@mui/icons-material';
import useResponseHandling from '../../../hooks/useResponseHandler.js';
import { createNewReferralCampaign } from '../../../api/referrals/referralRoutes.js';
import { ManageReferralsContext } from '../ManageReferrals.jsx';
import { CampaignOfferDetailsDialog } from '../dialogs/CampaignOfferDetailsDialog.jsx';

export const ReferralOfferContext = createContext();

const createReferralOfferColumns = (
  t,
  handleViewClick,
  handleAcceptReferral,
  setOfferDetailsDialog,
) => {
  return [
    {
      field: 'product_name',
      headerName: t('components.ManageReferralOffersList.columns.product_name'),
      editable: false,
      minWidth: 100,
      flex: 0.5,
    },
    {
      field: 'referral_terms.cpc',
      headerName: t('components.ManageReferralOffersList.columns.rpc'),
      editable: false,
      minWidth: 70,
      valueGetter: (params) => {
        const amount = params.row?.referral_terms?.cpc;

        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(amount);
      },
    },
    {
      field: 'referral_terms.cpa',
      headerName: t('components.ManageReferralOffersList.columns.rpa'),
      editable: false,
      minWidth: 70,
      valueGetter: (params) => {
        const amount = params.row?.referral_terms?.cpa;
        const formattedAmount = `${amount.toFixed(2)} US$`;
        return formattedAmount;
      },
    },
    {
      field: 'referral_terms.cpo',
      headerName: t('components.ManageReferralOffersList.columns.rpo'),
      editable: false,
      minWidth: 70,
      valueGetter: (params) => {
        const amount = params.row?.referral_terms?.cpo;
        const formattedAmount = `${parseFloat((amount * 100).toFixed(2))} %`;
        return formattedAmount;
      },
    },
    {
      field: 'lifetime',
      headerName: t('components.ManageReferralOffersList.columns.duration'),
      editable: false,
      valueGetter: (params) => {
        const days = params.row?.referral_terms?.lifetime;
        const formattedAmount = `${days} days`;
        return formattedAmount;
      },
    },
    {
      field: 'list_price',
      headerName: t('components.ManageReferralOffersList.columns.sales_price'),
      editable: false,
      valueGetter: (params) => {
        const amount = params.row?.list_price;

        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(amount);
      },
    },
    {
      field: 'view',
      headerName: t('components.ManageReferralOffersList.columns.view'),
      sortable: false,
      align: 'center',
      renderCell: (params) => {
        return (
          <IntuIconButton
            size="small"
            type="info"
            variant="outlined"
            iconOnly={true}
            tooltipTitle={t('components.ManageReferralOffersList.buttons.view')}
            onClick={() => handleViewClick(params)}
            IconComponent={VisibilityIcon}
          />
        );
      },
    },
    {
      field: 'accept',
      headerName: t('components.ManageReferralOffersList.columns.accept'),
      sortable: false,
      align: 'center',
      renderCell: (params) => {
        return (
          <IntuIconButton
            size="small"
            type="add"
            variant="outlined"
            tooltipTitle={t(
              'components.ManageReferralOffersList.buttons.accept',
            )}
            onClick={() => handleAcceptReferral(params.row)}
            IconComponent={Add}
          />
        );
      },
    },
    {
      field: 'details',
      headerName: t('components.ManageReferralOffersList.columns.details'),
      sortable: false,
      align: 'center',
      renderCell: (params) => {
        return (
          <IntuIconButton
            size="small"
            type="edit"
            variant="outlined"
            iconOnly={true}
            tooltipTitle={t(
              'components.ManageReferralOffersList.buttons.details',
            )}
            onClick={() =>
              setOfferDetailsDialog({
                open: true,
                data: params.row,
              })
            }
            // IconComponent={Edit}
          />
        );
      },
    },
  ];
};

const ReferralOffersList = () => {
  const MemoizedRow = memo(GridRow);
  const MemoizedColumnHeaders = memo(GridColumnHeaders);

  const { userProfile } = useContext(UserProfileContext);
  const { handleRegularResponse, handleErrorResponse } = useResponseHandling();

  const { t } = useTranslation();
  const { t: transMessages } = useTranslation('messages', {
    keyPrefix: 'referrals.referral_list',
  });

  const { referralOffers, getActiveReferralCampaigns, getReferralOffers } =
    useContext(ManageReferralsContext);

  const [offerDetailsDialog, setOfferDetailsDialog] = useState({
    open: false,
    data: {},
  });

  const theme = useTheme();
  const isMediumView = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const handleViewClick = (params) => {
    window.open(`/product/${params.row._id}`, '_blank');
  };

  const handleAcceptReferral = async (productInfo) => {
    // Create a New Referral Campaign
    const payload = {
      product: productInfo?._id,
      manufacturer: productInfo?.manufacturer,
      brand: productInfo?.brand,
      referral_terms: productInfo?.referral_terms,
      referrer: userProfile._id,
    };

    try {
      const createCampaign = await createNewReferralCampaign({ payload });

      const { statusCode, status, message } = createCampaign;

      handleRegularResponse({
        open: true,
        status: status,
        statusCode: statusCode,
        message: message,
      });

      getActiveReferralCampaigns({ refresh: false });
      getReferralOffers({ refresh: false });
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const referralColumns = useMemo(() => {
    return createReferralOfferColumns(
      t,
      handleViewClick,
      handleAcceptReferral,
      setOfferDetailsDialog,
    );
  }, [t, handleViewClick]);

  return (
    <ReferralOfferContext.Provider
      value={{
        offerDetailsDialog,
        setOfferDetailsDialog,
        handleAcceptReferral,
      }}
    >
      <Grid
        container
        className="content"
        sx={{ marginBottom: '8rem' }}
        spacing={5}
      >
        <Grid item className="content" xs={12}>
          {referralOffers?.fetching ? (
            <ListLoader columns={8} rows={4} />
          ) : !referralOffers?.data?.length ? (
            <Grid item className="title content-centered" xs={12}>
              <Typography variant="body1">
                <Trans t={transMessages} i18nKey="no_offer_records_alt" />
              </Typography>
            </Grid>
          ) : (
            <Box height="auto" width="100%" mb={5}>
              <DataGrid
                isCellEditable={(params) => false}
                getRowId={(row) => row._id}
                rows={referralOffers.data}
                columns={referralColumns}
                columnVisibilityModel={{
                  list_price: !isMobileView && !isMediumView,
                  lifetime: !isMobileView && !isMediumView,
                  'referral_terms.cpc': !isMobileView && !isMediumView,
                  'referral_terms.cpa': !isMobileView && !isMediumView,
                  'referral_terms.cpo': !isMobileView && !isMediumView,
                  accept: !isMobileView && !isMediumView,
                  details: isMobileView || isMediumView,
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                  sorting: {
                    sortModel: [{}],
                  },
                }}
                pageSizeOptions={[5, 10, 100]}
                checkboxSelection={false}
                disableRowSelectionOnClick
                disableColumnMenu
                slots={{
                  Row: MemoizedRow,
                  ColumnHeaders: MemoizedColumnHeaders,
                }}
                sx={{
                  borderRadius: '12px',
                  boxShadow: 6,
                  '& .MuiDataGrid-columnSeparator': {
                    display: 'none',
                  },
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-cell:focus-within': {
                    outline: 'none',
                  },
                }}
              />
            </Box>
          )}

          {/* Refresh Button */}
          {/* <Box width="100%" display="fex" justifyContent="center">
          <Button
            variant="contained"
            type="submit"
            color="refresh"
            endIcon={
              <RotateRight
                sx={{ color: (theme) => theme.palette.refresh.contrastText }}
              />
            }
            onClick={() => {
              getReferralOffers();
            }}
            sx={{ minWidth: 150, maxHeight: 40 }}
          >
            {transButtons('refresh')}
          </Button>
        </Box> */}
        </Grid>
        <CampaignOfferDetailsDialog />
      </Grid>
    </ReferralOfferContext.Provider>
  );
};

export default ReferralOffersList;
