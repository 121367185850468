import { useEffect } from 'react';
import { useGLTF } from '@react-three/drei';

const GLTFModel = ({ rotationX, rotationY, url, handleSceneLoaded }) => {
  const { scene } = useGLTF(url, true);

  useEffect(() => {
    if (scene && scene.visible) {
      handleSceneLoaded(scene);
    }
  }, [scene]);

  return (
    <primitive
      object={scene}
      receiveShadow={false}
      position={[0, 10, -50]}
      rotation={[rotationY / 10, rotationX / 10, 0]}
      // height={500}
    />
  );
};

export default GLTFModel;
