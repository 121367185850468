import { axiosPrivate } from '../../hooks/axios/axios.js';

const getSubscriptions = async () => {
  try {
    const { data, status } = await axiosPrivate.get(
      '/api/stripe/subscriptions',
    );
    if (status === 200) {
      return data.data;
    } else {
      return [];
    }
  } catch (error) {
    console.error('error', error);
    return [];
  }
};

const cancelSubscription = async (subscriptionId) => {
  try {
    const { data, status } = await axiosPrivate.get(
      `/api/stripe/subscriptions/cancel/${subscriptionId}`,
    );
    return {
      data,
      status,
    };
  } catch (error) {
    console.error('error', error);
  }
};

const createNewSubscription = async (payload) => {
  try {
    const { data, status } = await axiosPrivate.post(
      '/api/stripe/subscriptions/create',
      payload,
    );
    return {
      data,
      status,
    };
  } catch (error) {
    console.error('error', error);
  }
};

const updateSubscriptionAutoRenew = async (payload) => {
  try {
    const { data, status } = await axiosPrivate.put(
      `/api/stripe/subscriptions/update-auto-renew`,
      payload,
    );
    return {
      data,
      status,
    };
  } catch (error) {
    console.error('error', error);
  }
};

export {
  getSubscriptions,
  cancelSubscription,
  createNewSubscription,
  updateSubscriptionAutoRenew,
};
