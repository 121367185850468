import React, { useContext } from 'react';
import { CompanyProfileContext } from '../../../../../context/company/CompanyProfileProvider';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import ApplicationsField from '../../../../applications/ApplicationsField';
import ProductTypeField from '../../../../product-type/ProductTypeField';

const ProductApplicationsTab = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.account.company.ProductApplicationsTab',
  });
  const { companyProfile } = useContext(CompanyProfileContext);

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Box>
        <ApplicationsField
          fieldTitle={t('applications.title')}
          fieldDescription={t('applications.description')}
          required={true}
          disabled={!companyProfile.isAdmin}
        />
      </Box>

      <Box>
        <ProductTypeField
          fieldTitle={t('products.title')}
          fieldDescription={t('products.description')}
          required={true}
          disabled={!companyProfile.isAdmin}
        />
      </Box>
    </Box>
  );
};

export default ProductApplicationsTab;
