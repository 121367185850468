import { axiosPrivate } from '../../../hooks/axios/axios';

const listAttributeTypes = async () => {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/product-attributes/types/list';

  try {
    const { data } = await axiosPrivate.get(url, {
      signal,
    });

    return data;
  } catch (error) {
    console.error(error);
    return error;
  } finally {
    controller.abort(signal);
  }
};

const searchAttributeTypes = async (query) => {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/product-attributes/types/search';

  if (!query) {
    return;
  }

  try {
    const { data } = await axiosPrivate.post(url, query, {
      signal,
    });

    return data;
  } catch (error) {
    console.error(error);
    return error;
  } finally {
    controller.abort(signal);
  }
};

const listAttributes = async () => {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/product-attributes/attributes/list';

  try {
    const { data } = await axiosPrivate.get(url, {
      signal,
    });

    return data;
  } catch (error) {
    console.error(error);
    return error;
  } finally {
    controller.abort(signal);
  }
};

const listAttributeValues = async ({ attributeId }) => {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/product-attributes/attributes/values/list';

  if (!attributeId) {
    return;
  }

  try {
    const { data } = await axiosPrivate.get(url, {
      params: { attribute: attributeId },
      signal,
    });

    return data;
  } catch (error) {
    console.error(error);
    return error;
  } finally {
    controller.abort(signal);
  }
};

const addNewAttribute = async ({ attribute_type, name, attribute }) => {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/product-attributes/attributes/add';

  if (!attribute_type || !name || !attribute) {
    return;
  }

  const payload = {
    attribute_type,
    attribute,
    name,
  };

  try {
    const { data } = await axiosPrivate.post(url, payload, {
      signal,
    });

    console.log('Added New Attribute Response -> ', data);

    return data;
  } catch (error) {
    console.error(error);
    return error;
  } finally {
    controller.abort(signal);
  }
};

export {
  listAttributeTypes,
  listAttributes,
  listAttributeValues,
  searchAttributeTypes,
  addNewAttribute,
};
