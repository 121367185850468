import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import ProductInfoTabContent from './tabs/ProductInfoTabContent';
import AttributesContentTab from './tabs/AttributesContentTab';
import FormikTabs from '../formik/FormikTabs';
import PricingInfoTabContent from './tabs/PricingInfoTabContent';
import CommissionTabContent from './tabs/CommissionTabContent';
import FilesTabContent from './tabs/FilesTabContent';
import FullfillmentInfoTabContent from './tabs/FullfillmentInfoTabContent';
import ReferralTabContent from './tabs/ReferralTabContent';
import WarrantyTermsContent from './tabs/WarrantyTermsContent';

const ProductForm = () => {
  // Translation
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.productForm',
  });

  const tabContent = [
    {
      title: (
        <Typography variant="h6" textTransform="none">
          {t('tabs.product_info')}
        </Typography>
      ),
      content: <ProductInfoTabContent />,
    },
    {
      title: (
        <Typography variant="h6" textTransform="none">
          {t('tabs.attributes')}
        </Typography>
      ),
      content: <AttributesContentTab />,
    },
    {
      title: (
        <Typography variant="h6" textTransform="none">
          {t('tabs.files')}
        </Typography>
      ),
      content: <FilesTabContent />,
    },
    {
      title: (
        <Typography variant="h6" textTransform="none">
          {t('tabs.pricing')}
        </Typography>
      ),
      content: <PricingInfoTabContent />,
    },
    {
      title: (
        <Typography variant="h6" textTransform="none">
          {t('tabs.warranty')}
        </Typography>
      ),
      content: <WarrantyTermsContent />,
    },
    {
      title: (
        <Typography variant="h6" textTransform="none">
          {t('tabs.commission')}
        </Typography>
      ),
      content: <CommissionTabContent />,
    },
    {
      title: (
        <Typography variant="h6" textTransform="none">
          {t('tabs.fullfillment')}
        </Typography>
      ),
      content: <FullfillmentInfoTabContent />,
    },
    {
      title: (
        <Typography variant="h6" textTransform="none">
          {t('tabs.referral')}
        </Typography>
      ),
      content: <ReferralTabContent />,
    },
  ];

  return <FormikTabs tabsContent={tabContent} isScrollable={true} />;
};

export default ProductForm;
