import { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
  Box,
  TextField,
  Typography,
  InputLabel,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useResponseHandling from '../../hooks/useResponseHandler';
import { useAxiosPrivate } from '../../hooks/axios/useAxiosPrivate';
import OrderItemShippingDetailsDialogForm from './OrderItemShippingDetailsDialogForm';
import RatesContainer from './RatesContainer';
import { getPublicProductById } from '../../api/products/productsRoutes';
import IntuIconButton from '../buttons/IntuIconButton';
import CarriersBox from '../checkout/CarriersBox';

function OrderItemShippingDetailsDialog({
  open,
  onClose,
  onConfirm,
  selectedOrderItem,
  setOrders,
}) {
  const theme = useTheme();
  const { t: transButtons } = useTranslation('buttons');
  const { handleErrorResponse } = useResponseHandling();
  const axios = useAxiosPrivate();

  const [rates, setRates] = useState([]);
  const [selectedRate, setSelectedRate] = useState(null);
  const [isPriceLoading, setIsPriceLoading] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [product, setProduct] = useState(null);

  const getShippingPrice = async () => {
    try {
      setIsPriceLoading(true);

      const product = await getPublicProductById(selectedOrderItem.product);

      const payload = {
        addressFrom: selectedOrderItem.shipping.ship_from,
        addressFromName: selectedOrderItem.manufacturer.manufacturer_name,
        addressTo: selectedOrderItem.shipping.ship_to,
        addressToName: `${selectedOrderItem.shipping.ship_to.first_name} ${selectedOrderItem.shipping.ship_to.last_name}`,
        products: [
          {
            product: { ...product },
            quantity: selectedOrderItem.quantity,
          },
        ],
      };

      const { data, status } = await axios.post(
        '/api/shippo/get-rates',
        payload,
      );

      if (status === 200) {
        setRates(data.data);
        setProduct(product);
      }
    } catch (error) {
      console.error('error', error);
      handleErrorResponse(error);
    } finally {
      setIsPriceLoading(false);
    }
  };

  const handleSelectRate = (rate) => {
    setSelectedRate(rate);
  };

  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [trackingNumber, setTrackingNumber] = useState('');

  const handleVerifyTrackingNumber = async () => {
    try {
      const payload = {
        carrier: selectedCarrier.carrier,
        trackingNumber,
        orderItemId: selectedOrderItem._id,
      };
      const { data, status } = await axios.post(
        '/api/shippo/verify-tracking-number',
        payload,
      );
      if (status === 200) {
        setOrders((prev) => {
          return prev.map((orderItem) => {
            if (orderItem._id === data.data._id) {
              return data.data;
            } else {
              return orderItem;
            }
          });
        });
      }
      onClose();
    } catch (error) {
      console.error('error', error);
      handleErrorResponse(error);
    }
  };

  const handleConfirm = async () => {
    // Create shipment basesd on carrier, UPS has special logic
    setIsConfirmLoading(true);
    try {
      const payload = {
        addressFrom: selectedOrderItem.shipping.ship_from,
        addressFromName: selectedOrderItem.manufacturer.manufacturer_name,
        addressTo: selectedOrderItem.shipping.ship_to,
        addressToName: `${selectedOrderItem.shipping.ship_to.first_name} ${selectedOrderItem.shipping.ship_to.last_name}`,
        products: [
          {
            product: { ...product },
            quantity: selectedOrderItem.quantity,
          },
        ],
        selectedRate,
      };

      const { data, status } = await axios.post(
        '/api/shippo/create-shipment',
        payload,
      );

      if (status === 200) {
        onConfirm(data.data);
      }
    } catch (error) {
      console.error('error', error);
      handleErrorResponse(error);
    } finally {
      setIsConfirmLoading(false);
    }
  };

  if (!selectedOrderItem) return null;
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle textTransform="none">
        Order Item Shipping Details
      </DialogTitle>
      <DialogContent>
        <Box
          display="grid"
          gridTemplateColumns="1fr"
          gridTemplateRows="1fr 1fr 1fr"
          gap={2}
          my={4}
        >
          <DialogContentText
            border={`1px solid ${theme.palette.primary.main}`}
            borderRadius={1}
            px={1}
            py={2}
          >
            Carrier: {selectedOrderItem.shipping.provider}
          </DialogContentText>
          <DialogContentText
            border={`1px solid ${theme.palette.primary.main}`}
            borderRadius={1}
            px={1}
            py={2}
          >
            Shipping Method: {selectedOrderItem.shipping.shipping_method}
          </DialogContentText>
          <DialogContentText
            border={`1px solid ${theme.palette.primary.main}`}
            borderRadius={1}
            px={1}
            py={2}
          >
            Quoted Cost:{' '}
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(selectedOrderItem.shipping.quoted_cost)}
          </DialogContentText>
        </Box>
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" gap={2}>
            <DialogContentText
              border={`1px solid ${theme.palette.primary.main}`}
              borderRadius={1}
              px={1}
              py={2}
              flex={1}
            >
              Measurement Unit: {selectedOrderItem.shipping.dimensions.units}
            </DialogContentText>
            <DialogContentText
              border={`1px solid ${theme.palette.primary.main}`}
              borderRadius={1}
              px={1}
              py={2}
              flex={1}
            >
              Length: {selectedOrderItem.shipping.dimensions.length}
            </DialogContentText>
            <DialogContentText
              border={`1px solid ${theme.palette.primary.main}`}
              borderRadius={1}
              px={1}
              py={2}
              flex={1}
            >
              Width: {selectedOrderItem.shipping.dimensions.width}
            </DialogContentText>
            <DialogContentText
              border={`1px solid ${theme.palette.primary.main}`}
              borderRadius={1}
              px={1}
              py={2}
              flex={1}
            >
              Height: {selectedOrderItem.shipping.dimensions.height}
            </DialogContentText>
          </Box>
          <Box display="flex" gap={2}>
            <DialogContentText
              border={`1px solid ${theme.palette.primary.main}`}
              borderRadius={1}
              px={1}
              py={2}
              flex={1}
            >
              Weight Unit: {selectedOrderItem.shipping.weight.units}
            </DialogContentText>
            <DialogContentText
              border={`1px solid ${theme.palette.primary.main}`}
              borderRadius={1}
              px={1}
              py={2}
              flex={1}
            >
              Weight: {selectedOrderItem.shipping.weight.value}
            </DialogContentText>
          </Box>
        </Box>
        <Typography mt={2} variant="h6">
          Get a new rate
        </Typography>
        <OrderItemShippingDetailsDialogForm
          getShippingPrice={getShippingPrice}
        />
        <RatesContainer
          rates={rates}
          isPriceLoading={isPriceLoading}
          handleSelectRate={handleSelectRate}
          selectedRate={selectedRate}
        />
        <Box mt={2}>
          <Typography variant="h6">Or fulfill on your own</Typography>
          <CarriersBox
            selectedCarrier={selectedCarrier}
            setSelectedCarrier={setSelectedCarrier}
          />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap={4}
          >
            <InputLabel flex={1} sx={{ minWidth: 180 }}>
              Your tracking number
            </InputLabel>
            <TextField
              type="text"
              value={trackingNumber}
              onChange={(e) => setTrackingNumber(e.target.value)}
              placeholder="Enter your tracking number"
              sx={{ my: 2, flex: 1 }}
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button
            onClick={handleVerifyTrackingNumber}
            variant="outlined"
            disabled={!selectedCarrier || !trackingNumber}
          >
            Verify
          </Button>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '16px 24px',
        }}
      >
        <IntuIconButton
          onClick={onClose}
          color="primary"
          variant="outlined"
          type="cancel"
          tooltipTitle={transButtons('cancel')}
        />
        <IntuIconButton
          onClick={handleConfirm}
          color="primary"
          variant="contained"
          type="add"
          tooltipTitle={transButtons('buy')}
          disabled={!selectedRate}
          isLoading={isConfirmLoading}
        />
      </DialogActions>
    </Dialog>
  );
}

export default OrderItemShippingDetailsDialog;
