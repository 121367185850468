import { useContext } from 'react';
import ResponseContext from '../../../context/providers/ResponseProvider';
import useAuth from '../../../hooks/useAuth';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ContentCopy } from '@mui/icons-material';
import IntuIconButton from '../../buttons/IntuIconButton';
import { CampaignDetailsContext } from '../list/ReferralCampaignActiveList';

const ShareCampaignDialog = () => {
  const { setStatusMsg } = useContext(ResponseContext);
  const { campaignDetailsDialog, setCampaignDetailsDialog } = useContext(
    CampaignDetailsContext,
  );

  const { t: transDialogs } = useTranslation('dialogs', {
    keyPrefix: 'ShareCampaignDialog',
  });
  const { t: transButtons } = useTranslation('buttons');
  const customLink = `https://${window.location.host}/product/${campaignDetailsDialog?.data?.product}&referalID=${campaignDetailsDialog?.data?._id}`;

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(customLink);
      setStatusMsg({
        open: true,
        status: 'success',
        message: 'Copied to clipboard!',
      });
    } catch (error) {
      setStatusMsg({
        open: true,
        status: 'error',
        message: 'error copying to clipboard',
      });
      console.error('error', error);
    }
  };

  const handleClose = () => {
    setCampaignDetailsDialog({
      ...campaignDetailsDialog,
      shareDialogOpen: false,
    });
  };

  return (
    <Dialog
      open={campaignDetailsDialog?.shareDialogOpen}
      onClose={handleClose}
      maxWidth="md"
    >
      <DialogTitle>{transDialogs('title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{transDialogs('description')}</DialogContentText>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography fontSize="0.8rem" noWrap mr={2}>
            {customLink}
          </Typography>
          <IntuIconButton
            size="small"
            type="share"
            variant="outlined"
            iconOnly={true}
            tooltipTitle={transButtons('copy_clipboard')}
            onClick={handleCopyClick}
            IconComponent={ContentCopy}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" flexDirection="row" sx={{ marginRight: 'auto' }}>
          <IntuIconButton
            onClick={handleClose}
            type="cancel"
            tooltipTitle={transButtons('cancel')}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export { ShareCampaignDialog };
