import { useEffect, useState, useMemo, useRef } from 'react';
import { AnimatePresence } from 'framer-motion';
import {
  Box,
  CircularProgress,
  TextField,
  Typography,
  Autocomplete,
} from '@mui/material';
import ProductCard from '../products/ProductCard';
import ProductFilter, {
  PRICE_ASC,
  PRICE_DESC,
} from '../products/ProductsFilter';
import { getPublishedProducts } from '../../api/products/productsRoutes';

export const PublicProductsList = ({
  showSearchBar = true,
  initialPage = 1,
  initialLimit = 10,
}) => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [searchFilter, setSearchFilter] = useState(null);
  const [selectedFilterOption, setSelectedFilterOption] = useState(null);
  const [page, setPage] = useState(initialPage);
  const [limit] = useState(initialLimit);
  const loadMoreRef = useRef(null);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    getPublishedProducts({ page: 1, limit: limit })
      .then((res) => setProducts(res.data))
      .catch((err) => console.warn(err))
      .finally(() => setIsLoading(false));
  }, [limit]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isFetchingMore && hasMore) {
          loadMoreProducts();
        }
      },
      { threshold: 1 },
    );

    const currentRef = loadMoreRef.current;

    if (currentRef && hasMore) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) observer.unobserve(currentRef);
      observer.disconnect(); // <- Make sure to fully disconnect
    };
  }, [isFetchingMore, hasMore]);

  const loadMoreProducts = () => {
    setIsFetchingMore(true);
    const nextPage = page + 1;

    getPublishedProducts({ page: nextPage, limit: limit })
      .then((res) => {
        if (res.data.length > 0) {
          setProducts((prev) => [...prev, ...res.data]);
          setPage(nextPage);
          // Use a safe fallback if res.hasMore is not provided
          setHasMore(res.hasMore ?? res.data.length === limit);
        } else {
          setHasMore(false); // No more products
        }
      })
      .catch((err) => console.warn(err))
      .finally(() => setIsFetchingMore(false));
  };

  const filteredProducts = useMemo(() => {
    if (!products) return [];

    let _products = [...products];

    if (searchFilter) {
      _products = _products.filter((product) =>
        product.product_name.toLowerCase().includes(searchFilter.toLowerCase()),
      );
    }

    if (selectedFilterOption === PRICE_ASC) {
      return _products.sort((a, b) => a.list_price - b.list_price);
    }
    if (selectedFilterOption === PRICE_DESC) {
      return _products.sort((a, b) => b.list_price - a.list_price);
    }

    return _products;
  }, [searchFilter, selectedFilterOption, products]);

  const options = useMemo(() => {
    if (!products) return [];

    return products.map((product) => ({
      label: product.product_name,
    }));
  }, [products]);

  const handleAutoCompleteChange = (e, values) => {
    if (!values.length) {
      setSearchFilter(null);
      return;
    }

    const newestValue = values.at(-1);
    setSearchFilter((prevFilter) => {
      if (prevFilter) {
        return `${prevFilter} ${newestValue.label}`;
      } else {
        return newestValue.label;
      }
    });
  };

  return (
    <Box display="flex" alignItems="center" flexDirection="column" width="100%">
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Box width="100%" mx={4}>
          {showSearchBar ? (
            <Box display="flex" justifyContent="center">
              <Autocomplete
                multiple
                disablePortal
                id="search-autocomplete"
                getOptionLabel={(option) => option.label}
                options={options}
                selectOnFocus
                handleHomeEndKeys
                sx={{ width: '50%', mr: 2 }}
                onChange={handleAutoCompleteChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Search for a product"
                    value={searchFilter || ''}
                  />
                )}
              />
              <ProductFilter onFilterClick={setSelectedFilterOption} />
            </Box>
          ) : null}
          {Array.isArray(filteredProducts) && filteredProducts.length === 0 ? (
            <Box mt={4} display="flex" justifyContent="center">
              <Typography>No results found</Typography>
            </Box>
          ) : null}
          <Box
            width="100%"
            display="flex"
            justifyContent="space-evenly"
            flexWrap="wrap"
            mt={4}
            gap={2}
            mb={10}
          >
            <AnimatePresence>
              {filteredProducts.map((product) => (
                <ProductCard key={product._id} product={product} />
              ))}
            </AnimatePresence>
          </Box>

          {isFetchingMore && (
            <Box display="flex" justifyContent="center" my={4}>
              <CircularProgress />
            </Box>
          )}
          <div ref={loadMoreRef} style={{ height: '1px' }} />
        </Box>
      )}
    </Box>
  );
};
