import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
  Box,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTranslation } from 'react-i18next';
import useResponseHandling from '../../hooks/useResponseHandler';
import dayjs from 'dayjs';
import {
  updateOrderItemDate,
  getCustomerDetails as fetchCustomerDetails,
} from '../../api/orders/orders';
import IntuIconButton from '../buttons/IntuIconButton';

function OrderItemDetailsDialog({
  open,
  onClose,
  onConfirm,
  selectedOrderItem,
  confirmedDate,
  setConfirmedDate,
}) {
  const theme = useTheme();
  const { t: transButtons } = useTranslation('buttons');
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const [isLoading, setIsLoading] = useState(false);
  const [customerDetails, setCustomerDetails] = useState({
    name: '',
    email: '',
  });

  const handleConfrimDateChanged = async (date) => {
    setConfirmedDate(dayjs(date));

    try {
      const { data, status } = await updateOrderItemDate(
        selectedOrderItem._id,
        date,
      );

      if (status === 201) {
        handleRegularResponse({
          open: true,
          status: data.status,
          statusCode: data.statusCode,
          message: data.message,
        });
      }
    } catch (error) {
      console.error('error', error);
      handleErrorResponse(error);
    }
  };

  const loadCustomerDetails = async () => {
    if (!selectedOrderItem) return;

    setIsLoading(true);

    try {
      const { data, status } = await fetchCustomerDetails(
        selectedOrderItem.parent_id,
      );

      if (status === 200) {
        setCustomerDetails(data.data);
      }
    } catch (error) {
      console.error('error', error);
      handleErrorResponse(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setCustomerDetails(null);
    if (selectedOrderItem) {
      loadCustomerDetails();
    }
  }, [selectedOrderItem]);

  if (!selectedOrderItem) return null;
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle textTransform="none">Order Item Details</DialogTitle>
      <DialogContent>
        <Box display="flex" gap={2} my={2}>
          <DialogContentText
            border={`1px solid ${theme.palette.primary.main}`}
            borderRadius={1}
            px={1}
            py={2}
            flex={1}
          >
            Customer Name: {customerDetails?.name}
          </DialogContentText>
          <DialogContentText
            border={`1px solid ${theme.palette.primary.main}`}
            borderRadius={1}
            px={1}
            py={2}
            flex={1}
          >
            Customer Email: {customerDetails?.email}
          </DialogContentText>
        </Box>
        <Box display="flex" gap={2} my={2}>
          <DialogContentText
            border={`1px solid ${theme.palette.primary.main}`}
            borderRadius={1}
            px={1}
            py={2}
            flex={1}
          >
            Quantity: {selectedOrderItem.quantity}
          </DialogContentText>
          <DialogContentText
            border={`1px solid ${theme.palette.primary.main}`}
            borderRadius={1}
            px={1}
            py={2}
            flex={1}
          >
            Price:{' '}
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(selectedOrderItem.price)}
          </DialogContentText>
        </Box>
        <DialogContentText
          border={`1px solid ${theme.palette.primary.main}`}
          borderRadius={1}
          px={1}
          py={2}
          my={2}
        >
          Total:{' '}
          {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(selectedOrderItem.net_total)}
        </DialogContentText>
        <Box display="flex" gap={2} my={2}>
          <DialogContentText
            border={`1px solid ${theme.palette.primary.main}`}
            borderRadius={1}
            p={1}
            flex={1}
            display="flex"
            alignItems="center"
          >
            Required Date:{' '}
            {dayjs(selectedOrderItem.created.date).format('MM/DD/YYYY')}
          </DialogContentText>
          <DatePicker
            sx={{ flex: 1 }}
            label={'Confirm Date'}
            id="confirmed_date"
            name="confirmed_date"
            variant="standard"
            value={confirmedDate}
            onChange={handleConfrimDateChanged}
            minDate={dayjs(selectedOrderItem.created.date)}
            disabled={
              selectedOrderItem?.fullfillment?.intutec ||
              selectedOrderItem.status !== 'charged'
            }
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '16px 24px',
        }}
      >
        <IntuIconButton
          onClick={onClose}
          color="primary"
          variant="outlined"
          type="cancel"
          tooltipTitle={transButtons('cancel')}
        />
        <IntuIconButton
          onClick={onConfirm}
          color="primary"
          variant="contained"
          type="add"
          disabled={
            selectedOrderItem?.fullfillment?.intutec ||
            selectedOrderItem.status !== 'charged'
          }
          tooltipTitle={transButtons('fulfill')}
        />
      </DialogActions>
    </Dialog>
  );
}

export default OrderItemDetailsDialog;
