import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import UserContext from '../../../../context/users/UserInfoProvider';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Link,
  Typography,
} from '@mui/material';

const StripeConsentContainer = ({ consent, setConsent }) => {
  // Stripe Account User Consent
  const { t: stripeTranslations } = useTranslation('translation', {
    keyPrefix: 'form.consent.ssa',
  });

  const { user } = useContext(UserContext);
  const userIP = user?.location?.ip;

  const [accountCountry, setAccountCountry] = useState({
    label: '',
    code: '',
  });

  useEffect(() => {
    setAccountCountry({
      label: user.location.countryLabel,
      code: user.location.country,
    });
  }, [user.location.country]);

  return (
    <>
      <Typography level="body-sm">
        <Trans
          i18nKey={stripeTranslations('note')}
          values={{
            label: accountCountry.label,
          }}
          components={[
            <Link
              className="link"
              href={`https://stripe.com/${accountCountry.code}/legal/ssa#general-terms`}
              target="_blank"
              rel="noopener"
            />,
          ]}
        />
      </Typography>
      <FormGroup>
        <FormControlLabel
          checked={consent.accepted}
          control={
            <Checkbox
              name={'consent.accepted'}
              onChange={(e) => {
                const consentChange = e.target.checked;

                setConsent({
                  accepted: consentChange,
                  ip: consentChange ? userIP : '',
                  date: consentChange ? new Date() : '',
                  unixTimeStamp: consentChange
                    ? Math.floor(Date.now() / 1000)
                    : '',
                  agent: consentChange ? navigator.userAgent : '',
                });
              }}
              inputProps={{
                'aria-label': 'controlled',
              }}
            />
          }
          label={
            <Typography level="body-sm">
              {stripeTranslations('label')}
            </Typography>
          }
        />
      </FormGroup>
    </>
  );
};

export default StripeConsentContainer;
