import { measurementOptions, weightOptions } from '../../data/MeasruementUnits';

export const createAddNewProductAttributeInitialValues = ({
  selectedAttributeType,
  editingItem,
}) => {
  const isWeight = weightOptions.filter(
    (w) => w === selectedAttributeType?.data_keys,
  );
  const isMeasurement = measurementOptions.filter(
    (m) => m === selectedAttributeType?.data_keys,
  );

  // console.log('Is Weights -> ', isWeight);
  // console.log('Is Measurement -> ', isMeasurement);

  let unit = '';
  if (isWeight?.length > 0 || isMeasurement?.length > 0) {
    unit = selectedAttributeType?.data_keys[0];
  }

  // console.log('Editing Item in Intitial Values -> ', editingItem);

  if (editingItem?.attributeValue) {
    return {
      attribute_type: editingItem?.attributeValue?.attribute_type,
      attribute: editingItem?.attributeValue?.attribute,
      name: editingItem?.attributeValue?.name,
      unit: editingItem?.attributeValue?.unit,
      value: editingItem?.attributeValue?.value,
    };
  } else if (isWeight?.length > 0 || isMeasurement?.length > 0) {
    return {
      attribute_type: '',
      attribute: '',
      name: '',
      unit: unit,
      value: '',
    };
  } else {
    return {
      attribute_type: '',
      attribute: '',
      name: '',
      unit: '',
      value: '',
    };
  }
};
