import { CheckCircle, Warning, Draw } from '@mui/icons-material';
import { useTheme, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ProductStatusIcon = ({ status }) => {
  const theme = useTheme();
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.ProductStatusIcon',
  });

  const STATUS_ICON_MAP = {
    published: { icon: CheckCircle, color: 'success', label: t('published') },
    draft: { icon: Draw, color: 'info', label: t('draft') },
    archived: { icon: Warning, color: 'warning', label: t('archived') },
    // error: { icon: Error, color: 'error', label: t('error') },
    // default: { icon: Error, color: 'error', label: t('unknown') },
  };

  const { icon: IconComponent, color } =
    STATUS_ICON_MAP[status] || STATUS_ICON_MAP.default;

  return (
    <Tooltip
      title={STATUS_ICON_MAP[status]?.label || STATUS_ICON_MAP.default.label}
    >
      <IconComponent sx={{ color: theme.palette[color].main }} />
    </Tooltip>
  );
};

export default ProductStatusIcon;
