import { useMemo, useState, createContext, useContext } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PlaceHolderImage from '../../../../assets/core/intuPlaceHolder.png';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import UploadImageDialog from '../../dialogs/UploadImageDialog';
import TuneIcon from '@mui/icons-material/Tune';
import DraggableCarousel from '../../../mui/DraggableCarousel';
import { useFormikContext } from 'formik';
import ResponseContext from '../../../../context/providers/ResponseProvider';
import { useAxiosPrivate } from '../../../../hooks/axios/useAxiosPrivate';

export const ProductImageHeaderContext = createContext();

const ProductImageHeader = () => {
  const { t } = useTranslation('container', {
    keyPrefix: 'ProductImageHeader',
  });
  const { t: transButtons } = useTranslation('buttons');

  const { values, setValues } = useFormikContext();
  const { setStatusMsg } = useContext(ResponseContext);

  const axios = useAxiosPrivate();

  const [images, setImages] = useState([]);
  const [activeImage, setActiveImage] = useState(0);
  const [editingImage, setEditingImage] = useState(null);
  const [isUploadImageDialogOpen, setIsUploadImageDialogOpen] = useState(false);

  const filteredProductImages = useMemo(() => {
    if (!values?.images.length) return [];
    const filteredImages = values?.images.filter(
      (image) =>
        image.imageType.toLowerCase() !== 'stp' &&
        image.imageType.toLowerCase() !== 'step' &&
        image.imageType.toLowerCase() !== 'obj',
    );
    return filteredImages;
  }, [values?.images]);

  useMemo(() => {
    if (!filteredProductImages || !filteredProductImages.length) return;
    setImages(filteredProductImages);
  }, [filteredProductImages]);

  const handleEditClick = () => {
    setIsUploadImageDialogOpen(true);
    setEditingImage(images[activeImage]);
  };

  const handleClose = () => {
    setIsUploadImageDialogOpen(false);
    setEditingImage(null);
  };

  const handleConfirmNewImage = (newImage) => {
    console.log('New Image -> ', newImage);

    setValues({
      ...values,
      images: [
        ...values.images,
        {
          url: newImage.url,
          name: newImage.fileName,
          imageType: newImage.imageType,
          options: {},
          created: new Date(),
        },
      ],
    });

    setIsUploadImageDialogOpen(false);
  };

  const handleConfirmEdit = (remoteUrl, name, type, options) => {
    // No images exist yet

    const foundImage = values.images.find((image) => image.url === remoteUrl);

    if (!foundImage) {
      // We have a new image that has been edited
      setValues({
        ...values,
        images: [
          ...values.images,
          {
            url: remoteUrl,
            name,
            imageType: type,
            options: {
              ...options,
            },
            modified: new Date(),
          },
        ],
      });
    } else {
      // We are editing an existing image
      const newImages = values.images.map((image) => {
        if (image.url === remoteUrl) {
          return {
            url: remoteUrl,
            name,
            imageType: type,
            options: {
              ...options,
            },
            modified: new Date(),
          };
        }
        return image;
      });
      setValues({
        ...values,
        images: newImages,
      });
    }
    setEditingImage(null);
    setIsUploadImageDialogOpen(false);
  };

  // Set Default Image
  const handleSetDefault = (image) => {
    setValues({
      ...values,
      images: [image, ...values.images.filter((img) => img.url !== image.url)],
    });
    setEditingImage(null);
    setIsUploadImageDialogOpen(false);
  };

  const handleReSortImages = (updatedFilteredImages) => {
    setValues({
      ...values,
      images: updatedFilteredImages,
    });
  };

  // Delete Image
  const handleDeleteImage = async (file) => {
    const fileType = file.substring(file.lastIndexOf('.') + 1, file.length);
    const fileName = file.substring(file.lastIndexOf('/') + 1, file.length);
    const folder = `products/${values._id}/images/${fileType}/${fileName}`;

    const bucketPayload = {
      folder,
    };

    try {
      const { status, data } = await axios.post(
        '/api/aws/content/delete',
        bucketPayload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      if (status === 200) {
        setStatusMsg({
          open: true,
          status: data.status,
          message: data.message,
        });

        const newImages = values.images.filter((img) => img.url !== file);

        setValues({
          ...values,
          images: newImages,
        });

        setEditingImage(null);
        setIsUploadImageDialogOpen(false);
      }
    } catch (error) {
      console.error('error', error);
      setStatusMsg({
        open: true,
        status: 'error',
        message: error.message,
      });
    }
  };

  return (
    <ProductImageHeaderContext.Provider
      value={{
        images,
        setImages,
        activeImage,
        setActiveImage,
        isOpen: isUploadImageDialogOpen,
        handleClose,
        handleConfirmNewImage,
        handleConfirmEdit,
        handleDeleteImage,
        handleReSortImages,
        editingImage,
        setEditingImage,
        handleSetDefaultImage: handleSetDefault,
      }}
    >
      {images.length ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
          position="relative"
          py={10}
          className="intu__divider"
        >
          <Box
            display="flex"
            alignItems="flex-end"
            flexDirection="column"
            mr={4}
            mb={2}
          >
            <Button
              component="span"
              variant="outlined"
              startIcon={<FileUploadIcon sx={{ color: 'info.main' }} />}
              disabled={images.length > 10}
              sx={{
                textTransform: 'none',
                borderRadius: '24px',
                minWidth: 187,
                height: 40,
              }}
              color="info"
              onClick={() => setIsUploadImageDialogOpen(true)}
            >
              {transButtons('upload_img')}
            </Button>
            {images[activeImage] ? (
              <Button
                component="span"
                variant="outlined"
                startIcon={<TuneIcon />}
                disabled={images.length > 10}
                sx={{
                  textTransform: 'none',
                  mt: 2,
                  borderRadius: '24px',
                  minWidth: 187,
                  height: 40,
                }}
                color="secondary"
                onClick={handleEditClick}
              >
                {transButtons('edit_img')}
              </Button>
            ) : null}
          </Box>

          <DraggableCarousel />
        </Box>
      ) : (
        <Grid className="intu__divider" sx={{ top: 0, margin: 0 }}>
          <Grid
            item
            className="article"
            sx={{
              top: 0,
              backgroundImage: `url(${PlaceHolderImage})`,
              backgroundSize: '30%',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundAttachment: 'fixed',
              minHeight: '80vh',
            }}
          >
            <Grid
              item
              container
              direction="column"
              alignItems="center"
              textAlign="center"
            >
              <Grid item>
                <Typography
                  variant="h1"
                  className="header"
                  sx={{ color: 'white' }}
                >
                  {t('placeholder')}
                </Typography>
              </Grid>
              {/* Buttons in Edit Mode & View mode */}
              <Grid item>
                <Button
                  component="span"
                  variant="outlined"
                  startIcon={<FileUploadIcon sx={{ color: 'info.main' }} />}
                  disabled={images.length > 10}
                  sx={{
                    textTransform: 'none',
                    borderRadius: '24px',
                    minWidth: 187,
                    height: 40,
                  }}
                  color="info"
                  onClick={() => setIsUploadImageDialogOpen(true)}
                >
                  {transButtons('upload_img')}
                </Button>
              </Grid>
            </Grid>

            <Grid
              container
              direction={{ xs: 'column', md: 'row' }}
              alignItems="center"
              sx={{
                minWidth: 300,
                width: 'auto',
                height: 'auto',
              }}
            ></Grid>
          </Grid>
        </Grid>
      )}
      <UploadImageDialog images={images} productId={values._id} />
    </ProductImageHeaderContext.Provider>
  );
};

export default ProductImageHeader;
