import { useContext } from 'react';
import {
  Fade,
  Typography,
  Grid,
  Stack,
  Skeleton,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useFormikContext } from 'formik';
import IntuThemeContext from '../../../context/providers/IntuThemeProvider.jsx';
import { useTranslation } from 'react-i18next';
import { ColorPickerField } from '../../colors/ColorPickerField.jsx';
import { useProcessingHandler } from '../../../hooks/useProcessingHandler.js';

const CompanyThemeColors = ({
  fieldID = 'theme', // Field ID
  fieldTitle = 'title', // Field Title
  fieldDescription = 'description', // Field Description
  transNS = 'fields',
  transPrefix = 'account.company.CompanyThemeColors',
  transition = true, // Transition of the Field (true = fade in, false = fadeout)
  disabled = false, // Whether the field is disabled
  required = false, // Whether the field is required
}) => {
  const { transitionInterval, setUserTheme } = useContext(IntuThemeContext);
  const { isLoading } = useProcessingHandler();

  // Translation
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });
  const { t: transFields } = useTranslation('fields');
  const { t: transTypes } = useTranslation('types');

  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  return (
    <Grid container alignItems="center" direction={{ xs: 'column', lg: 'row' }}>
      <Grid item xs={12} lg={12} sx={{ width: '100%' }}>
        {fieldTitle && (
          <Fade in={transition} timeout={transitionInterval}>
            <Typography variant="h4" textAlign={{ xs: 'center', md: 'left' }}>
              {t('title')}
            </Typography>
          </Fade>
        )}
        {fieldDescription && (
          <Fade in={transition} timeout={transitionInterval}>
            <Typography
              className="form-note"
              textAlign={{ xs: 'center', md: 'left' }}
            >
              {t('description')}
            </Typography>
          </Fade>
        )}

        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={4}
          sx={{ marginTop: '0.5rem' }}
        >
          {/* Set Theme Active */}
          {isLoading.status ? (
            <Skeleton variant="rectangular" width={'100%'} height={60} />
          ) : (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values?.theme?.active}
                    onChange={(e) => {
                      let checked = e.target.checked;
                      setFieldValue('theme.active', checked);
                      setUserTheme({
                        ...values.theme,
                        active: checked,
                      });
                      setTimeout(() => {
                        setFieldTouched('theme.active', true);
                      }, 300);
                    }}
                    name="active"
                    color="primary"
                  />
                }
                label={transFields('global.active.label', {
                  type: transTypes('theme'),
                })}
              />
            </FormGroup>
          )}

          {/* Primary Color */}
          <ColorPickerField
            defaultColor={values?.theme?.primary?.main || null}
            fieldID="theme.primary.main"
            fieldName="theme.primary.main"
            transPrefix="account.company.CompanyThemeColors.primary"
            disabled={disabled}
            required={required}
            transition={transition}
            onChange={(color) => {
              setFieldValue('theme.primary.main', color);
              setFieldTouched('theme.primary.main', true, true);
              setUserTheme({
                ...values.theme,
                primary: {
                  main: color,
                },
              });
            }}
          />

          {/* Secondary Color */}
          <ColorPickerField
            defaultColor={values?.theme?.secondary?.main || null}
            fieldID="theme.secondary.main"
            fieldName="theme.secondary.main"
            transPrefix="account.company.CompanyThemeColors.secondary"
            disabled={disabled}
            required={required}
            transition={transition}
            onChange={(color) => {
              setFieldValue('theme.secondary.main', color);
              setFieldTouched('theme.secondary.main', true, true);
            }}
          />

          {/* Tertiary Color */}
          <ColorPickerField
            defaultColor={values?.theme?.secondary?.main || null}
            fieldID="theme.tertiary.main"
            fieldName="theme.tertiary.main"
            transPrefix="account.company.CompanyThemeColors.contrastColor"
            disabled={disabled}
            required={required}
            transition={transition}
            onChange={(color) => {
              setFieldValue('theme.tertiary.main', color);
              setFieldTouched('theme.tertiary.main', true, true);
            }}
          />

          {/* Text Color */}
          <ColorPickerField
            defaultColor={values?.theme?.secondary?.main || null}
            fieldID="theme.textColor"
            fieldName="theme.textColor"
            transPrefix="account.company.CompanyThemeColors.textColor"
            disabled={disabled}
            required={required}
            transition={transition}
            onChange={(color) => {
              setFieldValue('theme.textColor', color);
              setFieldTouched('theme.textColor', true, true);
            }}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default CompanyThemeColors;
