const createInitialValuesResetRequest = () => {
  return {
    email: '',
  };
};

const createInitialValuesResetConfirm = () => {
  return {
    userPass: '',
    userPassConf: '',
  };
};

export { createInitialValuesResetRequest, createInitialValuesResetConfirm };
