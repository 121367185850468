import {
  Autocomplete,
  Box,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAxiosPrivate } from '../../hooks/axios/useAxiosPrivate';

const defaultTaxCodes = [{ value: 'txcd_99999999', label: 'General' }];

const TaxCodeBox = () => {
  const { values, setValues } = useFormikContext();
  const axios = useAxiosPrivate();

  const { t: transFields } = useTranslation('fields');
  const formik = useFormikContext();
  const [taxCodes, setTaxCodes] = useState(defaultTaxCodes);

  const handleChange = (e) => {
    const { name, value } = e.target;

    formik.handleChange(e);
    setValues({
      ...values,
      [name]: value,
    });
  };

  const getTaxCodes = async () => {
    try {
      const { data, status } = await axios.get(`/api/stripe/tax/tax-codes`);
      if (status === 200) {
        setTaxCodes([...data.data]);
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  useEffect(() => {
    getTaxCodes();
  }, []);

  return (
    <Box>
      <FormControl sx={{ flex: 0.4 }}>
        <Typography mb={2}>{transFields('products.tax_code')}</Typography>
        <Autocomplete
          options={taxCodes}
          getOptionLabel={(option) => option.name_en}
          onChange={(event, newValue) => {
            handleChange({
              target: {
                name: 'tax_code',
                value: newValue ? newValue.code : '',
              },
            });
          }}
          value={
            taxCodes.find((code) => code.code === values.tax_code) ||
            taxCodes.find((code) => code.code === 'txcd_99999999') ||
            null
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Tax Code"
              error={formik.errors.tax_code && formik.touched.tax_code}
              helperText={formik.touched.tax_code && formik.errors.tax_code}
            />
          )}
          sx={{ minWidth: 460 }}
        />
      </FormControl>
    </Box>
  );
};

export default TaxCodeBox;
