export const formatCommissionForDatabase = (commissionSchedule) => {
  // Sort commissions by max_amount in ascending order
  const sortedCommissions = [...commissionSchedule].sort(
    (a, b) => a.max_amount - b.max_amount,
  );

  // Reindex the commissions
  const reFormattedCommissionSchedule = sortedCommissions.map((element) => ({
    commission: element.commission,
    max_amount: element.max_amount,
    index: element.index,
  }));

  return reFormattedCommissionSchedule;
};
