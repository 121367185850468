import {
  convertFromHTML,
  ContentState,
  convertToRaw,
  EditorState,
} from 'draft-js';

// Format for MUI Rich Text Editor
function getMuiRichTextEditorContent(value) {
  // Guard Clause
  if (!value) {
    return;
  }

  let content;
  const htmlContent = value;
  const contentHTML = convertFromHTML(htmlContent);
  const state = ContentState.createFromBlockArray(
    contentHTML.contentBlocks,
    contentHTML.entityMap,
  );
  content = convertToRaw(state);
  return content;
}

// Formats Plain Field Content -> MuiRichTextEditor Content
function formatMuiRichTextEditor(value) {
  // Guard Clause
  if (!value) {
    return;
  }

  // Create ContentState from plain text
  const contentState = ContentState.createFromText(value);

  // Convert ContentState to raw content
  const content = convertToRaw(contentState);

  return content;
}

function getRawContentState(text) {
  const contentState = ContentState.createFromText(text);
  const editorState = EditorState.createWithContent(contentState);
  return convertToRaw(editorState.getCurrentContent());
}

export {
  getMuiRichTextEditorContent,
  formatMuiRichTextEditor,
  getRawContentState,
};
