import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import CookieConsent from 'react-cookie-consent';
import { Trans, useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import useUser from '../../hooks/useUser';

// Initialize GA4 (Make sure to replace with your Measurement ID)
ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);

const CookieConsentComponent = () => {
  const { cookieSettings, setCookieSettings } = useUser();
  const { t } = useTranslation('translation', { keyPrefix: 'cookieConsent' });

  function handleChange(e, values) {
    const updatedSettings = {
      ...cookieSettings,
      [e.target.name]: values,
    };

    setCookieSettings(updatedSettings);
    handleGoogleConsent(updatedSettings); // Trigger consent handling
  }

  // Function to handle Google Consent Mode
  const handleGoogleConsent = (settings) => {
    if (window.gtag) {
      const ad_storage = settings.trck ? 'granted' : 'denied';
      const ad_user_data = settings.trck ? 'granted' : 'denied';
      const ad_personalization = settings.exp ? 'granted' : 'denied';
      const analytics_storage = settings.exp ? 'granted' : 'denied';

      window.gtag('consent', 'update', {
        ad_storage,
        analytics_storage,
        ad_user_data,
        ad_personalization,
      });

      if (settings.exp) {
        ReactGA.send('pageview');
      }
    }
  };

  // Initialize Consent Mode when settings are loaded
  useEffect(() => {
    handleGoogleConsent(cookieSettings);
  }, [cookieSettings]);

  return (
    <Grid>
      <Typography variant="body2">
        <Trans
          t={t}
          i18nKey="disclaimer"
          components={{
            anchorLink: <a href="/privacy/#5"></a>,
            spanText: <span style={{ color: 'var(--intu-darkGreen)' }}></span>,
          }}
        >
          We are using Cookies for an improved user experience such as staying
          signed in, or for showing you sales reps in your area. For detailed
          information about our use of cookies click{' '}
          <anchorLink>
            <spanText>here</spanText>
          </anchorLink>
        </Trans>
      </Typography>
      <FormControlLabel
        control={
          <Switch
            name="auth"
            checked={cookieSettings.auth}
            onChange={(e, values) => handleChange(e, values)}
          />
        }
        label={
          <Typography variant="body2">
            {i18n.format(t('auth'), 'capitalize')}
          </Typography>
        }
      />
      <FormControlLabel
        control={
          <Switch
            name="exp"
            checked={cookieSettings.exp}
            onChange={(e, values) => handleChange(e, values)}
          />
        }
        label={
          <Typography variant="body2">
            {i18n.format(t('exp'), 'capitalize')}
          </Typography>
        }
      />
      <FormControlLabel
        control={
          <Switch
            name="trck"
            checked={cookieSettings.trck}
            onChange={(e, values) => handleChange(e, values)}
          />
        }
        label={
          <Typography variant="body2">
            {i18n.format(t('marketing'), 'capitalize')}
          </Typography>
        }
      />
    </Grid>
  );
};

export const CookieConsentContainer = () => {
  const { cookieSettings } = useUser();

  return (
    <CookieConsent
      location="bottom"
      buttonText="Save"
      cookieName="intu_cookie_settings"
      cookieValue={`auth=${cookieSettings.auth};exp=${cookieSettings.exp};trck=${cookieSettings.trck}`}
      overlay
      style={{
        background: 'var(--cookie-consent-background)',
        color: 'var(--cookie-context-color)',
      }}
      buttonStyle={{
        backgroundColor: 'var(--intu-darkGreen)',
        color: 'var(--intu-darkGrey)',
        fontSize: '13px',
      }}
      expires={150}
      extraCookieOptions={{ domain: '.in2tec.io' }}
    >
      <CookieConsentComponent />
    </CookieConsent>
  );
};
