import React, { useContext } from 'react';
import { CompanyProfileContext } from '../../../../../context/company/CompanyProfileProvider';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import RegularTextField from '../../../../fields/text/RegularTextField';

const CompanyDetailsTab = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.account.company.CompanyDetailsTab',
  });
  const { companyProfile } = useContext(CompanyProfileContext);

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Box>
        <RegularTextField
          fieldTitle={t('taxid.title')}
          fieldDescription={t('taxid.description')}
          fieldID="tax_id"
          transPrefix="CompanyTaxIDfield"
          transTypeID="tax_id"
          required={true}
          disabled={!companyProfile.isAdmin}
        />
      </Box>
    </Box>
  );
};

export default CompanyDetailsTab;
