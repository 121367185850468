import { useContext } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  useTheme,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AttributeContainerContext } from '../../container/AttributeContainer';

const AttributeTypeSelection = () => {
  const theme = useTheme();
  const { t } = useTranslation('container', {
    keyPrefix: 'AttributeTypeSelection',
  });
  const {
    selectedAttributeType,
    setSelectedAttributeType,
    setSelectedAttribute,
    setSelectedAttributeValue,
    productAttributeTypeOptions,
  } = useContext(AttributeContainerContext);

  const handleListItemClick = (e, attributeType) => {
    e.stopPropagation();
    setSelectedAttributeType(attributeType);
    setSelectedAttribute('');
    setSelectedAttributeValue('');
  };

  return (
    <Box flex={1} display="flex" flexDirection="column">
      <Box display="flex" flexDirection="column">
        {/* Section Title */}
        <Box>
          <Typography variant="body" textTransform="none" p={1}>
            {t('title')}
          </Typography>
        </Box>

        {/* Search Field (Placeholder) */}
        <Box
          sx={{ height: '3rem', pb: '1rem' }}
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          className="intu__divider"
        />

        {/* Filter (Placeholder) */}
        <Box sx={{ marginTop: '0.5rem', minHeight: '1.5rem' }} />

        {/* List */}
        <Box
          sx={{
            marginTop: '1rem',
            flex: 1,
            overflowY: 'auto',
            maxHeight: '500px',
            paddingRight: '8px',
          }}
        >
          <List>
            {productAttributeTypeOptions.map((attribute, index) => {
              const isSelected = selectedAttributeType === attribute;
              return (
                <ListItem
                  key={index}
                  component="button"
                  onMouseDown={(e) => handleListItemClick(e, attribute)}
                  sx={{
                    background: isSelected && theme.palette.primary.main,
                    border: '1px solid',
                    borderRadius: '10px',
                    margin: '10px 0',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'center',
                    padding: '4px 0',
                    transform: isSelected
                      ? 'translateY(0px)'
                      : 'translateY(-2px)',
                    transition: 'all 0.15s ease-in-out',
                    '&:hover': {
                      color: 'white',
                      background: theme.palette.secondary.main,
                      transform: isSelected
                        ? 'translateY(0px)'
                        : 'translateY(-4px)',
                    },
                    '&:active': {
                      background: theme.palette.secondary.main,
                      transform: 'translateY(0px)',
                    },
                  }}
                >
                  <ListItemText
                    primary={attribute.name}
                    primaryTypographyProps={{
                      sx: {
                        color: isSelected
                          ? 'white'
                          : (theme) => theme.palette.text.primary,

                        '&:hover': {
                          color: 'white', // ensure it matches your hover background
                        },
                      },
                    }}
                  />
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export default AttributeTypeSelection;
