import React, { useContext, useRef } from 'react';

// Formik
import { Formik, useFormikContext } from 'formik';

// Response Habndling
import useResponseHandling from '../../../hooks/useResponseHandler.js';

// Translation
import { useTranslation } from 'react-i18next';

// Maeterial UI
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from '@mui/material';
import { classes } from '../../../settings/theme.js';

// Context
import { useAxiosPrivate } from '../../../hooks/axios/useAxiosPrivate.js';
import { createChangeUserPasswordValidationSchema } from './ValidationSchema.js';
import { createChangeUserPasswordInitialValues } from './InitialValues.js';
import IntuIconButton from '../../buttons/IntuIconButton.jsx';
import { SecurityTabContext } from '../../users/tabs/SecurityTab.jsx';

const ChageUserPasswordDialog = () => {
  const { changePasswordDialog, setChangePasswordDialog } =
    useContext(SecurityTabContext);

  //   Axios SetUp
  const axios = useAxiosPrivate();

  //   Set Host URL
  const host_url = window.location.host;

  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();

  const { t } = useTranslation('dialogs', {
    keyPrefix: 'ChangeUserPasswordDialog',
  });

  const { t: transButtons } = useTranslation('buttons');
  const { t: transTypes } = useTranslation('types');

  // Set Up Form refs
  const errRef = useRef();
  const formRef = useRef(null);

  const { values: parentFormValues } = useFormikContext();

  const initialValues = createChangeUserPasswordInitialValues(parentFormValues);
  const validationSchema = createChangeUserPasswordValidationSchema();

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount={true}
        validateOnChange={true}
        enableReinitialize={true}
        innerRef={formRef}
      >
        {(formik) => {
          const {
            values,
            setFieldValue,
            errors,
            touched,
            handleBlur,
            setValues,
            isValid,
          } = formik;
          return (
            <Dialog maxWidth="sm" fullWidth open={changePasswordDialog}>
              <form>
                <Typography variant="dialogBoxTitle">{t('title')}</Typography>
                <DialogContent>
                  <Typography variant="dialogBoxContent">
                    {t('description')}
                  </Typography>

                  {/* Existing Password */}
                  <TextField
                    required
                    style={classes.root}
                    id="userPassExisting"
                    name="userPassExisting"
                    className="form-select-field"
                    margin="dense"
                    aria-invalid={errors.userPassExisting ? 'true' : 'false'}
                    aria-describedby="uidnote"
                    variant="standard"
                    label={t('form.userPassExisting.label')}
                    type="password"
                    placeholder={t('form.userPassExisting.placeholder')}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(e.target.name, e.target.value);
                    }}
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    value={values.userPassExisting}
                    error={
                      errors.userPassExisting && touched.userPassExisting
                        ? true
                        : false
                    }
                    helperText={
                      errors.userPassExisting && touched.userPassExisting
                        ? errors.userPassExisting
                        : null
                    }
                  />

                  {/* New Password */}
                  <TextField
                    required
                    style={classes.root}
                    id="userPassNew"
                    name="userPassNew"
                    className="form-select-field"
                    margin="dense"
                    aria-invalid={errors.userPassNew ? 'true' : 'false'}
                    aria-describedby="uidnote"
                    variant="standard"
                    label={t('form.userPassNew.label')}
                    type="password"
                    placeholder={t('form.userPassNew.placeholder')}
                    onChange={(e) => {
                      setFieldValue(e.target.name, e.target.value);
                    }}
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    onBlur={handleBlur}
                    value={values.userPassNew}
                    error={
                      errors.userPassNew && touched.userPassNew ? true : false
                    }
                    helperText={
                      errors.userPassNew && touched.userPassNew
                        ? errors.userPassNew
                        : null
                    }
                  />

                  {/* New Password Confirmation */}
                  <TextField
                    required
                    id="userPassConf"
                    name="userPassConf"
                    className="form-select-field"
                    margin="dense"
                    aria-invalid={errors.userPassConf ? 'true' : 'false'}
                    aria-describedby="uidnote"
                    variant="standard"
                    label={t('form.userPassConfrm.label')}
                    type="password"
                    placeholder={t('form.userPassConfrm.placeholder')}
                    onChange={(e) => {
                      setFieldValue(e.target.name, e.target.value);
                    }}
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    onBlur={handleBlur}
                    value={values.userPassConf}
                    error={
                      errors.userPassConf && touched.userPassConf ? true : false
                    }
                    helperText={
                      errors.userPassConf && touched.userPassConf
                        ? errors.userPassConf
                        : null
                    }
                  />
                </DialogContent>
                <DialogActions>
                  <Box
                    display="flex"
                    flexDirection="row"
                    sx={{ width: '100%' }}
                  >
                    {/* Cancel */}
                    <Box>
                      <IntuIconButton
                        variant="outlined"
                        type="cancel"
                        color="error"
                        tooltipTitle={transButtons('cancel_type', {
                          type: transTypes('password_change'),
                        })}
                        onClick={() => {
                          setValues({
                            ...values,
                            userPassNew: '',
                            userPassExisting: '',
                            userPassConf: '',
                            show_password: false,
                            togglePasswordOpen: false,
                          });
                          setChangePasswordDialog(false);
                        }}
                      />
                    </Box>

                    {/* Confirm Button */}
                    <Box sx={{ marginLeft: 'auto' }}>
                      <IntuIconButton
                        type="submit"
                        variant="contained"
                        color="success"
                        tooltipTitle="change_pw"
                        disabled={
                          errors?.userPassExisting ||
                          errors?.userPassNew ||
                          errors?.userPassConf
                            ? true
                            : false
                        }
                        onClick={async (e) => {
                          const url = '/api/users/account/change/password';
                          const payload = {
                            existing_password: values.userPassExisting,
                            new_password: values.userPassNew,
                            host_url: host_url,
                          };

                          try {
                            const response = await axios.post(
                              url,
                              JSON.stringify(payload),
                              {
                                headers: { 'Content-Type': 'application/json' },
                                withCredentials: true,
                              },
                            );
                            const data = response.data;

                            if (response.status === 200) {
                              setValues(initialValues);

                              setChangePasswordDialog(false);

                              handleRegularResponse({
                                open: true,
                                status: data.status,
                                message: data.message,
                              });
                            } else {
                              handleRegularResponse({
                                open: true,
                                status: data.status,
                                message: data.message,
                              });
                            }
                          } catch (err) {
                            handleErrorResponse(err);
                            errRef.current?.focus();
                          }
                        }}
                      />
                    </Box>
                  </Box>
                </DialogActions>
              </form>
            </Dialog>
          );
        }}
      </Formik>
    </>
  );
};

export default ChageUserPasswordDialog;
