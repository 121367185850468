import React, { useContext, useRef } from 'react';
import { Autocomplete, Skeleton, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import FormikFieldWrapper from '../../formik/FormikFieldWrapper';
import ProcessingContext from '../../../context/providers/ProcessingProvider';
import { getNestedValue } from '../../../helpers/getNestedValuesIn';

const UnitSelectionField = ({
  fieldTitle, // Title above the field
  fieldDescription, // Field description above the field
  options = [],
  fieldID = 'unit', // Defines the ID and Name of the Field
  transition = true, // Whether the field is being shown and faded in
  disabled = false, // Disables the field
  required = false, // Whether the field is required or not
  transNS = 'fields', // Namespace for translation Value
  transPrefix = 'UnitSelectionField', // KeyPrefix which holds label and placeholder
  transTypeID = null, // Defines The Translation Type e.g. User, Company
  isLoadingManual = false, // Manually control the Loading State
  defaultCurrency = null, // ISO 3 Code of Default Currency
  variant = 'outlined',
  inputprops = null,
  ...props
}) => {
  // Translation
  const { t: transFields } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  const { t: transTypes } = useTranslation('types');

  // Field Ref
  const unitSelectionRef = useRef();

  // Context
  const { values, setFieldValue, touched, errors, handleBlur } =
    useFormikContext();

  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  const { isLoading } = useContext(ProcessingContext);

  return isLoading.status || isLoadingManual ? (
    <Skeleton variant="rectangular" width={'100%'} height={60} />
  ) : (
    <FormikFieldWrapper
      fieldTitle={fieldTitle}
      fieldDescription={fieldDescription}
      transition={transition}
    >
      <Autocomplete
        disabled={disabled}
        options={options}
        defaultValue={options[0]}
        getOptionLabel={(option) => option}
        value={fieldValue || null}
        onChange={(event, newValue) => {
          setFieldValue(fieldID, newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name={fieldID}
            id={fieldID}
            variant={variant}
            label={transFields('label', { type: transTypes(transTypeID) })}
            placeholder={transFields('placeholder', {
              type: transTypes(transTypeID),
            })}
            onBlur={(e) => handleBlur(e)}
            InputProps={{
              ...params.InputProps,
              ...inputprops,
            }}
            error={fieldError && fieldTouched ? true : false}
            helperText={fieldError && fieldTouched ? fieldError : null}
            required={required}
          />
        )}
        ref={unitSelectionRef}
        sx={{ width: '100%' }}
        {...props}
      />
    </FormikFieldWrapper>
  );
};

export default UnitSelectionField;
