import { useContext } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Accordion,
  Typography,
  AccordionDetails,
  Box,
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AttributeContainerContext } from '../container/AttributeContainer';
import AttributeTypeSelection from './selection/AttributeTypeSelection';
import AttributeSelection from './selection/AttributeSelection';
import AttributeValueSelection from './selection/AttributeValueSelection';
import IntuIconButton from '../../buttons/IntuIconButton';
import { useFormikContext } from 'formik';
import IntuThemeContext from '../../../context/providers/IntuThemeProvider';

const AddAttributeDialog = () => {
  const { t: transDialog } = useTranslation('dialogs', {
    keyPrefix: 'AddAttributeDialog',
  });
  const { t: transButtons } = useTranslation('buttons');

  const {
    isAddDialogOpen,
    setIsAddDialogOpen,
    selectedAttributeType,
    setSelectedAttributeType,
    selectedAttribute,
    setSelectedAttribute,
    selectedAttributeValue,
    setSelectedAttributeValue,
    setSelectedAttributes,
    setProductAttributeOptions,
  } = useContext(AttributeContainerContext);

  const { values, setValues } = useFormikContext();

  const { mobileView } = useContext(IntuThemeContext);

  const handleNewAttributeConfirm = () => {
    const newAttribute = {
      attribute_type: selectedAttributeType._id,
      attribute: selectedAttribute._id,
      name: selectedAttribute.name,
      values: [selectedAttributeValue],
    };

    setSelectedAttributes((prevState) => {
      const newAttributes = new Set(prevState);
      newAttributes.add(newAttribute);
      return newAttributes;
    });

    setProductAttributeOptions((prevState) => [...prevState, newAttribute]);
    setValues({
      ...values,
      product_attributes: [...values.product_attributes, newAttribute],
    });

    handleAttributeDialogClose();
  };

  const handleAttributeDialogClose = () => {
    setSelectedAttribute(null);
    setSelectedAttributeType(null);
    setSelectedAttributeValue(null);
    setIsAddDialogOpen(false);
  };

  return (
    <Dialog
      open={isAddDialogOpen}
      onClose={() => setIsAddDialogOpen(false)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>{transDialog('title')}</DialogTitle>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
          {transDialog('description')}
        </Typography>
        <Accordion>
          <AccordionDetails>
            <Box
              display={!mobileView ? 'flex' : null}
              alignItems="stretch"
              justifyContent="space-evenly"
              gap={2}
            >
              {/* Atribute Type Selection */}
              <AttributeTypeSelection />

              {/* Attribute Selection */}
              <AttributeSelection />

              {/* Value Selection */}
              <AttributeValueSelection />

              <Box alignSelf="center"></Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions
        style={{
          paddingBottom: '16px',
          paddingRight: '16px',
          paddingLeft: '16px',
        }}
      >
        <Grid container direction="row">
          <Grid item>
            <IntuIconButton
              type="cancel"
              variant="outlined"
              tooltipTitle={transButtons('cancel')}
              onClick={handleAttributeDialogClose}
            />
          </Grid>

          {/* Confirm Button */}
          <Grid item sx={{ marginLeft: 'auto' }}>
            <IntuIconButton
              disabled={
                !selectedAttributeType ||
                !selectedAttribute ||
                !selectedAttributeValue
              }
              tooltipTitle={transButtons('confirm')}
              type="submit"
              variant="outlined"
              sx={{
                marginLeft: 'auto',
              }}
              onClick={handleNewAttributeConfirm}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default AddAttributeDialog;
