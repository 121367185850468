import { Grid, Stack } from '@mui/material';
import ReferralContainer from '../container/ReferralContainer';

const ReferralTabContent = () => {
  return (
    <Grid container direction="column">
      <Stack spacing={3}>
        <Grid item xs={12}>
          <ReferralContainer />
        </Grid>
      </Stack>
    </Grid>
  );
};

export default ReferralTabContent;
