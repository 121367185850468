// import { useAxiosPrivate } from "../hooks/axios/useAxiosPrivate";
import axios from '../../hooks/axios/useAxios.js';
import i18n from '../../i18n.js';
import { axiosPrivate } from '../../hooks/axios/axios.js';

async function getProductCategories() {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/product-categories/families/list';

  try {
    const { status, data } = await axios.get(url, {
      signal,
      headers: { 'Content-Type': 'application/json' },
    });

    if (status === 200) {
      return {
        data: data.data,
        status: data.status,
        message: data.message,
      };
    } else {
      return {
        status: data.status,
        message: data.message,
      };
    }
  } catch (err) {
    console.error(err);
    return err;
  }
}

async function getProductGroups() {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/product-categories/groups/list';

  try {
    const { status, data } = await axios.get(url, {
      signal,
      headers: { 'Content-Type': 'application/json' },
    });

    if (status === 200) {
      return {
        data: data.data,
        status: data.status,
        message: data.message,
      };
    } else {
      return {
        status: data.status,
        message: data.message,
      };
    }
  } catch (err) {
    console.error(err);
    return err;
  }
}

async function searchProductGroups(payload) {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/product-categories/groups/search';

  // Guard Clause
  const req_fields = [];
  !payload && req_fields.push(i18n.t('routes.req_fields'));

  if (req_fields.length > 0) {
    throw new Error(
      i18n.t('validation.missing_fields', { missingFields: req_fields }),
    );
  }

  try {
    const { data, status } = await axios.post(url, JSON.stringify(payload), {
      signal,
      headers: { 'Content-Type': 'application/json' },
    });

    if (status === 200) {
      return {
        data: data.data,
        status: data.status,
        message: data.message,
      };
    } else {
      return {
        status: data.status,
        message: data.message,
      };
    }
  } catch (err) {
    console.error(err);
    return err;
  }
}

async function getProductTypes() {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/product-categories/types/list';

  try {
    const { status, data } = await axios.get(url, { signal });
    if (status === 200) {
      return {
        data: data.data,
        status: data.status,
        message: data.message,
      };
    } else {
      return {
        status: data.status,
        message: data.message,
      };
    }
  } catch (err) {
    console.error(err);
    return err;
  }
}

async function addProductTypes(newProductTypes) {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/product-categories/types/add';

  const req_fields = [];
  !newProductTypes &&
    req_fields.push(
      i18n.t('types.new', { type: i18n.t('types.product_types') }),
    );
  !Array.isArray(newProductTypes) &&
    req_fields.push(
      i18n.t('validation.req_data_type', {
        dataType: i18n.t('types.data.array'),
      }),
    );

  if (req_fields.length > 0) {
    throw new Error(
      i18n.t('validation.missing_fields', { missingFields: req_fields }),
    );
  }

  try {
    const payload = {
      data: newProductTypes.map((productType) => {
        const { family_name, ...newProductTypeObj } = Object.assign(
          {},
          productType,
        );
        return newProductTypeObj;
      }),
    };
    const { status, data } = await axiosPrivate.post(
      url,
      JSON.stringify(payload),
      {
        signal,
      },
    );

    console.log('API Response -> ', status, data);

    if (status === 200) {
      return {
        data: data.data,
        status: data.status,
        statusCode: data.statusCode,
        message: data.message,
      };
    } else {
      return {
        status: data.status,
        statusCode: data.statusCode,
        message: data.message,
        data: null,
      };
    }
  } catch (err) {
    console.error(err);
    return err;
  }
}

async function searchProductTypes(payload) {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/product-categories/types/search';

  // Guard Clause
  const req_fields = [];
  !payload && req_fields.push(i18n.t('routes.req_fields'));

  if (req_fields.length > 0) {
    throw new Error(
      i18n.t('validation.missing_fields', { missingFields: req_fields }),
    );
  }

  try {
    const { data, status } = await axios.post(url, JSON.stringify(payload), {
      signal,
      headers: { 'Content-Type': 'application/json' },
    });

    if (status === 200) {
      return {
        data: data.data,
        status: data.status,
        message: data.message,
      };
    } else {
      return {
        status: data.status,
        message: data.message,
      };
    }
  } catch (err) {
    console.error(err);
    return err;
  }
}

async function createNewProduct() {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/products/create';

  try {
    const { status, data } = await axiosPrivate.post(url, {
      signal,
      headers: { 'Content-Type': 'application/json' },
    });

    return data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

async function updateProduct({ id = null, payload = {} }) {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/products/update/';

  // console.log('Update ID -> ', id);
  // console.log('Payload -> ', payload);

  try {
    const { status, data } = await axiosPrivate.put(url, payload, {
      params: { id },
      headers: { 'Content-Type': 'application/json' },
      signal,
    });

    return data;
  } catch (err) {
    console.error(err);
    return err;
  } finally {
    controller.abort(signal);
  }
}

async function searchProducts(query) {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/products/search';

  // Guard Clause
  const req_fields = [];
  !query && req_fields.push(i18n.t('routes.req_fields'));

  if (req_fields.length > 0) {
    throw new Error(
      i18n.t('validation.missing_fields', { missingFields: req_fields }),
    );
  }
  try {
    const { data } = await axios.get(url, {
      params: query,
      signal,
      headers: { 'Content-Type': 'application/json' },
    });

    return data;
  } catch (err) {
    console.error(err);
    return err;
  } finally {
    controller.abort(signal);
  }
}

// Returns Products of the Company or all for Admin
async function getAllProducts() {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/products/list';

  try {
    const { data } = await axiosPrivate.get(url, { signal });

    return data;
  } catch (err) {
    return err;
  }
}

// Get Publised Products
async function getPublishedProducts({ limit = 10, page = 1 }) {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/products/published';

  try {
    const { data } = await axiosPrivate.get(url, {
      params: { limit, page },
      signal,
    });

    return data;
  } catch (err) {
    return err;
  }
}

// Get featured Products
async function getFeaturedProducts({ limit = 10, page = 1 }) {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/products/featured';

  try {
    const { data } = await axiosPrivate.get(url, {
      params: { limit: limit, page: page },
      signal,
    });

    return data;
  } catch (err) {
    return err;
  }
}

async function addSingleProductType({
  productGroup,
  productFamily,
  newProductType,
}) {
  const payload = {
    productGroup,
    productFamily,
    newProductType,
  };

  try {
    const response = await axiosPrivate.post(
      '/api/product-categories/types/add-single',
      JSON.stringify(payload),
      {
        headers: { 'Content-Type': 'application/json' },
      },
    );
  } catch (error) {
    console.error('error', error);
  }
}

async function searchProductGroup(payload) {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/product-categories/groups/search';

  // Guard Clause
  const req_fields = [];
  !payload && req_fields.push(i18n.t('routes.req_fields'));

  if (req_fields.length > 0) {
    throw new Error(
      i18n.t('validation.missing_fields', { missingFields: req_fields }),
    );
  }

  try {
    const { status, data } = await axios.post(url, JSON.stringify(payload), {
      signal,
      headers: { 'Content-Type': 'application/json' },
    });

    if (status === 200) {
      return {
        data: data.data,
        status: data.status,
        message: data.message,
      };
    } else {
      return {
        status: data.status,
        message: data.message,
      };
    }
  } catch (error) {
    console.error(error);
    return error;
  }
}

async function getProductDetailsById({ id }) {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/products/details';

  // console.log('Getting Product -> ', id);

  try {
    const { data } = await axiosPrivate.get(url, {
      params: { id },
      signal,
    });

    return data;
  } catch (error) {
    console.error('error', error);
    return error;
  } finally {
    controller.abort(signal);
  }
}

async function getPublicProductById(id) {
  try {
    const { data, status } = await axios.get(`/api/products/public/${id}`);
    if (status === 200) {
      return data.data;
    }
    return null;
  } catch (error) {
    console.error('error', error);
    return null;
  }
}

async function getProducRepresentativesById(id) {
  try {
    const { data, status } = await axios.get(
      `/api/users/product-representatives/${id}`,
    );
    if (status === 200) {
      return data.data;
    }
    return [];
  } catch (error) {
    console.error('error', error);
    return [];
  }
}

export {
  createNewProduct,
  updateProduct,
  getProductCategories,
  getProductGroups,
  searchProductGroups,
  getProductTypes,
  addProductTypes,
  searchProductTypes,
  getAllProducts,
  addSingleProductType,
  searchProducts,
  searchProductGroup,
  getProductDetailsById,
  getPublicProductById,
  getProducRepresentativesById,
  getPublishedProducts,
  getFeaturedProducts,
};
