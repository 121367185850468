import { useContext } from 'react';
import { StripeContext } from '../../../context/providers/stripe/StripeContextProvider';
import { Grid } from '@mui/material';
import StripeBalance from './balanace/StripeBalanace';
import PayoutAccountsList from './payoutAccounts/PayoutAccountsList';
import { AccountRequirements } from './AccountRequirements';
import PersonsList from './person/PersonsList';
import StripeBalanceTransactionsList from './balanace/StripeBalanceTransactionsList';
import ChargesList from './charges/ChargesList';
import { useTranslation } from 'react-i18next';
import { IntuForm } from '../../layout/IntuForm';

const MerchantSettingsDashboard = () => {
  const { stripeUserData } = useContext(StripeContext);
  // console.log('Stripe User Data ->', stripeUserData);

  const { t } = useTranslation('translation', {
    keyPrefix: 'components.account.merchantSettings.MerchantSettingsDashboard',
  });

  const requirements = stripeUserData?.stripe_connect_data?.currently_due || [];

  return (
    <IntuForm formDescription={t('description')} hCentered>
      <Grid>
        {/* Account Requirements */}
        <AccountRequirements requirements={requirements} />

        {/* Account Balanace */}
        <StripeBalance stripeUserData={stripeUserData} />

        {/* Account Transactions */}
        <StripeBalanceTransactionsList />

        {/* List of Payout Accounts */}
        <PayoutAccountsList />

        {/* List of Persons */}
        <PersonsList />
      </Grid>
    </IntuForm>
  );
};

export { MerchantSettingsDashboard };
