import { Paper, Typography, Grid, Button } from '@mui/material';
import LinearProgressWithLabel from '../../../formik/LinearProgressWithLabel';
import { useTranslation } from 'react-i18next';
import formatString from '../../../../hooks/useStringFormat';

const CompletionStatusContainer = ({
  message,
  progress = 0, // Numeric Progress Value
  cta = () => {}, // Call To Cation Functions
  missingFields = [], // List of Fiuelds in an Array of Strings
  requirementsLabel = 'missing_fields',
  transPrefix = 'CompletionStatusContainer',
  transNS = 'container',
  type = 'user',
  component = null,
  disableCTA = false,
}) => {
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  const { t: trasnButtons } = useTranslation('buttons');

  const { t: transTypes } = useTranslation('types');

  // Guard Clause
  if (!message || !cta) {
    return null;
  }

  return (
    <Grid
      item
      xs={12}
      container
      sx={{ marginBottom: '2rem', position: 'relative' }}
    >
      <Paper
        elevation={20}
        sx={{
          background: 'transparent',
          padding: '1rem',
          width: '100%',
          textAlign: 'left',
        }}
      >
        <Grid container direction="column" spacing={2}>
          {message ? (
            <Grid item xs={12}>
              <Typography variant="body1" align="left">
                {message}
              </Typography>
            </Grid>
          ) : null}

          <Grid
            item
            xs={12}
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {progress > 0 && (
              <Grid item xs>
                <LinearProgressWithLabel value={progress} />
              </Grid>
            )}

            <Grid item sx={{ marginLeft: 'auto' }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={cta}
                disabled={disableCTA}
              >
                {trasnButtons('complete')}
              </Button>
            </Grid>
          </Grid>
          {missingFields && (
            <Grid container item alignItems="center">
              {requirementsLabel ? (
                <Typography
                  variant="h6"
                  color="text.primary"
                  textTransform="none"
                >
                  {t(requirementsLabel, { type: transTypes(type) })}
                </Typography>
              ) : null}

              <Grid container spacing={2}>
                {missingFields.map((field, i) => {
                  return (
                    <Grid item key={`${field}-${i}`}>
                      <Typography variant="body1" color="text.secondary">
                        {field?.description}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          )}
          {component ? (
            <Grid container item alignItems="center">
              {component}
            </Grid>
          ) : null}
        </Grid>
      </Paper>
    </Grid>
  );
};

export default CompletionStatusContainer;
