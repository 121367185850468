import React, { createContext } from 'react';
import { useState, useEffect, useRef, useContext } from 'react';
import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard.jsx';

// Global Functions
import { toTitleCase } from '../../../helpers/toTitleCase.js';

// Formik
import { Formik, Form, useFormik } from 'formik';
import * as Yup from 'yup';

// MUI Compoents
import {
  Typography,
  Skeleton,
  Stack,
  FormControl,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  MenuItem,
  TextField,
  FormControlLabel,
  Switch,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { classes } from '../../../settings/theme.js';
import { stateToHTML } from 'draft-js-export-html';

// Icons
import LinkIcon from '@mui/icons-material/Link';
import SendIcon from '@mui/icons-material/Send';
import SourceIcon from '@mui/icons-material/Source';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

// Response & Loading Handlers
import useResponseHandling from '../../../hooks/useResponseHandler.js';

import { Link } from 'react-router-dom';

import { IntuRichTextEditor } from '../../../components/IntuRichTextEditor/IntuRichtTextEditor.js';

// Import Language support
import { useTranslation } from 'react-i18next';

import { axiosPrivate } from '../../../hooks/axios/axios.js';

import { getMuiRichTextEditorContent } from '../../../helpers/muiRichtTextEditor.js';

import IndividualFirstNameField from '../../../components/account/individual/IndividualFirstNameField.jsx';
import IndividualLastNameField from '../../../components/account/individual/IndividualLastNameField.jsx';
import { AssociateActivityDialog } from '../dialogs/AssociateActivityDialog.jsx';
import { AssociateActivity } from '../lists/AssociateActivity.jsx';
import { LeadActivity } from '../lists/LeadActivityList.jsx';
import { IntuCompanyContext } from '../../../context/providers/IntuCompanyInfoProvider.jsx';
import AddressField from '../../../components/address/AddressField.jsx';
import PhoneField from '../../../components/account/PhoneField .jsx';
import EmailField from '../../../components/account/fields/email/EmailField.jsx';
import { useProcessingHandler } from '../../../hooks/useProcessingHandler.js';
import IntuIconButton from '../../../components/buttons/IntuIconButton.jsx';
import SingleLanguageSelectionfield from '../../../components/language/SingleLanguageSelectionField.jsx';
import { CompanyBanner } from '../../../components/account/company/CompanyBanner.jsx';
import { ManageLeadContext } from '../../../context/providers/leads/ManageLeadContextProvider.jsx';
import useAuth from '../../../hooks/useAuth.js';
import { useAnalyticsEventTracker } from '../../../hooks/useAnalyticsTracker.jsx';
import { blankLeadForm } from '../../../context/providers/leads/blankLeadForm.js';
import UserContext from '../../../context/users/UserInfoProvider.jsx';
import IndustriesField from '../../industries/IndustryField.jsx';
import AssociateSelectField from '../../associate/AssociateSelectField.jsx';
import BusinessNameTextField from '../../account/business/BusinessNameTextField.jsx';
import LeadTypeTypeField from '../fields/LeadTypeSelectField.jsx';
import InvestmentFirmTypeSelect from '../fields/InvestmentFirmTypeSelectField.jsx';
import WebsiteField from '../../account/WebsiteField.jsx';

// Context
const MapContext = createContext();

// Lead Form
const LeadDetailsForm = () => {
  // Use Context
  const { leadInfo, setLeadInfo } = useContext(ManageLeadContext);
  const { employeeOptions } = useContext(IntuCompanyContext);

  const { lng } = useContext(UserContext);

  const { gaEventTracker } = useAnalyticsEventTracker();
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const {
    setActivityDialog,
    leadDetailsDialog,
    setLeadDetailsDialog,
    saveLead,
  } = useContext(ManageLeadContext);

  // Translator & Language
  const { t } = useTranslation();
  const { t: transTypes } = useTranslation('types');
  const { t: transFields } = useTranslation('fields');
  const { t: transButtons } = useTranslation('buttons');
  const { t: transDialogs } = useTranslation('dialogs');
  const { t: transValidations } = useTranslation('validation');

  const [mapRendered, setMapRendered] = useState(false);

  const { auth } = useAuth();

  // Copy Link to clipboard
  const { copy } = useCopyToClipboard();

  // Navigation
  const host_url = window.location.host;

  // Fetch Intro
  const [fetchHeadline, setFetchHeadline] = useState(false);
  const [fetchIntro, setFetchIntro] = useState(false);

  // Set Up Form refs
  const errRef = useRef();
  const headlineTitleRef = useRef();
  const introTitleRef = useRef();

  useEffect(() => {
    if (headlineTitleRef.current) {
      headlineTitleRef.current.focus();
    }
  }, [fetchHeadline]);

  // Initial Values
  const formik = useFormik({
    initialValues: {},
    initialTouched: {},
    values: {},
  });

  formik.initialValues = {
    ...leadInfo,
  };

  // Initial Values & Field Validation
  let validationSchema = Yup.object();

  // Modal Form Dependend Validation
  if (leadDetailsDialog?.lead_type === 'investor') {
    // Lead Type Depended Initial Values
    formik.initialValues.lead_type = 'investor';

    // Validation
    validationSchema = Yup.object().shape({
      investorLeadDialogOpen: Yup.boolean(),
      associate: Yup.object({
        _id: Yup.string().required(t('form.associate_required')),
      }),
      company: Yup.string().required(t('validation.company.name_required')),
      lead_type: Yup.string().required(t('validation.lead.type_required')),
      company_type: Yup.string().required(
        t('validation.company.type_required'),
      ),
      headline_title: Yup.string().required(
        t('validation.intro.title_required'),
      ),
      headline_body: Yup.string().required(t('validation.intro.text_required')),
      email: Yup.string().when('intro_type', {
        is: 'Email',
        then: () =>
          Yup.string()
            .required(t('validation.email.intro_required'))
            .matches(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              t('form.email.invalid'),
            ),
      }),
      website: Yup.string()
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          transValidations('website_invalid'),
        )
        .url(transValidations('website_invalid')),
      intro_subject: Yup.string().when('intro_type', {
        is: 'Email',
        then: () =>
          Yup.string().required(t('validation.intro.subject_required')),
      }),
      intro_message: Yup.string().when('intro_type', {
        is: 'Email',
        then: () => Yup.string().required(t('validation.intro.text_required')),
      }),
    });
  } else {
    // Validation
    validationSchema = Yup.object().shape({
      regularLeadDialogOpen: Yup.boolean(),
      first_name: Yup.string()
        .min(2, t('validation.short'))
        .max(50, t('validation.long'))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/,
          t('validation.name.first_matches'),
        )
        .required(t('validation.name.first_required')),
      last_name: Yup.string()
        .min(2, t('validation.short'))
        .max(50, t('validation.long'))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/,
          t('validation.name.last_matches'),
        )
        .required(t('validation.name.last_required')),
      active: Yup.string().required(t('validation.active_required')),
      headline_title: Yup.string().required(
        t('validation.headline_title_required'),
      ),
      headline_body: Yup.string().required(
        t('validation.headline_body_required'),
      ),
      address: Yup.object().when('company', {
        is: (company) => Boolean(company),
        then: () =>
          Yup.object({
            line1: Yup.string()
              .min(5, t('validation.short'))
              .required(t('validation.address.line1_required')),
            city: Yup.string()
              .min(3, t('validation.short'))
              .required(t('validation.address.city_required')),
            state: Yup.string()
              .min(2, t('validation.short'))
              .required(t('validation.address.state_required')),
            postcode: Yup.string()
              .min(3, t('validation.short'))
              .required(t('validation.address.postcode_required')),
            country: Yup.object({
              label: Yup.string().required(t('validation.country.required')),
              dialCode: Yup.number(t('validation.country.dial_code.invalid'))
                .required(t('validation.country.dial_code.required'))
                .typeError(t('validation.country.dial_code.error')),
              code: Yup.string()
                .required(t('validation.country.code.required'))
                .min(2, t('validation.country.code.min')),
            }),
          }),
      }),
      company: Yup.string()
        .nullable()
        .when('lead_type', {
          is: 'manufacturer',
          then: () =>
            Yup.string()
              .label('Company Name')
              .required(t('validation.company.name_required')),
        }),
      email: Yup.string().when('intro_type', {
        is: 'Email',
        then: () =>
          Yup.string()
            .required(t('validation.email.intro_required'))
            .matches(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              t('validation.email.invalid'),
            ),
      }),
      website: Yup.string()
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          transValidations('website_invalid'),
        )
        .url(transValidations('website_invalid')),
      intro_subject: Yup.string().when('intro_type', {
        is: 'Email',
        then: () =>
          Yup.string().required(t('validation.intro.subject_required')),
      }),
      intro_message: Yup.string().when('intro_type', {
        is: 'Email',
        then: () => Yup.string().required(t('validation.intro.text_required')),
      }),
      lead_type: Yup.string().required(t('validation.lead.type_required')),
      associate: Yup.object({
        _id: Yup.string().required(t('validation.associate_required')),
      }),
      industries: Yup.array()
        .min(1, t('validation.industry_min'))
        .required(t('validation.industry_name_required')),
    });
  }

  // Get CHAT GPT Intro Text
  async function getIntroText(values) {
    setFetchIntro(true);
    let aiPrompt;
    let industries;
    let industry;
    let voice;

    if (values.lead_type !== 'investor') {
      industries = values.industries;
      industry = industries.join(', ');
    }

    if (values.lead_type === 'manufacturer') {
      aiPrompt = `Convince ${values.company} him to advertise and sell his products on IN2TEC, a marketplace for sophisticated technology products, allowing him to advertise and sell ${values.company}'s products as well as providing on site support through a dedicated network of sales reps and referrers who promote their products. Include a brief descriptions of products and industries. Also point out our expertise within ${industry} and include best regards from ${values.associate.name}`;
      voice = 'Tony Robbins';
    } else if (values.lead_type === 'salesrep') {
      aiPrompt = `Convince ${values.first_name} ${values.last_name} to join IN2TEC as an independent sales rep within ${industry} industry. IN2TEC is a new marketplace of sophisticated technology products and offers individuals the opportunity to refer and sell products in return of a commission. Include best regards from ${values.associate.name}`;
      voice = 'Tony Robbins';
    } else if (values.lead_type === 'referrer') {
      aiPrompt = `Convince ${values.first_name} ${values.last_name}  to join IN2TEC as an referrer within ${industry} industry. IN2TEC is a new marketplace of sophisticated technology products and offers individuals the opportunity to refer products in return of a fee. Include best regards from ${values.associate.name}`;
      voice = 'Tony Robbins';
    } else if (values.lead_type === 'investor') {
      aiPrompt = `Produce an ${values.intro_type} in short using 150 words three paragraphs in the style of Steve Jobs message addressed to ${values.first_name} ${values.last_name}, convincing his ${values.lead_type} ${values.company} to invest in IN2TEC. IN2TEC is a new marketplace of sophisticated technology products and offers individuals the opportunity to refer products in return of a fee. Include best regards from ${values.associate.name}`;
      voice = 'Steve Jobs';
    }

    let payload = {
      messages: [
        {
          role: 'assistant',
          content: `Produce a summary for ${values.intro_type}, consisting a headline and a body not exceeding 150 words and/or three paragraphs in ${values?.language.name}`,
        },
        {
          role: 'assistant',
          content: `Write it in the style of ${voice} and address the message to ${values.first_name} ${values.last_name}.`,
        },
        {
          role: 'assistant',
          content: `Make sure that the title is clearly marked as "Subject:" regardless of the language..`,
        },
        {
          role: 'user',
          content: aiPrompt,
          name: 'IN2TEC',
        },
      ],
      temperature: 0.2,
    };

    let url = '/api/ai/content-creation/create-content';

    const controller = new AbortController();
    const signal = controller.signal;
    try {
      const response = await axiosPrivate.post(url, payload, {
        signal,
      });

      const data = response?.data;

      if (response.status === 200) {
        // Track Event on Google
        gaEventTracker({
          category: 'Lead Management',
          action: 'Event',
          label: 'New Chat GPT Recommendation',
        });

        return { status: response.status, data: response.data.data };
      } else {
        handleRegularResponse({
          open: true,
          status: data.status,
          message: data.message,
        });
      }
    } catch (err) {
      handleErrorResponse(err);
      errRef.current?.focus();
    } finally {
      controller.abort();
      setFetchIntro(false);
    }
  }

  // Get AI Headline Text
  async function getHeadlineText(values, formik) {
    setFetchHeadline(true);

    // IN2TEC Benefits
    const aboutIN2TEC =
      'IN2TEC is a marketplace for sophisticated technology products and a combination of Amazon and Uber, but with professional sales engineers. Currently 70-85% of all products sold are being sold indirect though traditional distribution channels. We allow manufacturers to list and sell their products online while providing neccesaary local support to potential customers based on region, application and product expertise. Sales Engineers can build build their own business and become independent from a local distributor they currently work for, or an employment - which both limit them in both financially and in terms of their offering. With IN2TEC, we build a fair and transparent marketplace where great technology finds customers - leading to better prices, better communication as it is more direct and less cost. We have official sales reps, who are individuals who officially represetn a manufacturer in their region and application and they are advertised publicly - They receive a commission in return of a sale. We also have referrers, who are and stay anomynous - they get a referal fee in return of an referral based on click, inquiry or sale.';

    // Construct Assistant Prompt
    const assistantPrompt = [
      {
        role: 'assistant',
        content: `Strictly respond with a "Title:" in plain text followed by a "Body:" in html tags like <b>,<ul>, <li>. No additional formatting, tags, or extra content outside of these labels is allowed. Ensure the "Title:" is clearly marked, and the "Body:" provides the content.`,
      },
      {
        role: 'assistant',
        content: `Base your response on the language ${values?.language.name}. For example:\n\nTitle: Your Personal Headline\n\nBody: This is the detailed body text describing why you are a good match, tailored to the specific audience and context.`,
      },
    ];

    // Research Website if existent
    if (values?.website) {
      assistantPrompt.push({
        role: 'assistant',
        content: `Do some research ${values.website} and include that in your summary`,
      });
    } else if (values?.company) {
      assistantPrompt.push({
        role: 'assistant',
        content: `Do some research on ${values.company} and include that in your summary`,
      });
    }

    // Add Industries
    if (values?.industries.length > 0) {
      const industries = values.industries
        .map((industry) => industry.industry_name)
        .join(', ');

      console.log('Industries -> ', industries);

      assistantPrompt.push({
        role: 'assistant',
        content: `Tailor it to the following ${JSON.stringify(industries)} industry and outline our expertise within each one of them.`,
      });
    }

    // Construct User Prompt
    const userPrompt = [];
    if (values.lead_type === 'investor') {
      // Investor
      userPrompt.push({
        role: 'user',
        content: `Outline why IN2TEC is such a great additioon to ${values.company}'s portfolio in the Voice of Steve Jobs. Consider the ${aboutIN2TEC} when building your arguments`,
      });
    } else if (values.lead_type === 'manufacturer') {
      // Manufacturer
      userPrompt.push({
        role: 'user',
        content: `Outline why ${values.company} is such a good match for IN2TEC and why they should join us as a manufacturer. Write in the voice of Tony Robbins`,
      });
    } else if (values.lead_type === 'salesrep') {
      // Sales Rep

      if (values.company) {
        userPrompt.push({
          role: 'user',
          content: `Produce personal arumgents as of why ${values.company} should join IN2TEC as a ${values.lead_type}. Write in the voice of Tony Robbins`,
        });
      } else {
        // Individual Sales Rep

        userPrompt.push({
          role: 'user',
          content: `Produce personal arumgents as of why ${values.first_name} ${values.last_name} should join IN2TEC as a ${values.lead_type} 
          Make sure that the title and conent is written in first person to ${values.first_name} ${values.last_name}!  Write in the voice of Tony Robbins`,
        });
      }
    } else if (values.lead_type === 'referrer') {
      // Referrer
      userPrompt.push({
        role: 'user',
        content: `Produce personal arumgents as of why ${values.first_name} ${values.last_name} should join IN2TEC as a ${values.lead_type}.  Write in the voice of Tony Robbins`,
      });
    }

    let payload = {
      messages: [...assistantPrompt, ...userPrompt],
      temperature: 0.2,
    };

    // console.log('Chat Payload -> ', payload);

    let url = '/api/ai/content-creation/create-content';
    const controller = new AbortController();
    const signal = controller.signal;
    try {
      const request = await axiosPrivate.post(url, payload, { signal });
      const response = request?.data;
      // console.log('Response -> ', response);
      if (request.status === 200) {
        // Trim and Convert Content
        let messages = response.data[0];
        let recommedation = messages.message.content;
        let recommendationTitle = recommedation.split(/\n\s*\n/);
        let pitchRaw = recommendationTitle[0];
        let title = pitchRaw.replace('Title:', '').trim();
        title = title.replace(/["]+/g, '');

        let body = recommedation.replace(pitchRaw, '').trim();
        body = body.replace('Body:', '').trim();

        formik.setFieldValue('headline_title', title);
        let htmlBody = getMuiRichTextEditorContent(body);
        formik.setFieldValue('headline_body_rte', htmlBody);
        setTimeout(
          () =>
            formik.setTouched({
              headline_title: true,
              headline_body_rte: true,
            }),
          400,
        );
      } else {
        handleRegularResponse({
          open: true,
          status: response.status,
          message: response.message,
        });
        setLeadInfo(values);
      }
    } catch (err) {
      console.log(err);
      handleRegularResponse({
        open: true,
        status: 'error',
        message: err.message,
      });
      setLeadInfo(values);
      errRef.current?.focus();
    } finally {
      controller.abort(signal);
      setFetchHeadline(false);
    }
  }

  return (
    <MapContext.Provider value={{ mapRendered, setMapRendered }}>
      <Formik
        values={formik.values}
        initialValues={formik.initialValues}
        errors={formik.errors}
        validationSchema={validationSchema}
        validateOnMount={true}
        validateOnChange={true}
        enableReinitialize={true}
      >
        {(formik) => {
          const {
            values,
            setFieldValue,
            errors,
            touched,
            handleBlur,
            setValues,
            setFieldTouched,
            resetForm,
          } = formik;
          return (
            <>
              <Form role="form">
                <FormControl fullWidth>
                  {/* Add/Edit Lead dialog */}
                  <Dialog maxWidth="md" fullWidth open={leadDetailsDialog.open}>
                    <DialogTitle>
                      <Grid
                        container
                        spacing={{ xs: 3 }}
                        justifyContent="center"
                        direction={{ xs: 'column', sm: 'column', md: 'row' }}
                      >
                        <Grid
                          item
                          xs={12}
                          alignItems="center"
                          justifyContent="flex-start"
                          sx={{ paddingBottom: 0, marginBottom: 0 }}
                        >
                          {leadDetailsDialog.title}
                        </Grid>
                        <Grid
                          item
                          container
                          direction="row"
                          xs={12}
                          spacing={1}
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{
                            fontSize: '1.0rem',
                            color: 'var(--color-highlight)',
                          }}
                        >
                          <Grid item>
                            {values?.created && `Created ${values?.created}`}
                          </Grid>
                          <Grid item>
                            <Button
                              variant="outlined"
                              color="info"
                              sx={{ color: 'white' }}
                              disabled
                              startIcon={<SourceIcon />}
                            >
                              <Typography variant="body1">
                                {toTitleCase(values.origin)}
                              </Typography>
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </DialogTitle>
                    <DialogContent>
                      {/* Form */}
                      <Stack
                        spacing={2}
                        style={classes.root}
                        sx={{ paddingBottom: '2rem' }}
                      >
                        <DialogContentText
                          sx={{ color: 'var(--color-text-error-message)' }}
                        >
                          {leadDetailsDialog.text}
                        </DialogContentText>

                        <Typography variant="h4">
                          {t('form.details', {
                            type: transTypes('lead'),
                          })}
                        </Typography>

                        {/* Associate */}

                        <AssociateSelectField
                          disabled={fetchIntro || fetchHeadline}
                          required
                          options={employeeOptions}
                          variant="standard"
                        />

                        {/* Lead Language */}
                        <SingleLanguageSelectionfield
                          required
                          disabled={fetchIntro || fetchHeadline ? true : false}
                          variant="standard"
                          transTypeID="lead"
                        />

                        {/* Lead Type (Lead Only*/}
                        <LeadTypeTypeField
                          disabled={fetchIntro || fetchHeadline}
                          required
                          transition={
                            leadDetailsDialog?.lead_type === 'lead'
                              ? true
                              : false
                          }
                          variant="standard"
                        />

                        {/* Company Type (For Investor Pitch only) */}
                        <InvestmentFirmTypeSelect
                          required
                          disabled={fetchIntro || fetchHeadline}
                          variant="standard"
                          transition={
                            values.lead_type === 'investor' ? true : false
                          }
                        />

                        {/* Industries */}
                        <IndustriesField
                          fieldTitle={transFields('IndustriesField.title', {
                            type: transTypes('lead'),
                          })}
                          disabled={fetchIntro || fetchHeadline}
                          transition={
                            values.lead_type !== 'investor' &&
                            values.lead_type &&
                            values.language
                              ? true
                              : false
                          }
                          required={values.lead_type !== 'investor'}
                          variant="standard"
                        />

                        {/* Company */}
                        <BusinessNameTextField
                          // fieldTitle={t('form.company.details')}
                          required={
                            values.lead_type === 'manufacturer' ||
                            (values.lead_type === 'investor' &&
                              values.company_type)
                          }
                          transition={
                            values.lead_type === 'manufacturer' ||
                            (values.lead_type === 'investor' &&
                              values.company_type)
                          }
                          disabled={fetchIntro || fetchHeadline}
                          variant="standard"
                          transPrefix={
                            values.lead_type === 'investor'
                              ? 'FirmNameFieldManual'
                              : 'BusinessNameFieldManual'
                          }
                        />

                        {/* Website */}
                        <WebsiteField
                          variant="standard"
                          type={transTypes('company')}
                        />

                        {/* Company Banner */}
                        <CompanyBanner
                          transition={values?.company && values.lead_type}
                        />

                        {values.lead_type && values.language && (
                          <>
                            {/* Personal Details */}
                            <Typography variant="h6">
                              {t('form.personal_details', {
                                type: transTypes('lead'),
                              })}
                            </Typography>
                            <Stack
                              spacing={{ xs: 3, sm: 3, md: 4 }}
                              style={classes.root}
                            >
                              {/* First & Last Name */}
                              <Stack
                                spacing={{ xs: 3, sm: 3, md: 4 }}
                                direction="row"
                                sx={{ paddingTop: '10px' }}
                                style={classes.root}
                                alignItems="stretch"
                              >
                                {/* First Name */}
                                <IndividualFirstNameField
                                  required={values.lead_type !== 'investor'}
                                  fieldID="first_name"
                                  variant="standard"
                                />

                                {/* Last Name */}
                                <IndividualLastNameField
                                  required={values.lead_type !== 'investor'}
                                  fieldID="last_name"
                                  variant="standard"
                                />
                              </Stack>

                              {/* Phone */}
                              <PhoneField
                                fieldID="phone"
                                variant="standard"
                                transTypeID={toTitleCase(
                                  leadDetailsDialog?.lead_type,
                                )}
                              />

                              {/* Email */}
                              <EmailField
                                fieldID="email"
                                disabled={fetchIntro || fetchHeadline}
                                required={values.intro_type === 'Email'}
                                transTypeID={toTitleCase(
                                  leadDetailsDialog?.lead_type,
                                )}
                                variant="standard"
                              />
                            </Stack>

                            {/* Lead/Investor Address */}
                            <AddressField
                              fieldTitle={`${toTitleCase(leadDetailsDialog?.lead_type)}'s Address`}
                              fieldID="address"
                              phoneFieldID={'phone'}
                              labelID={
                                values.company ||
                                (values?.first_name && values?.last_name)
                              }
                              transition={true}
                              disabled={fetchIntro || fetchHeadline}
                              required={values?.company ? true : false}
                              variant="standard"
                              showLocationMap={true}
                              disableZoom={true}
                            />

                            {/* Page Headline Title */}
                            {((leadDetailsDialog?.lead_type === 'lead' &&
                              values?.associate &&
                              ((values?.first_name && values?.last_name) ||
                                values?.company) &&
                              values?.industries?.length > 0 &&
                              values.language) ||
                              (leadDetailsDialog?.lead_type === 'investor' &&
                                values?.associate &&
                                values?.company &&
                                values.language)) && (
                              <Stack
                                spacing={2}
                                direction="column"
                                sx={{ paddingBottom: '100px' }}
                              >
                                <Typography variant="h6">
                                  {t('form.leads.headline.title')}
                                </Typography>

                                {/* Page Headline */}
                                <LoadingButton
                                  disabled={
                                    fetchIntro ||
                                    fetchHeadline ||
                                    formik.isSubmitting ||
                                    !values.language
                                  }
                                  size="small"
                                  endIcon={<SendIcon />}
                                  loading={fetchHeadline}
                                  loadingPosition="end"
                                  variant="contained"
                                  color="info"
                                  onClick={async () => {
                                    await getHeadlineText(values, formik);
                                  }}
                                >
                                  {transButtons('ai_summary')}
                                </LoadingButton>

                                <TextField
                                  ref={headlineTitleRef}
                                  required
                                  disabled={fetchIntro || fetchHeadline}
                                  rows={10}
                                  id="headline_title"
                                  name="headline_title"
                                  className="form-select-field"
                                  aria-describedby="uidnote"
                                  variant="standard"
                                  label={t('form.leads.headline.title_label')}
                                  type="text"
                                  placeholder={t(
                                    'form.leads.headline.title_placeholder',
                                  )}
                                  onChange={(e) => {
                                    setFieldValue(
                                      e.target.name,
                                      e.target.value,
                                    );
                                  }}
                                  onBlur={handleBlur}
                                  value={values?.headline_title}
                                  error={
                                    errors.headline_title &&
                                    touched.headline_title
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    errors.headline_title &&
                                    touched.headline_title
                                      ? errors.headline_title
                                      : null
                                  }
                                />

                                {/* Page Headline Body */}
                                <IntuRichTextEditor
                                  required
                                  id="headline_body_rte"
                                  disabled={fetchIntro || fetchHeadline}
                                  name="headline_body_rte"
                                  controls={[
                                    'title',
                                    'bold',
                                    'italic',
                                    'underline',
                                    'strikethrough',
                                    'highlight',
                                    'undo',
                                    'redo',
                                    'link',
                                    'media',
                                    'numberList',
                                    'bulletList',
                                    'quote',
                                    'code',
                                    'clear',
                                  ]}
                                  className="form-select-field"
                                  aria-describedby="uidnote"
                                  variant="standard"
                                  label={t('form.leads.headline.body_label')}
                                  placeholder={t(
                                    'form.leads.headline.body_placeholder',
                                  )}
                                  onChange={(value) => {
                                    const rteContent = stateToHTML(
                                      value.getCurrentContent(),
                                    ); // Convert to HTML
                                    rteContent &&
                                      setFieldValue(
                                        'headline_body',
                                        rteContent,
                                      ); // Store to Values
                                  }}
                                  defaultValue={JSON.stringify(
                                    values.headline_body_rte,
                                  )}
                                  inlineToolbar={true}
                                  error={
                                    errors.headline_body &&
                                    touched.headline_body
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    errors.headline_body &&
                                    touched.headline_body
                                      ? errors.headline_body
                                      : null
                                  }
                                />
                              </Stack>
                            )}

                            {/* Intro Type */}
                            {((leadDetailsDialog?.lead_type === 'lead' &&
                              values?.associate &&
                              ((values?.first_name && values?.last_name) ||
                                values?.company) &&
                              values?.industries?.length > 0 &&
                              values.language) ||
                              (leadDetailsDialog?.lead_type === 'investor' &&
                                values?.associate &&
                                values?.company &&
                                values.language)) && (
                              <>
                                <Typography variant="h6">
                                  {t('form.leads.intro.title')}
                                </Typography>

                                <Stack
                                  spacing={{ xs: 3, sm: 3, md: 1 }}
                                  direction="row"
                                  sx={{ paddingTop: '10px' }}
                                  style={classes.root}
                                  alignItems="stretch"
                                >
                                  {/* Intro Type */}
                                  <TextField
                                    select
                                    type="text"
                                    disabled={
                                      fetchIntro || fetchHeadline ? true : false
                                    }
                                    id="intro_type"
                                    label={t('form.leads.intro.type_label')}
                                    name="intro_type"
                                    variant="standard"
                                    value={values?.intro_type}
                                    onChange={(e) => {
                                      setFieldValue(
                                        e.target.name,
                                        e.target.value,
                                      );

                                      if (e.target.value === '') {
                                        setValues({
                                          ...values,
                                          email: '',
                                          intro_type: '',
                                          intro_subject: '',
                                          intro_message: '',
                                        });
                                      }
                                    }}
                                    onBlur={handleBlur}
                                    error={
                                      errors.lead_type && touched.lead_type
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      errors?.lead_type && touched?.lead_type
                                        ? errors?.lead_type
                                        : null
                                    }
                                  >
                                    <MenuItem key={1} value="">
                                      {t('form.no_selection')}
                                    </MenuItem>
                                    <MenuItem key={2} value="Email">
                                      {t('form.email.label_alt')}
                                    </MenuItem>
                                    <MenuItem key={3} value="LinkedIn">
                                      {t('form.linkedin')}
                                    </MenuItem>
                                  </TextField>

                                  {/* Send Email */}
                                  {values.intro_type === 'Email' && (
                                    <FormControlLabel
                                      label={t('form.leads.send_email.label')}
                                      disabled={leadInfo?._id ? true : false}
                                      control={
                                        <Switch
                                          id="send_email"
                                          name="send_email"
                                          className="form-select-field"
                                          aria-invalid={
                                            errors.send_email ? 'true' : 'false'
                                          }
                                          aria-describedby="uidnote"
                                          variant="standard"
                                          onChange={(e) => {
                                            setFieldValue(
                                              e.target.name,
                                              e.target.checked,
                                            );
                                          }}
                                          onBlur={handleBlur}
                                          checked={values.send_email}
                                        />
                                      }
                                    />
                                  )}
                                </Stack>
                              </>
                            )}

                            {/* Introduction */}
                            {values.intro_type > '' && (
                              <Stack
                                direction="column"
                                sx={{ marginBottom: '50px' }}
                              >
                                {/* Get Introduction Button */}
                                <LoadingButton
                                  disabled={
                                    ((!values.first_name ||
                                      !values.last_name) &&
                                      !values.company) ||
                                    (values.intro_type === 'Email' &&
                                      !values.email) ||
                                    !values.lead_type ||
                                    !values.associate ||
                                    !values.intro_type ||
                                    formik.isSubmitting ||
                                    fetchIntro ||
                                    !values.language ||
                                    fetchHeadline ||
                                    (leadDetailsDialog?.lead_type ===
                                      'investor' &&
                                      !values.lead_type)
                                  }
                                  size="small"
                                  endIcon={<SendIcon />}
                                  loading={fetchIntro}
                                  loadingPosition="end"
                                  variant="contained"
                                  color="info"
                                  onClick={async () => {
                                    setFetchIntro(true);
                                    const getIntro = await getIntroText(values);

                                    if (getIntro) {
                                      if (getIntro.status === 200) {
                                        let messages = getIntro.data[0];
                                        let recommedation =
                                          messages.message.content;

                                        var recommendationSubject =
                                          recommedation.split(/\n\s*\n/);
                                        var subjectRaw =
                                          recommendationSubject[0];
                                        var subject = subjectRaw
                                          .replace('Subject:', '')
                                          .trim();

                                        var body = recommedation
                                          .replace(subjectRaw, '')
                                          .trim();

                                        setFieldValue('intro_subject', subject);
                                        setFieldValue('intro_message', body);

                                        setTimeout(
                                          () =>
                                            setFieldTouched(
                                              'intro_message',
                                              true,
                                            ),
                                          400,
                                        );
                                      }
                                      setFetchIntro(false);
                                    }
                                  }}
                                >
                                  {transButtons('get_intro')}{' '}
                                  {values.intro_type}
                                </LoadingButton>

                                {/* Intro subject and content */}
                                <Grid item xs={12}>
                                  <Stack
                                    spacing={{ xs: 3, sm: 3, md: 1 }}
                                    direction="column"
                                    sx={{ paddingTop: '10px' }}
                                    style={classes.root}
                                    alignItems="stretch"
                                  >
                                    {/* Intro Subject */}
                                    <TextField
                                      required
                                      ref={introTitleRef}
                                      id="intro_subject"
                                      name="intro_subject"
                                      disabled={fetchIntro || fetchHeadline}
                                      className="form-select-field"
                                      aria-invalid={
                                        errors.intro_subject ? 'true' : 'false'
                                      }
                                      aria-describedby="uidnote"
                                      variant="standard"
                                      label={t(
                                        'form.leads.intro.subject_label',
                                      )}
                                      type="text"
                                      placeholder={t(
                                        'form.leads.intro.placeholder',
                                      )}
                                      onChange={(e) => {
                                        setFieldValue(
                                          e.target.name,
                                          e.target.value,
                                        );
                                      }}
                                      onBlur={handleBlur}
                                      value={values?.intro_subject}
                                      error={
                                        errors.intro_subject &&
                                        touched.intro_subject
                                          ? true
                                          : false
                                      }
                                      helperText={
                                        errors.intro_subject &&
                                        touched.intro_subject
                                          ? errors.intro_subject
                                          : null
                                      }
                                    />

                                    {/* Intro Body */}
                                    <TextField
                                      required
                                      multiline
                                      disabled={fetchIntro || fetchHeadline}
                                      rows={10}
                                      id="intro_message"
                                      name="intro_message"
                                      className="form-select-field"
                                      aria-invalid={
                                        errors.intro_message ? 'true' : 'false'
                                      }
                                      aria-describedby="uidnote"
                                      variant="standard"
                                      label={t('form.leads.intro.body_label')}
                                      type="text"
                                      placeholder={t(
                                        'form.leads.intro.placeholder',
                                      )}
                                      onChange={(e) => {
                                        setFieldValue(
                                          e.target.name,
                                          e.target.value,
                                        );
                                      }}
                                      onBlur={handleBlur}
                                      value={values?.intro_message}
                                      error={
                                        errors.intro_message &&
                                        touched.intro_message
                                          ? true
                                          : false
                                      }
                                      helperText={
                                        errors.intro_message &&
                                        touched.intro_message
                                          ? errors.intro_message
                                          : null
                                      }
                                    />
                                  </Stack>
                                </Grid>
                              </Stack>
                            )}
                          </>
                        )}

                        {/* Lead Activities */}
                        {values?.activity?.length > 0 && <LeadActivity />}
                        {/* Referal Link & Status */}
                        {values?._id && (
                          <>
                            {/* Referal Link */}
                            <Grid item xs={12}>
                              <Stack
                                spacing={{ xs: 3, sm: 3, md: 1 }}
                                direction="row"
                                sx={{ paddingTop: '10px' }}
                                style={classes.root}
                                alignItems="stretch"
                              >
                                <TextField
                                  id="link"
                                  name="link"
                                  disabled
                                  className="form-select-field"
                                  aria-describedby="uidnote"
                                  variant="standard"
                                  label={t('form.leads.referral_link')}
                                  type="text"
                                  placeholder={t('form.leads.referral_link')}
                                  onChange={(e) => {
                                    setFieldValue(
                                      e.target.name,
                                      e.target.value,
                                    );
                                  }}
                                  inputProps={{
                                    autoComplete: 'off',
                                  }}
                                  onBlur={handleBlur}
                                  value={
                                    values.lead_type === 'investor'
                                      ? `https://${host_url}/investor?referralID=${values.code}`
                                      : `https://${host_url}/how-it-works?referralID=${values.code}`
                                  }
                                />

                                <IntuIconButton
                                  type="connect"
                                  color="primary"
                                  aria-label="share link"
                                  tooltipTitle={transButtons('share', {
                                    type: transTypes('lead'),
                                  })}
                                  onClick={() => {
                                    let link =
                                      values.lead_type === 'investor'
                                        ? `https://${host_url}/investor?referralID=${values.code}`
                                        : `https://${host_url}/how-it-works?referralID=${values.code}`;
                                    copy(link);
                                  }}
                                >
                                  <LinkIcon />
                                </IntuIconButton>
                              </Stack>
                            </Grid>

                            <Grid item xs={6}>
                              {/* Custom field to the left */}
                              <FormControlLabel
                                label={t('form.leads.access_page', {
                                  type: t('types.lead'),
                                })}
                                control={
                                  <Switch
                                    required
                                    id="active"
                                    name="active"
                                    className="form-select-field"
                                    aria-invalid={
                                      errors.active ? 'true' : 'false'
                                    }
                                    aria-describedby="uidnote"
                                    variant="standard"
                                    onChange={(e) => {
                                      setFieldValue(
                                        e.target.name,
                                        e.target.checked,
                                      );
                                    }}
                                    onBlur={handleBlur}
                                    checked={values.active}
                                  />
                                }
                              />
                            </Grid>
                          </>
                        )}
                        {leadDetailsDialog.dialogAction === 'update' && (
                          <>
                            {/* Lead Status */}
                            <TextField
                              select
                              type="text"
                              id="status"
                              label={t('form.leads.status.label')}
                              name="status"
                              variant="standard"
                              value={values?.status}
                              onBlur={handleBlur}
                              onChange={(e) => {
                                setFieldValue(e.target.name, e.target.value);
                              }}
                              error={
                                errors.status && touched.status ? true : false
                              }
                              helperText={
                                errors?.status && touched?.status
                                  ? errors?.status
                                  : null
                              }
                            >
                              <MenuItem key={0} value="">
                                {t('form.leads.status.select')}
                              </MenuItem>
                              <MenuItem key={1} value="created">
                                {t('form.leads.status.pending')}
                              </MenuItem>
                              <MenuItem key={2} value="shared">
                                {t('form.leads.status.shared')}
                              </MenuItem>
                              <MenuItem key={3} value="active">
                                {t('form.leads.status.follow_up')}
                              </MenuItem>
                              <MenuItem key={4} value="closed-won">
                                {t('form.leads.status.won')}
                              </MenuItem>
                              <MenuItem key={5} value="closed-lost">
                                {t('form.leads.status.lost')}
                              </MenuItem>
                            </TextField>
                          </>
                        )}
                        {/* Associate Activity Log */}
                        {values?.associate_activity > '' && (
                          <AssociateActivity />
                        )}
                        {/* Lead Activity entry Form */}
                        <AssociateActivityDialog />
                        {/* Add new associate activity */}
                        {values?._id && (
                          <Button
                            variant="contained"
                            color="info"
                            onClick={(e) => {
                              setActivityDialog({
                                openDialog: true,
                                leadID: values?._id,
                                activityDialogTitle: transDialogs(
                                  'AssociateActivityDialog.add.title',
                                ),
                                activityDialogText: t(
                                  'AssociateActivityDialog.add.description',
                                ),
                                activityPrimaryButtonText: transButtons('add', {
                                  type: transTypes('activity'),
                                }),
                                activitySecondaryButtonText:
                                  transButtons('close'),
                                activityDialogAction: 'add',
                                activityLog: values?.associate_activity,
                                activityContent: {
                                  _id: '',
                                  user: auth.user_info.full_name,
                                  date: '',
                                  activity: '',
                                  comment: '',
                                },
                              });
                            }}
                          >
                            {transButtons('add', {
                              type: transTypes('activity'),
                            })}
                          </Button>
                        )}
                      </Stack>
                    </DialogContent>
                    <DialogActions>
                      <Grid container margin="1rem 1rem 0 1rem">
                        <Grid item>
                          {/* Close */}
                          <Button
                            variant="contained"
                            color="error"
                            disabled={
                              fetchHeadline || fetchIntro || formik.isSubmitting
                            }
                            onClick={() => {
                              const leadForm = blankLeadForm({ lng });
                              resetForm();
                              setLeadInfo(leadForm);
                              setLeadDetailsDialog({
                                open: false,
                                lead_type: '',
                                title: '',
                                text: '',
                                primaryButtonText: '',
                                secondaryButtonText: '',
                                dialogAction: '',
                              });
                            }}
                          >
                            {leadDetailsDialog.secondaryButtonText}
                          </Button>
                        </Grid>

                        <Grid item sx={{ marginLeft: 'auto' }}>
                          <Stack direction="row" spacing={2}>
                            {/* Preview Leanding Page */}
                            {values.code && (
                              <Button
                                disabled={
                                  fetchIntro ||
                                  fetchHeadline ||
                                  formik.isSubmitting
                                }
                                component={Link}
                                variant="contained"
                                color="info"
                                to={
                                  values.lead_type === 'investor'
                                    ? `https://${host_url}/investor?referralID=${values.code}&tracker=false`
                                    : `https://${host_url}/how-it-works?referralID=${values.code}&tracker=false`
                                }
                                target="_blank"
                                endIcon={<RemoveRedEyeIcon />}
                              >
                                {transButtons('preview')}
                              </Button>
                            )}

                            {/* Add/Update Button */}
                            <LoadingButton
                              variant="contained"
                              size="medium"
                              endIcon={<SendIcon />}
                              loading={formik.isSubmitting}
                              loadingPosition="end"
                              color="primary"
                              disabled={
                                !formik.isValid ||
                                !formik.dirty ||
                                formik.isSubmitting ||
                                fetchHeadline ||
                                fetchIntro
                              }
                              type="submit"
                              onClick={async (e) => {
                                let action = leadDetailsDialog.dialogAction;
                                await saveLead(action, values, formik);
                              }}
                            >
                              {leadDetailsDialog.primaryButtonText}
                            </LoadingButton>
                          </Stack>
                        </Grid>
                      </Grid>
                    </DialogActions>
                  </Dialog>
                </FormControl>
              </Form>
            </>
          );
        }}
      </Formik>
    </MapContext.Provider>
  );
};

export { LeadDetailsForm };
