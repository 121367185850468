import countryList from '../data/CountryData.json';

export function getUserPhoneDetails(countryCode) {
  const countryOptions = countryList;
  let phoneDetails = {
    value: '',
    info: {
      countryCallingCode: '+1',
      countryCode: 'US',
      nationalNumber: '',
      numberType: null,
      numberValue: null,
      reason: null,
    },
  };

  if (countryCode) {
    const [countryDetails] = countryOptions.filter(
      (country) => country.code === countryCode,
    );

    phoneDetails = {
      value: '+' + countryDetails.dialCode,
      info: {
        countryCallingCode: countryDetails.dialCode,
        countryCode: countryDetails.code,
        nationalNumber: '',
        numberType: null,
        numberValue: '+' + countryDetails.dialCode,
        reason: 'input',
      },
    };
  }

  return phoneDetails;
}
