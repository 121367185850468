import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FormikTabs from '../formik/FormikTabs';
import AdminIn2tecOrdersList from './AdminIn2tecOrdersList';
import AdminAllOrdersList from './AdminAllOrdersList';

const AdminOrdersDashboard = () => {
  const { t } = useTranslation();
  const tabs = [
    {
      title: <Typography>{t('components.orders.admin_orders')}</Typography>,
      content: <AdminIn2tecOrdersList />,
    },
    {
      title: <Typography>{t('components.orders.mfg_orders')}</Typography>,
      content: <AdminAllOrdersList />,
    },
  ];
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      px={4}
      margin="0 auto"
      width="100%"
    >
      <Box width="100%">
        <FormikTabs tabsContent={tabs} />
      </Box>
    </Box>
  );
};

export default AdminOrdersDashboard;
