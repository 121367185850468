import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputAdornment,
  Stack,
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AmountField from '../fields/number/AmountField';
import PercentageField from '../fields/number/PercentField';
import { useFormikContext } from 'formik';
import { reindexCommissionSchedule } from './reindexCommissionSchedule';
import IntuIconButton from '../buttons/IntuIconButton';
import { toTitleCase } from '../../helpers/toTitleCase';
import { CommissionBoxContext } from './ComissionBox';
import { useContext } from 'react';

const EditCommissionDialog = ({
  isOpen,
  handleClose,
  handleConfirm,
  handleDelete,
}) => {
  const { t } = useTranslation();
  const { t: transButtons } = useTranslation('buttons');
  const { t: transTypes } = useTranslation('types');

  const { values, isValid, touched, resetForm, setValues } = useFormikContext();
  const { calculateCommissions, calculateCosts, calculateEffectiveCommission } =
    useContext(CommissionBoxContext);

  const handleConfirmClick = () => {
    const commissionIndex = values.commission.index;
    const existingCommissionSchedule = values.commission_schedule;
    const newCommission = values.commission;
    const exampleRevenue = values.example.revenue;

    const newCommissionSchedule = existingCommissionSchedule.map((c) =>
      c.index === commissionIndex ? newCommission : c,
    );

    const reIndexedCommissionSchedule = reindexCommissionSchedule(
      newCommissionSchedule,
    );

    const newExampleCommmissionShedule = calculateCommissions(
      reIndexedCommissionSchedule,
      exampleRevenue,
    );

    if (newExampleCommmissionShedule) {
      const newExampleCost = calculateCosts(newExampleCommmissionShedule);
      const effectiveCommission = calculateEffectiveCommission(
        newExampleCost,
        exampleRevenue,
      );

      // Update Values
      setValues({
        ...values,
        commission_schedule: newExampleCommmissionShedule,
        example: {
          revenue: exampleRevenue,
          commission: newExampleCost,
          effective_commission: effectiveCommission,
        },
      });
    }

    // Execute callback function
    handleConfirm({
      newCommission,
      newCommissionSchedule: newExampleCommmissionShedule,
    });

    // Resetting the Form
    resetForm();
  };

  const handleDeleteClick = () => {
    const commissionIndex = values.commission.index;
    const existingCommissionSchedule = values.commission_schedule;
    const commissionToDelete = values.commission;
    const exampleRevenue = values.example.revenue;

    const newCommissionSchedule = existingCommissionSchedule.filter(
      (c) => c.index !== commissionIndex,
    );

    const reIndexedCommissionSchedule = reindexCommissionSchedule(
      newCommissionSchedule,
    );

    const newExampleCommmissionShedule = calculateCommissions(
      reIndexedCommissionSchedule,
      exampleRevenue,
    );

    if (newExampleCommmissionShedule) {
      const newExampleCost = calculateCosts(newExampleCommmissionShedule);
      const effectiveCommission = calculateEffectiveCommission(
        newExampleCost,
        exampleRevenue,
      );

      // Update Values
      setValues({
        ...values,
        commission_schedule: newExampleCommmissionShedule,
        example: {
          revenue: exampleRevenue,
          commission: newExampleCost,
          effective_commission: effectiveCommission,
        },
      });
    }

    // Resetting the Form
    resetForm();

    // Execute callback function
    handleDelete({
      commissionToDelete,
      newCommissionSchedule: newExampleCommmissionShedule,
    });
  };

  const handleCloseClick = () => {
    resetForm();
    handleClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>{t('components.commission.edit')}</DialogTitle>
      <DialogContent style={{ paddingTop: '20px' }}>
        <FormControl fullWidth>
          <Stack spacing={2}>
            {/* Commission Amount */}
            <AmountField
              fieldID="commission.max_amount"
              transNS="fields"
              transPrefix="AmountField"
              transTypeID="max_commission_bracket"
              inputAdornment="'$'"
              thousandSeparator={true}
              allowNegative={false}
              fixedDecimalScale
              decimalScale={0}
              InputProps={{
                endAdornment: <InputAdornment position="end">$</InputAdornment>,
                sx: {
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                    {
                      WebkitAppearance: 'none',
                      margin: 0,
                    },
                  '& input[type=number]': {
                    MozAppearance: 'textfield',
                  },
                },
              }}
            />

            {/* Commission Percentage */}
            <PercentageField
              fieldID="commission.commission"
              transNS="fields"
              transPrefix="PercentageField"
              transTypeID="commission"
              allowNegative={false}
              fixedDecimalScale
              decimalScale={2}
              inputAdornment="%"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                sx: {
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                    {
                      WebkitAppearance: 'none',
                      margin: 0,
                    },
                  '& input[type=number]': {
                    MozAppearance: 'textfield',
                  },
                },
              }}
            />
          </Stack>
        </FormControl>
      </DialogContent>
      <DialogActions style={{ paddingBottom: '16px' }}>
        <Grid container sx={{ margin: '1rem 1rem 0 1rem' }}>
          <Grid item>
            <Stack spacing={2} direction="row">
              {/* Cancel */}
              <IntuIconButton
                type="cancel"
                variant="outlined"
                tooltipTitle={transButtons('cancel')}
                onClick={handleCloseClick}
              />

              <IntuIconButton
                type="error"
                variant="outlined"
                tooltipTitle={transButtons('delete')}
                onClick={handleDeleteClick}
              />
            </Stack>
          </Grid>
          <Grid item sx={{ marginLeft: 'auto' }}>
            <IntuIconButton
              tooltipTitle={transButtons('change', {
                type: toTitleCase(transTypes('commission')),
              })}
              onClick={handleConfirmClick}
              color="primary"
              type="submit"
              disabled={!isValid || !touched?.commission}
              variant="contained"
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default EditCommissionDialog;
