import { useState, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  Stack,
} from '@mui/material';
import { useAxiosPrivateData } from '../../../../hooks/axios/useAxiosPrivate';
import { convertFileToBase64 } from '../../../../helpers/convertFileToBase64';
import { getFileName } from '../../../../helpers/getFileName';
import ResponseContext from '../../../../context/providers/ResponseProvider';
import IntuIconButton from '../../../buttons/IntuIconButton';
import DropzoneContainer from '../../../dropzoneContainer/DropzoneContainer';
import { ProductFileContext } from '../../container/files/FilesContainer';
import { Formik, useFormikContext } from 'formik';
import SelectionField from '../../../fields/selection/SelectionField';
import { createFileUploadInitialValues } from './initialValues';
import { createFileUploadValidationSchema } from './validationSchema';
import filePurposeOptions from '../../../../data/FilePurposeOptions.json';
import { getFileMimeType } from '../../../../helpers/getFileType';
import { LoadingSpinner } from '../../../../hooks/useProcessingHandler';

// Find existing FileName
const findFileInFiles = (files, fileUrl) => {
  return files.some(
    (file) => getFileName(file.file_url) === getFileName(fileUrl),
  );
};

const UploadDocumentDialog = () => {
  const { isUploadDocumentDialogOpen, setIsUploadDocumentDialogOpen } =
    useContext(ProductFileContext);

  const { values: parentValues, setValues: setParentValues } =
    useFormikContext();
  const { t: transDialog } = useTranslation('dialogs', {
    keyPrefix: 'UploadDocumentDialog',
  });
  const { t: transButtons } = useTranslation('buttons');
  const { t: transValidation } = useTranslation('validation');
  const { t: transData } = useTranslation('data', {
    keyPrefix: 'FilePurposeOptions',
  });

  const axiosData = useAxiosPrivateData();
  const { setStatusMsg } = useContext(ResponseContext);

  const [isUploading, setIsUploading] = useState(false);

  const onDrop = useCallback((file, formik) => {
    const { setFieldValue } = formik;

    console.log('File -> ', file);
    if (file) {
      setFieldValue(file);
      handleFileChange(file, formik);
    }
  }, []);

  // console.log('Edit Item DEtails -> ', editingItem);
  const initialValues = createFileUploadInitialValues();
  const validationSchema = createFileUploadValidationSchema();

  const handleFileChange = async (file, formik) => {
    if (!file) return null;

    const { values, setValues } = formik;
    // console.log('Formik Actions -> ', formik);

    // Check if product has max 10 documents or if this file already exists on product
    if (
      parentValues.files.length === 10 ||
      findFileInFiles(parentValues.files, file.name, values.purpose.value)
    ) {
      setStatusMsg({
        open: true,
        status: 'error',
        message:
          parentValues.files.length === 10
            ? transValidation('file.max', { maxFiles: 10 })
            : transValidation('file.duplicate'),
      });
      return null;
    }

    const fileType = getFileMimeType(file);

    if (fileType === 'application/pdf') {
      try {
        setIsUploading(true);
        setValues({
          ...values,
          preview_url: URL.createObjectURL(file),
          file_name: file.name,
          file: file,
        });

        const base64File = await convertFileToBase64(file);

        const requestData = {
          file: base64File,
          file_type: 'pdf',
          file_name: file.name,
          folder: `products/${parentValues._id}/documents`,
        };

        const { data, status } = await axiosData.post(
          '/api/aws/content/add',
          JSON.stringify(requestData),
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );

        // console.log('Upload File Response -> ', status, data);

        if (status === 200) {
          // setFileUrl(data.data);
          setValues({
            ...values,
            file_url: data.data,
            preview_url: URL.createObjectURL(file),
            file_name: file.name,
            file: file,
          });
        }
      } catch (error) {
        console.error('error', error);
      } finally {
        setIsUploading(false);
      }
    } else {
      console.log('File Info -> ', file);

      try {
        setIsUploading(true);
        setValues({
          ...values,
          // preview_url: URL.createObjectURL(file),
          file_name: file.name,
          file: file,
        });

        const base64File = await convertFileToBase64(file);

        const requestData = {
          file: base64File,
          file_type: fileType,
          file_name: file.name,
          folder: `products/${parentValues._id}/documents`,
        };

        const { data, status } = await axiosData.post(
          '/api/aws/content/add',
          JSON.stringify(requestData),
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );

        if (status === 200) {
          // setFileUrl(data.data);
          setValues({
            ...values,
            file_url: data.data,
            file_name: file.name,
            file_type: fileType,
            file: file,
          });
        }
      } catch (error) {
        console.error('error', error);
      } finally {
        setIsUploading(false);
      }
    }
  };

  const closeDialog = () => {
    setIsUploadDocumentDialogOpen(false);
  };

  const handleReset = (formik) => {
    const { resetForm } = formik;
    resetForm();
  };

  const onConfirm = (formik) => {
    const { values, resetForm } = formik;
    setParentValues({
      ...parentValues,
      files: [
        ...parentValues.files,
        { purpose: values.purpose.value, file_url: values.file_url },
      ],
    });

    resetForm();
    setIsUploadDocumentDialogOpen(false);
  };

  // Get Localized Options
  const purposeOptions = filePurposeOptions.map((option) => ({
    ...option,
    label: transData(`${option.value}`),
  }));

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={true}
      enableReinitialize={true}
      validateOnBlur={true}
    >
      {(formik) => {
        const { values, isValid } = formik;

        return (
          <Dialog
            open={isUploadDocumentDialogOpen}
            onClose={closeDialog}
            maxWidth={values.file ? 'md' : 'sm'}
            fullWidth
          >
            <DialogTitle>{transDialog('title')}</DialogTitle>
            <DialogContent style={{ paddingTop: '20px' }}>
              {!values.file ? (
                <DropzoneContainer onDrop={(file) => onDrop(file, formik)} />
              ) : null}

              <Box display="flex" gap={2} justifyContent="center">
                <Grid container direction="column">
                  {values.preview_url ? (
                    <Grid item>
                      <embed
                        src={`${values.preview_url}#page=1`}
                        type="application/pdf"
                        width="100%"
                        height="500px"
                      />
                    </Grid>
                  ) : null}
                  {values.file_url ? (
                    <>
                      <Grid item sx={{ mb: 3 }}>
                        <SelectionField
                          disabled={isUploading}
                          options={purposeOptions}
                          fieldID="purpose"
                          transNS="dialogs"
                          transPrefix="UploadDocumentDialog.purpose"
                        />
                      </Grid>

                      <Grid item>
                        <Typography variant="body2" mt={2}>
                          {transDialog('selected_file', {
                            fileName: values.file_name,
                          })}
                        </Typography>
                      </Grid>
                    </>
                  ) : null}
                </Grid>

                <LoadingSpinner isLoading={isUploading} />
              </Box>
            </DialogContent>
            <DialogActions
              sx={{
                paddingBottom: '16px',
                justifyContent: 'space-evenly',
              }}
            >
              <Grid container direction="row" sx={{ padding: '0 1rem 0 1rem' }}>
                <Grid item>
                  <Stack direction="row" spacing={2}>
                    <IntuIconButton
                      size="small"
                      type="cancel"
                      variant="outlined"
                      tooltipTitle={transButtons('close')}
                      onClick={() => closeDialog(formik)}
                    />
                    {values.file && (
                      <IntuIconButton
                        size="small"
                        type="reset"
                        variant="outlined"
                        tooltipTitle={transButtons('reset')}
                        onClick={() => handleReset(formik)}
                      />
                    )}
                  </Stack>
                </Grid>
                <Grid item sx={{ marginLeft: 'auto' }}>
                  <IntuIconButton
                    size="small"
                    type="submit"
                    variant="outlined"
                    tooltipTitle={transButtons('confirm')}
                    onClick={() => onConfirm(formik)}
                    disabled={!isValid}
                    isLoading={isUploading}
                  />
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default UploadDocumentDialog;
