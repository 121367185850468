import { Grid, Stack } from '@mui/material';
import WarrantyTermsComponent from '../../warrantyTerms/WarrantyTermsComponent';

const WarrantyTermsContent = () => {
  return (
    <Grid container direction="column">
      <Stack spacing={3}>
        <Grid item xs={12}>
          <WarrantyTermsComponent />
        </Grid>
      </Stack>
    </Grid>
  );
};

export default WarrantyTermsContent;
