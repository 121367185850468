import { useMemo, useState, useEffect, createContext } from 'react';
import {
  Box,
  Button,
  Chip,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import i18n from '../../../i18n.js';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import AddAttributeDialog from '../dialogs/AddAttributeDialog';
import { useFormikContext } from 'formik';
import { Cancel } from '@mui/icons-material';
import { useTheme } from '@emotion/react';
import { toTitleCase } from '../../../helpers/toTitleCase';
import { ExtractDataDialog } from '../dialogs/ExtractDataDialog';
import {
  listAttributes,
  listAttributeTypes,
} from '../../../api/products/attributes/attributeRoutes';

export const AttributeContainerContext = createContext();

const formatAttribute = (attribute) => {
  if (attribute.includes('_')) {
    return attribute
      .split('_')
      .map((str) => `${str[0].toUpperCase()}${str.slice(1)}`)
      .join(' ');
  }

  return `${attribute[0].toUpperCase()}${attribute.slice(1)}`;
};

const formatAttributeValue = (attributeValue) => {
  if (
    typeof attributeValue === 'string' ||
    typeof attributeValue === 'number'
  ) {
    return toTitleCase(attributeValue);
  }

  if (Array.isArray(attributeValue)) {
    const [key] = Object.keys(attributeValue[0]);
    const value = attributeValue[0][key];
    return `${key} - ${value}`;
  }
};

const AttributeContainer = ({
  displayTitle = false, // Wheter or not to display the title
  transNS = 'containers',
  transPrefix = 'AttrinbutesContainer',
}) => {
  const { values, setValues } = useFormikContext();
  const { t: transButtons } = useTranslation('buttons');
  const { t: transTypes } = useTranslation('types');
  const { t: transContainers } = useTranslation(transPrefix, {
    keyPrefix: transNS,
  });

  const theme = useTheme();

  const [isExtractDataDialogOpen, setIsExtractDataDialogOpen] = useState(false);
  const [selectedAttributeType, setSelectedAttributeType] = useState(null);
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const [selectedAttributeValue, setSelectedAttributeValue] = useState(null);
  const [selectedAttributes, setSelectedAttributes] = useState(
    new Set(values.product_attributes),
  );

  const [editingAttribute, setEditingAttribute] = useState(null);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);

  // Product Attribute Options
  const [productAttributeTypeOptions, setProductAttributeTypeOptions] =
    useState(new Set());

  const [productAttributeOptions, setProductAttributeOptions] = useState(
    new Set(),
  );

  const [productAttributeValueOptions, setProductAttributeValueOptions] =
    useState(new Set());

  // Get Attribute Type Options
  const getAttributeTypeOptions = async () => {
    try {
      const { data, statusCode } = await listAttributeTypes();
      if (statusCode === 200) {
        setProductAttributeTypeOptions(data);
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  // Get Attribute Options
  const getAttributeOptions = async () => {
    try {
      const { data, statusCode } = await listAttributes();
      if (statusCode === 200) {
        setProductAttributeOptions(data);
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  useEffect(() => {
    getAttributeTypeOptions();
    getAttributeOptions();
  }, [i18n.language, isAddDialogOpen]);

  const handleAttributeDelete = (attribute) => {
    setSelectedAttributes((prevState) => {
      const newAttributes = new Set(prevState);
      newAttributes.delete(attribute);
      return newAttributes;
    });
  };

  // Update Product Attributes
  useEffect(() => {
    // console.log(
    //   'Received new set of selected attributes to update product values -> ',
    //   selectedAttributes,
    // );
    setValues({
      ...values,
      product_attributes: Array.from(selectedAttributes),
    });
    // console.log('New Product Attribute Values -> ', values);
  }, [selectedAttributes]);

  const handleChipSelect = (attribute) => {
    setEditingAttribute(attribute);
    setSelectedAttributeType(attribute.attribute_type);
    setSelectedAttribute(attribute.attribute);
    setSelectedAttributeValue(attribute.value);
  };

  const handleAddAttribute = () => {
    setIsAddDialogOpen(true);
  };

  const handleAttributeTypeSelect = (newAttributeType) => {
    setSelectedAttributeType(newAttributeType);
    setSelectedAttribute(null);
  };

  const selectedTypeSet = useMemo(() => {
    const set = new Set();
    selectedAttributes.forEach((a) => {
      set.add(a.attribute_type);
    });
    return set;
  }, [selectedAttributes]);

  const handleExtractData = () => {
    setIsExtractDataDialogOpen(true);
  };

  // Attribute Names
  const attributeTypeLookup = Object.fromEntries(
    productAttributeTypeOptions.map((at) => [at._id, at]),
  );

  // console.log('Attribute Container Form Values -> ', values);

  return (
    <AttributeContainerContext.Provider
      value={{
        selectedAttributeType,
        setSelectedAttributeType,
        selectedAttribute,
        setSelectedAttribute,
        selectedAttributeValue,
        setSelectedAttributeValue,
        editingAttribute,
        setEditingAttribute,
        isAddDialogOpen,
        setIsAddDialogOpen,
        formatAttribute,
        formatAttributeValue,
        handleAttributeTypeSelect,
        productAttributeTypeOptions,
        setProductAttributeTypeOptions,
        productAttributeOptions,
        setProductAttributeOptions,
        productAttributeValueOptions,
        setProductAttributeValueOptions,
        isExtractDataDialogOpen,
        setIsExtractDataDialogOpen,
        selectedAttributes,
        setSelectedAttributes,
      }}
    >
      {/* Product Attributes */}
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        sx={{ mb: '1rem' }}
      >
        {displayTitle && (
          <Typography
            variant="h6"
            textTransform="none"
            fontSize="1.2rem"
            mr={2}
          >
            {transContainers('title')}
          </Typography>
        )}
        {/* Action Buttons */}
        <Stack direction="row" spacing={1} sx={{ marginLeft: 'auto' }}>
          <Button
            type="connect"
            variant="outlined"
            endIcon={<AddIcon sx={{ color: 'primary.main' }} />}
            onClick={() => handleAddAttribute()}
          >
            {transButtons('add', { type: transTypes('attribute') })}
          </Button>
          <Button
            type="extract"
            color="tertiary"
            variant="outlined"
            onClick={handleExtractData}
          >
            {transButtons('extract', {
              type: transTypes('datasheet'),
            })}
          </Button>
        </Stack>
      </Box>

      <Box
        minHeight={350}
        width="100%"
        bgcolor="background.default"
        mb={2}
        p={2}
        boxShadow={theme.shadows[6]}
        borderRadius="5px"
      >
        <Box mb={2}>
          {selectedTypeSet.size
            ? selectedTypeSet.map((selectedType, index) => {
                const attributeType = attributeTypeLookup[selectedType];
                const attributesArray = Array.from(selectedAttributes);

                return (
                  <Box key={index}>
                    <Typography textTransform="capitalize" my={1}>
                      {attributeType?.name}:
                    </Typography>
                    {attributesArray
                      ?.filter((a) => a.attribute_type === selectedType)
                      ?.map((selectedAttribute, index) => {
                        return (
                          <Chip
                            key={index}
                            label={`${selectedAttribute.name} - ${selectedAttribute.values[0].unit ? `${selectedAttribute.values[0].value} ${selectedAttribute.values[0].unit} ` : selectedAttribute.values[0].value} `}
                            variant="outlined"
                            clickable
                            onClick={() => handleChipSelect(selectedAttribute)}
                            onDelete={() => {}}
                            deleteIcon={
                              <Box>
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    handleAttributeDelete(selectedAttribute)
                                  }
                                >
                                  <Cancel
                                    sx={{
                                      color: 'grey.light',
                                    }}
                                  />
                                </IconButton>
                              </Box>
                            }
                            sx={{
                              mb: 1,
                              mr: 1,
                              background: (theme) =>
                                theme.palette.disabled.main,
                              color: (theme) => theme.palette.grey.main,
                            }}
                          />
                        );
                      })}
                  </Box>
                );
              })
            : null}
        </Box>
        {/* Add Attribute Dialog */}
        <AddAttributeDialog />

        {/* Extract Dialog */}
        <ExtractDataDialog />
      </Box>
    </AttributeContainerContext.Provider>
  );
};

export default AttributeContainer;
