import { useContext, useEffect, useRef, useState } from 'react';
import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigationFooter } from '../../context/footer/NavigationFooterContext';
import { Formik } from 'formik';
import DifferentAddressCheckbox from '../../components/checkout/DifferentAddressCheckbox';
import { useTranslation } from 'react-i18next';
import { createCheckoutPageValidationSchema } from './validationSchema';
import UserContext from '../../context/users/UserInfoProvider';
import { getUserPhoneDetails } from '../../helpers/getUserPhoneDetails';
import { getUserCountryDetails } from '../../helpers/getUserCountryDetails';
import RegularTextField from '../../components/fields/text/RegularTextField';
import IndividualFirstNameField from '../../components/account/individual/IndividualFirstNameField';
import IndividualLastNameField from '../../components/account/individual/IndividualLastNameField';
import AddressField from '../../components/address/AddressField';
import PhoneField from '../../components/account/PhoneField ';
import EmailField from '../../components/account/fields/email/EmailField';
import { toTitleCase } from '../../helpers/toTitleCase';
import OrderTotalsContainer from '../../components/checkout/container/OrderTotalsContainer';
import { CartContext } from '../../context/cart/CartContext';
import { useNavigate } from 'react-router-dom';

const CheckoutPage = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.CheckoutPage',
  });
  const { t: transTypes } = useTranslation('types');

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  const { setFooterConfig } = useNavigationFooter();
  const { user } = useContext(UserContext);
  const { cartProducts } = useContext(CartContext);

  const formikRef = useRef(null);

  const countryCode = user.location.country || 'US';
  const userPhoneDetails = getUserPhoneDetails(countryCode);
  const userCountryDetails = getUserCountryDetails(user);
  const navigate = useNavigate();

  const billingAddress = {
    line1: '',
    line2: '',
    city: '',
    state: '',
    postcode: '',
    country: userCountryDetails,
  };

  const shippingAddress = {
    line1: '',
    line2: '',
    city: '',
    state: '',
    postcode: '',
    country: userCountryDetails,
  };

  const billingIndividual = {
    first_name: '',
    last_name: '',
    company: '',
    email: '',
    phone: userPhoneDetails,
  };

  const shippingIndividual = {
    first_name: '',
    last_name: '',
    company: '',
    email: '',
    phone: userPhoneDetails,
  };

  const [isDifferentAddress, setIsDifferentAddress] = useState(false);

  // Setup navigation footer
  useEffect(() => {
    setFooterConfig((prev) => ({
      ...prev,
      showFooter: true,
    }));
    return () => {
      setFooterConfig((prev) => ({ ...prev, showFooter: false }));
    };
  }, []);

  // Guard Clause on Empty Cart
  useEffect(() => {
    // console.log('Running Guard Clause on empty products -> ', cartProducts);
    if (!cartProducts.length) {
      console.log(navigate);
      navigate('/cart');
    }
  });

  // Set Billing Details if Shipping Address is not set to Different
  const handleBillingAddressFieldBlur = async () => {
    const { values, setValues, setTouched, setFieldValue, validateForm } =
      formikRef.current;

    if (!isDifferentAddress) {
      setFieldValue('shippingAddress', values.billingAddress);
      setFieldValue('shippingIndividual', values.billingIndividual);

      const timer = setTimeout(() => {
        if (formikRef.current) {
          const { setTouched, validateForm, values } = formikRef.current;

          setTouched(
            {
              billingAddress: {
                line1: true,
                line2: true,
                city: true,
                state: true,
                postcode: true,
                country: true,
              },
              billingIndividual: {
                first_name: true,
                last_name: true,
                company: true,
                email: true,
                phone: true,
              },
              ...(isDifferentAddress && {
                shippingAddress: {
                  line1: true,
                  line2: true,
                  city: true,
                  state: true,
                  postcode: true,
                  country: true,
                },
                shippingIndividual: {
                  first_name: true,
                  last_name: true,
                  company: true,
                  email: true,
                  phone: true,
                },
              }),
            },
            true,
          );

          validateForm(values);
        }
      }, 400);
    }
  };

  const handleBillingFirstNameFieldBlur = (e) => {
    const proper = toTitleCase(e.target.value);
    const { values, setValues } = formikRef.current;

    if (!isDifferentAddress) {
      setValues({
        ...values,
        shippingIndividual: {
          ...values.shippingIndividual,
          first_name: proper,
        },
      });
    }
  };

  const handleBillingLastNameFieldBlur = (e) => {
    const proper = toTitleCase(e.target.value);
    const { values, setValues } = formikRef.current;

    if (!isDifferentAddress) {
      setValues({
        ...values,
        shippingIndividual: {
          ...values.shippingIndividual,
          last_name: proper,
        },
      });
    }
  };

  const handleBillingEmailFieldBlur = (e) => {
    const { values, setValues } = formikRef.current;

    const email = e.target.value.toLowerCase();

    if (!isDifferentAddress) {
      setValues({
        ...values,
        shippingIndividual: {
          ...values.shippingIndividual,
          email: email,
        },
      });
    }
  };

  const handleBillingPhoneFieldBlur = () => {
    const { values, setValues } = formikRef.current;

    if (!isDifferentAddress) {
      setValues({
        ...values,
        shippingIndividual: {
          ...values.shippingIndividual,
          phone: values.billingIndividual.phone,
        },
      });
    }
  };

  const handleBillingCompanyNameFieldBlur = (e) => {
    const { values, setValues } = formikRef.current;

    if (!isDifferentAddress) {
      setValues({
        ...values,
        shippingIndividual: {
          ...values.shippingIndividual,
          company: e.target.value,
        },
      });
    }
  };

  // Deal with Shipping Address Checkmark
  useEffect(() => {
    const { values, setValues, setTouched } = formikRef.current;
    if (isDifferentAddress) {
      // Clear Shipping Details
      setValues({
        ...values,
        shippingIndividual,
        shippingAddress,
      });

      setTouched(
        {
          shippingIndividual: true,
          shippingAddress: true,
        },
        true,
      );
    } else {
      // Set Shipping Details
      setValues({
        ...values,
        shippingIndividual: values.billingIndividual,
        shippingAddress: values.billingAddress,
      });
    }

    // Scroll to Shipping Address
    const checkmarkEl = document.getElementById('shipping-address-box');
    if (checkmarkEl && isDifferentAddress) {
      checkmarkEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [isDifferentAddress]);

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.validateForm();
    }
  }, []);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (formikRef.current) {
  //       const { setTouched, validateForm, values } = formikRef.current;

  //       setTouched(
  //         {
  //           billingAddress: {
  //             line1: true,
  //             line2: true,
  //             city: true,
  //             state: true,
  //             postcode: true,
  //             country: true,
  //           },
  //           billingIndividual: {
  //             first_name: true,
  //             last_name: true,
  //             company: true,
  //             email: true,
  //             phone: true,
  //           },
  //           ...(isDifferentAddress && {
  //             shippingAddress: {
  //               line1: true,
  //               line2: true,
  //               city: true,
  //               state: true,
  //               postcode: true,
  //               country: true,
  //             },
  //             shippingIndividual: {
  //               first_name: true,
  //               last_name: true,
  //               company: true,
  //               email: true,
  //               phone: true,
  //             },
  //           }),
  //         },
  //         true,
  //       );

  //       validateForm(values);
  //     }
  //   }, 500); // Wait for autocomplete to populate values

  //   return () => clearTimeout(timer);
  // }, [billingAddress, billingIndividual]);

  return (
    <Box mt={4} px={2} minHeight="60dvh" id={'shipping-address-box'}>
      <Formik
        initialValues={{
          billingAddress,
          shippingAddress,
          billingIndividual,
          shippingIndividual,
        }}
        // enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        validateOnMount={true}
        innerRef={formikRef}
        validationSchema={createCheckoutPageValidationSchema()}
      >
        {(formik) => {
          const { values, errors } = formik;
          console.log('Values -> ', values);
          console.log('Errors -> ', errors);
          return (
            <Box>
              <Box display="flex" justifyContent="center">
                <Typography variant="h2">{t('title')}</Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                gap={2}
                minHeight="60dvh"
              >
                {/* Billing & Shipping Details */}
                <Box
                  flex={1}
                  display="flex"
                  justifyContent="space-evenly"
                  flexDirection={isSmall ? 'column' : 'row'}
                  gap={2}
                >
                  {/* Billing Address */}
                  <Box flex={0.4} display="flex" flexDirection="column" gap={2}>
                    <Typography
                      variant="h6"
                      height={42}
                      display="flex"
                      alignItems="center"
                    >
                      {t('billing_details')}
                    </Typography>

                    {/* First & Last Name */}
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      mt={1}
                      gap={2}
                    >
                      <IndividualFirstNameField
                        fieldID="billingIndividual.first_name"
                        onBlur={handleBillingFirstNameFieldBlur}
                      />

                      <IndividualLastNameField
                        fieldID="billingIndividual.last_name"
                        onBlur={handleBillingLastNameFieldBlur}
                      />
                    </Box>

                    {/* Company Field */}
                    <RegularTextField
                      fieldID="billingIndividual.company"
                      transNS="fields"
                      transPrefix="CompanyNameField"
                      onBlur={handleBillingCompanyNameFieldBlur}
                    />

                    <Divider
                      sx={{
                        mb: 1,
                        mt: 1,
                      }}
                    />

                    {/* Address Field */}
                    <AddressField
                      fieldID="billingAddress"
                      labelID={transTypes('billing_address')}
                      autocompleteID="billingAddress.line1"
                      phoneFieldID="billingIndividual.phone"
                      showLocationMap={true}
                      onBlur={handleBillingAddressFieldBlur}
                    />

                    <Divider
                      sx={{
                        mb: 1,
                        mt: 1,
                      }}
                    />

                    {/* Phone */}
                    <PhoneField
                      fieldID="billingIndividual.phone"
                      transPrefix="customer.PhoneField"
                      transTypeID="billing_contact"
                      onBlur={handleBillingPhoneFieldBlur}
                    />
                    {/* Email */}
                    <EmailField
                      fieldID="billingIndividual.email"
                      transPrefix="customer.EmailField"
                      transTypeID="billing_contact"
                      onBlur={handleBillingEmailFieldBlur}
                    />

                    <DifferentAddressCheckbox
                      isDifferentAddress={isDifferentAddress}
                      setIsDifferentAddress={setIsDifferentAddress}
                    />
                  </Box>

                  {/* Shipping Address */}
                  <Box flex={0.4} display="flex" flexDirection="column" gap={2}>
                    {isDifferentAddress && (
                      <>
                        <Typography
                          variant="h6"
                          height={42}
                          display="flex"
                          alignItems="center"
                        >
                          {t('shipping_details')}
                        </Typography>

                        {/* First & Last Name */}
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          mt={1}
                          gap={2}
                        >
                          <IndividualFirstNameField fieldID="shippingIndividual.first_name" />

                          <IndividualLastNameField fieldID="shippingIndividual.last_name" />
                        </Box>

                        {/* Company Field */}
                        <RegularTextField
                          fieldID="shippingIndividual.company"
                          transNS="fields"
                          transPrefix="CompanyNameField"
                        />

                        <Divider
                          sx={{
                            mb: 1,
                            mt: 1,
                          }}
                        />

                        {/* Address Field */}
                        <AddressField
                          fieldID="shippingAddress"
                          autocompleteID="shippingAddress"
                          labelID={transTypes('shipping_address')}
                          phoneFieldID="shippingIndividual.phone"
                          showLocationMap={true}
                        />

                        <Divider
                          sx={{
                            mb: 1,
                            mt: 1,
                          }}
                        />

                        {/* Phone */}
                        <PhoneField
                          fieldID="shippingIndividual.phone"
                          transPrefix="customer.PhoneField"
                          transTypeID="shipping_contact"
                        />
                        {/* Email */}
                        <EmailField
                          fieldID="shippingIndividual.email"
                          transPrefix="customer.EmailField"
                          transTypeID="shipping_contact"
                        />
                      </>
                    )}
                  </Box>
                </Box>

                <Box sx={{ mt: 4 }}>
                  <OrderTotalsContainer
                    shippingIndividual={shippingIndividual}
                    shippingAddress={shippingAddress}
                    billingIndividual={billingIndividual}
                    billingAddress={billingAddress}
                  />
                </Box>
              </Box>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
};

export default CheckoutPage;
