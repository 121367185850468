// import { useAxiosPrivate } from "../hooks/axios/useAxiosPrivate";
import axios from 'axios';
import { axiosPrivate } from '../../hooks/axios/axios.js';

import i18n from '../../i18n.js';

async function listPublicCustomerInformation() {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = '/api/customers/public/list';

  try {
    const { data } = await axios.get(url, { signal });

    return data;
  } catch (err) {
    console.error('error', err);
    return err;
  } finally {
    controller.abort(signal);
  }
}

export { listPublicCustomerInformation };
