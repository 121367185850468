import React, { useContext, useState } from 'react';
import FormikFieldWrapper from '../formik/FormikFieldWrapper';
import { Skeleton, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import ProcessingContext from '../../context/providers/ProcessingProvider';
import { getNestedValue } from '../../helpers/getNestedValuesIn';
import { CardNumberElement, useElements } from '@stripe/react-stripe-js';
import { StripeElementWrapper, StripeTextField } from '../../mui/inputStyles';

const CreditCardNumberField = ({
  fieldTitle,
  fieldDescription,
  fieldID = 'card_number',
  transNS = 'fields',
  transPrefix = 'CreditCardNumberField',
  transition = true,
  disabled = false,
  required = false,
  variant = 'outlined',
  isLoadingManual = false,
  onValidityChange = () => {}, // New prop to expose validation state
}) => {
  const { t } = useTranslation(transNS, { keyPrefix: transPrefix });
  const { t: transValidations } = useTranslation('validations');
  const elements = useElements();

  const theme = useTheme();

  const {
    values,
    touched,
    errors,
    setFieldError,
    setFieldTouched,
    setFieldValue,
  } = useFormikContext();

  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  const { isLoading } = useContext(ProcessingContext);
  const [isValid, setIsValid] = useState(false);

  const handleChange = (event) => {
    const { complete } = event;
    setIsValid(complete);
    onValidityChange(complete); // Notify parent of the valid state
    setFieldValue(fieldID, complete); // Update Formik value for validation schema
  };

  return isLoading.status || isLoadingManual ? (
    <Skeleton variant="rectangular" width={'100%'} height={60} />
  ) : (
    <FormikFieldWrapper
      fieldTitle={fieldTitle}
      fieldDescription={fieldDescription}
      transition={transition}
    >
      <StripeTextField
        id={fieldID}
        name={fieldID}
        variant={variant}
        required={required}
        label={t('label')}
        InputLabelProps={{ shrink: true }}
        value={fieldValue}
        onBlur={() => {
          const cardNumberElement = elements.getElement('cardNumber');
          if (!cardNumberElement) return;

          if (cardNumberElement._empty) {
            setFieldError(fieldID, transValidations('card_number.required'));
          } else if (cardNumberElement._invalid) {
            setFieldError(fieldID, transValidations('card_number.invalid'));
          } else {
            setFieldError(fieldID, ''); // Clear errors if valid
          }
          setFieldTouched(fieldID, true);
        }}
        InputProps={{
          inputComponent: StripeElementWrapper,
          inputProps: {
            Component: CardNumberElement,
            options: {
              style: {
                base: {
                  color: theme.palette.text.primary,
                  fontSize: '1.2rem',
                  fontWeight: 50,
                  fontFamily: 'AvenirLight, sans-serif',
                  '::placeholder': {
                    color: theme.palette.disabled.primary,
                    fontWeight: 50,
                    opacity: 0.6,
                  },
                },
                invalid: { color: 'error.main' },
              },
              disabled: disabled,
            },
            onChange: handleChange,
          },
        }}
        error={fieldError && fieldTouched ? true : false}
        helperText={fieldError && fieldTouched ? fieldError : null}
      />
    </FormikFieldWrapper>
  );
};

export default CreditCardNumberField;
