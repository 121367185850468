import { createContext, useRef, useState } from 'react';
import {
  Box,
  InputAdornment,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import AddCommissionDialog from './AddCommissionDialog';
import EditCommissionDialog from './EditCommissionDialog';
import IntuIconButton from '../buttons/IntuIconButton';
import { createInitialValuesCommissionsBox } from './InitialValues';
import { createValidationSchemaCommissionBox } from './ValidationSchema';
import { Formik } from 'formik';
import PercentageField from '../fields/number/PercentField';
import AmountField from '../fields/number/AmountField';
import { formatCommissionForDatabase } from './formatCommissionForDatabase';

export const CommissionBoxContext = createContext();

// const commission_schedule = {
//   commission: [
//     { id: 0, max_amount: 1000, commission: 0.45, cost: null },
//     { id: 1, max_amount: 10000, commission: 0.4, cost: null },
//     { id: 2, max_amount: 50000, commission: 0.35, cost: null },
//     { id: 3, max_amount: 100000, commission: 0.3, cost: null },
//     { id: 4, max_amount: 250000, commission: 0.27, cost: null },
//     { id: 5, max_amount: 500000, commission: 0.25, cost: null },
//     { id: 6, max_amount: 1000000, commission: 0.2, cost: null },
//   ],
//   type: 'tiered',
// };

// const handleCommissionTypeChange = (newType) => {
//   setCommissionSchedule({
//     ...commission,
//     type: newType,
//   });
// };

// const handleAddCommission = (commission) => {
//   const newCommission = {
//     ...commission,
//     id: commission_schedule.commission.length,
//   };
//   setCommissionSchedule({
//     commissions: [...product.commission_schedule.commission, newCommission],
//   });
// };

// const handleEditCommission = (commission) => {
//   const newCommissions = product.commission_schedule.commission.map((c) =>
//     c.id === commission.id ? commission : c,
//   );
//   setCommissionSchedule({
//     commissions: newCommissions,
//   });
// };

// const handleDeleteCommission = (commission) => {
//   const newCommissions = product.commission_schedule.commission.filter(
//     (c) => c.id !== commission.id,
//   );
//   setCommissionSchedule({
//     commission_schedule: {
//       ...commission_schedule.type,
//       commission: newCommissions,
//     },
//   });
// };

const CommissionBox = ({
  commissonScheduleTemplate = [], // Template of Commissionn Schedule, used to reset the schedule when changed back from flat to tiered
  commissionSchedule = [], // Array of commission objects with id, max_amount, commission, and cost commissionSchedule === commission_schedule.commission
  commissionType = 'tiered', // String of commissionType, 'flat' | 'tierd', commissionType === commission_schedule.type
  handleCommissionTypeChange = (t) => {}, // Callback to handle changing of commission type
  handleAddCommission = (c) => {}, // Callback to handle adding a new commission
  handleEditCommission = (c) => {}, // Callback to handle editing an existing commission
  handleDeleteCommission = (c) => {}, // Callback to handle deleting an existing commission
  viewMode = 'edit', // The view mode, 'view' or 'edit'
}) => {
  const { t } = useTranslation();
  const { t: transButtons } = useTranslation('buttons');
  const [isAddCommissionDialogOpen, setIsAddCommissionDialogOpen] =
    useState(false);
  const [isEditCommissionDialogOpen, setIsEditCommissionDialogOpen] =
    useState(false);

  const [exampleRevenue, setExampleRevenue] = useState(40000);

  const handleAddCommissionConfirm = ({
    newCommission,
    newCommissionSchedule,
  }) => {
    // Add New commission
    setIsAddCommissionDialogOpen(false);

    const parentCommissionSchudele = formatCommissionForDatabase(
      newCommissionSchedule,
    );
    handleAddCommission({
      newCommission,
      newCommissionSchedule: parentCommissionSchudele,
    });
  };

  const handleEditCommissionClick = () => {
    setIsEditCommissionDialogOpen(true);
  };

  const handleEditCommissionConfirm = ({
    newCommission,
    newCommissionSchedule,
  }) => {
    setIsEditCommissionDialogOpen(false);
    const parentCommissionSchudele = formatCommissionForDatabase(
      newCommissionSchedule,
    );

    handleEditCommission({
      newCommission,
      newCommissionSchedule: parentCommissionSchudele,
    });
  };

  const handleDeleteCommissionConfirm = ({
    commissionToDelete,
    newCommissionSchedule,
  }) => {
    setIsEditCommissionDialogOpen(false);

    const parentCommissionSchudele = formatCommissionForDatabase(
      newCommissionSchedule,
    );

    handleDeleteCommission({
      commissionToDelete,
      newCommissionSchedule: parentCommissionSchudele,
    });
  };

  const handleCommissionTypeChangeSelection = (type, formik) => {
    const { setValues, values } = formik;

    let newCommissionSettings = {
      type: type,
    };

    if (type === 'flat') {
      newCommissionSettings = {
        ...newCommissionSettings,
        commission: 0,
      };

      setValues({
        ...values,
        ...newCommissionSettings,
      });
    } else {
      newCommissionSettings = {
        ...newCommissionSettings,
        commission: Array.isArray(commissonScheduleTemplate)
          ? commissonScheduleTemplate
          : [],
      };

      setValues({
        ...values,
        ...newCommissionSettings,
      });
    }

    handleCommissionTypeChange(newCommissionSettings);
  };

  const createColumns = (formik) => {
    const { setValues } = formik;

    return [
      {
        field: 'max_amount',
        headerName: t('components.commission.table.max_amount'),
        type: 'currency',
        flex: 1,
        minWidth: 150,
        editable: true,
        currencyModel: 'USD',
        valueFormatter: (params) =>
          t('components.commission.table.max_amount_value', {
            amount: new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(params.value),
          }),
      },
      {
        field: 'commission',
        headerName: t('components.commission.table.rate'),
        type: 'number',
        flex: 1,
        minWidth: 150,
        valueFormatter: (params) => `${(params.value * 100).toFixed(2)}%`,
      },
      {
        field: 'cost',
        headerName: t('components.commission.table.cost'),
        type: 'currency',
        flex: 1,
        minWidth: 110,
        currencyModel: 'USD',
        valueFormatter: (params) =>
          new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(params.value),
      },
      {
        field: 'action',
        headerName: t('components.commission.table.action'),
        sortable: false,

        minWidth: 160,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params) => {
          return (
            <IntuIconButton
              size="small"
              type="edit"
              variant="outlined"
              tooltipTitle={transButtons('edit')}
              onClick={() => {
                handleEditCommissionClick(params.row);
                setValues((prevValues) => ({
                  ...prevValues, // Preserve existing form values
                  commission: {
                    max_amount: params.row.max_amount || 0,
                    commission: params.row.commission || 0,
                    cost: params.row.cost || null,
                    index: params.row.index || 0,
                  },
                }));
              }}
              iconOnly
            />
          );
        },
      },
    ];
  };

  const calculateCommissions = (commissionSchedule, exampleRevenue) => {
    if (!commissionSchedule || !Array.isArray(commissionSchedule)) return null;

    // Sort commissionSchedule by max_amount in ascending order
    const sortedCommissions = [...commissionSchedule].sort(
      (a, b) => a.max_amount - b.max_amount,
    );

    // Reindex the commissionSchedule
    const reindexedCommissions = sortedCommissions.map(
      (commission, newIndex) => ({
        ...commission,
        index: newIndex,
      }),
    );

    return reindexedCommissions.map((commission, index, array) => {
      if (!exampleRevenue)
        return {
          ...commission,
          cost: 0,
        };

      const prevMax = array[index - 1]?.max_amount || 0;
      const isLastBracket = index === array.length - 1;

      if (exampleRevenue > prevMax) {
        const amountToApply = isLastBracket
          ? exampleRevenue - prevMax // Don't cap for the last bracket
          : Math.min(exampleRevenue, commission.max_amount) - prevMax;

        return {
          ...commission,
          cost: parseFloat((amountToApply * commission.commission).toFixed(2)),
        };
      }

      return {
        ...commission,
        cost: null,
      };
    });
  };

  const calculateEffectiveCommission = (cost, revenue) => {
    const effectiveCommmission = (cost / revenue).toFixed(2);
    if (!revenue || !cost) return 0;
    return effectiveCommmission;
  };

  const calculateCosts = (calculatedCommissionSchedule) => {
    if (!Array.isArray(calculatedCommissionSchedule)) return 0;
    return calculatedCommissionSchedule.reduce(
      (accumulator, currentValue) => accumulator + currentValue.cost || 0,
      0,
    );
  };

  const handleCalculateCommission = (value, formik) => {
    const { values, setValues } = formik;

    const newExampleRevenue = value;

    const newCommissionSchedule = calculateCommissions(
      commissionSchedule,
      newExampleRevenue,
    );

    if (newCommissionSchedule) {
      const newExampleCost = calculateCosts(newCommissionSchedule);
      const effectiveCommission = calculateEffectiveCommission(
        newExampleCost,
        newExampleRevenue,
      );

      // Update Values
      setValues({
        ...values,
        commission_schedule: newCommissionSchedule,
        example: {
          revenue: exampleRevenue,
          commission: newExampleCost,
          effective_commission: effectiveCommission,
        },
      });
    }
  };

  const formRef = useRef(null);

  const calculatedCommissions = calculateCommissions(
    commissionSchedule,
    exampleRevenue,
  );

  const cost = calculateCosts(calculatedCommissions);
  const percentCommission = calculateEffectiveCommission(cost, exampleRevenue);

  const initialValues = createInitialValuesCommissionsBox(
    commissionType,
    calculatedCommissions,
    cost,
    percentCommission,
    exampleRevenue,
  );

  const validationSchema = createValidationSchemaCommissionBox();

  return (
    <CommissionBoxContext.Provider
      value={{
        calculateCommissions,
        calculateCosts,
        calculateEffectiveCommission,
      }}
    >
      <Box>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Typography variant="h4" textTransform="none" mb={0}>
            {t('components.commission.title')}
          </Typography>
          <Typography variant="body1" textTransform="none">
            {t('components.commission.instructions')}
          </Typography>
        </Box>
        <Formik
          values={initialValues}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnMount={true}
          validateOnChange={true}
          innerRef={formRef}
          enableReinitialize={true}
        >
          {(formik) => {
            const { values, setFieldValue } = formik;
            return (
              <>
                <Box
                  width="100%"
                  my={2}
                  display="flex"
                  gap={4}
                  sx={{ mb: '2rem' }}
                >
                  {/* Select Label */}
                  <Box>
                    <FormControl>
                      <InputLabel id="commission-select">
                        {t('components.commission.type')}
                      </InputLabel>

                      <Select
                        labelId="commission-select"
                        id="select"
                        label={t('components.commission.type')}
                        value={values.type}
                        onChange={(e) => {
                          const type = e.target.value;
                          handleCommissionTypeChangeSelection(type, formik);
                        }}
                        name="type"
                        defaultValue="tiered"
                        disabled={viewMode === 'view'}
                        IconComponent={
                          viewMode === 'view' ? () => null : undefined
                        }
                        sx={{ width: 300 }}
                      >
                        <MenuItem value="tiered">
                          {t('components.commission.tiered')}
                        </MenuItem>
                        <MenuItem value="flat">
                          {t('components.commission.flat')}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                  {/* Commission */}
                  {values.type === 'flat' ? (
                    <Box>
                      <FormControl>
                        <PercentageField
                          fieldID="commission.commission"
                          transNS="fields"
                          transPrefix="PercentageField"
                          transTypeID="commission"
                          allowNegative={false}
                          fixedDecimalScale
                          decimalScale={2}
                          inputAdornment="%"
                          onChange={(e) => {
                            const newCommission = e.target.value / 100;

                            const newCommissionSchedule = {
                              commission: newCommission,
                              type: 'flat',
                            };
                            handleCommissionTypeChange(newCommissionSchedule);
                            setFieldValue(
                              'commission.commission',
                              newCommission,
                            );
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                            sx: {
                              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                                {
                                  WebkitAppearance: 'none',
                                  margin: 0,
                                },
                              '& input[type=number]': {
                                MozAppearance: 'textfield',
                              },
                            },
                          }}
                        />
                      </FormControl>
                    </Box>
                  ) : null}

                  {/* Add Commission */}
                  {viewMode === 'edit' && values.type === 'tiered' ? (
                    <Box alignContent="center">
                      <IntuIconButton
                        type="add"
                        variant="outlined"
                        color="primary"
                        tooltipTitle={t('components.commission.add')}
                        onClick={() => setIsAddCommissionDialogOpen(true)}
                      />
                    </Box>
                  ) : null}
                </Box>

                {/* Commissio Schedule */}
                {values.type === 'tiered' &&
                values.commission_schedule.length > 0 ? (
                  <Box width="100%" sx={{ my: 4 }}>
                    <DataGrid
                      getRowHeight={() => 'auto'}
                      rows={values.commission_schedule}
                      columns={createColumns(formik)}
                      hideFooterPagination
                      hideFooterSelectedRowCount
                      disableRowSelectionOnClick
                      autoWidth
                      disableClickEventBubbling
                      disableColumnMenu
                      getRowId={(row) => row.index}
                      columnVisibilityModel={{
                        action: viewMode === 'edit' ? true : false,
                      }}
                      sx={{
                        '& .MuiDataGrid-cell:focus': {
                          outline: 'none',
                        },
                      }}
                    />
                  </Box>
                ) : null}

                {/* test Commission */}
                <Box width="100%">
                  <Box>
                    <Typography
                      variant="h4"
                      textTransform="none"
                      mb={2}
                      vaiant="body"
                    >
                      {t('components.commission.test.title')}
                    </Typography>
                  </Box>
                  <Box sx={{ mb: '2rem' }}>
                    <Typography mb={2} vaiant="body">
                      {t('components.commission.test.description')}
                    </Typography>
                  </Box>

                  {/* Example Revenue */}
                  <Box>
                    <AmountField
                      fieldID="example.revenue"
                      name="example.revenue"
                      inputAdornment="$"
                      value={values.example.revenue}
                      onValueChange={(values, sourceInfo) => {
                        const value = values.floatValue;

                        setExampleRevenue(values.floatValue);
                        handleCalculateCommission(value, formik);
                      }}
                      sx={{ width: '50%' }}
                    />
                  </Box>
                </Box>

                <Box flex={1} display="flex" flexDirection="column" gap={4}>
                  <Box sx={{ mt: '2rem' }}>
                    <Typography mb={2} variant="body1">
                      {t('components.commission.description')}
                    </Typography>
                  </Box>

                  {/* Example Cost */}
                  <Box display="flex" flexDirection="row" gap={4}>
                    <AmountField
                      disabled
                      variant="standard"
                      fieldID="example.commission"
                      inputAdornment="$"
                      transNS="fields"
                      transPrefix="YourCostAmountField"
                    />

                    <PercentageField
                      disabled
                      variant="standard"
                      fieldID="example.effective_commission"
                      inputAdornment="$"
                      transNS="fields"
                      transPrefix="YourCostPercentField"
                      fixedDecimalScale
                      decimalScale={2}
                      thousandSeparator={false}
                    />
                  </Box>
                </Box>
                <AddCommissionDialog
                  commissions={commissionSchedule}
                  isOpen={isAddCommissionDialogOpen}
                  handleClose={() => setIsAddCommissionDialogOpen(false)}
                  handleConfirm={handleAddCommissionConfirm}
                  handleUpdateRevenueCalculation={() => calculateCommissions}
                />
                <EditCommissionDialog
                  commissions={commissionSchedule}
                  isOpen={isEditCommissionDialogOpen}
                  handleClose={() => setIsEditCommissionDialogOpen(false)}
                  handleConfirm={handleEditCommissionConfirm}
                  handleDelete={handleDeleteCommissionConfirm}
                />
              </>
            );
          }}
        </Formik>
      </Box>
    </CommissionBoxContext.Provider>
  );
};

export default CommissionBox;
