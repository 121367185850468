import React, { useContext } from 'react';
import FormikFieldWrapper from '../../../formik/FormikFieldWrapper';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import {
  Skeleton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@mui/material';
import ProcessingContext from '../../../../context/providers/ProcessingProvider';
import { getNestedValue } from '../../../../helpers/getNestedValuesIn';
import { CompanyProfileContext } from '../../../../context/company/CompanyProfileProvider';
import { EmployeeDetailsDialogContext } from './EmployeeDetailsDialog';

const EmployeeRoleField = ({
  fieldTitle, // Title above the field
  fieldDescription, // Field description above the field
  fieldID = 'role', // Defines the ID and Name of the Field
  transition = true, // Whether the field is being shown and faded in
  disabled = false, // Diables the field
  required = false, // Whether the field is required or not
  variant = 'outlined', //MUI variant
  transNS = 'fields', // Namespace for translation Value
  transPrefix = 'EmployeeRoleField', // KeyPrefix which holds label and placeholder
}) => {
  // Translation
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });
  const { empoyeeRolechange, setEmployeeRoleChange } = useContext(
    EmployeeDetailsDialogContext,
  );
  const { values, touched, errors, handleBlur, setValues } = useFormikContext();
  const { companyProfile } = useContext(CompanyProfileContext);
  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  const { isLoading } = useContext(ProcessingContext);

  return isLoading.status ? (
    <Skeleton variant="rectangular" width={'100%'} height={60} />
  ) : (
    <FormikFieldWrapper
      fieldTitle={fieldTitle}
      fieldDescription={fieldDescription}
      transition={transition}
    >
      <FormControl fullWidth={true}>
        <FormControl
          fullWidth
          variant="outlined"
          required={required}
          disabled={disabled}
          error={fieldError && fieldTouched}
        >
          <InputLabel id="role-label">{t('label')}</InputLabel>
          <Select
            labelId="role-label"
            label={t('label')}
            id={fieldID}
            name={fieldID}
            aria-invalid={!!errors[fieldID]}
            aria-describedby="uidnote"
            value={fieldValue}
            onChange={(e) => {
              setValues({
                ...values,
                role: e.target.value,
              });
              setEmployeeRoleChange(true);
            }}
            onBlur={handleBlur}
            variant={variant}
          >
            <MenuItem className="input-light" key={0} value="">
              {t('options.select')}
            </MenuItem>
            <MenuItem className="input-light" key={1} value="associate">
              {t('options.associate')}
            </MenuItem>
            <MenuItem className="input-light" key={2} value="super-user">
              {t('options.super_user')}
            </MenuItem>
            <MenuItem className="input-light" key={3} value="admin">
              {t('options.admin')}
            </MenuItem>
            {companyProfile.employees.length > 1 && (
              <MenuItem className="input-light" key={5} value="blocked">
                {t('options.blocked')}
              </MenuItem>
            )}
          </Select>
          {fieldError && fieldTouched && (
            <FormHelperText>{fieldError}</FormHelperText>
          )}
        </FormControl>
      </FormControl>
    </FormikFieldWrapper>
  );
};

export default EmployeeRoleField;
