import { Box, Divider, Typography } from '@mui/material';
import { useContext } from 'react';
import { CartContext } from '../../context/cart/CartContext';
import { useTranslation } from 'react-i18next';

const TaxTotalContainer = () => {
  const { taxTotal } = useContext(CartContext);
  const { t } = useTranslation('container', {
    keyPrefix: 'TaxTotalContainer',
  });

  return (
    <Box>
      <Divider />
      <Box
        my={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography
          variant="body1"
          sx={{
            fontWeight: 800,
            textTransform: 'none',
            flex: 1,
            p: 0,
          }}
          flex={1}
          p={0}
        >
          {t('title')}:
        </Typography>
        <Box flex={0.8}>
          <Typography variant="body1" textAlign="right">
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(taxTotal)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TaxTotalContainer;
