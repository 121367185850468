import { useEffect, useState, useMemo, useRef } from 'react';
import { AnimatePresence } from 'framer-motion';
import {
  Box,
  CircularProgress,
  Button,
  useTheme,
  Typography,
} from '@mui/material';
import ProductCard from '../products/ProductCard';
import { useTranslation } from 'react-i18next';
import { getFeaturedProducts } from '../../api/products/productsRoutes';
import i18n from 'i18next';
import { translateMultiLineText } from '../../api/translation/google';

export const FeaturedProductsList = ({
  initialPage = 1,
  initialLimit = 10,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.FeaturedProductsList',
  });
  const { t: transButtons } = useTranslation('buttons');
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    setIsLoading(true);
    getFeaturedProducts({ limit: initialLimit, page: initialPage })
      .then((res) => {
        const productData = res.data;

        setProducts(productData);
      })
      .catch((err) => console.warn(err))
      .finally(() => {
        setIsLoading(false);
      });
  }, [i18n.language]);

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      width="100%"
      mt={4}
    >
      <Typography textAlign="center" variant="h1">
        {t('title')}
      </Typography>
      <Typography textAlign="center" variant="body">
        {t('description')}
      </Typography>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Box width="100%" px={2} mb={4} justifyContent="center">
          <Box
            width="100%"
            display="flex"
            justifyContent="space-evenly"
            flexWrap="wrap"
            mt={4}
            gap={2}
            mb={10}
          >
            <AnimatePresence>
              {products?.length &&
                products.map((product) => (
                  <ProductCard key={product._id} product={product} />
                ))}
            </AnimatePresence>
          </Box>

          <Box textAlign="center">
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{
                padding: '15px 30px',
                fontSize: '1rem',
                backgroundColor: theme?.palette?.primary?.main || '#1976d2',
                '&:hover': {
                  backgroundColor: theme?.palette?.primary?.dark || '#1565c0',
                },
              }}
              onClick={() => (window.location.href = `/products`)}
            >
              {transButtons('discover_more_products')}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};
