import { Box, Chip, Divider, Tab, Tabs, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ProductApplicationsBox = ({
  productApplications = [],
  displayTitle = true,
}) => {
  const theme = useTheme();
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.ProductApplicationsBox',
  });

  return (
    <Box display="flex" flexDirection="column" my={4}>
      {displayTitle && (
        <Box>
          <Tabs
            value={0}
            variant="scrollable"
            sx={{
              '& .MuiTab-root': {
                textTransform: 'none',
                fontSize: '1.25rem',
                color: theme.palette.text.primary,
              },
              '& .Mui-selected': {
                color: theme.palette.text.primary + '!important',
              },
            }}
          >
            <Tab label={t('title')} />
          </Tabs>
          <Divider
            sx={{
              borderBottomWidth: '1px',
              borderColor: theme.palette.divider,
            }}
          />
        </Box>
      )}

      <Box>
        {productApplications.map((application) => {
          return (
            <Chip
              key={application}
              label={application}
              variant="outlined"
              sx={{
                m: 1,
                color: (theme) => theme.palette.tertiary.contrastText,
              }}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default ProductApplicationsBox;
