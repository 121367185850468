import { useEffect, useContext } from 'react';
import { Grid, Container, Typography, Box } from '@mui/material';
import { LayoutBodyContext } from '../../context/layout/LayoutContextProvider';
import IntuThemeContext from '../../context/providers/IntuThemeProvider';

function DashboardLayoutComponent({
  dashboardTitle, // dashboardTitle
  dashboardDescription, // Page Description
  hCentered, // Horizontal center true/false (Boolean)
  vCentered, // Vertical center true/false (Boolean)
  children, // Actual Content as chidlren (React.Element)
}) {
  const { setLayoutTitle, setLayoutDescription } =
    useContext(LayoutBodyContext);

  // Set the Page Title
  useEffect(() => {
    setLayoutTitle(dashboardTitle);
    setLayoutDescription(dashboardDescription);
  }, [dashboardTitle, dashboardDescription]);

  const alignItems = hCentered ? 'center' : 'flex-start';
  const alignText = hCentered ? 'center' : 'left';
  const justifyContent = vCentered ? 'center' : 'flex-start';

  return (
    <Container
      maxWidth="xxl"
      sx={{
        marginTop: { xs: '3rem', md: '5rem' },
        padding: { xs: '0 2rem 0 2rem', md: '0 3rem 0 3rem' },
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems={alignItems}
        justifyContent={justifyContent}
        gap={2}
      >
        <Box>
          {dashboardTitle && (
            <Typography variant="h1" textAlign={alignText}>
              {dashboardTitle}{' '}
            </Typography>
          )}
          {dashboardDescription && (
            <Typography variant="h5" textAlign={alignText}>
              {dashboardDescription}
            </Typography>
          )}
        </Box>
        <Box
          sx={{ width: '100%' }}
          // direction="column-reverse"
          justifyContent={justifyContent}
          alignItems={alignItems}
        >
          {children}
        </Box>
      </Box>
    </Container>
  );
}

export default DashboardLayoutComponent;
