import React, { useContext } from 'react';
import { CompanyProfileContext } from '../../../../../context/company/CompanyProfileProvider';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import CommissionBox from '../../../../commissions/ComissionBox';
import { useFormikContext } from 'formik';

const CompanyCommissionTab = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.account.company.CompanyCommissionTab',
  });
  const { companyProfile } = useContext(CompanyProfileContext);
  const formik = useFormikContext();
  const { values, setValues } = formik;

  // Manage Commission Schedule Changes
  const handleEditCommission = ({ formik, newCommissionSchedule }) => {
    const { values, setValues } = formik;

    setValues({
      ...values,
      commission_schedule: {
        ...values.commission_schedule,
        commission: [...newCommissionSchedule],
      },
    });
  };

  const handleAddCommission = ({ formik, newCommissionSchedule }) => {
    const { values, setValues } = formik;

    setValues({
      ...values,
      commission_schedule: {
        ...values.commission_schedule,
        commission: [...newCommissionSchedule],
      },
    });
  };

  const handleDeleteCommission = (formik, newCommissionSchedule) => {
    const { values, setValues } = formik;

    setValues({
      ...values,
      commission_schedule: {
        ...values.commission_schedule,
        commission: [...newCommissionSchedule],
      },
    });
  };

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Box>
        <CommissionBox
          commissonScheduleTemplate={
            companyProfile.commission_schedule.commission
          }
          commissionSchedule={values.commission_schedule.commission}
          commissionType={values.commission_schedule.type}
          viewMode="edit"
          handleAddCommission={({ newCommissionSchedule, newCommission }) => {
            handleAddCommission({ formik, newCommissionSchedule });
          }}
          handleEditCommission={({ newCommissionSchedule, newCommission }) => {
            handleEditCommission({
              formik,
              newCommissionSchedule,
              newCommission,
            });
          }}
          handleDeleteCommission={({ newCommissionSchedule }) => {
            handleDeleteCommission(formik, newCommissionSchedule);
          }}
          handleCommissionTypeChange={(newCommissionSettings) => {
            setValues({
              ...values,
              commission_schedule: {
                ...newCommissionSettings,
              },
            });
          }}
        />
      </Box>
    </Box>
  );
};

export default CompanyCommissionTab;
