import { useContext, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { FormBodyContext } from '../../context/layout/FormContextProvider';

export function IntuForm({
  formTitle, // Form Description
  formDescription, // Form Description
  formButton, //Form Button
  formType, // Form Type Name
  hCentered, // Horizontal center true/false (Boolean)
  vCentered, // Vertical center true/false (Boolean)
  children, // Actual Content as chidlren (React.Element)
}) {
  const {
    setFormTitle,
    setFormDescription,
    showForm,
    setFormType,
    setFormButton,
  } = useContext(FormBodyContext);

  // Change Form Title and Description on Language Change
  useEffect(() => {
    setFormType(formType);
    setFormTitle(formTitle);
    setFormDescription(formDescription);
    setFormButton(formButton);
  }, [formTitle, formDescription, formButton, formType]);

  const alignItems = hCentered ? 'center' : 'flex-start';
  const alignText = hCentered ? 'center' : 'left';
  const justifyContent = vCentered ? 'center' : 'flex-start';

  return (
    <Grid
      container
      direction="column"
      spacing={4}
      sx={{ width: '100%', mb: { xs: 15 } }}
      alignItems={alignItems}
      justifyContent={justifyContent}
      flexWrap="nowrap"
      margin="0 auto"
    >
      <Grid width="100%" textAlign={alignText} sx={{ marginBottom: 4 }}>
        {formTitle && <Typography variant="h3">{formTitle}</Typography>}
        {formDescription && (
          <Typography variant="body">{formDescription}</Typography>
        )}
      </Grid>

      {showForm && <Grid width="100%">{children}</Grid>}
    </Grid>
  );
}
