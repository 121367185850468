import { createContext, useEffect, useMemo, useState } from 'react';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartProducts, setCartProducts] = useState([]);
  const [manufacturers, setManufacturers] = useState(new Map());
  const [carrier, setCarrier] = useState(null);

  const cartSubTotal = useMemo(() => {
    if (!cartProducts || !cartProducts.length) return 0;

    return cartProducts.reduce((acc, curr) => {
      const hasSalesRep =
        curr.appointedRep?.account_type === 'salesrep' ? true : false;
      // If there is a sales rep add a 3 percent discount
      if (hasSalesRep) {
        const discount = curr.product.list_price * 0.03 * curr.quantity;
        return acc + (curr.quantity * curr.product.list_price - discount);
      }

      return acc + curr.quantity * curr.product.list_price;
    }, 0);
  }, [cartProducts]);

  const cartTotal = useMemo(() => {
    if (!cartProducts || !cartProducts.length) return 0;
    return cartProducts.reduce((acc, curr) => {
      const hasSalesRep =
        curr.appointedRep?.account_type === 'salesrep' ? true : false;
      let price = curr.quantity * curr.product.list_price;
      // If there is a sales rep add a 3 percent discount
      if (hasSalesRep) {
        const discount = curr.product.list_price * 0.03 * curr.quantity;
        price = curr.quantity * curr.product.list_price - discount;
      }

      const shippingCost = curr.selected_shipping
        ? Number(curr.selected_shipping.amountLocal)
        : 0;
      const taxAmount = curr.tax?.tax_amount_exclusive || 0;
      return acc + price + shippingCost + taxAmount;
    }, 0);
  }, [cartProducts]);

  useEffect(() => {
    const manufacturersMap = new Map();
    cartProducts.forEach((product) => {
      const manufacturerId = product.product.manufacturer;
      const manufacturerData = {
        _id: manufacturerId,
        name: product.product.author.manufacturer_name,
        address: product.product.author.manufacturer_address,
        stripe_acc_id: product.product.author.stripe_acc_id,
      };

      if (manufacturersMap.has(manufacturerId)) {
        const currentProducts = manufacturersMap.get(manufacturerId).products;
        currentProducts[product.product._id] = {
          product: product.product,
          quantity: product.quantity,
          appointedRep: product.appointedRep,
        };
      } else {
        manufacturersMap.set(manufacturerId, {
          ...manufacturerData,
          products: {
            [product.product._id]: {
              product: product.product,
              quantity: product.quantity,
              appointedRep: product.appointedRep,
            },
          },
        });
      }
    });
    setManufacturers(manufacturersMap);
  }, [cartProducts]);

  const taxTotal = useMemo(() => {
    return cartProducts.reduce((acc, curr) => {
      const taxAmount = curr.tax?.tax_amount_exclusive / 100 || 0;
      return acc + taxAmount;
    }, 0);
  }, [cartProducts]);

  const eachProductHasShipping = useMemo(() => {
    return cartProducts.every((product) => product.selected_shipping);
  }, [cartProducts]);

  const eachProductHasTax = useMemo(() => {
    return cartProducts.every((product) => product.tax);
  }, [cartProducts]);

  return (
    <CartContext.Provider
      value={{
        cartProducts,
        setCartProducts,
        cartSubTotal,
        cartTotal,
        manufacturers,
        setManufacturers,
        taxTotal,
        eachProductHasShipping,
        eachProductHasTax,
        carrier,
        setCarrier,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
