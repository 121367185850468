import { useContext, useRef, useState } from 'react';
import {
  Autocomplete,
  Box,
  CircularProgress,
  TextField,
  Typography,
  Grid,
  Stack,
  Fade,
} from '@mui/material';
import { classes } from '../../settings/theme.js';
import useResponseHandling from '../../hooks/useResponseHandler.js';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { getNestedValue } from '../../helpers/getNestedValuesIn.js';
import IntuThemeContext from '../../context/providers/IntuThemeProvider.jsx';
import { searchUsers } from '../../api/usersRoutes.js';

export const SearchUserField = ({
  fieldTitle, // Field Title
  fieldDescription, // Field Description
  fieldID = 'user', //Determines the field ID and Name
  autocompleteID = 'user', //Determines the Autocomplete Values
  transNS = 'fields', // Namespace for translation Value
  transPrefix = 'SearchUserField', // KeyPrefix which holds label and placeholder
  transition = true, // Whether the Field transitions fade in, fade out
  disabled = false, // Whether the field is disabled
  required = false, // Whether the Field is required or not
}) => {
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  const { t: transFields } = useTranslation('fields');

  const { values, setValues, errors, touched, setTouched, validateForm } =
    useFormikContext();

  const { handleErrorResponse } = useResponseHandling();

  const fieldValue = getNestedValue(values, fieldID);
  const autocompleteValue = autocompleteID
    ? getNestedValue(values, autocompleteID)
    : {};
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  const { transitionInterval } = useContext(IntuThemeContext);

  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [userOptions, setUserOptions] = useState([]);

  // SertUp Refs
  const userSearchRef = useRef(null);

  return (
    <>
      {fieldTitle && (
        <Fade in={transition} timeout={transitionInterval}>
          <Typography variant="h4" textAlign={{ xs: 'center', md: 'left' }}>
            {fieldTitle}
          </Typography>
        </Fade>
      )}
      {fieldDescription && (
        <Fade in={transition} timeout={transitionInterval}>
          <Typography
            className="form-note"
            textAlign={{ xs: 'center', md: 'left' }}
            sx={{ marginBottom: '1rem' }}
          >
            {fieldDescription}
          </Typography>
        </Fade>
      )}
      {transition && (
        <Stack style={classes.root}>
          <Autocomplete
            freeSolo
            id={autocompleteID}
            name={autocompleteID}
            disabled={disabled}
            autoComplete={false}
            includeInputInList
            filterSelectedOptions
            disableClearable={true}
            loading={isLoadingUser}
            loadingText={t('search.loading')}
            className="form-select-field"
            value={autocompleteValue || null}
            options={userOptions}
            filterOptions={(x) => x}
            getOptionLabel={(option) =>
              option?.label ? option.label : fieldValue
            }
            renderInput={(params) => (
              <TextField
                {...params}
                id={fieldID}
                name={fieldID}
                onChange={async (e) => {
                  let input = e.target.value;

                  if (input.length > 2) {
                    setIsLoadingUser(true);
                    let userSearchFunction = setTimeout(async () => {
                      const payloads = [
                        { key: 'first_name', values: input },
                        { key: 'last_name', values: input },
                        { key: 'email', values: input },
                      ];
                      try {
                        const responses = await Promise.all(
                          payloads.map((payload) => searchUsers(payload)),
                        );
                        const successfulResponses = responses.filter(
                          (response) => response.status === 'success',
                        );
                        const combinedOptions = successfulResponses.flatMap(
                          (response) =>
                            response.data.map((item) => ({
                              ...item,
                              label: item.first_name,
                            })),
                        );
                        const filteredOptions = combinedOptions.filter(
                          (obj, index, self) =>
                            index === self.findIndex((t) => t._id === obj._id),
                        );
                        setUserOptions(filteredOptions);
                      } catch (error) {
                        handleErrorResponse(error);
                      } finally {
                        setIsLoadingUser(false);
                        clearTimeout(userSearchFunction);
                      }
                    }, 100);
                  }
                }}
                variant="outlined"
                required={required}
                autoComplete="off"
                ref={userSearchRef}
                InputProps={{
                  ...params.InputProps,
                  type: 'text',
                  endAdornment: (
                    <>
                      {isLoadingUser ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                label={t('label')}
                placeholder={t('placeholder')}
                error={
                  fieldError && fieldTouched && fieldError && fieldTouched
                    ? true
                    : false
                }
                helperText={fieldError && fieldTouched ? fieldError : null}
              />
            )}
            renderOption={(props, option) => {
              return (
                <li {...props} key={props.id}>
                  <Grid container alignItems="center">
                    <Grid item>
                      <Box key={option._id}>
                        <Grid container justifyContent="space-around">
                          {transFields('name')}:{' '}
                          {option.first_name + ' ' + option.last_name}
                          <br />
                          {transFields('email.label_alt')}: {option.email}
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </li>
              );
            }}
            onChange={(e, option, reason) => {
              setValues({
                first_name: option.first_name,
                last_name: option.last_name,
                name: option.first_name + ' ' + option.last_name,
                email: option.email,
                _id: option._id,
              });
              setTouched({
                first_name: true,
                last_name: true,
                name: true,
                email: true,
                _id: true,
              });

              // Trigger validation for all fields
              validateForm();
            }}
          />
        </Stack>
      )}
    </>
  );
};
