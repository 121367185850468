import { useContext, useEffect, useState } from 'react';
import { Box, InputLabel, MenuItem, Select } from '@mui/material';
import { useAxiosPrivate } from '../../hooks/axios/useAxiosPrivate.js';
import { CartContext } from '../../context/cart/CartContext.jsx';

const CarriersBox = ({ selectedCarrier, setSelectedCarrier }) => {
  const axios = useAxiosPrivate();
  const { setCarrier } = useContext(CartContext);
  const [carriers, setCarriers] = useState([]);

  const getCarriers = async () => {
    try {
      const res = await axios.post('/api/shippo/carriers');
      setCarriers(res.data.data);
    } catch (error) {
      console.error('error', error);
    }
  };

  useEffect(() => {
    getCarriers();
  }, []);

  const handleCarrierChange = (event) => {
    setSelectedCarrier(event.target.value);
    setCarrier(event.target.value);
  };

  if (!carriers.length) return null;
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gap={4}
    >
      <InputLabel id="carrier-select" flex={1} sx={{ minWidth: 180 }}>
        Select a shipping service:
      </InputLabel>
      <Select
        labelId="carrier-select"
        label={'Select a shipping service:'}
        name="carrier"
        value={selectedCarrier}
        onChange={handleCarrierChange}
        sx={{ flex: 1 }}
      >
        {carriers.map((c) => (
          <MenuItem key={c.objectId} value={c}>
            {c.carrierName}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default CarriersBox;
