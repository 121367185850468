import {
  FormControl,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useFormikContext } from 'formik';
import AmountField from '../../fields/number/AmountField.jsx';
import { Trans, useTranslation } from 'react-i18next';
import PercentageField from '../../fields/number/PercentField.jsx';

const ReferralContainer = () => {
  const { values, setValues } = useFormikContext();
  const { t } = useTranslation('container', {
    keyPrefix: 'ReferralContainer',
  });
  const { t: transFields } = useTranslation('fields');

  const handleCheckboxChange = () => {
    setValues({
      ...values,
      fullfillment: {
        ...values.fullfillment,
        intutec: !values.fullfillment?.intutec,
      },
    });
  };

  return (
    <Box
      my={2}
      display="flex"
      flexDirection="column"
      gap={4}
      sx={{ mb: '2rem' }}
    >
      {/* Title */}
      <Box>
        <Typography variant="body" mb={2} color="text.primary">
          <Trans
            t={t}
            i18nKey="description"
            components={{
              ol: (
                <ol
                  style={{
                    paddingLeft: '1.2rem',
                    textAlign: 'left',
                  }}
                />
              ),
              li: (
                <li
                  style={{
                    wordWrap: 'break-word',
                    paddingBottom: '0.5rem',
                  }}
                />
              ),
            }}
          />
        </Typography>
      </Box>

      {/* Referral */}
      <Box sx={{ mb: '1rem' }}>
        <Typography variant="h6">
          {t('referral_terms.lifetime.title')}
        </Typography>
        <FormControlLabel
          control={<Checkbox />}
          label={transFields('ReferralStatus.label')}
          onChange={handleCheckboxChange}
        />
      </Box>

      {/* Campaign Lifetime */}
      <Box sx={{ mb: '2rem' }}>
        <Typography variant="h6">
          {t('referral_terms.lifetime.title')}
        </Typography>
        <Typography>
          <Trans
            t={t}
            i18nKey="referral_terms.lifetime.description"
            components={{
              ul: (
                <ul
                  style={{
                    paddingLeft: '1.2rem',
                    textAlign: 'left',
                  }}
                />
              ),
              li: (
                <li
                  style={{
                    wordWrap: 'break-word',
                    paddingBottom: '0.5rem',
                  }}
                />
              ),
            }}
          />
        </Typography>
        <FormControl sx={{ my: 2, mr: 2 }}>
          <AmountField
            required
            fieldID="referral_terms.lifetime"
            transPrefix="ReferalLifetime"
            transTypeID="reward"
            inputAdornment="days"
            decimalScale={0}
          />
        </FormControl>
      </Box>

      {/* Reward per Click */}
      <Box sx={{ mb: '2rem' }}>
        <Typography variant="h6">{t('referral_terms.cpc.title')}</Typography>
        <Typography>
          <Trans
            t={t}
            i18nKey="referral_terms.cpc.description"
            components={{
              ul: (
                <ul
                  style={{
                    paddingLeft: '1.2rem',
                    textAlign: 'left',
                  }}
                />
              ),
              li: (
                <li
                  style={{
                    wordWrap: 'break-word',
                    paddingBottom: '0.5rem',
                  }}
                />
              ),
            }}
          />
        </Typography>
        <FormControl sx={{ my: 2, mr: 2 }}>
          <AmountField
            required
            fieldID="referral_terms.cpc"
            transPrefix="CostPerClick"
            transTypeID="reward"
            inputAdornment="US$"
            decimalScale={2}
          />
        </FormControl>
      </Box>

      {/* Reward per Action */}
      <Box sx={{ mb: '2rem' }}>
        <Typography variant="h6">{t('referral_terms.cpa.title')}</Typography>
        <Typography>
          <Trans
            t={t}
            i18nKey="referral_terms.cpa.description"
            components={{
              ul: (
                <ul
                  style={{
                    paddingLeft: '1.2rem',
                    textAlign: 'left',
                  }}
                />
              ),
              li: (
                <li
                  style={{
                    wordWrap: 'break-word',
                    paddingBottom: '0.5rem',
                  }}
                />
              ),
            }}
          />
        </Typography>
        <FormControl sx={{ my: 2, mr: 2 }}>
          <AmountField
            required
            fieldID="referral_terms.cpa"
            transPrefix="CostPerClick"
            transTypeID="reward"
            inputAdornment="US$"
            decimalScale={2}
          />
        </FormControl>
      </Box>

      {/* Reward per Order */}
      <Box sx={{ mb: '2rem' }}>
        <Typography variant="h6">{t('referral_terms.cpo.title')}</Typography>
        <Typography>
          <Trans
            t={t}
            i18nKey="referral_terms.cpo.description"
            components={{
              ul: (
                <ul
                  style={{
                    paddingLeft: '1.2rem',
                    textAlign: 'left',
                  }}
                />
              ),
              li: (
                <li
                  style={{
                    wordWrap: 'break-word',
                    paddingBottom: '0.5rem',
                  }}
                />
              ),
            }}
          />
        </Typography>

        {/* Reward per Order */}
        <FormControl sx={{ my: 2, mr: 2 }}>
          <PercentageField
            required
            fieldID="referral_terms.cpo"
            transPrefix="CostPerClick"
            transTypeID="reward"
            inputAdornment="%"
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </FormControl>
      </Box>
    </Box>
  );
};

export default ReferralContainer;
