import { useContext } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import IntuIconButton from '../../buttons/IntuIconButton';
import { CampaignDetailsContext } from '../list/ReferralCampaignActiveList';
import { Share } from '@mui/icons-material';
import ResponseContext from '../../../context/providers/ResponseProvider';

const CampaignDetailsDialog = () => {
  const { campaignDetailsDialog, setCampaignDetailsDialog } = useContext(
    CampaignDetailsContext,
  );

  const { setStatusMsg } = useContext(ResponseContext);

  const { t: transDialogs } = useTranslation('dialogs', {
    keyPrefix: 'CampaignDetailsDialog',
  });
  const { t: transButtons } = useTranslation('buttons');
  const { t: transMessages } = useTranslation('messages');

  const customLink = `https://${window.location.host}/product/${campaignDetailsDialog?.data?.product}&referalID=${campaignDetailsDialog?.data?._id}`;

  const handleShareLink = async () => {
    try {
      await navigator.clipboard.writeText(customLink);
      setStatusMsg({
        open: true,
        status: 'success',
        message: transMessages('copyToClipboard.success'),
      });
    } catch (error) {
      setStatusMsg({
        open: true,
        status: 'error',
        message: transMessages('copyToClipboard.error'),
      });
      console.error('error', error);
    }
  };

  const handleClose = () => {
    setCampaignDetailsDialog({
      ...campaignDetailsDialog,
      detailsDialogOpen: false,
    });
  };

  function getRemainingDays(expiresOn) {
    const expiresDate = new Date(expiresOn);
    const today = new Date();

    // Calculate the difference in milliseconds
    const diffTime = expiresDate - today;

    // Convert milliseconds to days
    const remainingDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return remainingDays;
  }

  return (
    <Dialog
      open={campaignDetailsDialog.detailsDialogOpen}
      onClose={() => handleClose()}
    >
      <DialogTitle>{transDialogs('title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {transDialogs('fields.total', {
            reward: new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(
              campaignDetailsDialog?.data?.referral_performance?.reward,
            ),
          })}
        </DialogContentText>
        <Box
          display="flex"
          flexDirection="column"
          gap={1}
          sx={{ marginTop: '1rem' }}
        >
          {/* Campaign Lifetime */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="body1">
                {transDialogs('fields.lifetime.label')}
              </Typography>
            </Box>
            <Box>
              {transDialogs('fields.lifetime.value', {
                lifetime: getRemainingDays(
                  campaignDetailsDialog?.data?.expires_on,
                ),
              })}
            </Box>
          </Box>

          {/* Reward per Visit */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="body1">
                {transDialogs('fields.rpc', {
                  count:
                    campaignDetailsDialog?.data?.referral_performance?.visits
                      ?.count,
                })}
              </Typography>
            </Box>
            <Box>
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(
                campaignDetailsDialog?.data?.referral_performance?.visits
                  ?.revenue,
              )}
            </Box>
          </Box>

          {/* Reward per Inquiry */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="body1">
                {transDialogs('fields.rpa', {
                  count:
                    campaignDetailsDialog?.data?.referral_performance?.inquiries
                      ?.count,
                })}
              </Typography>
            </Box>
            <Box>
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(
                campaignDetailsDialog?.data?.referral_performance?.inquiries
                  ?.revenue,
              )}
            </Box>
          </Box>

          {/* Reward per order*/}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="body1">
                {transDialogs('fields.rpo', {
                  count:
                    campaignDetailsDialog?.data?.referral_performance?.orders
                      ?.count,
                })}
              </Typography>
            </Box>
            <Box>
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(
                campaignDetailsDialog?.data?.referral_performance?.orders
                  ?.revenue,
              )}
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
        >
          <IntuIconButton
            onClick={() => handleClose()}
            type="cancel"
            tooltipTitle={transButtons('cancel')}
          />
        </Box>

        <Box>
          <IntuIconButton
            size="small"
            disabled={
              campaignDetailsDialog.data?.status === 'completed' ||
              campaignDetailsDialog.data?.status === 'archived'
            }
            type="add"
            variant="outlined"
            tooltipTitle={transButtons('share')}
            onClick={() => handleShareLink()}
            IconComponent={Share}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export { CampaignDetailsDialog };
