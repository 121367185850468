import { Box, MenuItem, Button, Stack, Badge } from '@mui/material';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CartContext } from '../../../context/cart/CartContext';
import IntuIconButton from '../../buttons/IntuIconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const NavBarContent = ({ generalNavItems, mode }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('buttons', {
    keyPrefix: 'navigation',
  });

  const { cartProducts } = useContext(CartContext);

  const numCartItems = useMemo(() => {
    if (!cartProducts || !cartProducts.length) return 0;
    return cartProducts.reduce((acc, curr) => acc + curr.quantity, 0);
  }, [cartProducts]);

  return (
    <Box
      sx={{
        display: { xs: 'none', sm: 'flex' },
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      {/* Cart */}
      {numCartItems > 0 ? (
        <Box mr={2}>
          <Badge badgeContent={numCartItems} color="primary">
            <IntuIconButton
              size="small"
              type="add"
              variant="outlined"
              tooltipTitle={'Cart'}
              onClick={() => navigate('/cart')}
              IconComponent={ShoppingCartIcon}
            />
          </Badge>
        </Box>
      ) : null}

      {/* General Nav Items */}
      {generalNavItems.map((menuItem) =>
        menuItem.visible &&
        (menuItem.location === 'nav' || menuItem.location === 'all') ? (
          <MenuItem
            key={`${mode}.item.${menuItem.index}`}
            sx={{
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <Button
              key={`${mode}.button.${menuItem.index}`}
              variant={menuItem.variant}
              color={menuItem.color}
              type="submit"
              onClick={menuItem.target}
              sx={{
                width: menuItem?.width || '100%',
              }}
            >
              {menuItem.label}
            </Button>
          </MenuItem>
        ) : null,
      )}
    </Box>
  );
};

export default NavBarContent;
