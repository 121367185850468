import { useContext, useEffect } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ConsentDialog from '../../../tos-ppa-ssa/ConsentDialog.jsx';

import { TermsOfServiceAgreement } from '../../../tos-ppa-ssa/TermsOfServiceAccept.jsx';
import PrivacyPolicyAgreement from '../../../tos-ppa-ssa/PrivacyPolicyAccept.jsx';
import StripeServiceAgreement from '../../../tos-ppa-ssa/StripeServiceAccept.jsx';

import { useFormikContext } from 'formik';
import { PrivacyContent } from '../../../../privacy/PrivacyContent.jsx';
import { TermsOfUseContent } from '../../../../terms/TermsOfUseContent.jsx';
import {
  TermsOfServiceContext,
  TermsOfServiceProvider,
} from '../../../../../context/providers/TermsOfServicecontextProvider.jsx';
import TwoFactorVerificationField from '../../../two-factor-authentication/TwoFactorVerificationField.jsx';

const TermsOfServicFormContent = () => {
  // Trranslation
  const { t } = useTranslation('translation', {
    keyPrefix: 'context.account.SignUpComplete.TermsOfServiceForm',
  });

  const { dialogType } = useContext(TermsOfServiceContext);

  const { errors, setFieldError } = useFormikContext();

  useEffect(() => {
    // Set MObile Field on initial error to make sure the request OTP button is hidden
    setFieldError('mobile');
  }, []);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        gap={4}
        sx={{ paddingBottom: '50px' }}
      >
        <Box>
          <TermsOfServiceAgreement
            fieldTitle={t('tos.title')}
            fieldDescription={t('tos.description')}
            required={true}
          />
        </Box>
        <Box>
          <PrivacyPolicyAgreement
            fieldTitle={t('ppa.title')}
            fieldDescription={t('ppa.description')}
            transition={!errors.tos_accepted?.accepted ? true : false}
            required={true}
          />
        </Box>
        <Box>
          <StripeServiceAgreement
            fieldTitle={t('ssa.title')}
            fieldDescription={t('ssa.description')}
            transition={!errors.ppa_accepted?.accepted ? true : false}
            required={true}
          />
        </Box>
        <Box>
          <TwoFactorVerificationField
            fieldTitle={t('2fa.title')}
            fieldDescription={t('2fa.description')}
            fieldID="mobile"
            transition={!errors.ssa_accepted?.accepted ? true : false}
            required={true}
          />
        </Box>
      </Box>
      <ConsentDialog>
        {dialogType === 'ppa' && <PrivacyContent />}
        {dialogType === 'tos' && <TermsOfUseContent />}
      </ConsentDialog>
    </>
  );
};

const TermsOfServiceForm = () => {
  return (
    <TermsOfServiceProvider>
      <TermsOfServicFormContent />
    </TermsOfServiceProvider>
  );
};

export { TermsOfServiceForm };
