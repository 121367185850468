import { useRef } from 'react';
import { Stack, Typography } from '@mui/material';
import AmountField from '../../fields/number/AmountField';
import { Form, Formik } from 'formik';
import { getExchangeSpotRate } from '../../../api/exchangeRates/exchangeRateRoutes';
import { createInitialValueCurrencyConversionContainer } from './initialValues';
import { createValidationSchemaCurrencyConversionContainer } from './validationSchema';

import IntuIconButton from '../../buttons/IntuIconButton';
import { useTranslation } from 'react-i18next';

const CurrencyConversionContainer = ({
  from = 'EUR', // Convert from Currency
  to = 'USD', // Convert To Currency
  amount = 0,
  rate = 1,
  timestamp = null, // ISO Formatted Date YYYY-MM-DD (optional)
  handleConvertedCurrency = () => {},
}) => {
  const { t: transContainers } = useTranslation('container', {
    keyPrefix: 'CurrencyConversionContainer',
  });

  const initialValues = createInitialValueCurrencyConversionContainer({
    from,
    to,
    timestamp,
    rate,
    amount,
  });
  const validationSchema = createValidationSchemaCurrencyConversionContainer();

  const formRef = useRef(null);

  const getExchangeRates = async ({ formik }) => {
    const { values, setValues, setSubmitting } = formik;

    const today = new Date();
    const formattedDate = today.toISOString().slice(0, 10);

    try {
      setSubmitting(true);
      const { data, statusCode } = await getExchangeSpotRate({
        base: values.from,
        date: formattedDate,
      });

      if (statusCode === 200) {
        const [exchangeRate] = Object.entries(data.rates)
          .map(([currency, rate]) => (currency === values.to ? rate : null))
          .filter((rate) => rate !== null);

        const timestamp = new Date(data.timestamp);

        const newCurrencyInformation = {
          ...values,
          rate: exchangeRate,
          timestamp: timestamp,
        };

        setValues(newCurrencyInformation);

        handleConvertedCurrency({ conversionInfo: newCurrencyInformation });
      }
    } catch (error) {
      console.error('error', error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      values={initialValues}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={true}
      validateOnChange={true}
      innerRef={formRef}
      initial
      enableReinitialize={true}
    >
      {(formik) => {
        const {
          values,
          setFieldValue,
          errors,
          handleBlur,
          touched,
          isValid,
          isSubmitting,
        } = formik;

        // console.log('Currency Conversion Container Values -> ', values);

        return (
          <Form>
            <Stack direction="row" spacing={2} sx={{ mb: '1rem' }}>
              {/* Amount Field */}
              {values.from !== 'USD' && (
                <>
                  {/* Amount to Convert */}
                  <AmountField
                    fieldID="amount"
                    transPrefix="products.amount"
                    transTypeID={values.from}
                    value={values.amount}
                    onValueChange={(values) => {
                      setFieldValue('amount', values.floatValue);
                    }}
                    onBlur={handleBlur}
                    decimalScale={2}
                    inputAdornment={null}
                    fixedDecimalScale={true}
                    error={errors.amount && touched.amount}
                    helperText={
                      errors.amount && touched.amount ? errors.amount : ''
                    }
                    sx={{
                      width: 200,
                    }}
                  />

                  {/* Convert Button */}
                  <IntuIconButton
                    type="proceed"
                    tooltipTitle="Convert"
                    size="large"
                    iconOnly
                    isLoading={isSubmitting}
                    disabled={!isValid || values.amount === 0}
                    onClick={() => getExchangeRates({ formik })}
                  />
                </>
              )}
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CurrencyConversionContainer;
