import i18next from 'i18next';
import * as Yup from 'yup';

export const createFileUploadValidationSchema = () => {
  const t = i18next.getFixedT(null, 'validation');
  const transTypes = i18next.getFixedT(null, 'types');

  return Yup.object({
    purpose: Yup.object({
      value: Yup.string().required(t('file.purpose.required')),
    }),
    file_name: Yup.string().required(t('file.file_name')),
    file: Yup.string().required(t('files.file')),
  });
};
