import React, { useContext } from 'react';
import { CompanyProfileContext } from '../../../../../context/company/CompanyProfileProvider';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import CompanyThemeColors from '../../../../account/company/CompanyThemeColors';

const CompanyThemeTab = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.account.company.CompanyThemeTab',
  });
  const { companyProfile } = useContext(CompanyProfileContext);

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Box>
        <CompanyThemeColors
          fieldID="theme"
          required={companyProfile.type === 'manufacturer'}
          disabled={!companyProfile.isAdmin}
          transition={
            companyProfile.isAdmin && companyProfile.type === 'manufacturer'
          }
        />
      </Box>
    </Box>
  );
};

export default CompanyThemeTab;
