import { Box, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useAxiosPrivate } from '../../hooks/axios/useAxiosPrivate';
import { CartContext } from '../../context/cart/CartContext';
import { useFormikContext } from 'formik';
import useResponseHandling from '../../hooks/useResponseHandler';

const OrderTotalsProduct = ({ product }) => {
  const axios = useAxiosPrivate();
  const { setCartProducts } = useContext(CartContext);
  const { values, isValid, validateForm } = useFormikContext();
  const { handleRegularResponse, handleErrorResponse } = useResponseHandling();
  const shippingAddress = values.shippingAddress;

  const getTax = async () => {
    try {
      const payload = {
        ship_from_address: product.ship_from_address,
        ship_to_address: {
          line1: shippingAddress.line1,
          line2: shippingAddress.line2,
          postal_code: shippingAddress.postcode,
          city: shippingAddress.state,
          state: shippingAddress.state,
          country: shippingAddress.country.code,
        },
        product: product.product,
        shipping_cost: product.selected_shipping,
      };

      const { data, status } = await axios.post(
        '/api/stripe/tax/get-tax-single',
        payload,
      );
      // console.log('Raw back tax', data, status);
      // Set tax on product
      if (status === 200) {
        setCartProducts((prev) => {
          return prev.map((p) => {
            if (product.product._id === p.product._id) {
              return {
                ...p,
                tax: data.data,
              };
            } else {
              return p;
            }
          });
        });
      } else {
        handleRegularResponse({
          open: true,
          status: data.status,
          statusCode: data.statusCode,
          message: data.message,
        });
      }
    } catch (error) {
      console.error('error', error);
      handleErrorResponse(error);
    }
  };

  useEffect(() => {
    const handleValidation = async () => {
      validateForm().then((errors) => {
        const hasErrors = Object.keys(errors).length > 0;

        if (!hasErrors && product.selected_shipping && isValid) {
          getTax();
        }
      });
    };

    handleValidation();
  }, [shippingAddress, product.selected_shipping]);

  const subtotal = product.product.list_price * product.quantity;
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      borderRadius="4px"
      p={4}
    >
      <Typography flex={1}>{product.product.product_name}</Typography>
      <Typography flex={0.25} textAlign="right">
        {new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(subtotal)}
      </Typography>
    </Box>
  );
};

export default OrderTotalsProduct;
