// Stripe Context, everything in regards to stripe

import { useState, createContext, useContext, useMemo, useEffect } from 'react';
import { useAxiosPrivate } from '../../../hooks/axios/useAxiosPrivate';
import AuthContext from '../../auth/AuthProvider';
import { UserProfileContext } from '../../users/UserProfileProvider';
import { CompanyProfileContext } from '../../company/CompanyProfileProvider';

export const StripeContext = createContext({});

export const StripeProvider = ({ children }) => {
  const { auth, isAdmin } = useContext(AuthContext);
  const { userProfile } = useContext(UserProfileContext);
  const { companyProfile } = useContext(CompanyProfileContext);

  const axios = useAxiosPrivate();
  const [stripeUserData, setStripeUserData] = useState({
    currently_due: [],
    eventually_due: [],
    past_due: [],
    account_link: '',
    account_complete: false,
    stripe_connect_data: {},
    stripe_customer_data: {},
    active_subscription: false,
  });
  const [isFetched, setIsFetched] = useState(false);

  const getStripeAccountDetails = async () => {
    if (!auth.auth_info || auth?.user_info?.account_type === 'intutec') {
      return;
    }

    const controller = new AbortController();
    const { signal } = controller;

    try {
      setIsFetched(false);

      const payload = {
        host_url: window.location.host,
      };

      const getStripeAccountDetails = await axios.get(
        '/api/stripe/account/retrieve',
        JSON.stringify(payload),
        {
          signal,
        },
      );

      const { statusCode, data } = getStripeAccountDetails.data;

      if (statusCode === 200) {
        // console.log('Stripe User Data -> ', getStripeAccountDetails.data);
        const stripeConnectAccountData = data?.stripe_connect_data;
        const stripeCustomerData = data?.stripe_customer_data;
        let accountLink = '';
        let accountComplete = false;
        let profileCompletion = 0;

        // Set Account Status
        if (stripeConnectAccountData?.id) {
          if (
            stripeConnectAccountData?.currently_due?.length ||
            stripeConnectAccountData?.past_due?.length
          ) {
            const getAccountCompleteLink = await axios.get(
              '/api/stripe/connect/get-onboarding-complete-link',
              {
                params: payload,
                signal,
                headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                },
              },
            );
            const { statusCode, data } = getAccountCompleteLink.data;

            if (statusCode === 200) {
              accountLink = data.url;
            }
          } else {
            accountComplete = true;
          }

          const accountRequirements =
            stripeConnectAccountData?.currently_due?.length || 0;
          if (accountRequirements > 0) {
            const percentage = 100 - accountRequirements;
            if (percentage) {
              profileCompletion = parseFloat(percentage);
            }
          }

          // Safely update state with functional updates
          setStripeUserData(() => ({
            stripe_connect_data: stripeConnectAccountData,
            stripe_customer_data: stripeCustomerData,
            currently_due: stripeConnectAccountData?.currently_due || [],
            eventually_due: stripeConnectAccountData?.eventually_due || [],
            past_due: stripeConnectAccountData?.past_due || [],
            account_link: accountLink,
            account_complete: accountComplete,
            profile_completion: profileCompletion,
          }));
        }
      }
    } catch (error) {
      console.error('error', error);
    } finally {
      controller.abort(signal);
      setIsFetched(true);
    }
  };

  useEffect(() => {
    getStripeAccountDetails();
  }, [auth?.auth_info, userProfile._id, companyProfile._id, isAdmin]);

  const usersActiveSubscriptions = useMemo(() => {
    if (
      !stripeUserData?.stripe_customer_data?.subscriptions?.data ||
      !stripeUserData.stripe_customer_data.subscriptions.data.length
    )
      return false;
    return stripeUserData.stripe_customer_data.subscriptions.data.filter(
      (s) => s.status === 'active',
    );
  }, [stripeUserData]);

  return (
    <StripeContext.Provider
      value={{
        stripeUserData,
        setStripeUserData,
        getStripeAccountDetails,
        usersActiveSubscriptions,
        isFetched,
        setIsFetched,
      }}
    >
      {children}
    </StripeContext.Provider>
  );
};
