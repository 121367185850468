import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import IntuIconButton from '../buttons/IntuIconButton';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { translateMultiLineText } from '../../api/translation/google';

const renderWarrantyTerms = (blocks) => {
  return blocks.map((block, index) => (
    <Typography key={index} variant="body1" paragraph>
      {block.text}
    </Typography>
  ));
};

const WarrantyTermsBox = ({ product, displayTitle = true }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [warrantyTerms, setWarrantyTerms] = useState([]);
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.WarrantyTermsBox',
  });
  const { t: transButtons } = useTranslation('buttons');
  const { t: transTypes } = useTranslation('types');

  const handleClickOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const fetchWarrantyTerms = async () => {
      if (!product || !product.tow || !product.tow.terms) {
        setWarrantyTerms([]);
        return;
      }

      const findLocalTerms = product?.tow?.terms.find(
        (term) => i18n.language === term.code,
      );

      if (findLocalTerms?.term) {
        setWarrantyTerms(findLocalTerms?.term?.blocks || []);
      } else if (product.tow.terms[0]?.term?.blocks) {
        try {
          const standardTermsText = product.tow.terms[0].term.blocks.map(
            (block) => block.text,
          );

          const payload = {
            inputText: standardTermsText,
            targetLanguage: i18n.language,
          };

          const getTranslation = await translateMultiLineText(payload);
          const { data, statusCode } = getTranslation;

          if (statusCode === 200) {
            const translatedBlocks = data.map((text, index) => ({
              key: `${index}`,
              text,
              type:
                product.tow.terms[0].term.blocks[index].type ||
                'unordered-list-item',
              depth: 0,
              inlineStyleRanges: [],
              entityRanges: [],
            }));

            setWarrantyTerms(translatedBlocks);
          } else {
            setWarrantyTerms(product.tow.terms[0]?.term?.blocks || []);
          }
        } catch (error) {
          setWarrantyTerms(product.tow.terms[0]?.term?.blocks || []);
        }
      }
    };

    fetchWarrantyTerms();
  }, [product, i18n.language]);

  return (
    <>
      {displayTitle && (
        <>
          <Typography variant="h6">{t('title')}</Typography>
          <Divider />
        </>
      )}

      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        gap={4}
      >
        {product?.tow?.duration && (
          <Box>
            <Typography variant="body1" mr={2}>
              {t('labels.coverage')}:
            </Typography>
            {product.tow.duration}
          </Box>
        )}
        <Box>
          {/* <Typography variant="body1" mr={2}>
            {t('labels.terms')}
          </Typography> */}
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClickOpen}
          >
            {transButtons('view', {
              type: transTypes('warranty_terms_conditions'),
            })}
          </Button>
        </Box>
      </Box>

      <Dialog open={isOpen} onClose={handleClose} maxWidth="md">
        <DialogTitle display="flex" justifyContent="space-between">
          {t('title')}
          <IntuIconButton
            type="cancel"
            variant="outlined"
            onClick={handleClose}
          />
        </DialogTitle>
        <DialogContent>{renderWarrantyTerms(warrantyTerms)}</DialogContent>
      </Dialog>
    </>
  );
};

export default WarrantyTermsBox;
