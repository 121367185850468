import { useFormikContext } from 'formik';
import CommissionBox from '../../commissions/ComissionBox';
import SearchCompanyCommission from '../SearchCompanyCommission';
import { useContext, useMemo } from 'react';
import { ManageProductContext } from '../../../pages/products/NewProductPage';
import useAuth from '../../../hooks/useAuth';

const CommissionTabContent = () => {
  const { auth } = useAuth();
  const { values, setValues } = useFormikContext();
  const { commissionScheduleTemplate } = useContext(ManageProductContext);

  const isIntutecAdmin = useMemo(() => {
    return auth.user_info.account_type === 'intutec';
  }, [auth.user_info.account_type]);

  const handleAddCommission = ({ newCommissionSchedule }) => {
    setValues({
      ...values,
      commission_schedule: {
        ...values.commission_schedule,
        commission: newCommissionSchedule,
      },
    });
  };

  const handleEditCommission = ({ newCommissionSchedule }) => {
    setValues({
      ...values,
      commission_schedule: {
        commission: newCommissionSchedule,
      },
    });
  };

  const handleDeleteCommission = ({ newCommissionSchedule }) => {
    setValues({
      ...values,
      commission_schedule: {
        commission: newCommissionSchedule,
      },
    });
  };

  const handleCommissionTypeChange = (newCommissionSettings) => {
    setValues({
      ...values,
      commission_schedule: {
        ...newCommissionSettings,
      },
    });
  };

  return (
    <>
      {isIntutecAdmin ? (
        <SearchCompanyCommission setProduct={setValues} />
      ) : null}

      <CommissionBox
        commissonScheduleTemplate={commissionScheduleTemplate.commission}
        commissionSchedule={values.commission_schedule.commission}
        commissionType={values.commission_schedule.type}
        handleCommissionTypeChange={handleCommissionTypeChange}
        handleAddCommission={({ newCommissionSchedule }) =>
          handleAddCommission({ newCommissionSchedule })
        }
        handleEditCommission={({ newCommissionSchedule }) =>
          handleEditCommission({ newCommissionSchedule })
        }
        handleDeleteCommission={({ newCommissionSchedule }) =>
          handleDeleteCommission({ newCommissionSchedule })
        }
      />
    </>
  );
};

export default CommissionTabContent;
