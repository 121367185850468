import { useState, memo, useMemo, useContext } from 'react';
import { Grid, Typography, Box, useTheme, useMediaQuery } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { GridRow, GridColumnHeaders } from '@mui/x-data-grid-premium';
import { Trans, useTranslation } from 'react-i18next';
import IntuIconButton from '../../buttons/IntuIconButton.jsx';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ListLoader from '../../status/ListLoader.jsx';
import { ManageReferralsContext } from '../ManageReferrals.jsx';

const createReferralCampaignColumns = ({ t, handleViewClick, transTypes }) => {
  return [
    {
      field: 'product_name',
      headerName: t('columns.product_name'),
      editable: false,
      minWidth: 100,
      flex: 0.5,
    },
    {
      field: 'referral_performance.visits',
      headerName: t('columns.visits'),
      editable: false,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      minWidth: 70,
      renderCell: (params) => {
        const count = params?.row?.referral_performance?.visits?.count;
        const revenue = params?.row?.referral_performance?.visits?.revenue;
        return (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            textAlign="center"
          >
            <Typography sx={{ fontSize: '0.8rem' }}>
              {count} {transTypes('visits')}
            </Typography>
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(revenue)}
          </Box>
        );
      },
    },
    {
      field: 'referral_performance.inquiries',
      headerName: t('columns.inquiries'),
      editable: false,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      minWidth: 70,
      renderCell: (params) => {
        const count = params?.row?.referral_performance?.inquiries?.count;
        const revenue = params?.row?.referral_performance?.inquiries?.revenue;
        return (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            textAlign="center"
          >
            <Typography sx={{ fontSize: '0.8rem' }}>
              {count} {transTypes('clicks')}
            </Typography>
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(revenue)}
          </Box>
        );
      },
    },
    {
      field: 'referral_performance.order',
      headerName: t('columns.orders'),
      editable: false,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      minWidth: 70,
      renderCell: (params) => {
        const count = params?.row?.referral_performance?.orders?.count;
        const revenue = params?.row?.referral_performance?.orders?.revenue;
        return (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            textAlign="center"
          >
            <Typography sx={{ fontSize: '0.8rem' }}>
              {count} {transTypes('orders')}
            </Typography>
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(revenue)}
          </Box>
        );
      },
    },
    {
      field: 'referral_performance.reward',
      headerName: t('columns.reward'),
      editable: false,
      minWidth: 70,
      valueGetter: (params) => {
        const amount = params.row?.referral_performance?.reward;
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(amount);
      },
    },
    {
      field: 'referral_performance.expires_on',
      headerName: t('columns.expires_on'),
      editable: false,
      valueGetter: (params) => {
        const userLocale = navigator.languages?.[0] || navigator.language; // Get user's locale

        if (!params.row.expires_on) {
          return ''; // Return empty string if no date exists
        }

        const date = new Date(params.row.expires_on); // Convert ISO string to Date object

        if (isNaN(date.getTime())) {
          return 'Invalid Date'; // Handle invalid date cases
        }

        // Format the date in the user's local format
        return new Intl.DateTimeFormat(userLocale, {
          dateStyle: 'medium', // Adjust to 'full', 'long', 'medium', or 'short'
          timeStyle: 'short', // Include time if needed
        }).format(date);
      },
    },

    {
      field: 'view',
      headerName: t('columns.view'),
      sortable: false,
      renderCell: (params) => {
        return (
          <IntuIconButton
            size="small"
            type="info"
            variant="outlined"
            iconOnly={true}
            tooltipTitle={t('buttons.view')}
            onClick={() => handleViewClick(params)}
            IconComponent={VisibilityIcon}
          />
        );
      },
    },
  ];
};

const ReferralCompletedCampaignList = () => {
  const { referralCampaignsCompleted } = useContext(ManageReferralsContext);

  const MemoizedRow = memo(GridRow);
  const MemoizedColumnHeaders = memo(GridColumnHeaders);

  const { t } = useTranslation('translation', {
    keyPrefix: 'components.ManageReferralCapaignsList',
  });
  const { t: transMessages } = useTranslation('messages', {
    keyPrefix: 'referrals.referral_campaigns',
  });
  const { t: transTypes } = useTranslation('types');

  const theme = useTheme();
  const isMediumView = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const handleViewClick = (params) => {
    // navigate(`/product/${params.row._id}`);
    window.open(`/product/${params.row.product}`, '_blank');
  };

  const referralCampaignColumns = useMemo(() => {
    return createReferralCampaignColumns({
      t,
      handleViewClick,
      transTypes,
    });
  }, [t, handleViewClick]);

  return (
    <Grid
      container
      className="content"
      sx={{ marginBottom: '8rem' }}
      spacing={5}
    >
      <Grid item className="content" xs={12}>
        {referralCampaignsCompleted?.fetching ? (
          <ListLoader columns={8} rows={4} />
        ) : !referralCampaignsCompleted?.data?.length ? (
          <Grid item className="title content-centered" xs={12}>
            <Typography variant="body1">
              <Trans t={transMessages} i18nKey="no_complete_records_alt" />
            </Typography>
          </Grid>
        ) : (
          <Box height="auto" width="100%" mb={5}>
            <DataGrid
              isCellEditable={(params) => false}
              getRowId={(row) => row._id}
              rows={referralCampaignsCompleted?.data}
              columns={referralCampaignColumns}
              columnVisibilityModel={{
                list_price: !isMobileView,
                attributes: !isMobileView && !isMediumView,
                applications: !isMobileView && !isMediumView,
                product_group: !isMobileView && !isMediumView,
                mpn: !isMobileView,
              }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
                sorting: {
                  sortModel: [{}],
                },
              }}
              pageSizeOptions={[5, 10, 100]}
              checkboxSelection={false}
              disableRowSelectionOnClick
              disableColumnMenu
              slots={{
                Row: MemoizedRow,
                ColumnHeaders: MemoizedColumnHeaders,
              }}
              sx={{
                borderRadius: '12px',
                boxShadow: 6,
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-cell:focus-within': {
                  outline: 'none',
                },
              }}
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default ReferralCompletedCampaignList;
