const intuGrey = '#2c2c2e';
const intuLighterGrey = '#DEDEDE';
const intuLightGrey = '#4D4D4D';
const intuMiddleGrey = '#6F7978';
const intuDarkGrey = '#1A1A1A';

const intuGreen = '#A8C957';
const intuDarkGreen = '#8AA43B';
const intuLightGreen = '#C3DF7F';

const intuPurple = '#A10976';
const intuLightPurple = '#C13B94';
const intuDarkPurple = '#81035E';

const intuOrange = '#d76446';
const intuLightOrange = '#eb927b';
const intuDarkOrange = '#aa4e35';

const intuBlue = '#57A8C9';
const intuDarkBlue = '#79BCD4';
const intuLightBlue = '#3E7F9A';

const intuRed = '#f44336';
const intuDarkRed = '#D32F2F';
const intuLightRed = '#F6685A';

const intuDisabled = '#ededed';
const intuDisabledDark = '#4D4D4D';
const white = '#f7f7f8';

const intuHoverGreen = '#E8F0D2';
const intuHoverGrey = '#A2A2A2';

export const globalTheme = {
  palette: {
    background: {
      default: white,
      paper: white,
    },
    green: {
      main: '#A8C957',
      light: '#C3DF7F',
      dark: '#8AA43B',
    },
    grey: {
      main: '#333333',
      light: '#4D4D4D',
      middle: '#6F7978',
      dark: '#1A1A1A',
    },
    purple: {
      main: '#A10976',
      light: '#C13B94',

      dark: '#81035E',
    },
    orange: {
      main: '#d76446',
      light: '#eb927b',
      dark: '#aa4e35',
    },
    blue: {
      main: '#57A8C9',
      light: '#79BCD4',
      dark: '#3E7F9A',
    },
    red: {
      main: '#f44336',
      light: '#D32F2F',
      dark: '#F6685A',
    },
    primary: {
      main: intuGreen,
      dark: intuDarkGreen,
      light: intuLightGreen,
      contrastText: white,
      backgroundColor: white,
    },
    secondary: {
      main: intuGrey,
      light: intuLightGrey,
      dark: intuDarkGrey,
      contrastText: white,
      backgroundColor: white,
    },
    tertiary: {
      main: intuPurple,
      dark: intuDarkPurple,
      light: intuLightPurple,
      contrastText: white,
      backgroundColor: white,
    },
    success: {
      main: intuGreen,
      light: intuLightGreen,
      dark: intuDarkGreen,
      contrastText: white,
    },
    hover: {
      primary: intuHoverGreen,
      secondary: intuHoverGrey,
    },
    error: {
      main: intuRed,
      light: intuLightRed,
      dark: intuDarkRed,
      contrastText: white,
    },
    warning: {
      main: intuOrange,
      light: intuLightOrange,
      dark: intuDarkOrange,
      contrastText: intuDarkRed,
    },
    delete: {
      main: intuRed,
      light: intuLightRed,
      dark: intuDarkRed,
      contrastText: white,
    },
    add: {
      main: intuGreen,
      dark: intuDarkGreen,
      light: intuLightGreen,
      contrastText: white,
      backgroundColor: white,
    },
    update: {
      main: intuGreen,
      dark: intuDarkGreen,
      light: intuLightGreen,
      contrastText: white,
      backgroundColor: white,
    },
    edit: {
      main: intuGrey,
      dark: intuDarkGrey,
      light: intuLightGrey,
      contrastText: white,
      backgroundColor: white,
    },
    info: {
      main: intuGrey,
      dark: intuDarkGrey,
      light: intuLightGrey,
      contrastText: white,
      backgroundColor: white,
    },
    connect: {
      main: intuBlue,
      dark: intuDarkBlue,
      light: intuLightBlue,
      contrastText: white,
      backgroundColor: white,
    },
    refresh: {
      main: intuBlue,
      dark: intuDarkBlue,
      light: intuLightBlue,
      contrastText: white,
      backgroundColor: white,
    },
    proceed: {
      main: intuBlue,
      dark: intuDarkBlue,
      light: intuLightBlue,
      contrastText: white,
      backgroundColor: white,
    },
    extract: {
      main: intuPurple,
      dark: intuDarkPurple,
      light: intuLightPurple,
      contrastText: white,
      backgroundColor: white,
    },
    submit: {
      main: intuGreen,
      light: intuLightGreen,
      dark: intuDarkGreen,
      contrastText: white,
    },
    linkedIn: {
      main: '#04669A',
      dark: '#04669A',
      light: '#0a66c2',
      contrastText: white,
    },
    cancel: {
      main: intuLightGrey,
      dark: intuGrey,
      light: intuDarkGrey,
      contrastText: white,
      backgroundColor: intuDarkGrey,
    },
    reset: {
      main: intuBlue,
      light: intuLightBlue,
      dark: intuDarkBlue,
      contrastText: intuDarkGrey,
    },
    disabled: {
      main: intuDisabled,
      dark: intuLightGrey,
      contrastText: intuDarkGrey,
    },
    text: {
      primary: intuDarkGrey,
      secondary: intuDarkGreen,
      disabled: intuDisabledDark,
    },
    action: {
      disabledBackground: intuLightGrey,
      disabled: intuMiddleGrey,
    },
    profile: {
      dark: intuGrey,
      light: intuLighterGrey,
    },
    ar_on: {
      main: intuGrey,
      dark: intuDarkGrey,
      light: intuLightGrey,
      contrastText: white,
      backgroundColor: white,
    },
    ar_off: {
      main: intuRed,
      dark: intuDarkRed,
      light: intuLightRed,
      contrastText: white,
      backgroundColor: white,
    },
    play: {
      main: intuGrey,
      dark: intuDarkGrey,
      light: intuLightGrey,
      contrastText: white,
      backgroundColor: white,
    },
    stop: {
      main: intuRed,
      dark: intuDarkRed,
      light: intuLightRed,
      contrastText: white,
      backgroundColor: white,
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          color: intuDarkGrey,
        },
      },
    },
    // MuiListItemButton: {
    //   styleOverrides: {
    //     root: {
    //       color: intuDarkGrey,
    //       '&:hover': {
    //         color: intuDarkGreen,
    //       },
    //       '&:hover .MuiListItemIcon-root': {
    //         color: intuDarkGreen,
    //       },
    //     },
    //   },
    // },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          color: 'white',
        },
        label: {
          color: intuMiddleGrey,
          '&.Mui-active': {
            color: intuGrey,
          },
          '&.Mui-completed': {
            color: intuGreen,
          },
          '&.Mui-error': {
            color: intuRed,
          },
        },
      },
    },
    MUIRichTextEditor: {
      styleOverrides: {
        editor: {
          color: intuGrey,
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-active': {
            color: intuGrey,
          },
          '&.Mui-completed': {
            color: intuGreen,
          },
          '&.Mui-error': {
            color: intuRed,
          },
        },
        text: {
          fill: intuGreen,
          '&.MuiStepIcon-text': {
            fill: intuGreen,
          },
          '.Mui-active &': {
            fill: white,
          },
          '.Mui-completed &': {
            fill: white,
          },
          '.Mui-error &': {
            fill: intuRed,
          },
        },
      },

      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            // Controls default (unchecked) color for the thumb
            color: '#ccc',
          },
          colorPrimary: {
            '&.Mui-checked': {
              // Controls checked color for the thumb
              color: '#f2ff00',
            },
          },
          track: {
            // Controls default (unchecked) color for the track
            opacity: 0.2,
            backgroundColor: '#fff',
            '.Mui-checked.Mui-checked + &': {
              // Controls checked color for the track
              opacity: 0.7,
              backgroundColor: '#fff',
            },
          },
        },
      },
    },
    MuiAutocompleteDropDown: {
      header: {
        backgroundColor: intuGreen,
        color: white,
      },
      icon: {
        color: intuDarkGrey,
      },
    },
    TextareaAutoSize: {
      styleOverrides: {
        root: {
          color: intuDarkGreen,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backdropFilter: 'blur(10px)',
          backgroundImage: 'none',
          boxShadow: `0px 1px 12px #A8C957`,
          height: '80px',
        },
      },
      menuIcon: {
        color: white,
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: '260px',
          boxSizing: 'border-box',
        },
      },
    },
    // Mui Drop-down menu item
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: intuGrey,
          '& .MuiTypography-body1': {
            color: 'black', // Set the specific color for the DataGrid's menu
          },
          '&:hover': {
            backgroundColor: intuHoverGreen,
          },
        },
      },
    },
    // Nav Footer
    IntuNavFooter: {
      display: 'flex',
      alignItems: 'flex-end',
      bottom: 0,
      position: { xs: 'none', sm: 'absolute', md: 'absolute', lg: 'absolute' },
      height: {
        desktop: '200px',
        mobile: '350px',
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: 'var(--color-divider)',
        },
      },
    },

    MuiDataGrid: {
      border: 'none',
      '& .MuiDataGrid-columnHeader': {
        borderBottom: 'none',
        width: '100%',
      },
      '& .MuiDataGrid-row': {
        width: '100%',
        borderBottom: 'none',
      },
      '& .MuiDataGrid-checkboxInput': {
        color: 'white',
      },
      '& .MuiDataGrid-cell': {
        borderBottom: 'none',
      },
    },
    MuiGrid: {
      variants: [
        {
          props: { variant: 'mobileAlignFix' },
          style: {
            paddingLeft: '0 !important',
          },
        },
        {
          props: { variant: 'mobileWidthFix' },
          style: {
            maxWidth: '100%',
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          textTransform: 'none',
          padding: '8px 15px',
          fontWeight: 500,
          '&.Mui-disabled': {
            backgroundColor: intuDisabled,
            color: intuLightGrey,
          },
        },
      },
      variants: [
        {
          props: { variant: 'submit' },
          style: {
            backgroundColor: intuGreen,
            color: intuLightGrey,
            '&:hover': {
              backgroundColor: intuDarkGreen,
            },
          },
        },
        {
          props: { variant: 'reset' },
          style: {
            backgroundColor: intuOrange,
            color: white,
            '&:hover': {
              backgroundColor: intuDarkOrange,
            },
          },
        },
        {
          props: { variant: 'cancel' },
          style: {
            backgroundColor: 'rgba(243, 244, 246)',
            color: '#4B5563',
            '&:hover': {
              backgroundColor: 'rgba(229, 231, 235)',
            },
          },
        },
      ],
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          '& .MuiPickersCalendarHeader-label': {
            color: 'black',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        expandIconWrapper: {
          right: '49.5%',
          position: 'relative',
        },
        content: {
          width: '100%',
        },
      },
    },
  },
  typography: {
    fontFamily: ['AvenirLight', 'sans-serif'].join(','),
    h1: {
      fontFamily: ['UbuntuRegular', 'sans-serif'].join(','),
      fontStyle: 'normal',
      fontSize: '3rem',
      fontWeight: 400,
      letterSpacing: '-0.04em',
      paddingBottom: '1.5rem',
    },
    h2: {
      fontFamily: ['UbuntuRegular', 'sans-serif'].join(','),
      fontStyle: 'normal',
      fontSize: '2.5rem',
      fontWeight: 300,
      letterSpacing: '-0.04em',
      paddingBottom: '1rem',
    },
    h3: {
      fontFamily: ['UbuntuLight', 'sans-serif'].join(','),
      textTransform: 'uppercase',
      fontStyle: 'normal',
      fontSize: '2.0rem',
      fontWeight: 200,
      letterSpacing: '-0.04em',
      paddingBottom: '1rem',
    },
    h4: {
      fontFamily: ['UbuntuLight', 'sans-serif'].join(','),
      fontStyle: 'normal',
      textTransform: 'uppercase',
      fontWeight: 100,
      fontSize: '1.75rem',
      marginBottom: '1rem',
      '&:after': {
        content: '""',
        display: 'block',
        width: '3em',
        height: '2px',
        left: 0,
        top: '50%',
        textAlign: 'center',
        position: 'relative',
        borderTop: '2px solid var(--color-devider)',
        marginTop: '0.7rem',
      },
    },
    h5: {
      fontFamily: ['UbuntuLight', 'sans-serif'].join(','),
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '1.50rem',
      paddingBottom: '1rem',
    },
    h6: {
      fontFamily: ['UbuntuLight', 'sans-serif'].join(','),
      fontStyle: 'normal',
      textTransform: 'uppercase',
      fontWeight: 100,
      fontSize: '1.25rem',
      paddingBottom: '1rem',
    },
    subtitle1: {
      fontFamily: ['UbuntuLight', 'sans-serif'].join(','),
      fontStyle: 'normal',
      textTransform: 'uppercase',
      fontWeight: 100,
      fontSize: '1.25rem',
      color: 'var(--color-devider)',
      paddingBottom: '1rem',
    },
    statement: {
      fontFamily: 'AvenirLightOblique',
      fontSize: '1.5rem',
      lineHeight: '1.8rem',
      '::before': {
        content: '"\\201D"',
      },
      '::after': {
        content: '"\\201D"',
      },
      display: 'block',
    },
    body: {
      fontFamily: ['AvenirLight', 'sans-serif'].join(','),
      fontSize: '1.2rem',
      lineHeight: '1.3rem',
    },
    body1: {
      fontFamily: ['AvenirLight', 'sans-serif'].join(','),
      fontSize: '1rem',
      // lineHeight: '1.2rem',
    },
    body2: {
      fontFamily: ['AvenirLight', 'sans-serif'].join(','),
      fontSize: '0.8rem',
    },
    dialogBoxTitle: {
      fontFamily: ['UbuntuLight', 'sans-serif'].join(','),
      color: intuGrey,
      margin: 0,
      fontStyle: 'normal',
      fontWeight: 100,
      fontSize: '1.125rem',
      lineHeight: 1.6,
      padding: '24px 0 0 24px',
      flex: '0 0 auto',
    },
    dialogBoxContent: {
      margin: 0,
      fontFamily: ['AvenirLight', 'sans-serif'].join(','),
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    button: {
      fontFamily: ['AvenirLight', 'sans-serif'].join(','),
    },
    caption: {},
    overline: {},
    heroTitleWhite: {
      fontFamily: ['UbuntuLight', 'sans-serif'].join(','),
      fontStyle: 'normal',
      fontSize: '3rem',
      fontWeight: 600,
      letterSpacing: '-0.04em',
      paddingBottom: '1.5rem',
      color: white,
    },
    heroSubtitleWhite: {
      fontFamily: ['UbuntuLight', 'sans-serif'].join(','),
      fontStyle: 'normal',
      textTransform: 'uppercase',
      fontWeight: 600,
      fontSize: '1.25rem',
      paddingBottom: '1rem',
      color: white,
    },
  },
  overrides: {
    MuiGrid: {
      root: {
        '@media (min-width: 1200px)': {
          width: '100%',
          flex: 'auto',
        },
      },
    },
  },
  intuHoverButtonGradiant: [intuGreen, white, intuLightGreen],
};

export const darkTheme = {
  ...globalTheme,
  palette: {
    ...globalTheme.palette,
    mode: 'dark',
    background: {
      default: intuDarkGrey,
      paper: white,
    },
    primary: {
      main: intuGreen,
      light: intuLightGreen,
      dark: intuDarkGreen,
      contrastText: intuDarkGrey,
      backgroundColor: white,
    },
    secondary: {
      main: intuDisabled,
      light: intuDisabled,
      dark: intuLightGrey,
      contrastText: intuGreen,
      backgroundColor: white,
    },
    tertiary: {
      main: intuBlue,
      dark: intuDarkBlue,
      light: intuLightBlue,
      contrastText: white,
      backgroundColor: white,
    },
    edit: {
      main: intuMiddleGrey,
      contrastText: intuGreen,
      dark: intuMiddleGrey,
      light: intuLightGrey,
      backgroundColor: white,
    },
    ar_on: {
      main: intuBlue,
      dark: intuDarkBlue,
      light: intuLightBlue,
      contrastText: white,
      backgroundColor: white,
    },
    ar_off: {
      main: intuRed,
      dark: intuDarkRed,
      light: intuLightRed,
      contrastText: white,
      backgroundColor: white,
    },
    text: {
      primary: white,
      secondary: intuDarkGrey,
      disabled: intuDisabled,
    },
    info: {
      main: white,
      dark: white,
      light: intuLightGrey,
      contrastText: white,
      backgroundColor: white,
    },
    cancel: {
      main: white,
      dark: white,
      light: white,
      contrastText: white,
      backgroundColor: intuDarkGrey,
    },
  },
  typography: {
    ...globalTheme.typography,
    h1: {
      ...globalTheme.typography.h1,
      color: intuGreen,
    },
    h2: {
      ...globalTheme.typography.h2,
      color: intuGreen,
    },
    h3: {
      ...globalTheme.typography.h3,
      color: white,
    },
    h4: {
      ...globalTheme.typography.h4,
      color: white,
    },
    h5: {
      ...globalTheme.typography.h5,
      color: white,
    },
    h6: {
      ...globalTheme.typography.h6,
      color: white,
    },
    body1: {
      ...globalTheme.typography.body1,
      color: white,
    },
    dialogBoxTitle: {
      ...globalTheme.typography.dialogBoxTitle,
      color: white,
    },
    dialogBoxContent: {
      ...globalTheme.typography.dialogBoxContent,
      color: white,
    },
  },
  components: {
    ...globalTheme.components,
    MuiInputBase: {
      styleOverrides: {
        input: {
          // Note: This targets the actual input element
          '&:-webkit-autofill': {
            WebkitBoxShadow: `0 0 0 1000px ${intuDarkGrey} inset !important`, // This ensures it covers the autofill styling
            boxShadow: `0 0 0 1000px ${intuDarkGrey} inset !important`,
            WebkitTextFillColor: `${white} !important`,
            backgroundColor: `${intuDarkGrey} !important`,
            transition: 'background-color 0s',
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: white,
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          background: 'transparent',
          backgroundColor: '#333333',
          fontWeight: 'bold',
        },
      },
    },
    MuiAppBar: {
      ...globalTheme.components.MuiAppBar,
      styleOverrides: {
        ...globalTheme.components.MuiAppBar.styleOverrides,
        root: {
          ...globalTheme.components.MuiAppBar.styleOverrides.root,
          backgroundColor: 'var(--color-navbar-dark-mode)',
          boxShadow: '0px 1px 12px #A8C957',
        },
      },
    },
    IconButton: {
      color: intuGreen,
    },
    // Stepper
    MuiStepLabel: {
      styleOverrides: {
        root: {
          color: 'white',
        },
        label: {
          color: intuMiddleGrey,
          '&.Mui-active': {
            color: white,
          },
          '&.Mui-completed': {
            color: intuGreen,
          },
          '&.Mui-error': {
            color: intuRed,
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-active': {
            color: white,
          },
          '&.Mui-completed': {
            color: intuGreen,
          },
          '&.Mui-error': {
            color: intuRed,
          },
        },
        text: {
          fill: 'var(--color-label-icon-default-text)', // default text color for non-active/non-completed steps
          '&.MuiStepIcon-text': {
            fill: intuGreen,
          },
          '.Mui-active &': {
            fill: intuGrey,
          },
          '.Mui-completed &': {
            fill: white,
          },
          '.Mui-error &': {
            fill: intuRed,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        ...globalTheme.components.MuiMenuItem.styleOverrides,
        root: {
          ...globalTheme.components.MuiMenuItem.styleOverrides.root,
          color: white,
          '& .MuiTypography-body1': {
            color: 'white', // Set the specific color for the DataGrid's menu
          },
          '&:hover': {
            color: intuDarkGrey,
            backgroundColor: intuHoverGreen,
          },
        },
      },
    },
    // Popper
    MuiPopper: {
      styleOverrides: {
        root: {
          // Target the specific input field inside the DataGrid filter popover
          '& .MuiDataGrid-filterForm .MuiInputBase-input': {
            color: 'white !important', // Set color to black for filter input field
          },
          '& .MuiDataGrid-paper': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    // Select Field
    MuiSelect: {
      styleOverrides: {
        root: {
          color: white,
          '& fieldset': {
            borderColor: intuMiddleGrey,
          },
          '&:hover': {
            '&& fieldset': {
              border: `1px solid ${intuLightGrey}`,
            },
          },
          '&.Mui-disabled': {
            '& fieldset': {
              borderColor: intuLightGrey,
            },
          },
        },
      },
    },
    // All Input text
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: intuDisabled,
          },
        },
      },
    },
    // DataGrid Table
    MuiDataGrid: {
      styleOverrides: {
        root: {
          ...globalTheme.components.MuiDataGrid,
          '& .MuiDataGrid-cell': {
            // color: white,
          },
          '& .MuiDataGrid-overlay': {
            // color: white,
          },
          '& .MuiInputBase-input': {
            color: intuDarkGrey,
          },
        },
      },
    },
    // MuiTable Footer
    MuiTablePagination: {
      styleOverrides: {
        root: {
          color: 'white',
        },
      },
    },
    // Autofill Color
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: 'var(--color-inputbox-border-disabled-dark-mode)',
            color: 'var(--color-text-body-dark-mode)',
          },
        },
        input: {
          '&.Mui-disabled': {
            WebkitTextFillColor: 'var(--color-text-body-dark-mode)',
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: intuGreen,
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: white,
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          // Default background color and shadow for all MuiPaper components
          backgroundColor: 'transparent', // transparent background
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', // subtle box shadow for elevation
          backdropFilter: 'blur(15px)', // optional: adds a frosted glass effect

          // Additional styles for 'scrollingCards' class
          '&.scrollingCards': {
            backgroundColor: 'rgba(255, 255, 255, 0.8)', // slight transparency on scrolling cards
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)', // shadow with more opacity
          },

          // Overrides for MuiDialog paper
          '&.MuiDialog-paper': {
            background: 'transparent', // keeping it transparent
            opacity: 0.99, // semi-transparency
            boxShadow: '0px 4px 20px rgba(160, 201, 87, 0.3)', // subtle box shadow for elevation
            color: 'white', // text color
            backdropFilter: 'blur(15px)', // stronger frosted glass effect for dialog
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        markLabel: {
          color: white,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: intuGrey,
          boxShadow: 'none',
        },
      },
    },
    MUIRichTextEditor: {
      styleOverrides: {
        editor: {
          color: white,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: white,
          '&:hover': {
            color: intuDarkGreen,
          },
          '&:hover .MuiListItemIcon-root': {
            color: intuDarkGreen,
          },
        },
      },
    },
  },
};

export const lightTheme = {
  ...globalTheme,
  palette: {
    ...globalTheme.palette,
    mode: 'light',
    primary: {
      main: intuGreen,
      dark: intuDarkGreen,
      light: intuLightGreen,
      contrastText: intuDarkGrey,
      backgroundColor: white,
    },
  },

  typography: {
    ...globalTheme.typography,
    h1: {
      ...globalTheme.typography.h1,
      color: intuGreen,
    },
    h2: {
      ...globalTheme.typography.h2,
      color: intuGreen,
    },
    h3: {
      ...globalTheme.typography.h3,
      color: intuGrey,
    },
    h4: {
      ...globalTheme.typography.h4,
      color: intuGrey,
    },
    h5: {
      ...globalTheme.typography.h5,
      color: intuGrey,
    },
    h6: {
      ...globalTheme.typography.h6,
      color: intuGrey,
    },
    body1: {
      ...globalTheme.typography.body1,
      color: intuGrey,
    },
  },
  components: {
    ...globalTheme.components,
    MuiInputBase: {
      styleOverrides: {
        input: {
          // Note: This targets the actual input element
          '&:-webkit-autofill': {
            WebkitBoxShadow: `0 0 0 1000px ${white} inset !important`, // This ensures it covers the autofill styling
            boxShadow: `0 0 0 1000px ${white} inset !important`,
            WebkitTextFillColor: `${intuDarkGrey} !important`,
            backgroundColor: `${white} !important`,
            transition: 'background-color 0s',
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: intuGrey,
        },
      },
    },
    DrawerListItem: {
      color: 'var(--color-list-item-light-theme)',
    },

    MuiAppBar: {
      ...globalTheme.components.MuiAppBar,
      styleOverrides: {
        ...globalTheme.components.MuiAppBar.styleOverrides,
        root: {
          ...globalTheme.components.MuiAppBar.styleOverrides.root,
          backdropFilter: 'none',
          backgroundColor: '#3e3e3e',
          backgroundImage: 'none',
          boxShadow: `0px 1px 12px #A8C957`,
          height: '80px',
        },
      },
      menuIcon: {
        color: white,
      },
    },

    IconButton: {
      color: intuGreen,
    },
    // Select Field
    MuiSelect: {
      styleOverrides: {
        root: {
          borderColor: intuGreen,
          '& fieldset': {
            borderColor: intuGreen,
          },
          '&:hover': {
            '& fieldset': {
              borderColor: intuLightGreen,
            },
          },
          '&.Mui-disabled': {
            '& fieldset': {
              borderColor: intuGreen,
            },
            '&:hover': {
              '& fieldset': {
                color: intuDarkGreen,
                borderColor: intuGreen,
              },
            },
          },
        },
        select: {
          '&.hover': {
            color: intuGreen,
          },
          '&.Mui-disabled': {
            WebkitTextFillColor:
              'var(--color-inputbox-content-disabled-light-mode)',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: 'var(--color-inputbox-label-light-mode)',
          },
        },
      },
    },
    // Tags in TextFields
    MuiChip: {
      styleOverrides: {
        root: {
          color: 'var(--color-tags-primary-light-mode)',
          backgroundColor: 'var(--color-tags-secondary-light-mode)',
          '&.Mui-disabled': {
            opacity: 1,
            color: 'var(--color-tags-primary-light-mode)',
            backgroundColor: 'var(--color-tags-secondary-light-mode)',
          },
        },
        deleteIcon: {
          color: 'var(--color-tags-primary-light-mode)',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',
          borderTop: '1px solid var(--color-grid-border-light-mode)',
          '& .MuiDataGrid-cell': {
            color: intuDarkGrey,
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
  },
};

export const classes = {
  root: {
    display: 'flex',
  },
  title: {
    flexGrow: 1,
    paddingBottom: '2em',
  },
  content: {
    width: '100vw',
    flexGrow: 1,
    padding: '2em',
    height: '100vh',
    overflow: 'auto',
  },
  paper: {
    padding: 20,
    textAlign: 'center',
    color: 'blue',
    fontFamily: ['AvenirLight', 'sans-serif'].join(','),
  },
};
