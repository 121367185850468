import axios from '../../hooks/axios/useAxios.js';
import i18n from '../../i18n.js';

async function trackActivity({ payload = {} }) {
  // Guard CLause
  const req_fields = [];

  const documentId = payload?.documentId; // Taerget Document ID e.g. referral_id
  !documentId &&
    req_fields.push(
      i18n.t('validations:document', {
        dataType: i18n.t('types:tracker'),
      }),
    );

  const module = payload?.module; // Target Collection Name e.g. referral_campaigns
  !module &&
    req_fields.push(
      i18n.t('validations:module', {
        dataType: i18n.t('types:tracker'),
      }),
    );

  const action = payload?.action; // Actions e.g. click, inqquiry, order, product_view
  !action &&
    req_fields.push(
      i18n.t('validations:action', {
        dataType: i18n.t('types:tracker'),
      }),
    );

  if (req_fields.length > 0) {
    throw new Error(
      i18n.t('validation.missing_fields', { missingFields: req_fields }),
    );
  }

  const tracker = payload?.tracker;
  const additionalParams = payload?.additionalParams; // e.g. { visit:{count: true}}

  const update = payload.update ?? false;

  try {
    const query = {
      documentId,
      module,
      action,
      tracker,
      update,
    };

    const payload = {
      additionalParams,
    };

    const { data, status } = await axios.post(
      `/api/tracker/track`,
      JSON.stringify(payload),
      {
        params: query,
        headers: { 'Content-Type': 'application/json' },
      },
    );

    if (status === 200) {
      return data.data;
    }
    return null;
  } catch (error) {
    console.error('error', error);
    return null;
  }
}

export { trackActivity };
