import { useMemo, useState } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import IntuIconButton from '../../components/buttons/IntuIconButton';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import ClearIcon from '@mui/icons-material/Clear';
import getUserImage from '../../helpers/getUserImage';
import FaceIcon from '@mui/icons-material/Face';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';

const BuyDialog = ({
  isOpen,
  handleConfirm,
  handleClose,
  handleSkip,
  productName,
  quantity,
  productRepresentatives,
}) => {
  const { t: transButtons } = useTranslation('buttons');
  const { t: transDialogs } = useTranslation('dialogs', {
    keyPrefix: 'BuyDialog',
  });

  const [selectedRep, setSelectedRep] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  const isConfirmDisabled = useMemo(() => {
    if (!selectedRep) return true;
    return false;
  }, [selectedRep]);

  const filteredProductRepresentatives = useMemo(() => {
    if (!searchValue.length)
      return productRepresentatives.filter(
        (rep) => rep.account_type !== 'intutec',
      );

    return productRepresentatives.filter((rep) => {
      const name = `${rep.first_name} ${rep.last_name}`;
      return (
        name.toLowerCase().includes(searchValue.toLowerCase()) &&
        rep.account_type !== 'intutec'
      );
    });
  }, [productRepresentatives, searchValue]);

  const handleSelect = (rep) => {
    setSelectedRep(rep);
  };

  const handleUnSelect = () => {
    setSelectedRep(null);
  };

  const handleAppoint = () => {
    handleConfirm(selectedRep);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          textTransform: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {quantity} {productName}
      </DialogTitle>
      <DialogContent
        style={{
          paddingTop: '20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '0 auto',
          width: '80%',
        }}
      >
        <Typography my={2}>{transDialogs('description')}</Typography>
        <TextField
          type="text"
          label="Search for a sales engineer..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {searchValue && (
                  <IconButton
                    aria-label="clear input"
                    onClick={() => setSearchValue('')}
                    edge="end"
                  >
                    <ClearIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
        <Box display="flex" flexDirection="column" width="100%" my={2}>
          <List
            sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}
          >
            {filteredProductRepresentatives?.map((rep) => {
              const isSelected = selectedRep && rep._id === selectedRep._id;
              return (
                <ListItem
                  key={rep._id}
                  sx={{
                    bgcolor: isSelected ? 'primary.main' : 'background.default',
                    border: '1px solid',
                    borderColor: isSelected
                      ? 'secondary.light'
                      : 'primary.main',
                    borderRadius: '5px',
                    my: 2,
                    flex: 0.45,
                  }}
                >
                  <ListItemAvatar>
                    <Avatar src={getUserImage(rep)} alt="profile picture">
                      <FaceIcon sx={{ color: 'primary.dark' }} />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      rep.account_type === 'intutec'
                        ? `${rep.name}`
                        : `${rep.first_name} ${rep.last_name}`
                    }
                    secondary={
                      rep.address
                        ? `${rep?.address?.city}, ${rep.address?.state.toUpperCase()}`
                        : ''
                    }
                  />
                  {isSelected ? (
                    <IntuIconButton
                      size="small"
                      type="edit"
                      variant="outlined"
                      tooltipTitle={'Remove'}
                      onClick={handleUnSelect}
                      IconComponent={ClearIcon}
                      disabled={false}
                    />
                  ) : (
                    <IntuIconButton
                      size="small"
                      type="add"
                      variant="outlined"
                      tooltipTitle={'Select'}
                      onClick={() => handleSelect(rep)}
                      disabled={false}
                    />
                  )}
                </ListItem>
              );
            })}
          </List>
        </Box>
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: 'space-between',
          paddingLeft: '16px',
          paddingRight: '16px',
          paddingBottom: '16px',
        }}
      >
        <Box>
          <IntuIconButton
            size="small"
            variant="outlined"
            tooltipTitle={transButtons('I did not receive support today')}
            onClick={handleSkip}
            IconComponent={SkipNextIcon}
          />
        </Box>
        <Box>
          <IntuIconButton
            size="small"
            type="submit"
            variant="outlined"
            tooltipTitle={transButtons('Appoint & Buy')}
            onClick={handleAppoint}
            disabled={isConfirmDisabled}
            IconComponent={ShoppingCartCheckoutIcon}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default BuyDialog;
