import { Box, Typography } from '@mui/material';
import { useContext } from 'react';
import { CartContext } from '../../context/cart/CartContext';

const CartTotalsBox = () => {
  const { cartSubTotal } = useContext(CartContext);
  return (
    <Box
      display="flex"
      flexDirection="column"
      my={4}
      width="40%"
      margin="0 auto"
      minWidth={400}
    >
      <Typography my={2}>Cart Totals</Typography>
      <Box
        boxShadow={2}
        display="flex"
        border="1px solid"
        borderColor="secondary.light"
        borderRadius="4px"
      >
        <Box
          bgcolor="secondary.light"
          flex={0.4}
          p={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-evenly"
        >
          <Typography my={2} color="primary.light">
            Subtotal
          </Typography>
        </Box>
        <Box
          flex={1}
          p={2}
          display="flex"
          flexDirection="column"
          justifyContent="space-evenly"
        >
          <Typography my={2} fontWeight="bold">
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(cartSubTotal)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CartTotalsBox;
