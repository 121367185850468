export const reindexCommissionSchedule = (commissionSchedule) => {
  // Sort commissions by max_amount in ascending order
  const sortedCommissions = [...commissionSchedule].sort(
    (a, b) => a.max_amount - b.max_amount,
  );

  // Reindex the commissions
  const reindexedCommissions = sortedCommissions.map(
    (exCommission, newIndex) => ({
      ...exCommission,
      index: newIndex,
    }),
  );

  return reindexedCommissions;
};
