import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CompanyProductList from '../../components/products/list/CompanyProductList';
import FormContextProvider from '../../context/layout/FormContextProvider';
import DashboardLayoutComponent from '../../components/layout/DashboardLayout';
import useAuth from '../../hooks/useAuth';
import SalesRepProductList from '../../components/products/list/SalesRepProductList';
import AdminProductList from '../../components/products/list/AdminProductList';

function ManageProductListPage() {
  const { auth } = useAuth();
  const { t } = useTranslation();

  return (
    <FormContextProvider>
      <Container maxWidth="xl" style={{ marginTop: '8rem' }}>
        {auth.user_info.account_type === 'manufacturer' ? (
          <DashboardLayoutComponent
            dashboardTitle={t('pages.CompanyProductsPage.title')}
            dashboardDescription={t('pages.CompanyProductsPage.description')}
          >
            <CompanyProductList />
          </DashboardLayoutComponent>
        ) : auth.user_info.account_type === 'salesrep' ? (
          <DashboardLayoutComponent
            dashboardTitle="Products You Represent"
            dashboardDescription="Share and manage the products you represent"
          >
            <SalesRepProductList />
          </DashboardLayoutComponent>
        ) : auth.user_info.account_type === 'intutec' ? (
          <DashboardLayoutComponent dashboardTitle={'Admin Products'}>
            <AdminProductList />
          </DashboardLayoutComponent>
        ) : null}
      </Container>
    </FormContextProvider>
  );
}

export default ManageProductListPage;
